.placeholder,
.placeholder .image.header:after,
.placeholder .line,
.placeholder .line:after,
.placeholder>:before {
  background-color: #fff;
}
.placeholder {
  position: static;
  overflow: hidden;
  -webkit-animation: placeholderShimmer 2s linear;
  -webkit-animation: placeholderShimmer 2s linear;
  -moz-animation: placeholderShimmer 2s linear;
  -o-animation: placeholderShimmer 2s linear;
  -ms-animation: placeholderShimmer 2s linear;
  animation: placeholderShimmer 2s linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.08)), color-stop(15%, rgba(0,0,0,0.15)), color-stop(30%, rgba(0,0,0,0.08)));
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.08)), color-stop(15%, rgba(0,0,0,0.15)), color-stop(30%, rgba(0,0,0,0.08)));
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.08)), color-stop(15%, rgba(0,0,0,0.15)), color-stop(30%, rgba(0,0,0,0.08)));
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.08)), color-stop(15%, rgba(0,0,0,0.15)), color-stop(30%, rgba(0,0,0,0.08)));
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.08)), color-stop(15%, rgba(0,0,0,0.15)), color-stop(30%, rgba(0,0,0,0.08)));
  background-image: -webkit--webkit-linear-gradient(left, rgba(0,0,0,0.08) 0, rgba(0,0,0,0.15) 15%, rgba(0,0,0,0.08) 30%);
  background-image: -webkit--moz-linear-gradient(left, rgba(0,0,0,0.08) 0, rgba(0,0,0,0.15) 15%, rgba(0,0,0,0.08) 30%);
  background-image: -webkit--o-linear-gradient(left, rgba(0,0,0,0.08) 0, rgba(0,0,0,0.15) 15%, rgba(0,0,0,0.08) 30%);
  background-image: -webkit--ms-linear-gradient(left, rgba(0,0,0,0.08) 0, rgba(0,0,0,0.15) 15%, rgba(0,0,0,0.08) 30%);
  background-image: -webkit-linear-gradient(to right, rgba(0,0,0,0.08) 0, rgba(0,0,0,0.15) 15%, rgba(0,0,0,0.08) 30%);
  background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.08) 0, rgba(0,0,0,0.15) 15%, rgba(0,0,0,0.08) 30%);
  background-image: -moz-linear-gradient(left, rgba(0,0,0,0.08) 0, rgba(0,0,0,0.15) 15%, rgba(0,0,0,0.08) 30%);
  background-image: -o-linear-gradient(left, rgba(0,0,0,0.08) 0, rgba(0,0,0,0.15) 15%, rgba(0,0,0,0.08) 30%);
  background-image: -ms-linear-gradient(left, rgba(0,0,0,0.08) 0, rgba(0,0,0,0.15) 15%, rgba(0,0,0,0.08) 30%);
  background-image: linear-gradient(to right, rgba(0,0,0,0.08) 0, rgba(0,0,0,0.15) 15%, rgba(0,0,0,0.08) 30%);
  -webkit-background-size: 1200px 100%;
  -moz-background-size: 1200px 100%;
  background-size: 1200px 100%;
  max-width: 30rem;
}
.placeholder > div {
  height: 100px;
}
.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #f460a3;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 0.7s linear infinite;
  -moz-animation: spin 0.7s linear infinite;
  -o-animation: spin 0.7s linear infinite;
  -ms-animation: spin 0.7s linear infinite;
  animation: spin 0.7s linear infinite;
}
.loader.cart-loader {
  width: 36px;
  height: 36px;
  display: inline-block;
  vertical-align: middle;
}
.shop-autocomplete-loading:before {
  position: absolute;
  content: '';
  right: 10px;
  top: 50%;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #f3f3f3;
  border-top: 1px solid #f460a3;
  -webkit-animation: spin 0.7s linear infinite;
  -moz-animation: spin 0.7s linear infinite;
  -o-animation: spin 0.7s linear infinite;
  -ms-animation: spin 0.7s linear infinite;
  animation: spin 0.7s linear infinite;
  margin-top: -12.5px;
}
.shop-autocomplete-loading .form-control.autocomplete {
  padding: 11px 45px 12px 10px;
}
.shop-autocomplete-loading.sylius-autocomplete {
  position: relative;
}
.v1 .row,
.v1 .row-simple {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.v1 .row {
  margin: 0 -15px;
}
.v1 .row_offset-bottom {
  margin-bottom: 30px;
}
.v1 .row-simple {
  margin: 0 -6px;
}
.v1 .row-simple [class^="col-"],
.v1 .row-simple [class*=" col-"] {
  padding: 0 6px;
}
.v1 .col-1 {
  width: 8.333333333333334%;
}
@media (max-width: 767px) {
  .v1 .col-1 {
    width: 100%;
  }
}
.v1 .col-2 {
  width: 16.666666666666668%;
}
@media (max-width: 767px) {
  .v1 .col-2 {
    width: 100%;
  }
}
.v1 .col-3 {
  width: 25%;
}
@media (max-width: 767px) {
  .v1 .col-3 {
    width: 100%;
  }
}
.v1 .col-4 {
  width: 33.333333333333336%;
}
@media (max-width: 767px) {
  .v1 .col-4 {
    width: 100%;
  }
}
.v1 .col-5 {
  width: 41.66666666666667%;
}
@media (max-width: 767px) {
  .v1 .col-5 {
    width: 100%;
  }
}
.v1 .col-6 {
  width: 50%;
}
@media (max-width: 767px) {
  .v1 .col-6 {
    width: 100%;
  }
}
.v1 .col-7 {
  width: 58.333333333333336%;
}
@media (max-width: 767px) {
  .v1 .col-7 {
    width: 100%;
  }
}
.v1 .col-8 {
  width: 66.66666666666667%;
}
@media (max-width: 767px) {
  .v1 .col-8 {
    width: 100%;
  }
}
.v1 .col-9 {
  width: 75%;
}
@media (max-width: 767px) {
  .v1 .col-9 {
    width: 100%;
  }
}
.v1 .col-10 {
  width: 83.33333333333334%;
}
@media (max-width: 767px) {
  .v1 .col-10 {
    width: 100%;
  }
}
.v1 .col-11 {
  width: 91.66666666666667%;
}
@media (max-width: 767px) {
  .v1 .col-11 {
    width: 100%;
  }
}
.v1 .col-12 {
  width: 100%;
}
@media (max-width: 767px) {
  .v1 .col-12 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .v1 .col-xs-1 {
    width: 8.333333333333334%;
    max-width: 8.333333333333334%;
    -webkit-flex-basis: 8.333333333333334%;
    flex-basis: 8.333333333333334%;
  }
}
@media (max-width: 767px) {
  .v1 .col-xs-2 {
    width: 16.666666666666668%;
    max-width: 16.666666666666668%;
    -webkit-flex-basis: 16.666666666666668%;
    flex-basis: 16.666666666666668%;
  }
}
@media (max-width: 767px) {
  .v1 .col-xs-3 {
    width: 25%;
    max-width: 25%;
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
  }
}
@media (max-width: 767px) {
  .v1 .col-xs-4 {
    width: 33.333333333333336%;
    max-width: 33.333333333333336%;
    -webkit-flex-basis: 33.333333333333336%;
    flex-basis: 33.333333333333336%;
  }
}
@media (max-width: 767px) {
  .v1 .col-xs-5 {
    width: 41.66666666666667%;
    max-width: 41.66666666666667%;
    -webkit-flex-basis: 41.66666666666667%;
    flex-basis: 41.66666666666667%;
  }
}
@media (max-width: 767px) {
  .v1 .col-xs-6 {
    width: 50%;
    max-width: 50%;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
}
@media (max-width: 767px) {
  .v1 .col-xs-7 {
    width: 58.333333333333336%;
    max-width: 58.333333333333336%;
    -webkit-flex-basis: 58.333333333333336%;
    flex-basis: 58.333333333333336%;
  }
}
@media (max-width: 767px) {
  .v1 .col-xs-8 {
    width: 66.66666666666667%;
    max-width: 66.66666666666667%;
    -webkit-flex-basis: 66.66666666666667%;
    flex-basis: 66.66666666666667%;
  }
}
@media (max-width: 767px) {
  .v1 .col-xs-9 {
    width: 75%;
    max-width: 75%;
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
  }
}
@media (max-width: 767px) {
  .v1 .col-xs-10 {
    width: 83.33333333333334%;
    max-width: 83.33333333333334%;
    -webkit-flex-basis: 83.33333333333334%;
    flex-basis: 83.33333333333334%;
  }
}
@media (max-width: 767px) {
  .v1 .col-xs-11 {
    width: 91.66666666666667%;
    max-width: 91.66666666666667%;
    -webkit-flex-basis: 91.66666666666667%;
    flex-basis: 91.66666666666667%;
  }
}
@media (max-width: 767px) {
  .v1 .col-xs-12 {
    width: 100%;
    max-width: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .col-sm-1 {
    width: 8.333333333333334%;
    max-width: 8.333333333333334%;
    -webkit-flex-basis: 8.333333333333334%;
    flex-basis: 8.333333333333334%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .col-sm-2 {
    width: 16.666666666666668%;
    max-width: 16.666666666666668%;
    -webkit-flex-basis: 16.666666666666668%;
    flex-basis: 16.666666666666668%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .col-sm-3 {
    width: 25%;
    max-width: 25%;
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .col-sm-4 {
    width: 33.333333333333336%;
    max-width: 33.333333333333336%;
    -webkit-flex-basis: 33.333333333333336%;
    flex-basis: 33.333333333333336%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .col-sm-5 {
    width: 41.66666666666667%;
    max-width: 41.66666666666667%;
    -webkit-flex-basis: 41.66666666666667%;
    flex-basis: 41.66666666666667%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .col-sm-6 {
    width: 50%;
    max-width: 50%;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .col-sm-7 {
    width: 58.333333333333336%;
    max-width: 58.333333333333336%;
    -webkit-flex-basis: 58.333333333333336%;
    flex-basis: 58.333333333333336%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .col-sm-8 {
    width: 66.66666666666667%;
    max-width: 66.66666666666667%;
    -webkit-flex-basis: 66.66666666666667%;
    flex-basis: 66.66666666666667%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .col-sm-9 {
    width: 75%;
    max-width: 75%;
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .col-sm-10 {
    width: 83.33333333333334%;
    max-width: 83.33333333333334%;
    -webkit-flex-basis: 83.33333333333334%;
    flex-basis: 83.33333333333334%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .col-sm-11 {
    width: 91.66666666666667%;
    max-width: 91.66666666666667%;
    -webkit-flex-basis: 91.66666666666667%;
    flex-basis: 91.66666666666667%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .col-sm-12 {
    width: 100%;
    max-width: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .col-tablet-1 {
    width: 8.333333333333334%;
    max-width: 8.333333333333334%;
    -webkit-flex-basis: 8.333333333333334%;
    flex-basis: 8.333333333333334%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .col-tablet-2 {
    width: 16.666666666666668%;
    max-width: 16.666666666666668%;
    -webkit-flex-basis: 16.666666666666668%;
    flex-basis: 16.666666666666668%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .col-tablet-3 {
    width: 25%;
    max-width: 25%;
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .col-tablet-4 {
    width: 33.333333333333336%;
    max-width: 33.333333333333336%;
    -webkit-flex-basis: 33.333333333333336%;
    flex-basis: 33.333333333333336%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .col-tablet-5 {
    width: 41.66666666666667%;
    max-width: 41.66666666666667%;
    -webkit-flex-basis: 41.66666666666667%;
    flex-basis: 41.66666666666667%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .col-tablet-6 {
    width: 50%;
    max-width: 50%;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .col-tablet-7 {
    width: 58.333333333333336%;
    max-width: 58.333333333333336%;
    -webkit-flex-basis: 58.333333333333336%;
    flex-basis: 58.333333333333336%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .col-tablet-8 {
    width: 66.66666666666667%;
    max-width: 66.66666666666667%;
    -webkit-flex-basis: 66.66666666666667%;
    flex-basis: 66.66666666666667%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .col-tablet-9 {
    width: 75%;
    max-width: 75%;
    -webkit-flex-basis: 75%;
    flex-basis: 75%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .col-tablet-10 {
    width: 83.33333333333334%;
    max-width: 83.33333333333334%;
    -webkit-flex-basis: 83.33333333333334%;
    flex-basis: 83.33333333333334%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .col-tablet-11 {
    width: 91.66666666666667%;
    max-width: 91.66666666666667%;
    -webkit-flex-basis: 91.66666666666667%;
    flex-basis: 91.66666666666667%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .col-tablet-12 {
    width: 100%;
    max-width: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
}
.v1 [class^="col-"],
.v1 [class*=" col-"] {
  padding: 0 15px;
  min-height: 1px;
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 14px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  filter: alpha(opacity=90);
}
.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip.top .tooltip-arrow {
  bottom: -4px;
  left: 50%;
  margin-left: -5px;
  border-width: 10px 5px 0;
  border-top-color: #fff;
}
.tooltip.top .tooltip-arrow:after {
  left: 50%;
  bottom: -2px;
  height: 6px;
  width: 0;
  margin-left: -6px;
  border-width: 12px 6px 0;
  border-color: #f460a3 transparent transparent transparent;
}
.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.bottom .tooltip-arrow {
  top: -4px;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 10px;
  border-bottom-color: #fff;
}
.tooltip.bottom .tooltip-arrow:after {
  left: 50%;
  top: -2px;
  height: 12px;
  width: 0;
  margin-left: -6px;
  border-width: 0 6px 12px;
  border-color: transparent transparent #f460a3;
}
.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 1px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #fff;
}
.tooltip.left .tooltip-arrow:after {
  top: 50%;
  left: -4px;
  height: 0;
  width: 6px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #f460a3;
}
.tooltip-inner {
  max-width: 400px;
  padding: 15px 20px;
  color: #333;
  text-align: left;
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #f460a3;
  -webkit-box-shadow: 0 6px 16px rgba(0,0,0,0.21);
  box-shadow: 0 6px 16px rgba(0,0,0,0.21);
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip-arrow:after {
  content: "";
  position: absolute;
  border-style: solid;
  z-index: -1;
}
.v1 .btn:not(.exclude) {
  display: inline-block;
  vertical-align: middle;
  padding: 15px 25px 17px;
  border-radius: 5px;
  font-family: 'Avenir LT', sans-serif;
  font-size: 20px;
  line-height: 1.3;
  color: #fff;
  font-weight: bold;
  text-align: center;
  background: #f460a3;
  border: 1px solid #f460a3;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.2s background ease-in-out, 0.2s color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s background ease-in-out, 0.2s color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s background ease-in-out, 0.2s color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s background ease-in-out, 0.2s color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: 0.2s background ease-in-out, 0.2s color ease-in-out, 0.2s box-shadow ease-in-out;
}
.nps-survey .v1 .btn:not(.exclude) {
  -webkit-box-shadow: 2px 2px 2px rgba(127,127,127,0.2);
  box-shadow: 2px 2px 2px rgba(127,127,127,0.2);
}
@media (max-width: 767px) {
  .v1 .btn:not(.exclude) {
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 15px;
  }
}
.v1 .btn:not(.exclude):hover,
.v1 .btn:not(.exclude):active {
  background: #f460a3;
  color: #fff;
  -webkit-box-shadow: 0 6px 16px rgba(244,96,163,0.5);
  box-shadow: 0 6px 16px rgba(244,96,163,0.5);
}
.nps-survey .v1 .btn:not(.exclude):hover,
.nps-survey .v1 .btn:not(.exclude):active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.v1 .btn:not(.exclude):active {
  -webkit-box-shadow: 0 6px 16px rgba(244,96,163,0.9);
  box-shadow: 0 6px 16px rgba(244,96,163,0.9);
}
.nps-survey .v1 .btn:not(.exclude):active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.v1 .btn:not(.exclude):focus {
  outline: none;
}
a.v1 .btn:not(.exclude) {
  text-decoration: none;
}
.v1 .btn:not(.exclude).disabled,
.v1 .btn:not(.exclude)[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  pointer-events: none;
}
.v1 .btn:not(.exclude)-block {
  display: block;
  width: 100%;
}
.v1 .btn:not(.exclude)-default {
  font-weight: normal;
  color: #343434;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.v1 .btn:not(.exclude)-white {
  background: #fff;
  border: 1px solid #d6d6d6;
  color: #434343;
}
.v1 .btn:not(.exclude)-white .btn-icon {
  margin-left: 18px;
  padding-top: 8px;
  font-size: 12px;
}
@media (max-width: 767px) {
  .v1 .btn:not(.exclude)-white .btn-icon {
    padding-top: 3px;
  }
}
.v1 .btn:not(.exclude)-white:hover,
.v1 .btn:not(.exclude)-white:active {
  background: #fff;
  -webkit-box-shadow: 0 6px 16px rgba(127,127,127,0.2);
  box-shadow: 0 6px 16px rgba(127,127,127,0.2);
  color: #434343;
}
.v1 .btn:not(.exclude)-white:active {
  -webkit-box-shadow: 0 6px 16px rgba(127,127,127,0.5);
  box-shadow: 0 6px 16px rgba(127,127,127,0.5);
}
.v1 .btn:not(.exclude)-red {
  background: #de1b1b;
  border: 1px solid #de1b1b;
  color: #fff;
}
.v1 .btn:not(.exclude)-red .btn-icon {
  margin-left: 18px;
  padding-top: 8px;
  font-size: 12px;
}
@media (max-width: 767px) {
  .v1 .btn:not(.exclude)-red .btn-icon {
    padding-top: 3px;
  }
}
.v1 .btn:not(.exclude)-red:hover,
.v1 .btn:not(.exclude)-red:active {
  background: #de1b1b;
  -webkit-box-shadow: 0 6px 16px rgba(222,27,27,0.5);
  box-shadow: 0 6px 16px rgba(222,27,27,0.5);
  color: #fff;
}
.v1 .btn:not(.exclude)-red:active {
  -webkit-box-shadow: 0 6px 16px rgba(222,27,27,0.9);
  box-shadow: 0 6px 16px rgba(222,27,27,0.9);
}
.v1 .btn:not(.exclude)-grey {
  background: #5c5b5c;
  border: 1px solid #5c5b5c;
  color: #fff;
}
.v1 .btn:not(.exclude)-grey .btn-icon {
  margin-left: 18px;
  padding-top: 8px;
  font-size: 12px;
}
@media (max-width: 767px) {
  .v1 .btn:not(.exclude)-grey .btn-icon {
    padding-top: 3px;
  }
}
.v1 .btn:not(.exclude)-grey:hover,
.v1 .btn:not(.exclude)-grey:active {
  background: #5c5b5c;
  -webkit-box-shadow: 0 6px 16px rgba(92,91,92,0.5);
  box-shadow: 0 6px 16px rgba(92,91,92,0.5);
  color: #fff;
}
.v1 .btn:not(.exclude)-grey:active {
  -webkit-box-shadow: 0 6px 16px rgba(92,91,92,0.9);
  box-shadow: 0 6px 16px rgba(92,91,92,0.9);
}
.v1 .btn:not(.exclude)-yellow {
  background: #ffd800;
  border: 1px solid #ffd800;
  color: #fff;
}
.v1 .btn:not(.exclude)-yellow .btn-icon {
  margin-left: 18px;
  padding-top: 8px;
  font-size: 12px;
}
@media (max-width: 767px) {
  .v1 .btn:not(.exclude)-yellow .btn-icon {
    padding-top: 3px;
  }
}
.v1 .btn:not(.exclude)-yellow:hover,
.v1 .btn:not(.exclude)-yellow:active {
  background: #ffd800;
  -webkit-box-shadow: 0 6px 16px rgba(255,216,0,0.5);
  box-shadow: 0 6px 16px rgba(255,216,0,0.5);
  color: #fff;
}
.v1 .btn:not(.exclude)-yellow:active {
  -webkit-box-shadow: 0 6px 16px rgba(255,216,0,0.9);
  box-shadow: 0 6px 16px rgba(255,216,0,0.9);
}
.v1 .btn:not(.exclude)-canva {
  background: #23bfc8;
  border: 1px solid #23bfc8;
  color: #fff;
}
.v1 .btn:not(.exclude)-canva .btn-icon {
  margin-left: 18px;
  padding-top: 8px;
  font-size: 12px;
}
@media (max-width: 767px) {
  .v1 .btn:not(.exclude)-canva .btn-icon {
    padding-top: 3px;
  }
}
.v1 .btn:not(.exclude)-canva:hover,
.v1 .btn:not(.exclude)-canva:active {
  background: #23bfc8;
  -webkit-box-shadow: 0 6px 16px rgba(35,191,200,0.5);
  box-shadow: 0 6px 16px rgba(35,191,200,0.5);
  color: #fff;
}
.v1 .btn:not(.exclude)-canva:active {
  -webkit-box-shadow: 0 6px 16px rgba(35,191,200,0.9);
  box-shadow: 0 6px 16px rgba(35,191,200,0.9);
}
.v1 .btn:not(.exclude).btn-gradient-purple {
  background: #f460a3;
  background: -webkit-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: -moz-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: -o-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: -ms-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: linear-gradient(to right, #f460a3 0%, #812975 100%);
}
.v1 .btn:not(.exclude).btn-gradient-purple:hover {
  background: -webkit-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: -moz-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: -o-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: -ms-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: linear-gradient(to right, #f460a3 0%, #812975 100%);
  -webkit-box-shadow: 0 6px 16px rgba(129,41,129,0.5);
  box-shadow: 0 6px 16px rgba(129,41,129,0.5);
}
.v1 .btn:not(.exclude)-lg {
  padding-top: 22px;
  padding-bottom: 24px;
}
@media (max-width: 767px) {
  .v1 .btn:not(.exclude)-lg {
    font-size: 18px;
  }
}
.v1 .btn:not(.exclude)-lg .btn-icon {
  margin-right: 15px;
  font-size: 22px;
}
@media (max-width: 767px) {
  .v1 .btn:not(.exclude)-lg .btn-icon {
    font-size: 18px;
  }
}
.v1 .btn:not(.exclude)-md {
  padding-top: 18px;
  padding-bottom: 19px;
}
.v1 .btn:not(.exclude)-md .btn-icon {
  margin-right: 15px;
}
.v1 .btn:not(.exclude)-sm,
.v1 .btn:not(.exclude)-xsm {
  font-weight: 500;
}
@media (max-width: 767px) {
  .v1 .btn:not(.exclude)-sm,
  .v1 .btn:not(.exclude)-xsm {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}
.v1 .btn:not(.exclude)-sm .btn-icon,
.v1 .btn:not(.exclude)-xsm .btn-icon {
  margin-top: -2px;
  margin-bottom: -2px;
  margin-left: -2px;
}
@media (max-width: 767px) {
  .v1 .btn:not(.exclude)-sm .btn-icon,
  .v1 .btn:not(.exclude)-xsm .btn-icon {
    margin-top: 0;
  }
}
.v1 .btn:not(.exclude)-sm {
  font-size: 16px;
  padding: 16px 15px;
}
.v1 .btn:not(.exclude)-xsm {
  font-size: 15px;
  padding: 7px 15px;
}
.v1 .btn:not(.exclude)-icon {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  font-size: 20px;
}
@media (max-width: 767px) {
  .v1 .btn:not(.exclude)-icon {
    font-size: 16px;
  }
}
.v1 .btn:not(.exclude)-label + .btn-icon {
  margin: 0 0 0 10px;
}
.v1 .btn:not(.exclude)-link {
  color: #434343;
  border-color: transparent;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.v1 .btn:not(.exclude)-link:hover,
.v1 .btn:not(.exclude)-link:active {
  color: #f460a3;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.v1 .btn:not(.exclude)-link img {
  display: inline-block;
  vertical-align: middle;
}
.v1 .btn:not(.exclude)-link img:last-child {
  margin-left: 10px;
}
.v1 .btn:not(.exclude)-link-colored {
  color: #f460a3;
}
.v1 .btn:not(.exclude)-link-colored:hover {
  color: #434343;
}
@media (max-width: 767px) {
  .v1 .btn:not(.exclude)-xs-block {
    display: block;
    width: 100%;
  }
}
.v1 .form-group {
  position: relative;
  margin-bottom: 20px;
}
.v1 .form-group sup {
  position: absolute;
  z-index: 2;
  top: 28px;
  right: -15px;
  font-size: 16px;
  color: #bfbfbf;
}
@media (max-width: 767px) {
  .v1 .form-group sup {
    right: 10px;
  }
  .v1 .form-group sup + .form-control {
    padding-right: 25px;
  }
  .v1 .form-group sup + .select7 + .select7 .select7__current {
    padding-right: 45px;
  }
  .v1 .form-group sup + .select7 + .select7 .select7__caret {
    right: 24px;
  }
}
@media (max-width: 767px) {
  .v1 .mobile-form-group {
    margin-bottom: 20px;
  }
}
.v1 .floating-label {
  font-size: 17px;
  left: 10px;
  position: absolute;
  pointer-events: none;
  top: 13px;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  transition: 0.2s ease all;
  color: #a4a7a8;
}
.v1 .floating-label sup {
  position: initial;
  color: #f460a3;
}
.v1 .form-control-wrap .floating-label {
  left: 40px;
}
.v1 input:focus ~ .floating-label,
.v1 textarea:focus ~ .floating-label,
.v1 input:not([value=""]) ~ .floating-label,
.v1 textarea:not([value=""]) ~ .floating-label,
.v1 .floating-label.active {
  top: 4px;
  bottom: 8px;
  left: 10px;
  font-size: 12px;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  color: #a4a7a8;
}
.v1 .form-control-wrap input:focus ~ .floating-label,
.v1 .form-control-wrap textarea:focus ~ .floating-label,
.v1 .form-control-wrap input:not([value=""]) ~ .floating-label,
.v1 .form-control-wrap textarea:not([value=""]) ~ .floating-label,
.v1 .form-control-wrap .floating-label.active {
  left: 40px;
}
.v1 .form-control-wrap input:focus ~ .floating-label,
.v1 .form-control-wrap textarea:focus ~ .floating-label,
.v1 .form-control-wrap input:-webkit-autofill ~ .floating-label,
.v1 .form-control-wrap textarea:-webkit-autofill ~ .floating-label {
  left: 40px;
}
.v1 input:focus ~ .floating-label,
.v1 textarea:focus ~ .floating-label,
.v1 .floating-label.active {
  color: #7a7a7a;
}
.v1 .form-control[floating-label="true"] {
  padding: 16px 10px 8px;
}
.v1 .control-with-button {
  position: relative;
}
.v1 .control-with-button.active .btn {
  visibility: visible;
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.v1 .control-with-button .form-control {
  padding-right: 70px;
}
.v1 .control-with-button .btn {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 9px 10px;
  width: 50px;
  font-size: 14px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.v1 .control-with-button .btn.second-btn {
  right: 60px;
}
.v1 .has-error .form-control,
.v1 .has-error .select7__current {
  border-color: #f460a3;
}
.v1 .has-error .error-message {
  display: block;
}
.v1 .error-message {
  position: relative;
  display: none;
  margin-top: 20px;
  padding-left: 45px;
  font-size: 16px;
  color: #333;
}
@media (max-width: 767px) {
  .v1 .error-message {
    margin-top: 15px;
    font-size: 14px;
  }
}
.v1 .error-message:before {
  content: "";
  position: absolute;
  top: 0;
  left: 10px;
  background: url("i/warning-sign.svg") no-repeat 0 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  width: 23px;
  height: 20px;
}
.v1 .form-control-wrap {
  position: relative;
}
.v1 .form-control-wrap .form-control {
  padding-left: 40px;
}
.v1 .form-control-wrap .form-control-icon {
  position: absolute;
  top: 8px;
  left: 5px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  color: #c1c1c1;
  text-align: center;
}
.v1 .form-control-wrap .form-control-icon-right {
  right: 10px;
  left: inherit;
  top: 20px;
}
.v1 .control-label {
  display: block;
  font-weight: 500;
}
.v1 .control-label-static {
  padding: 11px 0 0 11px;
}
@media (max-width: 999px) {
  .v1 .control-label-static {
    padding: 11px 0;
  }
}
@media (max-width: 767px) {
  .v1 .control-label-static {
    font-size: 15px;
  }
}
.v1 .control-label-vertical {
  font-weight: 500;
}
@media (min-width: 768px) {
  .v1 .control-label-vertical {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 48px;
  }
}
@media (max-width: 767px) {
  .v1 .control-label-vertical {
    display: block;
    margin-bottom: 3px;
  }
}
@media (min-width: 768px) {
  .v1 .control-label-vertical_right {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -o-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
}
.v1 .control-label-simple {
  display: block;
  margin-bottom: 5px;
  font-size: 17px;
  color: #888b8d;
}
@media (max-width: 767px) {
  .v1 .control-label-simple {
    font-size: 14px;
  }
}
.v1 .control-message {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-weight: 500;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 48px;
  font-size: 16px;
  line-height: 1.375;
}
@media (max-width: 767px) {
  .v1 .control-message {
    font-size: 14px;
    color: #888b8d;
  }
}
.v1 .control-message_md {
  height: 60px;
}
.v1 .form-control-clone {
  position: absolute;
  left: 100%;
  top: 8px;
  margin-left: 10px;
  width: 29px;
  height: 29px;
  border: 1px solid #e6e6e6;
  border-radius: 50px;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: 0.2s border-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out;
  transition: 0.2s border-color ease-in-out;
}
@media (max-width: 767px) {
  .v1 .form-control-clone {
    display: none;
  }
}
.v1 .form-control-clone:hover {
  border-color: #f460a3;
}
.v1 .form-control-clone:hover:before,
.v1 .form-control-clone:hover:after {
  background-color: #f460a3;
}
.v1 .form-control-clone:before,
.v1 .form-control-clone:after {
  content: "";
  position: absolute;
  background-color: #e6e6e6;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
.v1 .form-control-clone:before {
  top: 7px;
  left: 13px;
  width: 1px;
  height: 13px;
}
.v1 .form-control-clone:after {
  width: 13px;
  height: 1px;
  left: 7px;
  top: 13px;
}
.v1 .form-control {
  display: block;
  width: 100%;
  height: 48px;
  padding: 11px 10px 12px;
  font-family: 'Avenir LT', sans-serif;
  font-size: 17px;
  line-height: 1.4;
  color: #494949;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  border-radius: 2px;
  -webkit-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  outline: 0 none;
  -webkit-appearance: none;
}
.v1 .form-control:focus {
  border-color: #b1b1b1;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  box-shadow: 0 5px 10px rgba(0,0,0,0.12);
}
textarea.v1 .form-control {
  height: auto;
  resize: vertical;
}
.v1 .form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  box-shadow: 0 0 0 1000px #fff inset;
}
.v1 .form-control::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
  color: #9b9b9b;
}
.v1 .form-control::-moz-placeholder {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
  color: #9b9b9b;
}
.v1 .form-control:-moz-placeholder {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
  color: #9b9b9b;
}
.v1 .form-control:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
  color: #9b9b9b;
}
.v1 .form-control:focus::-webkit-input-placeholder {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.v1 .form-control:focus::-moz-placeholder {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.v1 .form-control:focus:-moz-placeholder {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.v1 .form-control:focus:-ms-input-placeholder {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.v1 .form-control-sm {
  padding-top: 9px;
  padding-bottom: 10px;
  height: 40px;
  font-size: 14px;
}
.v1 .form-control-search {
  border: none;
  height: 70px;
}
.v1 .form-control,
.v1 .form-control .collection-widget {
  height: auto;
}
.v1 .form-message {
  font-size: 14px;
}
.v1 form {
  width: 100%;
}
.v1 fieldset {
  padding: 0;
  margin: 0;
  border: 0 none;
  min-width: 0;
}
.v1 .radio,
.v1 .checkbox {
  position: relative;
  display: block;
}
.v1 .radio label,
.v1 .checkbox label {
  display: block;
  min-height: 22px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.v1 .radio input,
.v1 .checkbox input {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  cursor: pointer;
}
.v1 .radio input+ span,
.v1 .checkbox input+ span {
  position: relative;
  display: block;
  min-height: 22px;
  padding: 0 0 0 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v1 .radio input+ span:before,
.v1 .checkbox input+ span:before,
.v1 .radio input+ span:after,
.v1 .checkbox input+ span:after {
  content: "";
  position: absolute;
}
.v1 .radio input+ span:before,
.v1 .checkbox input+ span:before {
  top: 1px;
  left: 0;
  width: 22px;
  height: 22px;
  border: 1px solid #c9c9c9;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
}
.v1 .radio input+ span:after,
.v1 .checkbox input+ span:after {
  visibility: hidden;
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -o-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: 0.2s -webkit-transform ease-in-out, 0.2s opacity ease-in-out;
  -moz-transition: 0.2s -moz-transform ease-in-out, 0.2s opacity ease-in-out;
  -o-transition: 0.2s -o-transform ease-in-out, 0.2s opacity ease-in-out;
  -ms-transition: 0.2s -ms-transform ease-in-out, 0.2s opacity ease-in-out;
  transition: 0.2s transform ease-in-out, 0.2s opacity ease-in-out;
}
.v1 .radio input:checked + span:after,
.v1 .checkbox input:checked + span:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
}
.v1 .radio input[name*="termsOfProtectionPrivateInformation"],
.v1 .checkbox input[name*="termsOfProtectionPrivateInformation"] {
  display: none;
}
.v1 .checkbox input+ span:after {
  content: "\e906";
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  top: 6px;
  left: 5px;
  width: 14px;
  height: 14px;
}
.has-error .v1 .checkbox input+ span:before {
  border-color: #f460a3;
}
.v1 .checkbox_sm {
  font-size: 14px;
}
.v1 .checkbox_sm input+ span {
  padding: 0 0 0 20px;
  min-height: 20px;
}
.v1 .checkbox_sm input+ span:before {
  width: 14px;
  height: 14px;
  top: 2px;
}
.v1 .checkbox_sm input+ span:after {
  top: 5px;
  left: 3px;
  font-size: 9px;
}
.v1 .radio input+ span:before {
  border-radius: 100px;
}
.v1 .radio input+ span:after {
  top: 7px;
  left: 6px;
  width: 10px;
  height: 10px;
  background-color: #434343;
  border-radius: 100px;
}
.v1 input[type="radio"][disabled],
.v1 input[type="checkbox"][disabled],
.v1 input[type="radio"].disabled,
.v1 input[type="checkbox"].disabled,
fieldset[disabled] .v1 input[type="radio"],
fieldset[disabled] .v1 input[type="checkbox"] {
  cursor: not-allowed;
}
.v1 .radio.disabled label,
.v1 .checkbox.disabled label,
fieldset[disabled] .v1 .radio label,
fieldset[disabled] .v1 .checkbox label {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: not-allowed;
}
.v1 label > * {
  pointer-events: none;
}
.v1 label a {
  pointer-events: all;
}
.v1 .radio-inline .radio {
  display: inline-block;
}
.v1 .radio-inline .checkbox {
  display: inline-block;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.font-medium {
  font-weight: 500;
}
.tab-pane {
  display: none;
}
.tab-pane.active {
  display: block;
}
.bg-pink {
  background-color: #f460a3;
}
.force-bg-grey {
  background-color: #f5f5f7 !important;
}
.force-bg-white {
  background-color: #fff !important;
}
.hidden-all {
  display: none !important;
}
@media (min-width: 1000px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .hidden-tablet {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hidden-mobile {
    display: none !important;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (max-width: 999px) {
  .visible-desktop {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .visible-mobile {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .visible-tablet-desktop {
    display: none !important;
  }
}
.float-left-all {
  float: left !important;
}
@media (min-width: 768px) {
  .float-left-desktop {
    float: left !important;
  }
}
@media (max-width: 767px) {
  .float-left-mobile {
    float: left !important;
  }
}
.float-right-all {
  float: right !important;
}
@media (min-width: 768px) {
  .float-right-desktop {
    float: right !important;
  }
}
@media (max-width: 767px) {
  .float-right-mobile {
    float: right !important;
  }
}
.clear-both {
  clear: both;
}
.text-decoration-none {
  text-decoration: none !important;
}
::selection {
  background-color: rgba(0,0,0,0.2);
  color: #fff;
}
::-moz-selection {
  background-color: rgba(0,0,0,0.2);
  color: #fff;
}
* {
  -webkit-font-smoothing: antialiased;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
body {
  display: inherit;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}
body:after {
  content: "";
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  background: rgba(200,200,200,0.84);
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
body.user-panel-open:after,
body.notification-box-open:after,
body.settings-panel-open:after {
  z-index: 140;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
}
body.user-panel-open .header__menu,
body.notification-box-open .header__menu,
body.settings-panel-open .header__menu {
  z-index: -1;
}
body.user-panel-open .header__menu:after,
body.notification-box-open .header__menu:after,
body.settings-panel-open .header__menu:after {
  content: "";
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
body.menu-active:after {
  z-index: 140;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
}
body .link,
body span.altlink {
  cursor: pointer;
}
body li span.fas,
body li span.fa,
body li span.far,
body li span.fab,
body li span.fal {
  padding-right: 5px;
}
.overlay {
  z-index: 10000;
  background-color: #fff;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.overlay .loader {
  position: fixed;
  top: 50%;
  left: 50vw;
  right: 50vw;
}
.joblist-title-block {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 40px 0;
}
.joblist-title-block .joblist-title-text {
  font-size: 21px;
  margin: 0;
}
.joblist-title-info-block {
  margin-left: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}
.joblist-title-icon-block {
  background-color: #fff;
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}
h1 {
  font-size: 36px;
  font-weight: 500;
  margin: 0 0 30px;
}
h2 {
  font-size: 30px;
  margin: 0 0 30px;
}
@media (max-width: 767px) {
  h2 {
    margin: 0 0 20px;
    font-size: 20px;
  }
}
h3 {
  font-size: 20px;
  margin: 0 0 15px;
}
h4 {
  font-size: 18px;
  margin: 0 0 15px;
}
h5 {
  font-size: 16px;
  margin: 0 0 15px;
}
h6 {
  font-size: 14px;
  margin: 0 0 15px;
}
p {
  margin: 0 0 1.1em;
}
a {
  color: #434343;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
a:focus {
  outline: none;
}
b,
strong {
  font-weight: bold;
}
hr {
  margin: 50px 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: #e8e8e8;
}
small {
  font-size: 75%;
}
code {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  padding: 3px 5px;
  font-size: 80%;
  color: #434343;
  background-color: #fed5e8;
  border-radius: 2px;
}
@media (max-width: 767px) {
  .rich-text {
    font-size: 15px;
  }
}
.rich-text img {
  max-width: 100%;
}
.table,
.rich-text table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e8e8e8;
  font-size: 16px;
}
.table tr,
.rich-text table tr {
  -webkit-transition: 0.1s background-color ease-in-out;
  -moz-transition: 0.1s background-color ease-in-out;
  -o-transition: 0.1s background-color ease-in-out;
  -ms-transition: 0.1s background-color ease-in-out;
  transition: 0.1s background-color ease-in-out;
}
.table tr:nth-child(even) td,
.rich-text table tr:nth-child(even) td {
  background-color: #fff;
}
.table tr:nth-child(odd) td,
.rich-text table tr:nth-child(odd) td {
  background-color: #fbfbfb;
}
.table th,
.rich-text table th {
  padding: 25px 20px;
  font-weight: bold;
  text-align: left;
  background-color: #fff;
}
.table td,
.rich-text table td {
  padding: 18px 20px;
}
@media (max-width: 767px) {
  .table th,
  .rich-text table th,
  .table td,
  .rich-text table td {
    padding: 18px 13px;
    font-size: 14px;
  }
}
.table_no-border {
  border: 0 none !important;
}
.table_bordered th {
  border-bottom: 1px solid #e8e8e8 !important;
}
.table_bordered td {
  border-right: 1px solid #e8e8e8;
}
.table_bordered td:last-child {
  border-right: 0 none;
}
.table_bordered-styled {
  border: 0 none;
}
.table_bordered-styled th {
  background-color: #fcfcfc;
}
.table_bordered-styled td {
  background-color: #fff !important;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}
.table_bordered-styled td:first-child {
  border-left: 1px solid #e8e8e8;
}
.table_bordered-styled tr:first-child td {
  border-top: 1px solid #e8e8e8;
}
.table_small th,
.table_small td {
  padding: 7px 10px 8px;
}
@media (max-width: 767px) {
  .table_small th,
  .table_small td {
    font-size: 14px;
  }
}
.table_small th {
  font-size: 17px;
  font-weight: 500;
  background-color: #fcfcfc;
  border-bottom: 1px solid #e8e8e8;
}
@media (max-width: 767px) {
  .table_small th {
    padding: 7px 13px;
  }
}
.table_small td {
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background-color: #fff !important;
}
@media (max-width: 767px) {
  .table_small td {
    padding: 5px 13px;
  }
}
.table_small td:last-child {
  border-right: 0 none;
}
.table_small tr:last-child td {
  border-bottom: 0 none;
}
.underline {
  text-decoration: underline;
}
.text-help {
  font-size: 12px;
  font-style: italic;
}
.table tr.table-selected {
  background-color: #ffeffb;
}
.table tr.table-selected > td {
  background-color: #ffeffb;
}
.table tr.table-selected > th {
  background-color: #ffeffb;
}
.table th.aligned.left {
  text-align: left;
}
.table th.aligned.center {
  text-align: center;
}
.table th.aligned.right {
  text-align: right;
}
.enter-active,
.leave-active {
  overflow: hidden;
  -webkit-transition: height 0.2s linear;
  -moz-transition: height 0.2s linear;
  -o-transition: height 0.2s linear;
  -ms-transition: height 0.2s linear;
  transition: height 0.2s linear;
}
th.sortable {
  position: relative;
}
th.sortable a {
  text-decoration: none;
}
.v1 .account-grid-orders {
  font-size: 14px;
}
.v1 .account-grid-orders .order-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  border: 1px solid #e8e8e8;
  border-bottom: 0;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 50px;
  background-color: #fff;
}
.v1 .account-grid-orders .order-row .block {
  padding: 10px;
}
.v1 .account-grid-orders .order-row .block-header {
  border-bottom: 1px solid #e8e8e8;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}
@media (max-width: 999px) {
  .v1 .account-grid-orders .order-row .block-header {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.v1 .account-grid-orders .order-row .block-header .header-cell {
  padding: 5px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 15%;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  color: #7c7e7e;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
}
@media (max-width: 999px) {
  .v1 .account-grid-orders .order-row .block-header .header-cell {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
}
.v1 .account-grid-orders .order-row .block-header .header-cell p {
  margin: 0;
}
.v1 .account-grid-orders .order-row .block-header .header-cell.growth {
  -webkit-flex-basis: auto;
  flex-basis: auto;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.v1 .account-grid-orders .order-row .block-header .header-cell .title {
  text-transform: uppercase;
}
.v1 .account-grid-orders .order-row .block-product-info,
.v1 .account-grid-orders .order-row .block-address,
.v1 .account-grid-orders .order-row .block-actions {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 33%;
  -ms-flex: 1 0 33%;
  flex: 1 0 33%;
  border-left: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
@media (max-width: 999px) {
  .v1 .account-grid-orders .order-row .block-product-info,
  .v1 .account-grid-orders .order-row .block-address,
  .v1 .account-grid-orders .order-row .block-actions {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
}
.v1 .account-grid-orders .order-row .block-address {
  -webkit-flex-basis: 30%;
  flex-basis: 30%;
}
@media (max-width: 999px) {
  .v1 .account-grid-orders .order-row .block-address {
    display: none;
  }
}
.v1 .account-grid-orders .order-row .block-actions {
  -webkit-flex-basis: 25%;
  flex-basis: 25%;
  line-height: 2;
}
@media (max-width: 999px) {
  .v1 .account-grid-orders .order-row .block-actions {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-left: 0;
  }
}
.v1 .account-grid-orders .order-row .block-actions a {
  color: #f460a3;
}
@media (max-width: 999px) {
  .v1 .account-grid-orders .order-row .block-actions a {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 0 33%;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    -webkit-align-self: center;
    align-self: center;
    -ms-flex-item-align: center;
    text-align: center;
  }
}
@media (max-width: 999px) {
  .v1 .account-grid-orders .order-row .block-actions br {
    display: none;
  }
}
.v1 .account-grid-orders .order-row .block-product-info {
  -webkit-flex-basis: 40%;
  flex-basis: 40%;
  border-left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.v1 .account-grid-orders .order-row .block-product-info p {
  margin-bottom: 0;
}
@media (max-width: 999px) {
  .v1 .account-grid-orders .order-row .block-product-info {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.v1 .account-grid-orders .order-row .block-product-info .aside {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 120px;
  -ms-flex: 0 0 120px;
  flex: 0 0 120px;
  width: 120px;
  padding-right: 10px;
}
.v1 .account-grid-orders .order-row .block-product-info .aside .cart__attach_file_thumb {
  width: auto;
}
.v1 .account-grid-orders .order-row .block-product-info .body {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.v1 .account-grid-orders .order-row .block-product-info .body .title {
  font-weight: bold;
}
.v1 .account-grid-orders .order-row .block-product-info .body .description {
  font-size: 12px;
  color: #4f5559;
}
.v1 .account-grid-orders .order-row .block-product-info .body .description ul {
  padding-left: 0;
  list-style-type: none;
}
@media (max-width: 999px) {
  .v1 .account-grid-orders .order-row .block-product-info .body .description ul {
    display: none;
  }
}
@media (max-width: 999px) {
  .v1 .account-grid-orders .order-row .block-product-info .body2 {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    max-width: 30%;
  }
}
.v1 .account-grid-orders .order-row .status {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  border-bottom: 1px solid #e8e8e8;
  padding: 10px;
}
.v1 .account-order-item-show {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.v1 .account-order-item-show .block.infos .content {
  font-size: 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.v1 .account-order-item-show .block.infos .content > .label-text,
.v1 .account-order-item-show .block.infos .content .value {
  margin-top: 5px;
}
.v1 .account-order-item-show .block.infos .content .label-text {
  color: #888b8d;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 40%;
  -ms-flex: 1 1 40%;
  flex: 1 1 40%;
  text-align: right;
  padding-right: 15px;
}
.v1 .account-order-item-show .block.infos .content .value {
  color: #434343;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 60%;
  -ms-flex: 1 1 60%;
  flex: 1 1 60%;
}
.v1 .account-order-item-show .block.product .content {
  font-size: 12px;
}
.v1 .account-order-item-show .block.product ul {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}
.v1 .account-order-item-show .block.product .actions {
  font-size: 12px;
  padding-top: 10px;
}
.v1 .account-order-item-show .block.product .actions a {
  margin-right: 15px;
}
.v1 .account-order-item-show .block.documents .table-sources .row-header .col-1,
.v1 .account-order-item-show .block.documents .table-sources .row-content .col-1,
.v1 .account-order-item-show .block.documents .table-sources .row-header .col-3,
.v1 .account-order-item-show .block.documents .table-sources .row-content .col-3 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}
.v1 .account-order-item-show .block.documents .table-sources .row-header .col-2,
.v1 .account-order-item-show .block.documents .table-sources .row-content .col-2 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 60%;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
}
@media (max-width: 999px) {
  .v1 .account-order-item-show .block.documents .table-sources .row-header .col-1,
  .v1 .account-order-item-show .block.documents .table-sources .row-content .col-1 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    font-size: 12px;
    padding: 15px;
  }
  .v1 .account-order-item-show .block.documents .table-sources .row-header .col-1 p,
  .v1 .account-order-item-show .block.documents .table-sources .row-content .col-1 p {
    margin-bottom: 0;
  }
}
@media (max-width: 999px) {
  .v1 .account-order-item-show .block.documents .table-sources .row-header .col-2,
  .v1 .account-order-item-show .block.documents .table-sources .row-content .col-2,
  .v1 .account-order-item-show .block.documents .table-sources .row-header .col-3,
  .v1 .account-order-item-show .block.documents .table-sources .row-content .col-3 {
    display: none;
  }
}
.v1 .account-order-item-show .block.documents .table-sources .row-header a,
.v1 .account-order-item-show .block.documents .table-sources .row-footer a {
  display: inline-block;
  padding: 0 10px;
  color: #f460a3;
}
.v1 .account-order-item-show .block.documents .table-sources .row-header a.active,
.v1 .account-order-item-show .block.documents .table-sources .row-footer a.active {
  font-weight: bold;
  text-decoration: none;
  cursor: default;
}
@media (max-width: 999px) {
  .v1 .account-order-item-show .block.documents .table-sources .row-content .col-1 {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .v1 .account-order-item-show .block.documents .table-sources .row-content .col-1 .thumbnails {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 0 40%;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    margin-right: 10px;
  }
}
@media (max-width: 999px) {
  .v1 .account-order-item-show .block.documents .table-sources .row-content .thumbnails {
    max-width: 160px;
  }
}
.v1 .account-order-item-show .block.history .inner .table .row-content .col-1,
.v1 .account-order-item-show .block.history .inner .table .row-header .col-1,
.v1 .account-order-item-show .block.history .inner .table .row-content .col-2,
.v1 .account-order-item-show .block.history .inner .table .row-header .col-2,
.v1 .account-order-item-show .block.history .inner .table .row-content .col-3,
.v1 .account-order-item-show .block.history .inner .table .row-header .col-3 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}
.v1 .account-order-item-show .block.history .inner .table .row-content .col-4,
.v1 .account-order-item-show .block.history .inner .table .row-header .col-4 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 40%;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
}
@media (max-width: 999px) {
  .v1 .account-order-item-show .block.history .inner .table .row-content .col-1,
  .v1 .account-order-item-show .block.history .inner .table .row-header .col-1 {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    font-size: 12px;
    padding: 15px;
  }
  .v1 .account-order-item-show .block.history .inner .table .row-content .col-1 p,
  .v1 .account-order-item-show .block.history .inner .table .row-header .col-1 p {
    margin-bottom: 0;
  }
}
@media (max-width: 999px) {
  .v1 .account-order-item-show .block.history .inner .table .row-content .col-2,
  .v1 .account-order-item-show .block.history .inner .table .row-header .col-2,
  .v1 .account-order-item-show .block.history .inner .table .row-content .col-3,
  .v1 .account-order-item-show .block.history .inner .table .row-header .col-3,
  .v1 .account-order-item-show .block.history .inner .table .row-content .col-4,
  .v1 .account-order-item-show .block.history .inner .table .row-header .col-4 {
    display: none;
  }
}
@media (max-width: 999px) {
  .v1 .account-order-item-show .block.history .inner .table .row-content .thumbnails {
    max-width: 160px;
  }
}
.v1 .account-order-item-show .block.shipping .col-1 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 10%;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
}
.v1 .account-order-item-show .block.shipping .col-2,
.v1 .account-order-item-show .block.shipping .col-3,
.v1 .account-order-item-show .block.shipping .col-4 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}
.v1 .account-order-item-show .block.shipping .col-5 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 15%;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
}
.v1 .account-order-show {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.v1 .account-order-show .order-row {
  margin-bottom: 0;
}
.v1 .account-order-show .block.order-details .content {
  font-size: 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.v1 .account-order-show .block.order-details .content > .label,
.v1 .account-order-show .block.order-details .content > .value {
  margin-top: 5px;
}
.v1 .account-order-show .block.order-details .content .label {
  color: #888b8d;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 40%;
  -ms-flex: 1 1 40%;
  flex: 1 1 40%;
  text-align: right;
  padding-right: 15px;
}
.v1 .account-order-show .block.order-details .content .value {
  color: #434343;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 60%;
  -ms-flex: 1 1 60%;
  flex: 1 1 60%;
}
.v1 .account-order-show .block.accounting-documents .content ul {
  font-size: 14px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.v1 .account-order-show .block.accounting-documents .content ul li a {
  display: block;
  color: #f460a3;
}
.v1 .account-order-show .block.accounting-documents .content ul li span {
  color: #888b8d;
}
.v1 .account-order-show .account-grid-orders {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  padding: 15px;
}
.v1 .account-order-show .account-grid-orders .block-product-info {
  -webkit-flex-basis: 40%;
  flex-basis: 40%;
}
@media (max-width: 999px) {
  .v1 .account-order-show .account-grid-orders .block-product-info {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
}
.v1 .account-order-show .account-grid-orders .block-address {
  -webkit-flex-basis: 25%;
  flex-basis: 25%;
}
.v1 .account-order-show .account-grid-orders .block-actions {
  -webkit-flex-basis: 20%;
  flex-basis: 20%;
  border-right: 1px solid #e8e8e8;
}
@media (max-width: 999px) {
  .v1 .account-order-show .account-grid-orders .block-actions {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
}
.v1 .account-order-show .account-grid-orders .block-total {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 15%;
  -ms-flex: 1 1 15%;
  flex: 1 1 15%;
  border-bottom: 1px solid #e8e8e8;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media (max-width: 999px) {
  .v1 .account-order-show .account-grid-orders .block-total {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -o-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .v1 .account-order-show .account-grid-orders .block-total p {
    margin: 0 15px;
  }
}
.v1 .account-order-show .account-grid-orders .row-footer {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #fff;
}
.v1 .account-order-show .account-grid-orders .row-footer > .label,
.v1 .account-order-show .account-grid-orders .row-footer > .value {
  font-size: 16px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  text-align: right;
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-top: 0;
}
.v1 .account-order-show .account-grid-orders .row-footer > .label {
  -webkit-flex-basis: 85%;
  flex-basis: 85%;
}
@media (max-width: 999px) {
  .v1 .account-order-show .account-grid-orders .row-footer > .label {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
}
.v1 .account-order-show .account-grid-orders .row-footer > .value {
  -webkit-flex-basis: 15%;
  flex-basis: 15%;
  border-left: 0;
}
@media (max-width: 999px) {
  .v1 .account-order-show .account-grid-orders .row-footer > .value {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
}
.v1 .address-box {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .v1 .address-box {
    background-color: #fff;
    border: 1px solid #e8e8e8;
  }
}
@media (max-width: 767px) {
  .v1 .address-box_items-2 {
    margin-left: 0;
    margin-right: 0;
  }
}
.v1 .address-box_items-2 .address-box__item {
  width: 50%;
}
@media (max-width: 999px) {
  .v1 .address-box_items-2 .address-box__item {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .v1 .address-box__list {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
}
@media (min-width: 768px) {
  .v1 .address-box__item {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 10px;
    margin-bottom: 20px;
    min-height: 298px;
  }
}
@media (min-width: 1000px) {
  .v1 .address-box__item {
    width: 33.333%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .address-box__item {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .v1 .address-box__item {
    margin-bottom: 5px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .address-box__item_message {
    width: 100%;
    min-height: inherit;
  }
}
@media (max-width: 767px) {
  .v1 .address-box__item_message {
    padding: 10px 15px 0;
  }
}
@media (min-width: 1000px) {
  .v1 .address-box__item.address-form-wrap {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .address-box__item.address-form-wrap {
    width: 100%;
  }
}
.v1 .address-box__form {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.v1 .address-box__text {
  width: 100%;
  padding: 25px 20px;
  border-radius: 4px;
  background-color: #f7f7f7;
  border: 1px solid #e7e7e7;
  outline: 0;
  resize: none;
  -webkit-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
}
@media (max-width: 767px) {
  .v1 .address-box__text {
    font-size: 15px;
    padding: 15px;
  }
}
.v1 .address-box__text:focus,
.v1 .address-box__text:hover {
  border-color: #d5d5d5;
  -webkit-box-shadow: 0 30px 42px rgba(51,51,51,0.06);
  box-shadow: 0 30px 42px rgba(51,51,51,0.06);
}
.v1 .address-card {
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid transparent;
  -webkit-transition: 0.2s box-shadow ease-in-out, 0.2s border-color ease-in-out;
  -moz-transition: 0.2s box-shadow ease-in-out, 0.2s border-color ease-in-out;
  -o-transition: 0.2s box-shadow ease-in-out, 0.2s border-color ease-in-out;
  -ms-transition: 0.2s box-shadow ease-in-out, 0.2s border-color ease-in-out;
  transition: 0.2s box-shadow ease-in-out, 0.2s border-color ease-in-out;
}
.no-touchevents .v1 .address-card:hover {
  border-color: #d5d5d5;
  -webkit-box-shadow: 0 30px 42px rgba(51,51,51,0.06);
  box-shadow: 0 30px 42px rgba(51,51,51,0.06);
}
.no-touchevents .v1 .address-card:hover .address-card__header {
  border-color: #d5d5d5;
}
.no-touchevents .v1 .address-card:hover .address-card__button {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
}
.v1 .address-card__header {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: -1px -1px 0;
  padding: 22px 90px 22px 30px;
  background-color: #fcfcfc;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  -webkit-transition: 0.2s border-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out;
  transition: 0.2s border-color ease-in-out;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .address-card__header {
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .v1 .address-card__header {
    padding: 20px 90px 15px 15px;
  }
}
.v1 .address-card__body {
  padding: 25px 30px 35px;
  font-size: 18px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .address-card__body {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 767px) {
  .v1 .address-card__body {
    padding: 20px 15px;
  }
}
.v1 .address-card__title {
  font-size: 20px;
  font-weight: bold;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .address-card__title {
    font-size: 16px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .address-card__title {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .v1 .address-card__title {
    font-size: 16px;
  }
}
.v1 .address-card__receiver {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .v1 .address-card__receiver {
    font-size: 15px;
    margin-bottom: 20px;
  }
}
.v1 .address-card__address {
  color: #777;
  letter-spacing: 0.25pt;
}
@media (max-width: 767px) {
  .v1 .address-card__address {
    font-size: 15px;
  }
}
.v1 .address-card__address p {
  margin: 0 0 5px;
}
.v1 .address-card__button {
  position: absolute;
  top: 11px;
  right: 20px;
  padding: 8px 0 0;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  font-size: 21px;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.no-touchevents .v1 .address-card__button {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
@media (max-width: 767px) {
  .v1 .address-card__button {
    top: 9px;
    right: 15px;
    padding-top: 9px;
    width: 40px;
    height: 40px;
  }
}
.v1 .address-list {
  background-color: #fff;
}
@media (max-width: 767px) {
  .v1 .address-list {
    margin-top: 20px;
  }
}
.v1 .address-list__header {
  padding: 25px;
  border-bottom: 1px solid #e8e8e8;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .address-list__header {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .v1 .address-list__header {
    padding: 12px 15px;
  }
}
.v1 .address-list__title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #777;
}
@media (max-width: 999px) {
  .v1 .address-list__title {
    font-size: 17px;
  }
}
.v1 .address-list__footer {
  padding: 20px 25px;
  background-color: #fcfcfc;
}
@media (max-width: 767px) {
  .v1 .address-list__footer {
    text-align: center;
  }
}
.v1 .address-list__button {
  min-width: 270px;
}
.v1 .address-list__item {
  position: relative;
  font-size: 18px;
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
@media (min-width: 768px) {
  .v1 .address-list__item {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (max-width: 767px) {
  .v1 .address-list__item {
    padding: 15px 50px 15px 15px;
  }
}
.v1 .address-list__item:hover {
  background-color: #fafafa;
}
.v1 .address-list__item:hover .address-list__control {
  visibility: visible;
}
.v1 .address-list__item:hover .address-list__control-link {
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  -ms-transition-delay: 0.1s;
  transition-delay: 0.1s;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.v1 .address-list__item.default {
  background-color: #fff5ff;
}
@media (min-width: 768px) {
  .v1 .address-list__col {
    width: 33.333%;
  }
}
@media (min-width: 1000px) {
  .v1 .address-list__col {
    padding: 22px 25px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .address-list__col {
    padding: 22px 15px;
  }
  .v1 .address-list__col:last-child {
    padding-right: 45px;
  }
}
@media (min-width: 768px) {
  .v1 .address-list__col-name {
    width: 33.333%;
  }
}
@media (min-width: 1000px) {
  .v1 .address-list__col-name {
    padding: 22px 25px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .address-list__col-name {
    padding: 22px 15px;
  }
  .v1 .address-list__col-name:last-child {
    padding-right: 45px;
  }
}
@media (min-width: 768px) {
  .v1 .address-list__col-location {
    width: 66.666%;
  }
}
@media (min-width: 1000px) {
  .v1 .address-list__col-location {
    padding: 22px 25px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .address-list__col-location {
    padding: 22px 15px;
  }
  .v1 .address-list__col-location:last-child {
    padding-right: 45px;
  }
}
.v1 .address-list__name {
  font-weight: bold;
}
@media (max-width: 767px) {
  .v1 .address-list__name {
    font-size: 15px;
  }
}
.v1 .address-list__name p {
  margin-bottom: 0;
}
.v1 .address-list__name p.address-type,
.v1 .address-list__name p.address-label {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .v1 .address-list__description,
  .v1 .address-list__location {
    color: #828282;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .address-list__description,
  .v1 .address-list__location {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .v1 .address-list__description,
  .v1 .address-list__location {
    font-size: 14px;
  }
}
.v1 .address-list__control {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
}
.no-touchevents .v1 .address-list__control {
  visibility: hidden;
}
@media (min-width: 1000px) {
  .v1 .address-list__control {
    padding: 24px 0 0 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .address-list__control {
    padding-top: 24px;
  }
}
@media (max-width: 999px) {
  .v1 .address-list__control {
    right: 0;
    left: auto;
    visibility: visible !important;
  }
}
.v1 .address-list__control-link {
  display: block;
  width: 48px;
  height: 48px;
  padding: 9px 0 0 1px;
  font-size: 20px;
  border-radius: 100px;
  -webkit-transition: 0.2s opacity ease-in-out, 0.2s -webkit-transform;
  -moz-transition: 0.2s opacity ease-in-out, 0.2s -moz-transform;
  -o-transition: 0.2s opacity ease-in-out, 0.2s -o-transform;
  -ms-transition: 0.2s opacity ease-in-out, 0.2s -ms-transform;
  transition: 0.2s opacity ease-in-out, 0.2s transform;
  margin-bottom: 10px;
}
@media (min-width: 1000px) {
  .no-touchevents .v1 .address-list__control-link {
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}
@media (max-width: 999px) {
  .v1 .address-list__control-link {
    background: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 0 none;
    font-size: 19px;
    color: #c2c2c2 !important;
    opacity: 1 !important;
    -ms-filter: none !important;
    filter: none !important;
  }
}
.no-touchevents .v1 .address-list__control-link {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.v1 .address-list__actions a {
  text-decoration: none;
  color: #f460a3;
  font-size: 75%;
}
.v1 .address-modal__title {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 36px;
}
@media (max-width: 767px) {
  .v1 .address-modal__title {
    font-size: 18px;
  }
}
.v1 .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-color: transparent;
  border-radius: 0.25rem;
}
.v1 .alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.v1 .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.v1 .autocomplete-suggestions .autocomplete-suggestions-item,
.v1.autocomplete-suggestions .autocomplete-suggestions-item {
  padding: 11px 10px 12px;
  font-size: 17px;
}
.v1 .autocomplete-suggestions .autocomplete-suggestions-item:hover,
.v1.autocomplete-suggestions .autocomplete-suggestions-item:hover {
  background-color: #ffcfeb;
}
.v1 .benefits-block {
  margin-top: 20px;
}
.v1 .benefits-list {
  background-color: #fff;
}
@media (min-width: 768px) {
  .v1 .benefits-list {
    padding-top: 15px;
  }
}
@media (max-width: 767px) {
  .v1 .benefits-list_layout-2 {
    padding-top: 25px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .benefits-list_layout-2 .benefits-list__item {
    padding: 0 20px;
  }
}
@media (max-width: 767px) {
  .v1 .benefits-list_layout-2 .benefits-list__item {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .v1 .benefits-list_layout-2 .benefits-list__item.benefits-list__item_separator {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .benefits-list_layout-2 .benefits-list__item.benefits-list__item_separator:before {
    left: 0;
  }
}
@media (max-width: 767px) {
  .v1 .benefits-list_layout-2 .benefits-list__item.benefits-list__item_separator:before {
    content: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .benefits-list_layout-2 .rating__title {
    font-size: 19px;
  }
}
@media (min-width: 768px) {
  .v1 .benefits-list_border-top {
    margin-top: 50px;
    padding-top: 45px;
    border-top: 2px solid #f7f7f7;
  }
}
@media (max-width: 767px) {
  .v1 .benefits-list_border-top {
    margin: 0 -15px;
    padding: 20px 15px 5px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .benefits-list_border-top .benefits-list__title {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .v1 .benefits-list__wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (max-width: 767px) {
  .v1 .benefits-list__wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.v1 .benefits-list__item {
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .v1 .benefits-list__item {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media (min-width: 1240px) {
  .v1 .benefits-list__item {
    padding: 0 15px;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .benefits-list__item {
    padding: 0 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .benefits-list__item {
    padding: 0 10px;
  }
}
@media (max-width: 767px) {
  .v1 .benefits-list__item {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 1 33%;
    -ms-flex: 0 1 33%;
    flex: 0 1 33%;
    vertical-align: top;
    padding: 0 5px;
  }
  .v1 .benefits-list__item-inner {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .v1 .benefits-list__item.ekomi {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 66%;
    -ms-flex: 1 1 66%;
    flex: 1 1 66%;
  }
  .v1 .benefits-list__item.ekomi .benefits-list__item-inner {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
.v1 .benefits-list__item_separator {
  position: relative;
}
.v1 .benefits-list__item_separator:before {
  content: "";
  position: absolute;
  left: -10px;
  top: 20px;
  bottom: 20px;
  width: 1px;
  background-color: #e2e2e3;
}
.v1 .benefits-list__image {
  height: 70px;
  text-align: center;
  float: left;
  margin-right: 10px;
}
.v1 .benefits-list__image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.v1 .benefits-list__image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.benefits-list__item.ekomi .v1 .benefits-list__image {
  float: none;
}
@media (max-width: 767px) {
  .benefits-list__item.ekomi .v1 .benefits-list__image {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}
@media (max-width: 767px) {
  .v1 .benefits-list__image {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 0 32px;
    -ms-flex: 0 0 32px;
    flex: 0 0 32px;
    height: 32px;
    margin-bottom: 0;
    margin-right: 5px;
  }
}
.ekomi .v1 .benefits-list__image {
  float: none;
  height: auto;
}
.v1 .benefits-list__image:after {
  display: block;
}
.v1 .benefits-list__title {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 18px;
  line-height: 1.333;
  text-align: left;
  padding-top: 10px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .benefits-list__title {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .v1 .benefits-list__title {
    min-height: auto;
    text-align: left;
    font-size: 13px;
  }
}
.v1 .benefits-list__rating {
  padding-top: 10px;
}
.v1 .benefits-list .ekomi-star-rating {
  color: #ffd630;
  font-size: 28px;
  margin-bottom: 0;
}
.v1 .benefits-list .ekomi-star-rating p {
  margin-bottom: 0;
}
@media (max-width: 999px) {
  .v1 .benefits-list .ekomi-star-rating {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .v1 .benefits-list .ekomi-star-rating {
    font-size: 14px;
  }
}
.v1 .benefits-reseller .item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 20px;
}
.content-dashboard .v1 .benefits-reseller .item {
  margin-bottom: 0;
}
.v1 .benefits-reseller .item div.image {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
  padding: 10px;
}
.content-dashboard .v1 .benefits-reseller .item div.image img {
  width: 50px;
}
.v1 .benefits-reseller .item .text {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
}
.content-dashboard .v1 .benefits-reseller .item .text {
  font-size: 16px;
}
.v1 .benefits-reseller .item .text .title {
  font-weight: bold;
  margin-bottom: 0;
}
.content-dashboard .v1 .benefits-reseller .item .text .description {
  color: #4f5559;
}
.v1 ul.bitbag-frequently-asked-question {
  padding-left: 15px;
}
.v1 ul.bitbag-frequently-asked-question li.block-bitbag-faq {
  list-style-type: square;
  padding: 15px 0 15px 15px;
  margin: 0;
}
.v1 ul.bitbag-frequently-asked-question li.block-bitbag-faq h2.bitbag-question {
  margin-bottom: 15px;
}
.v1 .block {
  padding: 15px;
}
.v1 .block-33 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 33%;
  -ms-flex: 1 1 33%;
  flex: 1 1 33%;
}
@media (max-width: 999px) {
  .v1 .block-33 {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
.v1 .block-40 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 40%;
  -ms-flex: 1 1 40%;
  flex: 1 1 40%;
}
@media (max-width: 999px) {
  .v1 .block-40 {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
.v1 .block-50 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
}
@media (max-width: 999px) {
  .v1 .block-50 {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
.v1 .block-60 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 60%;
  -ms-flex: 1 1 60%;
  flex: 1 1 60%;
}
@media (max-width: 999px) {
  .v1 .block-60 {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
.v1 .block-66 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 66%;
  -ms-flex: 1 1 66%;
  flex: 1 1 66%;
}
@media (max-width: 999px) {
  .v1 .block-66 {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
.v1 .block-100 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}
.v1 .block .inner {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  padding: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.v1 .block .inner .preview-info-tooltip {
  color: #03a9f4;
}
.v1 .block .inner .header,
.v1 .block .inner .content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.v1 .block .inner .header {
  margin-bottom: 20px;
}
@media (max-width: 999px) {
  .v1 .block .inner .header {
    position: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.v1 .block .inner .header .title {
  color: #888b8d;
  font-size: 18px;
  font-weight: bold;
}
.v1 .block .inner .header a {
  float: right;
  font-size: 14px;
}
.v1 .block.block-table .content div.table {
  margin: 0 -15px -15px;
  width: auto;
  border-width: 1px 0 0;
  font-size: 14px;
}
.v1 .block.block-table .content div.table .row-header,
.v1 .block.block-table .content div.table .row-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.v1 .block.block-table .content div.table .row-header .col,
.v1 .block.block-table .content div.table .row-content .col {
  border: 1px solid #e8e8e8;
  border-width: 0 1px 1px 0;
  padding: 20px;
}
.v1 .block.block-table .content div.table .row-header .col:last-child,
.v1 .block.block-table .content div.table .row-content .col:last-child {
  border-right: 0;
}
.v1 .block.block-table .content div.table .row-header .col:first-child,
.v1 .block.block-table .content div.table .row-content .col:first-child {
  border-left: 0;
}
.v1 .block.block-table .content div.table .row-content:last-child .col {
  border-bottom: 0;
}
.v1 .block.block-table .content div.table .row-header {
  color: #7c7e7e;
}
@media (max-width: 999px) {
  .v1 .block.block-table .content div.table .row-header {
    display: none;
  }
}
.v1 .block.block-table .content div.table .row-content .col.empty {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-style: italic;
  color: #888b8d;
  text-align: center;
  padding: 50px;
}
.v1 .block.block-table .content div.table .row-content .col.actions {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.v1 .block.block-table .content div.table .row-footer {
  padding: 20px;
}
.v1 .breadcrumbs {
  max-width: 50%;
}
@media (max-width: 767px) {
  .v1 .breadcrumbs {
    display: none;
  }
}
.v1 .breadcrumbs__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (max-width: 767px) {
  .v1 .breadcrumbs__list {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
  .v1 .breadcrumbs__list::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 767px) {
  .v1 .breadcrumbs__list {
    padding: 0 15px;
  }
}
.v1 .breadcrumbs__list-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-right: 25px;
  margin-right: 10px;
}
.v1 .breadcrumbs__list-item:after {
  content: "\e902";
  position: absolute;
  top: 50%;
  right: 0;
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  color: #888b8d;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.v1 .breadcrumbs__list-item:last-child {
  padding-right: 0;
  margin-right: 0;
}
.v1 .breadcrumbs__list-item:last-child:after {
  content: none;
}
.v1 .breadcrumbs__list-item.active .breadcrumbs__link {
  color: #434343;
}
.v1 .breadcrumbs__link {
  font-size: 17px;
  color: #888b8d;
  letter-spacing: 0.24pt;
  text-decoration: none;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
@media (max-width: 767px) {
  .v1 .breadcrumbs__link {
    font-size: 15px;
  }
}
.v1 .breadcrumbs__link:hover {
  color: #434343;
}
.canva-grid-banner {
  height: 275px;
  margin-bottom: 18px;
  margin-left: 9px;
  margin-right: 9px;
  width: 100%;
}
@media (max-width: 767px) {
  .canva-grid-banner {
    height: 180px;
    margin-bottom: 15px;
  }
}
.canva-grid-banner__background {
  background: url("i/canva/grid_banner_background.png");
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 100%;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  line-height: 1.2;
  padding: 20px;
  width: 100%;
}
@media (max-width: 767px) {
  .canva-grid-banner__background {
    background: url("i/canva/mobile/grid_banner_background.png");
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    padding: 10px;
  }
}
.canva-grid-banner__button {
  background: #fff;
  border: none;
  -webkit-box-shadow: 0 6px 16px rgba(0,0,0,0.2);
  box-shadow: 0 6px 16px rgba(0,0,0,0.2);
  color: #23bfc8;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 40px;
  padding: 10px 20px;
}
.canva-grid-banner__button:hover,
.canva-grid-banner__button:active {
  background: #fff;
  -webkit-box-shadow: 0 6px 16px rgba(0,0,0,0.4);
  box-shadow: 0 6px 16px rgba(0,0,0,0.4);
  color: #23bfc8;
}
.canva-grid-banner__button:active {
  -webkit-box-shadow: 0 6px 16px rgba(0,0,0,0.6);
  box-shadow: 0 6px 16px rgba(0,0,0,0.6);
}
@media (max-width: 767px) {
  .canva-grid-banner__button {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 6px 16px;
  }
}
.canva-grid-banner__content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 66%;
  -ms-flex: 0 66%;
  flex: 0 66%;
}
@media (max-width: 767px) {
  .canva-grid-banner__content {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 75%;
    -ms-flex: 0 75%;
    flex: 0 75%;
  }
}
.canva-grid-banner__desc {
  font-size: 1.4rem;
}
@media (max-width: 767px) {
  .canva-grid-banner__desc {
    font-size: 16px;
  }
}
.canva-grid-banner__logo {
  height: 96px;
  margin-left: 20px;
  width: 96px;
}
@media (max-width: 767px) {
  .canva-grid-banner__logo {
    height: 48px;
    margin-left: 10px;
    width: 48px;
  }
}
.canva-grid-banner__logo img {
  width: 100%;
}
.canva-grid-banner__title {
  font-size: 2rem;
  font-weight: bold;
  padding: 20px 0;
}
@media (max-width: 767px) {
  .canva-grid-banner__title {
    font-size: 24px;
    padding: 10px 0;
  }
}
.canva-grid-element a {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background: url("i/canva/grid_element_background.png");
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  border-radius: 2px 2px 0 0;
  color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 14px;
  height: 275px;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s box-shadow ease-in-out;
  transition: 0.2s box-shadow ease-in-out;
}
.canva-grid-element a:hover {
  -webkit-box-shadow: 0 4px 18px rgba(0,0,0,0.15);
  box-shadow: 0 4px 18px rgba(0,0,0,0.15);
}
@media (max-width: 767px) {
  .canva-grid-element a {
    background: url("i/canva/mobile/grid_element_background.png");
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    height: 180px;
    padding: 10px;
  }
}
.canva-grid-element__button {
  background: #fff;
  border: none;
  -webkit-box-shadow: 0 6px 16px rgba(0,0,0,0.2);
  box-shadow: 0 6px 16px rgba(0,0,0,0.2);
  color: #23bfc8;
  font-weight: bold;
  margin-top: 20px;
  padding: 10px 20px;
}
.canva-grid-element__button:hover,
.canva-grid-element__button:active {
  background: #fff;
  -webkit-box-shadow: 0 6px 16px rgba(0,0,0,0.4);
  box-shadow: 0 6px 16px rgba(0,0,0,0.4);
  color: #23bfc8;
}
.canva-grid-element__button:active {
  -webkit-box-shadow: 0 6px 16px rgba(0,0,0,0.6);
  box-shadow: 0 6px 16px rgba(0,0,0,0.6);
}
@media (max-width: 767px) {
  .canva-grid-element__button {
    font-size: 14px;
    margin-top: 10px;
    padding: 6px 16px;
  }
}
.canva-grid-element__desc {
  line-height: 1.25;
}
.canva-grid-element__logo {
  height: 96px;
  margin-bottom: 20px;
  width: 96px;
}
@media (max-width: 767px) {
  .canva-grid-element__logo {
    height: 48px;
    margin-bottom: 10px;
    width: 48px;
  }
}
.canva-grid-element__logo img {
  width: 100%;
}
.v1 .buttons-panel {
  padding: 0 15px;
  background-color: #333;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .buttons-panel__item {
  position: relative;
  width: 33.333333%;
  padding: 6px;
  font-size: 15px;
  line-height: 1.2;
  color: #e2e2e2;
  text-decoration: none;
  text-align: center;
}
.v1 .buttons-panel__item:after {
  content: "";
  position: absolute;
  top: 8px;
  bottom: 8px;
  right: 0;
  width: 1px;
  background-color: #474747;
}
.v1 .buttons-panel__item:last-child:after {
  content: none;
}
.v1 .buttons-panel__item-icon {
  display: block;
  margin-bottom: 5px;
  height: 20px;
}
.v1 .buttons-panel__item-icon-i {
  max-height: 100%;
}
.v1 .buttons-panel__item-title {
  display: block;
  font-weight: 500;
}
.v1 .cart {
  margin-bottom: 40px;
}
@media (min-width: 1000px) {
  .v1 .cart {
    background-color: #fff;
    border: 1px solid #e8e8e8;
  }
}
@media (max-width: 767px) {
  .v1 .cart {
    margin: 0 -15px 30px;
  }
}
.v1 .cart .secondary {
  color: #888b8d;
}
.v1 .cart__header {
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .cart__header {
    font-size: 18px;
  }
}
@media (max-width: 999px) {
  .v1 .cart__header {
    display: none;
  }
}
@media (min-width: 1240px) {
  .v1 .cart__header .cart__col {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.v1 .cart__header .secondary {
  font-size: 16px;
}
.v1 .cart__body .secondary {
  font-size: 14px;
}
@media (min-width: 1000px) {
  .v1 .cart__body .cart__row {
    border-bottom: 1px solid #e8e8e8;
  }
  .v1 .cart__body .cart__row:last-child {
    border: 0 none;
  }
  .v1 .cart__body .cart__col {
    border-right: 1px solid #e8e8e8;
  }
  .v1 .cart__body .cart__col-separator {
    position: relative;
  }
  .v1 .cart__body .cart__col-separator span {
    position: absolute;
    background: #fff;
    left: 50%;
    padding: 0 15px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-top: -8px;
    color: #a2a2a2;
    font-size: 75%;
  }
  .v1 .cart__body .cart__col-separator hr {
    margin: 30px 0;
  }
  .v1 .cart__body .cart__col:last-child {
    border: 0 none;
  }
}
.v1 .cart__row {
  position: relative;
}
@media (min-width: 1000px) {
  .v1 .cart__row {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-transition: 0.2s background-color ease-in-out;
    -moz-transition: 0.2s background-color ease-in-out;
    -o-transition: 0.2s background-color ease-in-out;
    -ms-transition: 0.2s background-color ease-in-out;
    transition: 0.2s background-color ease-in-out;
  }
  .v1 .cart__row:hover {
    background-color: #fcfcfd;
  }
  .v1 .cart__row:hover .cart__controls {
    height: 100%;
    padding-right: 15px;
    visibility: visible;
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: 0.1s;
    -moz-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    -ms-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  .cart__header .v1 .cart__row {
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
@media (max-width: 999px) {
  .v1 .cart__row {
    margin-bottom: 20px;
    background-color: #fff;
    margin-left: 15px;
    margin-right: 15px;
    border: 1px solid #e8e8e8;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .cart__row {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .v1 .cart__row {
    padding: 15px;
  }
}
@media (min-width: 1000px) {
  .v1 .cart__col {
    width: 10%;
    padding: 20px 15px;
  }
}
@media (min-width: 1240px) {
  .v1 .cart__col_lg,
  .v1 .cart__col_main {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (min-width: 1000px) {
  .v1 .cart__col_lg {
    width: 53%;
  }
}
@media (min-width: 1000px) {
  .v1 .cart__col_main {
    width: 23%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .cart__col_main {
    padding-right: 70px;
  }
}
@media (min-width: 1000px) {
  .v1 .cart__col_sm {
    width: 18%;
  }
}
.v1 .cart__col_centered {
  text-align: center;
}
@media (max-width: 999px) {
  .v1 .cart__col_section {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e9e9e9;
  }
}
.v1 .cart__col_section .preview-info-tooltip {
  color: #03a9f4;
  font-size: 75%;
}
.v1 .cart__col-label {
  display: none;
  font-weight: bold;
}
@media (max-width: 999px) {
  .v1 .cart__col-label {
    display: block;
  }
  .v1 .cart__col-label_inline {
    display: inline;
    margin: 0 5px 0 0;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .cart__col-label {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .v1 .cart__col-label {
    margin-bottom: 10px;
    font-size: 15px;
  }
}
@media (min-width: 1000px) {
  .v1 .cart__col-value {
    padding-top: 40px;
    text-align: center;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .cart__col-value {
    font-size: 15px;
  }
}
@media (max-width: 999px) {
  .v1 .cart__col-value {
    display: inline;
  }
}
@media (max-width: 767px) {
  .v1 .cart__col-value {
    font-size: 15px;
  }
  .v1 .cart__col-value p {
    display: inline-block;
  }
}
.v1 .cart__col-value p.date-title {
  font-size: 14px;
  font-weight: bold;
  color: #888b8d;
}
@media (max-width: 999px) {
  .v1 .cart__col_mobile-last-line {
    float: left;
    width: 50%;
    margin-top: -15px;
    margin-bottom: -15px;
    padding: 10px;
  }
  .v1 .cart__col_mobile-last-line .cart__col-value {
    display: block;
  }
  .v1 .cart__col_mobile-last-line .cart__col-value p {
    display: block;
  }
  .v1 .cart__col_mobile-last-line .cart__col-value.cart__estimated-delivery-date {
    font-size: 12px;
  }
  .v1 .cart__col_mobile-last-line .cart__col-value.cart__estimated-delivery-date p {
    font-size: 12px;
  }
  .v1 .cart__col_mobile-last-line .cart__col-value.cart__estimated-delivery-date p.date-title {
    font-size: 12px;
  }
  .v1 .cart__col_mobile-last-line .cart__price {
    margin-top: 10px;
    font-size: 18px;
  }
  .v1 .cart__col_mobile-last-line .cart__price p.secondary {
    font-size: 18px;
  }
  .v1 .cart__col_mobile-last-line .cart__price p.primary.discount {
    font-size: 14px;
  }
  .v1 .cart__col_mobile-last-line.second {
    border-left: 1px solid #e9e9e9;
  }
}
.v1 .cart__product {
  zoom: 1;
}
.v1 .cart__product:before,
.v1 .cart__product:after {
  content: "";
  display: table;
}
.v1 .cart__product:after {
  clear: both;
}
@media (min-width: 1000px) {
  .v1 .cart__product {
    margin: 0 0 10px;
  }
}
.v1 .cart__product-image {
  float: left;
  margin-right: 12px;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
@media (max-width: 999px) {
  .v1 .cart__product-image {
    width: 100%;
    height: 100%;
    max-width: 110px;
    -webkit-box-align: baseline;
    -moz-box-align: baseline;
    -o-box-align: baseline;
    -ms-flex-align: baseline;
    -webkit-align-items: baseline;
    align-items: baseline;
  }
}
.v1 .cart__product-image img,
.v1 .cart__product-image picture {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}
.v1 .cart__product-body {
  overflow: hidden;
}
.v1 .cart__product-item {
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 50px;
}
@media (max-width: 999px) {
  .v1 .cart__product-item {
    font-size: 14px;
    line-height: inherit;
  }
}
.v1 .cart__product-item:last-child {
  margin-bottom: 0;
}
.v1 .cart__product-item-details {
  color: #888b8d;
  font-size: 75%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.v1 .cart__product-item-details ul {
  list-style-type: none;
  padding-left: 0;
  margin: 5px 0 0;
}
.v1 .cart__product-item-details__options-inline li {
  display: inline;
  border-right: solid 1px #d6d5d5;
  padding-right: 6px;
}
.v1 .cart__product-item-details__options-inline li:last-child {
  border: none;
  padding-right: 0;
}
.v1 .cart__product-item-details a {
  color: #f460a3;
  text-decoration: none;
}
.v1 .cart__product-item a {
  text-decoration: none;
}
.v1 .cart__product-item .actions {
  list-style-type: none;
  padding-left: 0;
  margin: 10px 0 0;
}
.v1 .cart__product-item .actions a {
  color: #f460a3;
  text-decoration: none;
}
.v1 .cart__product-item p {
  margin-bottom: 0px;
}
.v1 .cart__product-item p.small {
  font-size: 12px;
}
@media (max-width: 999px) {
  .v1 .cart__upload,
  .v1 .cart__file {
    display: none;
  }
}
.v1 .cart__file {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .cart__file-content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  padding-right: 15px;
}
@media (min-width: 1000px) {
  .v1 .cart__file-content .form-group.has-error {
    margin-right: -115px;
  }
}
.v1 .cart__file-text {
  margin-bottom: 10px;
  font-weight: 500;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .cart__file-text {
    font-size: 16px;
  }
}
.v1 .cart__file-text a {
  text-decoration: none;
}
.v1 .cart__file-text a:hover {
  color: #f460a3;
}
.v1 .cart__file-text.dropzone-clickable:hover {
  color: #f460a3;
  cursor: pointer;
}
.v1 .cart__file-list {
  min-height: 120px;
}
.v1 .cart__file-check {
  display: inline-block;
}
.v1 .cart__file-check-label {
  display: inline;
  font-size: 14px;
  color: #888b8d;
}
.v1 .cart__file .select7__current {
  padding-right: 8px !important;
}
.v1 .cart__file .select7__current-value {
  font-size: 14px;
  color: #888b8d;
  border-bottom: 0 none !important;
}
.v1 .cart__file .select7__option {
  font-size: 14px;
}
.v1 .cart__file .select7__drop {
  padding-top: 37px;
}
.v1 .cart__file .select7__caret {
  border-width: 6px 5px 0;
}
.v1 .cart__file .select7_open .select7__caret {
  border-width: 0 5px 6px;
}
.v1 .cart__file .upload-file {
  width: 105px;
  height: 105px;
}
@media (min-width: 1000px) {
  .v1 .cart__add {
    display: none;
  }
}
.v1 .cart__add-title {
  margin-bottom: 5px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .v1 .cart__add-title {
    font-size: 14px;
  }
}
.v1 .cart__add-field {
  margin-bottom: 10px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .cart__add-field {
    width: 70%;
  }
}
.v1 .cart__add-more {
  color: #f460a3;
  font-weight: bold;
  text-decoration: none;
}
@media (max-width: 767px) {
  .v1 .cart__add-more {
    font-size: 15px;
  }
}
.no-touchevents .v1 .cart__add-more:hover {
  color: #434343;
}
.v1 .cart__controls {
  position: absolute;
  top: 0;
  padding: 20px 0 0 15px;
}
@media (min-width: 1000px) {
  .v1 .cart__controls {
    left: 100%;
    visibility: hidden;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    transform: translateX(-5px);
    -webkit-transition: 0.2s opacity ease-in-out, 0.2s -webkit-transform ease-in-out;
    -moz-transition: 0.2s opacity ease-in-out, 0.2s -moz-transform ease-in-out;
    -o-transition: 0.2s opacity ease-in-out, 0.2s -o-transform ease-in-out;
    -ms-transition: 0.2s opacity ease-in-out, 0.2s -ms-transform ease-in-out;
    transition: 0.2s opacity ease-in-out, 0.2s transform ease-in-out;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .cart__controls {
    right: 20px;
    display: none;
  }
}
@media (max-width: 767px) {
  .v1 .cart__controls {
    display: none;
  }
}
.v1 .cart__control-link {
  display: block;
  width: 48px;
  height: 48px;
  padding: 10px 0 0 1px;
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: normal;
  border-radius: 100px;
}
@media (max-width: 999px) {
  .v1 .cart__control-link {
    border: 0 none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background: none !important;
    color: #c8c8c8 !important;
  }
}
@media (max-width: 767px) {
  .v1 .cart__control-link {
    margin: 0 0 0 7px;
    padding: 5px 0 0 1px;
    width: 30px;
    height: 30px;
  }
}
.v1 .cart__attach {
  zoom: 1;
  margin-bottom: 5px;
}
.v1 .cart__attach:before,
.v1 .cart__attach:after {
  content: "";
  display: table;
}
.v1 .cart__attach:after {
  clear: both;
}
@media (min-width: 1240px) {
  .v1 .cart__attach_big {
    margin-bottom: 10px;
  }
  .v1 .cart__attach_big .cart__attach-icon {
    width: 72px;
    margin: 0 -5px -15px -20px;
  }
  .v1 .cart__attach_big .cart__attach-content {
    padding-top: 25px;
  }
  .v1 .cart__attach_big .cart__attach-controls {
    top: 25px;
  }
}
.v1 .cart__attach-icon {
  float: left;
  margin: 0 5px -5px -10px;
  width: 35px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .cart__attach-icon {
    display: none;
  }
}
.v1 .cart__attach-icon img {
  display: block;
  width: 100% !important;
  height: auto !important;
}
.v1 .cart__attach-content {
  position: relative;
  display: inline-block;
  overflow: hidden;
  min-height: 28px;
  padding: 5px 55px 0 0;
}
.v1 .cart__attach-title {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 154px;
  font-size: 16px;
  font-weight: 500;
}
.v1 .cart__attach-controls {
  position: absolute;
  top: 5px;
  right: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.v1 .cart__attach_file_thumb:not(:first-child) {
  padding-left: 5px;
}
.v1 .cart__attach_file_thumb:not(:last-child) {
  padding-right: 5px;
}
.v1 .cart__attach_file_thumbs-message {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .cart__attach_file_thumbs-message p {
  color: #aeaeae;
  font-size: 70%;
}
.v1 .cart__attach_file_thumbs {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .cart__attach_file_thumb {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
}
.v1 .cart__attach_file_thumb__preview_box {
  background-color: #eee;
  height: 100px;
  font-size: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.v1 .cart__attach_file_thumb__preview_box__content {
  text-align: center;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 125px;
  -ms-flex: 0 0 125px;
  flex: 0 0 125px;
}
.v1 .cart__attach_file_thumb-image {
  position: relative;
  text-align: center;
}
.v1 .cart__attach_file_thumb-image_picture {
  max-height: 275px;
  max-width: 100%;
}
.v1 .cart__attach_file_thumb-action-list {
  background: rgba(255,255,255,0.831);
  position: absolute;
  bottom: 5px;
  padding: 5px;
  width: 100%;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.v1 .cart__attach_file_thumb-action-item {
  width: 100%;
  display: block;
}
.v1 .cart__attach-control {
  display: block;
  min-width: 20px;
  margin-left: 5px;
  font-size: 18px;
  color: #dcdcdd;
  text-decoration: none;
  text-align: center;
}
.v1 .cart__attach-control:first-child {
  margin-left: 0;
}
.v1 .cart__attach-control_view {
  font-size: 24px;
}
.v1 .cart__attach-control_remove {
  margin-top: 1px;
}
.v1 .cart__attach-control:hover {
  color: #f460a3;
}
.v1 .cart p {
  margin-bottom: 0;
}
.v1 .cart__help {
  font-size: 14px;
  color: #888b8d;
  margin-top: 16px;
}
.v1 .cart__help a {
  color: #3cbdf7;
}
.v1 .order-item-action-link {
  color: #f460a3;
  text-decoration: none;
  font-size: 75%;
}
.v1 .order-item-customer-comment_container hr {
  margin: 12px 0;
}
.v1 .order-item-customer-comment_edit-button {
  color: #f460a3;
  text-decoration: none;
  font-size: 75%;
}
.v1 .order-item-customer-comment_form p {
  margin-bottom: 10px;
  color: #888b8d;
  font-size: 75%;
}
.v1 .order-item-customer-comment_form .form-group {
  margin-bottom: 10px;
}
.v1 .order-item-customer-comment_form_actions {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.v1 .order-item-customer-comment_form_actions-item {
  padding: 0 5px;
}
.v1 .change-pass__title {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .v1 .change-pass__title {
    margin-bottom: 20px;
    font-size: 15px;
  }
}
.v1 .change-pass__form-footer {
  margin-top: 50px;
}
@media (max-width: 767px) {
  .v1 .change-pass__form-footer {
    margin-top: 20px;
  }
}
.v1 .chat {
  border: 1px solid #e7e7e7;
  background-color: #fff;
}
.v1 .chat_slim {
  max-width: 555px;
  margin-left: auto;
  margin-right: auto;
}
.v1 .chat__messages {
  position: relative;
  height: 265px;
  overflow-y: auto;
}
.v1 .chat__messages-inner {
  padding: 30px;
}
@media (max-width: 767px) {
  .v1 .chat__messages-inner {
    padding: 15px;
  }
}
.v1 .chat__message {
  padding-bottom: 45px;
  font-size: 14px;
}
@media (max-width: 767px) {
  .v1 .chat__message {
    padding-bottom: 25px;
  }
}
.v1 .chat__message:last-child {
  padding-bottom: 0;
}
.v1 .chat__message-text {
  white-space: pre-line;
}
.v1 .chat__message-info {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 5px;
}
.v1 .chat__message-username {
  margin-right: 10px;
  font-weight: 500;
}
.v1 .chat__message-date {
  color: #888b8d;
}
.v1 .chat__input {
  border-top: 1px solid #e6e6e6;
}
.v1 .chat__input-field {
  padding: 18px 60px 19px 30px !important;
  height: 60px;
  border: 0 none;
  font-size: 17px;
  color: #3b3b3b;
}
.v1 .chat__input-field:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.v1 .chat__input-button {
  top: 50% !important;
  right: 15px;
  left: auto !important;
  margin-top: -15px;
  padding: 0;
  background: none;
  border: 0 none;
  font-size: 23px;
  color: #8f9193;
  outline: none;
  cursor: pointer;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
.v1 .chat__input-button:hover {
  color: #f460a3;
}
.v1 .chat__actions {
  padding: 15px;
  background-color: #fafafa;
  text-align: center;
}
.v1 .chat__button {
  font-size: 17px;
  padding: 10px 21px;
}
.v1 .chat .upload-container {
  padding: 18px 20px 19px 30px;
  position: relative;
}
.v1 .check-link {
  display: inline-block;
  width: 72px;
  height: 38px;
  padding: 8px 10px;
  text-decoration: none;
  text-align: center;
  border-radius: 20px;
  background-color: #f460a3;
  color: #fff;
  -webkit-box-shadow: 0 4px 12px rgba(255,145,195,0.5);
  box-shadow: 0 4px 12px rgba(255,145,195,0.5);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v1 .check-link:active,
.v1 .check-link:hover {
  color: #fff;
  -webkit-box-shadow: 0 3px 7px rgba(0,0,0,0.25) inset;
  box-shadow: 0 3px 7px rgba(0,0,0,0.25) inset;
}
.v1 .check-link:before {
  content: "\e906";
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.v1 .check-link_simple {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #aaa;
  background-color: transparent;
  color: #a8a8a9;
  -webkit-transition: 0.2s border-color ease-in-out, 0.2s color ease-in-out, 0.2s background-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out, 0.2s color ease-in-out, 0.2s background-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out, 0.2s color ease-in-out, 0.2s background-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out, 0.2s color ease-in-out, 0.2s background-color ease-in-out;
  transition: 0.2s border-color ease-in-out, 0.2s color ease-in-out, 0.2s background-color ease-in-out;
}
.v1 .check-link_simple:hover,
.v1 .check-link_simple:active {
  border-color: #f460a3;
  background-color: #f460a3;
  color: #fff;
}
@media (min-width: 1000px) {
  .minigrid .v1 .check-link {
    width: 32px;
    height: 32px;
    padding: 4px 5px;
  }
}
@media (min-width: 768px) {
  .minigrid .v1 .check-link {
    width: 32px;
    height: 32px;
    padding: 4px 5px;
  }
}
@media (min-width: 1000px) {
  .params-board .v1 .check-link {
    width: 32px;
    height: 32px;
    padding: 4px 5px;
  }
}
@media (min-width: 768px) {
  .params-board .v1 .check-link {
    width: 32px;
    height: 32px;
    padding: 4px 5px;
  }
}
@media (min-width: 1000px) {
  .params-board.params-board_items-2 .v1 .check-link {
    width: 72px;
    height: 38px;
    padding: 8px 10px;
  }
}
@media (min-width: 768px) {
  .params-board.params-board_items-2 .v1 .check-link {
    width: 72px;
    height: 38px;
    padding: 8px 10px;
  }
}
.quotation-pricings .v1 .check-link {
  display: none;
}
@media (min-width: 1000px) {
  .v1 .minigrid .check-link {
    width: 32px;
    height: 32px;
    padding: 4px 5px;
  }
}
@media (min-width: 768px) {
  .v1 .minigrid .check-link {
    width: 32px;
    height: 32px;
    padding: 4px 5px;
  }
}
@media (min-width: 1000px) {
  .v1 .params-board .check-link {
    width: 32px;
    height: 32px;
    padding: 4px 5px;
  }
}
@media (min-width: 768px) {
  .v1 .params-board .check-link {
    width: 32px;
    height: 32px;
    padding: 4px 5px;
  }
}
@media (min-width: 1000px) {
  .v1 .params-board.params-board_items-2 .check-link {
    width: 72px;
    height: 38px;
    padding: 8px 10px;
  }
}
@media (min-width: 768px) {
  .v1 .params-board.params-board_items-2 .check-link {
    width: 72px;
    height: 38px;
    padding: 8px 10px;
  }
}
.v1 .quotation-pricings .check-link {
  display: none;
}
#checkout-confirm .modal-content,
#unsupportedFiles .modal-content {
  padding: 20px 50px 50px;
}
.checkout-confirm p.title,
.unsupportedFiles p.title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.checkout-confirm .content,
.unsupportedFiles .content {
  font-size: 15px;
  padding-bottom: 30px;
}
.checkout-confirm .btns,
.unsupportedFiles .btns {
  text-align: right;
}
.checkout-confirm .btns .btn,
.unsupportedFiles .btns .btn {
  margin-left: 40px;
}
.checkout-confirm .clear,
.unsupportedFiles .clear {
  clear: both;
}
.v1 .cms-content .container {
  padding-top: 50px;
  padding-bottom: 50px;
}
.v1 .cms-content .container embed,
.v1 .cms-content .container iframe,
.v1 .cms-content .container object,
.v1 .cms-content .container video {
  max-width: 100%;
}
.v1 .cms-content .container img {
  max-width: 90%;
}
.v1 .cms-content li {
  margin-bottom: 10px;
}
.v1 .cms-content dd {
  margin-bottom: 10px;
}
.v1 .cms-content dt {
  margin-bottom: 10px;
}
.v1 .block-cms-menu {
  margin-bottom: 20px;
}
.v1 .col-add-to-cart-inner {
  background-color: #ffcfeb;
  padding: 25px 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 25px;
}
.v1 .col-add-to-cart-inner .fa-check {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  font-size: 80px;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
  padding: 0 25px;
  text-align: center;
}
.v1 .col-add-to-cart-inner div.text {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-right: 5px;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
}
.v1 .col-add-to-cart-inner div.prices {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  font-size: 26px;
}
.v1 .col-add-to-cart-inner div.prices .price-ht {
  font-weight: bold;
}
.v1 .col-add-to-cart-inner div.prices p {
  margin-bottom: 0;
}
.v1 .col-add-to-cart-inner div.prices p.estimated-delivery-date {
  font-size: 14px;
}
.v1 .col-add-to-cart-inner .inner {
  padding: 0 15px;
}
.v1 .commander-des__info {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .commander-des__adress-wrap {
  border-radius: 8px;
  border: 1px solid rgba(165,165,165,0.45);
  margin: 15px 0;
}
.v1 .commander-des__delivery {
  background-color: #fcfcfc;
  padding: 20px;
  border-radius: 7px 8px 0 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.v1 .commander-des__title {
  color: #434343;
  font-size: 20px;
  letter-spacing: 1pt;
  line-height: 28px;
  font-weight: 300;
  -webkit-flex-basis: 95%;
  flex-basis: 95%;
}
.v1 .commander-des__icon {
  width: 49px;
  height: 49px;
  -webkit-box-shadow: 0 8px 24px rgba(255,145,194,0.5);
  box-shadow: 0 8px 24px rgba(255,145,194,0.5);
  background-color: #f560a3;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}
.v1 .commander-des__icon:before {
  font-family: 'icons' !important;
  font-size: 20px;
  content: "\e90c";
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.v1 .com-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .com-wrap__pic__wrap {
  display: inline-block;
}
.v1 .com-wrap__pic {
  margin-right: 20px;
}
.v1 .com-wrap__text {
  color: #888b8d;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 1pt;
  line-height: 25px;
}
.v1 .com-wrap__text__status {
  font-size: 16px;
  color: #343434;
  font-weight: 400;
  margin-bottom: 10px;
}
.v1 .com-wrap__text__status span {
  color: #434343;
  font-weight: 600;
}
.v1 .adress-info {
  padding: 20px;
}
.v1 .adress-info__list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 85%;
}
.v1 .adress-info__item {
  width: 49%;
  display: inline-block;
}
.v1 .adress-info__text {
  color: #434343;
  font-size: 18px;
  font-weight: 400;
}
.v1 .commitments-block {
  margin: 20px -10px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.v1 .commitments-block .item {
  width: 25%;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}
@media (max-width: 767px) {
  .v1 .commitments-block .item {
    width: 100%;
  }
}
@media (max-width: 999px) {
  .v1 .commitments-block .item {
    width: 100%;
  }
}
.v1 .commitments-block .item .item-inner {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  margin: 10px 10px;
  padding: 20px;
  height: 100%;
  background-color: #fff;
}
@media (max-width: 767px) {
  .v1 .commitments-block .item .item-inner {
    height: auto;
  }
}
.v1 .commitments-block .item .item-inner .title {
  font-weight: bold;
  margin-bottom: 15px;
}
.v1 .commitments-block .item .item-inner .description {
  color: #888b8d;
}
.v1 .commitments-block .item .item-inner .image {
  text-align: center;
  padding: 10px 0;
}
.v1 .commitments-block .item .item-inner .image img {
  width: 100px;
}
.v1 .confirm-order {
  background-color: #fff;
}
.v1 .confirm-order__inner {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 30px 0;
}
@media (max-width: 767px) {
  .v1 .confirm-order__inner {
    padding: 40px 0 20px;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.v1 .confirm-order__prices,
.v1 .confirm-order__controls {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
}
@media (max-width: 767px) {
  .v1 .confirm-order__prices,
  .v1 .confirm-order__controls {
    -webkit-flex-basis: auto;
    flex-basis: auto;
    width: 100%;
  }
}
.v1 .confirm-order__controls {
  text-align: right;
}
.v1 .confirm-order__price_no-taxes {
  font-size: 30px;
  font-weight: 500;
  color: #333;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .v1 .confirm-order__price_no-taxes {
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
  }
}
.v1 .confirm-order__price_taxes {
  font-size: 17px;
  color: #888b8d;
}
@media (max-width: 767px) {
  .v1 .confirm-order__price_taxes {
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
  }
}
.v1 .confirm-order__button {
  min-width: 270px;
}
@media (max-width: 767px) {
  .v1 .confirm-order__button {
    width: 100%;
    font-size: 16px;
  }
}
.v1 .confirm-order__bottom-fixed {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-top: 1px solid #f0f0f0;
}
@media (min-width: 1000px) {
  .v1 .contacts-box {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .contacts-box {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .v1 .contacts-box {
    margin-top: 15px;
  }
}
@media (min-width: 1000px) {
  .v1 .contacts-box__content {
    -webkit-flex-basis: 45%;
    flex-basis: 45%;
    padding-top: 25px;
  }
}
@media (min-width: 1000px) {
  .v1 .contacts-box__question {
    -webkit-flex-basis: 55%;
    flex-basis: 55%;
  }
}
@media (min-width: 1240px) {
  .v1 .contacts-box__question {
    padding-right: 100px;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .contacts-box__question {
    padding-right: 50px;
  }
}
@media (max-width: 999px) {
  .v1 .contacts-box__question {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .v1 .contacts-box__question {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.v1 .contacts-box__section {
  margin-bottom: 60px;
}
@media (max-width: 767px) {
  .v1 .contacts-box__section {
    margin-bottom: 30px;
  }
}
.v1 .contacts-box__section:last-child {
  margin-bottom: 0;
}
.v1 .contacts-box__section-title {
  margin: 0 0 20px;
  font-size: 30px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .contacts-box__section-title {
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .v1 .contacts-box__section-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 10px;
  }
}
.v1 .contacts-box__info {
  font-size: 20px;
}
.v1 .contacts-box__info-item {
  margin-bottom: 10px;
}
.v1 .contacts-box__info-icon {
  display: inline-block;
  vertical-align: top;
  width: 30px;
  margin: 2px 10px 0 0;
  font-size: 23px;
  text-align: center;
  color: #c1c1c1;
}
.v1 .contacts-box__info-label {
  margin-right: 20px;
  color: #888b8d;
}
@media (max-width: 767px) {
  .v1 .contacts-box__info-label {
    font-size: 16px;
    margin-right: 10px;
  }
}
.v1 .contacts-box__info-value {
  font-weight: 500;
}
@media (max-width: 767px) {
  .v1 .contacts-box__info-value {
    font-size: 16px;
  }
}
.v1 .contacts-box__info a {
  color: #434343;
  text-decoration: none;
}
.v1 .contacts-box__info a:hover {
  color: #f460a3;
}
@media (min-width: 1240px), (min-width: 768px) and (max-width: 999px) {
  .v1 .contacts-box__list {
    columns: 2;
  }
}
.v1 .contacts-box__list-item {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 10px;
  padding-right: 15px;
}
.v1 .contacts-box__link {
  font-size: 20px;
  line-height: 1.3;
  text-decoration: none;
  color: #55acee;
}
@media (max-width: 767px) {
  .v1 .contacts-box__link {
    font-size: 16px;
  }
}
.v1 .contacts-box__link:hover {
  color: #f460a3;
}
.v1.container:not(.exclude),
.v1 .container:not(.exclude) {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  zoom: 1;
}
.v1.container:not(.exclude):before,
.v1 .container:not(.exclude):before,
.v1.container:not(.exclude):after,
.v1 .container:not(.exclude):after {
  content: "";
  display: table;
}
.v1.container:not(.exclude):after,
.v1 .container:not(.exclude):after {
  clear: both;
}
.v1 .content-box {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .v1 .content-box {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .content-box_simple {
    margin-bottom: 0;
  }
}
@media (min-width: 1000px) {
  .v1 .content-box_simple .content-box__content {
    padding-left: 15px;
  }
}
@media (max-width: 767px) {
  .v1 .content-box_simple .content-wrap {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    background-color: transparent;
  }
}
.v1 .content-box__wrap {
  display: block;
}
@media (max-width: 999px) {
  .v1 .content-box__wrap {
    display: block;
  }
}
.v1 .content-box__sidebar {
  width: 315px;
  padding: 0 15px 20px 0;
  float: left;
}
@media (max-width: 999px) {
  .v1 .content-box__sidebar {
    padding: 0;
    width: 100vw;
    float: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .content-box__sidebar {
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    float: none;
  }
}
@media (max-width: 767px) {
  .v1 .content-box__sidebar {
    margin-left: -15px;
    margin-right: -15px;
    float: none;
  }
}
.v1 .content-box__content {
  float: left;
}
@media (min-width: 1000px) {
  .v1 .content-box__content {
    width: calc(100% - 315px);
  }
}
.v1 .content-box__title {
  margin: 0 0 30px;
  font-size: 24px;
  font-weight: 500;
}
@media (max-width: 999px) {
  .v1 .content-box__title {
    display: none;
  }
}
.v1 .content-box__list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
}
.v1 .content-box__list--cancel-margin-30 {
  margin: 0 -30px;
}
@media (max-width: 767px) {
  .v1 .content-box__list.active .content-box__list-item:nth-child(n+5) {
    display: block;
  }
  .v1 .content-box__list.active .content-box__more-text:after {
    top: 5px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.v1 .content-box__list-item {
  padding: 0 9px;
  margin-bottom: 20px;
}
@media (min-width: 1240px) {
  .v1 .content-box__list-item {
    width: 25%;
    max-width: 25%;
  }
}
@media (min-width: 768px) and (max-width: 1239px) {
  .v1 .content-box__list-item {
    width: 33.333%;
    max-width: 33.333%;
  }
}
@media (max-width: 767px) {
  .v1 .content-box__list-item {
    width: 50%;
    margin-bottom: 15px;
    max-width: 50%;
  }
}
.v1 .content-box__list-item--lg {
  margin-bottom: 60px;
  width: 33.333%;
  max-width: 33.333%;
  padding: 0 30px;
}
@media (max-width: 767px) {
  .v1 .content-box__list-item--lg {
    width: 100%;
    max-width: 100%;
  }
}
.v1 .content-box__list_items-3 .content-box__list-item {
  -webkit-flex-basis: 33.333%;
  flex-basis: 33.333%;
  width: 33.333%;
  max-width: 33.333%;
}
@media (max-width: 767px) {
  .v1 .content-box__list_items-3 .content-box__list-item {
    -webkit-flex-basis: auto;
    flex-basis: auto;
    width: auto;
    max-width: auto;
  }
}
.v1 .content-box__list_md {
  margin: 0 -15px;
}
.v1 .content-box__list_md .content-box__list-item {
  padding: 0 15px;
  margin-bottom: 30px;
}
.v1 .content-box__more {
  display: none;
  width: 100%;
  padding: 0 9px;
  color: #f460a3;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
@media (max-width: 767px) {
  .v1 .content-box__more {
    display: block;
  }
}
.v1 .content-box__more-text {
  position: relative;
  display: inline-block;
  padding-right: 15px;
  font-size: 15px;
  line-height: 1.2;
  color: inherit;
  font-weight: 500;
}
.v1 .content-box__more-text:after {
  content: "\e91f";
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 8px;
  position: absolute;
  top: 6px;
  right: 0;
}
.v1 .product-page__default .dropdown {
  position: inherit;
}
@media (max-width: 767px) {
  .v1 .templateFilterForm .content-nav__dropdown.open {
    display: block;
  }
}
.v1 .content-nav_items-7 .content-nav__item-title,
.v1 .content-nav_items-8 .content-nav__item-title,
.v1 .content-nav_items-9 .content-nav__item-title {
  font-size: 14px;
}
.v1 .content-nav_items-10 .content-nav__item-title,
.v1 .content-nav_items-11 .content-nav__item-title,
.v1 .content-nav_items-12 .content-nav__item-title {
  font-size: 10px;
}
.v1 .display2cols .content-nav.steps {
  width: 50%;
}
@media (max-width: 767px) {
  .v1 .display2cols .content-nav.steps {
    width: auto;
  }
}
.v1 .steps .content-nav__item > * {
  margin: 0 auto;
}
.v1 .content-nav {
  position: relative;
  margin-bottom: 15px;
  height: 60px;
  border-radius: 6px;
  background-color: #fff;
  -webkit-box-shadow: 0 6px 13px rgba(51,51,51,0.05);
  box-shadow: 0 6px 13px rgba(51,51,51,0.05);
}
.v1 .content-nav.steps {
  float: left;
  width: 70%;
  height: auto;
}
@media (max-width: 767px) {
  .v1 .content-nav.steps {
    width: auto;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .content-nav.steps {
    width: 60%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .content-nav.steps {
    width: 60%;
  }
}
.v1 .content-nav.steps.full-width {
  width: 100%;
}
@media (max-width: 767px) {
  .v1 .content-nav {
    border-radius: 0;
    height: 51px;
    margin: 0 -15px 30px;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
  .v1 .content-nav::-webkit-scrollbar {
    display: none;
  }
}
.v1 .content-nav_simple {
  margin-bottom: 50px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .content-nav_simple {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .v1 .content-nav_simple {
    margin: 15px 0;
    height: auto;
    overflow: visible;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #e8e8e8;
  }
}
.v1 .content-nav_simple .content-nav__item {
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
@media (min-width: 768px) {
  .v1 .content-nav_simple .content-nav__item {
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;
  }
}
@media (max-width: 767px) {
  .v1 .content-nav_simple .content-nav__item {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 11px 10px;
    height: auto;
    border-bottom: 1px solid #e8e8e8;
  }
  .v1 .content-nav_simple .content-nav__item:last-child {
    border-bottom: 0 none;
  }
}
.v1 .content-nav_simple .content-nav__item-title,
.v1 .content-nav_simple .content-nav__item-label {
  margin-top: 2px;
  font-size: 17px;
}
.v1 .content-nav_simple .content-nav__item-title {
  margin-right: 15px;
}
@media (max-width: 767px) {
  .v1 .content-nav_simple .content-nav__item-title {
    font-size: 15px;
  }
}
.v1 .content-nav_simple .content-nav__item-label {
  font-weight: 500;
  color: #333;
}
@media (max-width: 767px) {
  .v1 .content-nav_simple .content-nav__item-label_drop {
    font-size: 15px;
  }
}
.v1 .content-nav_simple .content-nav__item-label_drop:before {
  top: 8px;
}
@media (max-width: 767px) {
  .v1 .content-nav_simple .content-nav__item-label_drop:before {
    border-width: 6px 5px 0;
    top: 7px;
  }
}
.v1 .content-nav_simple .content-nav__menu {
  padding: 0 55px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .content-nav_simple .content-nav__menu {
    padding: 0 25px;
  }
}
@media (max-width: 767px) {
  .v1 .content-nav_simple .content-nav__menu {
    padding: 0 10px;
    border-top-color: #e8e8e8;
    margin-top: -1px;
  }
  .v1 .content-nav_simple .content-nav__menu-item {
    padding: 15px 0;
  }
}
@media (max-width: 767px) {
  .v1 .content-nav_simple .content-nav__list {
    display: block;
    height: auto;
  }
}
@media (max-width: 767px) {
  .v1 .content-nav_simple .content-nav__list-item {
    width: auto !important;
    border-right: 0 none;
  }
}
.v1 .content-nav_simple .content-nav__list-item-arrow:before,
.v1 .content-nav_simple .content-nav__list-item-arrow:after {
  content: none;
}
.v1 .content-nav_simple .content-nav__list-item:last-child {
  border-right: 0 none;
}
@media (max-width: 767px) {
  .v1 .content-nav_simple .content-nav__dropdown {
    display: block;
  }
}
.v1 .content-nav_items-1 .content-nav__list-item {
  width: 100%;
}
.v1 .content-nav_items-2 .content-nav__list-item {
  width: 50%;
}
@media (min-width: 1240px) {
  .v1 .content-nav_items-3 .content-nav__item,
  .v1 .content-nav_items-3 .content-nav__menu {
    padding: 0 50px;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .content-nav_items-3 .content-nav__item,
  .v1 .content-nav_items-3 .content-nav__menu {
    padding: 0 25px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .content-nav_items-3 .content-nav__item,
  .v1 .content-nav_items-3 .content-nav__menu {
    padding: 0 10px;
  }
}
@media (min-width: 768px) {
  .v1 .content-nav_items-3 .content-nav__list-item {
    width: 33.333%;
  }
}
.v1 .content-nav_items-4 .content-nav__list-item {
  width: 25%;
}
@media (max-width: 767px) {
  .v1 .content-nav_items-4 .content-nav__list-item {
    width: 50%;
  }
}
.v1 .content-nav_items-5 .content-nav__list-item {
  width: auto;
  max-width: 15%;
  min-width: 12%;
}
.v1 .content-nav_items-5.steps .content-nav__list-item {
  width: 20%;
  max-width: none;
  min-width: none;
}
.v1 .content-nav_items-6 .content-nav__list-item {
  width: 16.66%;
}
.v1 .content-nav_items-7 .content-nav__list-item {
  width: 14.28%;
}
.v1 .content-nav_items-8 .content-nav__list-item {
  width: 12.5%;
}
.v1 .content-nav_items-9 .content-nav__list-item {
  width: 11.111%;
}
.v1 .content-nav_items-10 .content-nav__list-item {
  width: 10%;
}
@media (max-width: 767px) {
  .v1 .content-nav_items-1,
  .v1 .content-nav_items-2,
  .v1 .content-nav_items-3,
  .v1 .content-nav_items-4,
  .v1 .content-nav_items-5,
  .v1 .content-nav_items-6,
  .v1 .content-nav_items-7,
  .v1 .content-nav_items-8,
  .v1 .content-nav_items-9,
  .v1 .content-nav_items-10 {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (max-width: 767px) {
  .v1 .content-nav_items-1 .content-nav__body,
  .v1 .content-nav_items-2 .content-nav__body,
  .v1 .content-nav_items-3 .content-nav__body,
  .v1 .content-nav_items-4 .content-nav__body,
  .v1 .content-nav_items-5 .content-nav__body,
  .v1 .content-nav_items-6 .content-nav__body,
  .v1 .content-nav_items-7 .content-nav__body,
  .v1 .content-nav_items-8 .content-nav__body,
  .v1 .content-nav_items-9 .content-nav__body,
  .v1 .content-nav_items-10 .content-nav__body {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .content-nav_items-1 .content-nav__body__refresh-control,
  .v1 .content-nav_items-2 .content-nav__body__refresh-control,
  .v1 .content-nav_items-3 .content-nav__body__refresh-control,
  .v1 .content-nav_items-4 .content-nav__body__refresh-control,
  .v1 .content-nav_items-5 .content-nav__body__refresh-control,
  .v1 .content-nav_items-6 .content-nav__body__refresh-control,
  .v1 .content-nav_items-7 .content-nav__body__refresh-control,
  .v1 .content-nav_items-8 .content-nav__body__refresh-control,
  .v1 .content-nav_items-9 .content-nav__body__refresh-control,
  .v1 .content-nav_items-10 .content-nav__body__refresh-control {
    margin: -20px 0 0 -20px;
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .content-nav_items-1 .content-nav__body_refresh,
  .v1 .content-nav_items-2 .content-nav__body_refresh,
  .v1 .content-nav_items-3 .content-nav__body_refresh,
  .v1 .content-nav_items-4 .content-nav__body_refresh,
  .v1 .content-nav_items-5 .content-nav__body_refresh,
  .v1 .content-nav_items-6 .content-nav__body_refresh,
  .v1 .content-nav_items-7 .content-nav__body_refresh,
  .v1 .content-nav_items-8 .content-nav__body_refresh,
  .v1 .content-nav_items-9 .content-nav__body_refresh,
  .v1 .content-nav_items-10 .content-nav__body_refresh {
    padding-right: 85px;
  }
}
.v1 .content-nav__title {
  text-align: center;
  float: left;
  width: 15%;
  height: 60px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 30px;
  font-size: 20px;
  font-weight: bold;
  color: #434343;
  border-right: 1px solid #efefef;
}
@media (max-width: 999px) {
  .v1 .content-nav__title {
    display: none;
  }
}
@media (max-width: 767px) {
  .v1 .content-nav__title {
    height: 51px;
  }
}
.v1 .content-nav__title-icon {
  display: inline-block;
  margin-right: 15px;
  font-size: 18px;
  color: #b6b7b9;
}
.v1 .content-nav__body {
  float: left;
  width: 100%;
}
@media (max-width: 999px) {
  .v1 .content-nav__body {
    float: none;
    width: auto;
  }
}
.v1 .content-nav__list {
  height: 60px;
}
@media (max-width: 999px) {
  .v1 .content-nav__list {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (max-width: 767px) {
  .v1 .content-nav__list {
    height: 51px;
    white-space: nowrap;
  }
}
.v1 .content-nav__list_dropwide {
  position: relative;
}
.v1 .content-nav__list_dropwide .content-nav__dropdown.open .content-nav__item-label_drop:after {
  display: block;
}
.v1 .content-nav__list_dropwide .content-nav__list-item {
  position: static;
}
.v1 .content-nav__list_dropwide .content-nav__item-label_drop {
  position: relative;
  z-index: 12;
}
.v1 .content-nav__list_dropwide .content-nav__item-label_drop:after {
  content: "";
  display: none;
  position: absolute;
  top: 100%;
  right: 1px;
  width: 11px;
  height: 11px;
  margin-top: 6px;
  background: url("i/caret-top.svg") no-repeat 0 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}
.v1 .content-nav__list-item {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  float: left;
  border-right: 2px solid #f2f2f2;
}
@media (max-width: 999px) {
  .v1 .content-nav__list-item {
    float: none;
  }
}
.v1 .content-nav__list-item:last-child {
  border-right: 0 none;
}
.v1 .content-nav__list-item_last {
  border-right: 2px solid #f2f2f2 !important;
}
.v1 .content-nav__list-item.active .content-nav__item {
  border-bottom-color: #f460a3;
}
.v1 .content-nav__list-item.grey {
  background-color: #ddd;
  border-right: 2px solid #ccc !important;
}
.v1 .content-nav__list-item.grey:last-child {
  border-right: 0 none;
}
.v1 .content-nav__list-item-arrow {
  position: relative;
  background-color: #f00;
}
.v1 .content-nav__list-item-arrow:before,
.v1 .content-nav__list-item-arrow:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  border-style: solid;
  z-index: 5;
}
.v1 .content-nav__list-item-arrow:before {
  border-width: 8px 0 8px 9px;
  margin-top: -8px;
  border-color: transparent transparent transparent #f2f2f2;
}
.v1 .content-nav__list-item-arrow:after {
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #fff;
}
.v1 .content-nav__list-item-arrow.grey:before {
  border-color: transparent transparent transparent #ccc;
}
.v1 .content-nav__list-item-arrow.grey:after {
  border-color: transparent transparent transparent #ddd;
}
.v1 .content-nav__item {
  padding: 0 12px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  height: 60px;
  line-height: 1.3;
  border-bottom: 2px solid transparent;
}
@media (max-width: 767px) {
  .v1 .content-nav__item {
    height: 51px;
  }
}
.v1 .content-nav__item-title {
  max-width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #555;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
@media (max-width: 767px) {
  .v1 .content-nav__item-title {
    font-size: 15px;
  }
}
.v1 .content-nav__item-title a {
  text-decoration: none;
}
.v1 .content-nav__item-title.option-value-selected a {
  text-decoration: underline;
}
.v1 .content-nav__item-label {
  max-width: 100%;
  position: relative;
  font-size: 12px;
  color: #888b8d;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v1 .content-nav__item-label_drop {
  padding-right: 20px;
  cursor: pointer;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
@media (max-width: 767px) {
  .v1 .content-nav__item-label_drop {
    padding-right: 0;
  }
}
.v1 .content-nav__item-label_drop:hover {
  color: #f460a3;
}
.v1 .content-nav__item-label_drop:hover:before {
  border-top-color: #f460a3;
}
.v1 .content-nav__item-label_drop:before {
  content: "";
  position: absolute;
  right: 0;
  top: 5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 7px 0;
  border-color: #c9c9c9 transparent transparent;
  -webkit-transition: 0.2s border-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out;
  transition: 0.2s border-color ease-in-out;
}
@media (max-width: 767px) {
  .v1 .content-nav__item-label_drop:before {
    display: none;
  }
}
.v1 .content-nav__item-label-inner {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow: hidden;
}
.v1 .content-nav__item-label-inner span {
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.steps .v1 .content-nav__item > * {
  margin: 0 auto;
}
.v1 .content-nav__dropdown {
  max-width: 100%;
}
.v1 .content-nav__dropdown.open .content-nav__menu {
  display: block;
}
@media (max-width: 767px) {
  .v1 .content-nav__dropdown.open .content-nav__menu {
    display: none;
  }
  .templateFilterForm .v1 .content-nav__dropdown.open .content-nav__menu {
    display: block;
  }
}
.v1 .content-nav__menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0 25px;
  background-color: #fff;
  border-top: 1px solid #cecece;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: 0 24px 36px rgba(51,51,51,0.06);
  box-shadow: 0 24px 36px rgba(51,51,51,0.06);
  z-index: 10;
}
.v1 .content-nav__menu_big {
  left: 0;
  right: 0;
  padding: 0;
  border-top-color: #ededee;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .content-nav__menu_big {
    right: -85px;
  }
  .v1 .content-nav__menu_big .params-board__item {
    width: 33.333333%;
  }
  .v1 .content-nav__menu_big .params-list_items-2 .params-list__item {
    width: 50%;
  }
}
.v1 .content-nav__menu-item {
  display: block;
  padding: 20px 0;
  font-size: 17px;
  color: #888b8d;
  text-align: center;
  text-decoration: none;
  border-bottom: 1px solid #f6f6f6;
}
.v1 .content-nav__menu-item:hover {
  color: #f460a3;
}
.v1 .content-nav__refresh {
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: 7px;
  width: 65px;
  height: 60px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .content-nav__refresh {
    margin-left: 0;
    width: 80px;
  }
}
@media (max-width: 999px) {
  .v1 .content-nav__refresh {
    margin-left: 10px;
    margin-right: 10px;
    left: auto;
    right: 0;
  }
}
@media (max-width: 767px) {
  .v1 .content-nav__refresh {
    margin-right: 0;
    margin-left: 0;
    padding-left: 7px;
    padding-right: 7px;
    height: 51px;
    position: static;
    right: auto;
    white-space: nowrap;
    width: auto;
    line-height: 51px;
  }
}
@media (min-width: 1000px) {
  .v1 .content-nav__refresh:hover .content-nav__refresh-control {
    -webkit-transform: translateY(-12px);
    -moz-transform: translateY(-12px);
    -o-transform: translateY(-12px);
    -ms-transform: translateY(-12px);
    transform: translateY(-12px);
  }
  .v1 .content-nav__refresh:hover .content-nav__refresh-label {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
.v1 .content-nav__refresh-control {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 100px;
  -webkit-transition: 0.2s background-color ease-in-out, 0.2s box-shadow ease-in-out, 0.2s border-color ease-in-out, 0.2s -webkit-transform ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out, 0.2s box-shadow ease-in-out, 0.2s border-color ease-in-out, 0.2s -moz-transform ease-in-out;
  -o-transition: 0.2s background-color ease-in-out, 0.2s box-shadow ease-in-out, 0.2s border-color ease-in-out, 0.2s -o-transform ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out, 0.2s box-shadow ease-in-out, 0.2s border-color ease-in-out, 0.2s -ms-transform ease-in-out;
  transition: 0.2s background-color ease-in-out, 0.2s box-shadow ease-in-out, 0.2s border-color ease-in-out, 0.2s transform ease-in-out;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .content-nav__refresh-control {
    margin: -20px 0 0 -20px;
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 999px) {
  .v1 .content-nav__refresh-control {
    display: block;
    position: relative;
    top: auto;
    left: auto;
    width: 24px;
    height: 24px;
    margin: 14px 0 0 20.5px;
  }
}
@media (max-width: 767px) {
  .v1 .content-nav__refresh-control {
    display: inline-block;
    margin: 0;
    margin-right: 5px;
    vertical-align: text-top;
  }
}
.v1 .content-nav__refresh-control:before,
.v1 .content-nav__refresh-control:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 1px;
  margin-left: -12px;
  background-color: #e0e0e0;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
@media (max-width: 999px) {
  .v1 .content-nav__refresh-control:before,
  .v1 .content-nav__refresh-control:after {
    width: 12px;
    margin-left: -6px;
  }
}
.v1 .content-nav__refresh-control:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.v1 .content-nav__refresh-control:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.v1 .content-nav__refresh-control:hover {
  background-color: #f460a3;
  border-color: #f460a3;
  -webkit-box-shadow: 0 8px 24px rgba(255,145,195,0.5);
  box-shadow: 0 8px 24px rgba(255,145,195,0.5);
}
.v1 .content-nav__refresh-control:hover:before,
.v1 .content-nav__refresh-control:hover:after {
  background-color: #fff;
}
.v1 .content-nav__refresh-control:active {
  -webkit-box-shadow: 0 3px 7px rgba(0,0,0,0.25) inset;
  box-shadow: 0 3px 7px rgba(0,0,0,0.25) inset;
}
.v1 .content-nav__refresh-label {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.2;
  color: #666;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .content-nav__refresh-label {
    bottom: 5px;
  }
}
@media (max-width: 999px) {
  .v1 .content-nav__refresh-label {
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@media (max-width: 767px) {
  .v1 .content-nav__refresh-label {
    display: inline-block;
    margin-top: 0;
  }
}
.v1 .content-panel {
  margin-bottom: 30px;
  font-size: 17px;
}
.v1 .content-panel__item {
  display: inline-block;
  vertical-align: top;
  margin: 0 10px 10px 0;
}
@media (max-width: 767px) {
  .v1 .content-panel__item {
    font-size: 14px;
  }
}
.v1 .content-panel__item:last-child {
  margin-right: 0;
}
.v1 .content-panel__label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  color: #888b8d;
}
.v1 .content-panel__value {
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  color: #555;
}
.v1 .content-panel__info {
  display: inline-block;
  vertical-align: middle;
  font-size: 21px;
  color: #03a9f4;
}
.v1 .content-section {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .v1 .content-section {
    margin-bottom: 25px;
  }
}
.v1 .content-section_h-offset .content-section__header {
  padding: 0 20px;
}
@media (min-width: 1240px) {
  .v1 .content-section_h-offset .content-section__header {
    padding: 0 30px;
  }
}
@media (max-width: 767px) {
  .v1 .content-section_h-offset .content-section__header {
    padding: 0 15px;
  }
}
.v1 .content-section__header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 20px;
}
.v1 .content-section__title {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .v1 .content-section__title {
    font-size: 17px;
  }
}
.v1 .content-section__label {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 17px;
  color: #888b8d;
  text-align: right;
}
@media (min-width: 1000px) {
  .v1 .content-section__table {
    font-size: 17px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .content-section__table {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .v1 .content-section__table {
    font-size: 16px;
  }
}
.v1 .content-section__table-head {
  border-radius: 4px 4px 0 0;
  background-color: #fcfcfc;
  font-weight: 500;
}
@media (max-width: 767px) {
  .v1 .content-section__table-head {
    display: none;
  }
}
@media (min-width: 1000px) {
  .v1 .content-section__table-head .content-section__table-col {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.v1 .content-section__table-body {
  background-color: #fff;
}
.v1 .content-section__table-body .content-section__table-row {
  border-top: 1px solid #f2f2f2;
}
.v1 .content-section__table-body .content-section__table-col {
  border-right: 1px solid #f2f2f2;
}
.v1 .content-section__table-body .content-section__table-col:last-child {
  border-right: 0 none;
}
@media (min-width: 768px) {
  .v1 .content-section__table-row {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (max-width: 767px) {
  .v1 .content-section__table-row {
    padding: 10px 0;
  }
}
.v1 .content-section__table-col {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 18px 20px;
}
@media (min-width: 768px) {
  .v1 .content-section__table-col {
    width: 25%;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .content-section__table-col {
    padding: 15px;
  }
}
@media (max-width: 767px) {
  .v1 .content-section__table-col {
    padding: 10px 15px;
  }
}
@media (min-width: 1240px) {
  .v1 .content-section__table-col:first-child {
    padding-left: 30px;
  }
}
@media (min-width: 768px) {
  .v1 .content-section__table-col_wide {
    width: 50%;
  }
}
.v1 .content-section__table-label {
  display: none;
  width: 50%;
  font-weight: bold;
}
@media (max-width: 767px) {
  .v1 .content-section__table-label {
    display: block;
  }
}
@media (max-width: 767px) {
  .v1 .content-section__table-value {
    width: 50%;
    text-align: right;
  }
}
.v1 .content-wrap {
  padding: 50px;
  background-color: #fff;
}
@media (max-width: 767px) {
  .v1 .content-wrap {
    padding: 30px 15px 15px;
  }
}
@media (max-width: 767px) {
  .v1 .content-wrap_no-offset {
    padding: 0;
  }
}
.v1 .content-wrap__title {
  margin: 0 0 10px;
  font-size: 36px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .v1 .content-wrap__title {
    margin: 0 0 15px;
    font-size: 17px;
    font-weight: bold;
  }
}
.v1 .content-wrap__footer {
  padding: 25px 50px;
  margin: 30px -50px -50px;
  background-color: #fcfcfc;
}
@media (max-width: 767px) {
  .v1 .content-wrap__footer {
    margin: 0 -15px -15px;
    padding: 25px 15px 25px;
  }
}
@media (min-width: 768px) {
  .v1 .content-wrap__button {
    min-width: 270px;
    margin-right: 20px;
  }
  .v1 .content-wrap__button:last-child {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .v1 .content-wrap__button {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .v1 .content-wrap__button:last-child {
    margin-bottom: 0;
  }
}
.v1 .contextual-actions a {
  display: block;
}
.v1 .cover-size {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 999px) {
  .v1 .cover-size {
    display: block;
    padding: 15px;
  }
}
.v1 .cover-size__image {
  -webkit-flex-basis: 320px;
  flex-basis: 320px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.v1 .cover-size__image-inner {
  height: 170px;
  text-align: center;
}
.v1 .cover-size__image-inner:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.v1 .cover-size__image-inner >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.v1 .cover-size__image-inner svg {
  max-width: 100%;
}
@media (max-width: 999px) {
  .v1 .cover-size__image-inner {
    margin-bottom: 15px;
    height: auto;
    text-align: left;
  }
}
.v1 .cover-size__text {
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  -ms-box-flex: 0;
  box-flex: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  font-size: 18px;
  font-weight: 500;
  padding-left: 70px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .cover-size__text {
    width: 250px;
    margin: 0 auto;
  }
}
@media (max-width: 999px) {
  .v1 .cover-size__text {
    margin-bottom: 25px;
    padding-left: 0;
    font-size: 15px;
  }
}
.v1 .delivery-modal__header {
  position: relative;
}
@media (min-width: 1000px) {
  .v1 .delivery-modal__header {
    padding: 50px 100px 40px 50px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .delivery-modal__header {
    padding: 30px 70px 30px 30px;
  }
}
@media (max-width: 767px) {
  .v1 .delivery-modal__header {
    padding: 20px 70px 20px 15px;
  }
}
.v1 .delivery-modal__add {
  position: absolute;
  right: 15px;
  width: 40px;
  height: 40px;
  padding: 4px 0 0 !important;
  font-size: 22px !important;
  border-radius: 100px;
}
@media (min-width: 1000px) {
  .v1 .delivery-modal__add {
    right: 30px;
    top: 65px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .delivery-modal__add {
    top: 45px;
  }
}
@media (max-width: 767px) {
  .v1 .delivery-modal__add {
    top: 18px;
  }
}
.v1 .delivery-modal__title {
  font-size: 36px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .v1 .delivery-modal__title {
    font-size: 17px;
  }
}
.v1 .delivery-modal__subtitle {
  font-size: 18px;
  color: #777;
}
@media (max-width: 767px) {
  .v1 .delivery-modal__subtitle {
    font-size: 14px;
  }
}
@media (min-width: 1000px) {
  .v1 .delivery-modal__footer {
    padding: 30px 50px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .delivery-modal__footer {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .v1 .delivery-modal__footer {
    padding: 30px 15px;
  }
}
.v1 .delivery-modal__list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid #d7d7d7;
}
@media (max-width: 767px) {
  .v1 .delivery-modal__list {
    border-top: 0 none;
  }
}
.v1 .delivery-modal__list-item {
  position: relative;
  padding: 20px 220px 20px 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: transparent;
  -webkit-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
}
@media (max-width: 767px) {
  .v1 .delivery-modal__list-item {
    padding: 15px 70px 15px 15px;
    border-width: 0;
  }
  .v1 .delivery-modal__list-item:nth-child(odd) {
    background-color: #f7f7f7;
  }
}
.v1 .delivery-modal__list-item:last-child {
  border-bottom: 0 none;
  border-radius: 0 0 8px 8px;
}
.v1 .delivery-modal__list-item:first-child {
  border-top: 0 none;
}
@media (min-width: 768px) {
  .v1 .delivery-modal__list-item.active {
    background-color: #f7f7f7 !important;
    border-color: #d7d7d7;
  }
  .v1 .delivery-modal__list-item.active .delivery-modal__check {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    color: #434343;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .v1 .delivery-modal__list-item.active + .active {
    border-top-color: transparent;
  }
}
.v1 .delivery-modal__list-item:hover {
  background-color: #fafafa;
}
.v1 .delivery-modal__list-item:hover .delivery-modal__edit,
.v1 .delivery-modal__list-item:hover .delivery-modal__check {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.v1 .delivery-modal__content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media (max-width: 767px) {
  .v1 .delivery-modal__content {
    display: block;
  }
}
.v1 .delivery-modal__content-col {
  -webkit-flex-basis: 33.333%;
  flex-basis: 33.333%;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .v1 .delivery-modal__content-col {
    -webkit-flex-basis: auto;
    flex-basis: auto;
    padding: 0;
  }
}
.v1 .delivery-modal__content-title {
  font-weight: bold;
}
@media (max-width: 767px) {
  .v1 .delivery-modal__content-title {
    margin-bottom: 10px;
  }
}
.v1 .delivery-modal__content-text {
  color: #777;
}
@media (max-width: 767px) {
  .v1 .delivery-modal__content-address {
    margin-bottom: 5px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .delivery-modal__content-title,
  .v1 .delivery-modal__content-text {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .v1 .delivery-modal__content-title,
  .v1 .delivery-modal__content-text {
    font-size: 14px;
  }
}
.v1 .delivery-modal__edit,
.v1 .delivery-modal__check {
  position: absolute;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  text-decoration: none;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.touchevents .v1 .delivery-modal__edit,
.touchevents .v1 .delivery-modal__check {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.v1 .delivery-modal__edit {
  top: 34px;
  right: 160px;
  color: #f460a3;
  font-size: 20px;
}
@media (max-width: 767px) {
  .v1 .delivery-modal__edit {
    top: 15px;
    right: 15px;
    color: #cacccd;
  }
}
.v1 .delivery-modal__edit:hover {
  color: #434343;
}
.v1 .delivery-modal__check {
  top: 26px;
  right: 20px;
}
@media (max-width: 767px) {
  .v1 .delivery-modal__check {
    display: none;
  }
}
.v1 .discount-card {
  font-size: 17px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
.v1 .discount-card__header {
  padding: 22px 25px;
  background-color: #fcfcfc;
  border-bottom: 1px solid #e6e6e6;
  border-radius: 4px 4px 0 0;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .discount-card__header {
    padding: 22px 10px;
  }
}
.v1 .discount-card__title {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
@media (min-width: 1000px) and (max-width: 1239px), (max-width: 767px) {
  .v1 .discount-card__title {
    font-size: 15px;
  }
}
.v1 .discount-card__body {
  padding: 25px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .discount-card__body {
    padding: 25px 10px;
  }
}
.v1 .discount-card__box {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 45px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .discount-card__box {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .v1 .discount-card__box {
    margin-bottom: 35px;
  }
}
.v1 .discount-card__aside {
  padding-right: 20px;
  width: 45%;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .discount-card__aside {
    width: 35%;
  }
}
.v1 .discount-card__content {
  width: 55%;
  margin-top: 10px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .discount-card__content {
    width: 65%;
    margin-top: 0;
  }
}
.v1 .discount-card__image {
  height: 82px;
  text-align: center;
}
.v1 .discount-card__image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.v1 .discount-card__image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 767px) {
  .v1 .discount-card__image {
    height: 75px;
  }
}
.v1 .discount-card__text {
  margin-bottom: 5px;
  line-height: 1.2;
}
@media (max-width: 999px), (min-width: 1000px) and (max-width: 1239px) {
  .v1 .discount-card__text {
    font-size: 15px;
  }
}
.v1 .discount-card__price {
  margin-bottom: 5px;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.1;
  color: #343434;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .discount-card__price {
    font-size: 25px;
  }
}
@media (max-width: 999px) {
  .v1 .discount-card__price {
    font-size: 32px;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .discount-card__description {
    font-size: 13px;
  }
}
@media (max-width: 999px) {
  .v1 .discount-card__description {
    font-size: 15px;
  }
}
.v1 .discount-card__date {
  color: #888b8d;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .discount-card__date {
    font-size: 13px;
  }
}
@media (max-width: 999px) {
  .v1 .discount-card__date {
    font-size: 15px;
  }
}
@media (min-width: 768px) {
  .v1 .discount-cards {
    margin: 0 -10px;
    zoom: 1;
  }
  .v1 .discount-cards:before,
  .v1 .discount-cards:after {
    content: "";
    display: table;
  }
  .v1 .discount-cards:after {
    clear: both;
  }
}
.v1 .discount-cards__item {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .v1 .discount-cards__item {
    float: left;
    width: 50%;
    padding: 0 10px;
  }
}
.v1 .equipment {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 100px 0;
  margin: 0 -15px;
}
@media (max-width: 767px) {
  .v1 .equipment {
    display: block;
    padding: 0 0 25px;
  }
}
.v1 .equipment__content,
.v1 .equipment__aside {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
  padding: 0 15px;
}
.v1 .equipment__video {
  display: block;
  position: relative;
  height: 385px;
  overflow: hidden;
  background-color: #d8d8d8;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 5px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .equipment__video {
    height: 285px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .equipment__video {
    margin-top: 15px;
    height: 235px;
  }
}
@media (max-width: 767px) {
  .v1 .equipment__video {
    height: 0;
    padding-bottom: 60%;
  }
}
.v1 .equipment__video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 108px;
  height: 108px;
  margin: -54px 0 0 -54px;
  background-color: rgba(255,255,255,0.76);
  border-radius: 100px;
  -webkit-box-shadow: 0 9px 14px rgba(0,0,0,0.22);
  box-shadow: 0 9px 14px rgba(0,0,0,0.22);
  -webkit-transition: 0.2s -webkit-transform ease-in-out, 0.2s background-color ease-in-out;
  -moz-transition: 0.2s -moz-transform ease-in-out, 0.2s background-color ease-in-out;
  -o-transition: 0.2s -o-transform ease-in-out, 0.2s background-color ease-in-out;
  -ms-transition: 0.2s -ms-transform ease-in-out, 0.2s background-color ease-in-out;
  transition: 0.2s transform ease-in-out, 0.2s background-color ease-in-out;
}
@media (max-width: 999px) {
  .v1 .equipment__video-icon {
    width: 56px;
    height: 56px;
    margin: -28px 0 0 -28px;
  }
}
.v1 .equipment__video-icon:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent #f460a3;
  border-width: 16px 24px;
  margin: -15px 0 0 -7px;
  -webkit-transition: 0.2s border-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out;
  transition: 0.2s border-color ease-in-out;
}
@media (max-width: 999px) {
  .v1 .equipment__video-icon:after {
    border-width: 7px 11px;
    margin: -7px 0 0 -4px;
  }
}
.no-touchevents .v1 .equipment__video:hover .equipment__video-icon {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  background-color: rgba(244,96,163,0.9);
}
.no-touchevents .v1 .equipment__video:hover .equipment__video-icon:after {
  border-color: transparent transparent transparent #fff;
}
.v1 .equipment__title {
  margin: 0 0 10px;
  font-size: 36px;
  line-height: 1.25;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .equipment__title {
    margin: 0 0 15px;
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  .v1 .equipment__title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
  }
}
.v1 .equipment__text {
  margin-bottom: 65px;
  font-size: 20px;
  line-height: 1.3;
  color: #888b8d;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .equipment__text {
    margin-bottom: 45px;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .v1 .equipment__text {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.v1 .equipment__button {
  min-width: 240px;
}
@media (max-width: 767px) {
  .v1 .equipment__button {
    display: none;
  }
}
.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0,0,0,0.2) !important;
  box-shadow: 0 10px 25px rgba(0,0,0,0.2) !important;
}
.fancybox-lock {
  overflow: visible !important;
}
.fancybox-lock body {
  overflow: visible !important;
}
.fancybox-close {
  top: auto;
  bottom: calc(100% - 38px);
  right: -97px;
  width: 20px;
  height: 20px;
  margin-bottom: 15px;
  background: none !important;
  text-decoration: none;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.fancybox-wrap.no-thumbs .fancybox-close {
  right: 3px;
}
@media (max-width: 999px) {
  .fancybox-close {
    right: 3px;
  }
}
.fancybox-close:before,
.fancybox-close:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  background-color: #c7c7c7;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
.fancybox-close:before {
  margin: -8px 0 0 -1px;
  width: 2px;
  height: 16px;
}
.fancybox-close:after {
  margin: -1px 0 0 -8px;
  width: 16px;
  height: 2px;
}
.fancybox-close:hover:before,
.fancybox-close:hover:after {
  background-color: #f460a3;
}
.fancybox-wrap {
  background-color: #fff;
}
.fancybox-wrap .fancybox-skin {
  padding: 0 !important;
  border-radius: 0;
}
.fancybox-thumbs {
  position: absolute;
  right: -100px;
  width: 100px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-direction: reverse;
  -moz-box-direction: reverse;
  -o-box-direction: reverse;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  top: 0;
  height: 100%;
  padding: 5px;
  background-color: #fff;
}
@media (max-width: 999px) {
  .fancybox-thumbs {
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -o-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    position: static;
    height: 100px;
  }
}
.fancybox-image-thumb {
  width: 100%;
  padding: 5px 0 0;
  cursor: pointer;
}
@media (max-width: 999px) {
  .fancybox-image-thumb {
    width: auto;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    padding: 0 5px 0 0;
    height: 100%;
  }
}
.fancybox-wrap {
  padding: 5px;
  background-color: #fff;
  margin-left: -50px;
}
@media (max-width: 999px) {
  .fancybox-wrap {
    margin-left: 0;
  }
}
.v1 .product-page-title {
  margin-top: 30px;
  margin-bottom: 10px;
}
.v1 .list-featured-products {
  margin-bottom: 50px;
}
.v1 .list-featured-products .list-featured-products-grid {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.v1 .list-featured-products .list-featured-products-grid .list-featured-products-item {
  width: 25%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 0.45em 0.45em 0;
  overflow: hidden;
}
.v1 .list-featured-products .list-featured-products-grid .list-featured-products-item img {
  height: auto;
  vertical-align: middle;
  width: 100%;
}
.v1 .list-featured-products .list-featured-products-grid .list-featured-products-item p {
  text-align: left;
  margin-top: 10px;
}
.v1 .list-featured-products .list-featured-products-grid .list-featured-products-item a {
  text-decoration: none;
}
.v1 .list-featured-products .list-featured-products-grid .list-featured-products-item h4 {
  font-weight: normal;
}
.v1 .list-featured-product-others {
  margin-top: 30px;
}
.v1 .list-featured-product-others .list-featured-product-others-grid {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.v1 .list-featured-product-others .list-featured-product-others-grid .list-featured-product-others-item {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .list-featured-product-others .list-featured-product-others-grid .list-featured-product-others-item:not(:last-child) {
  margin-right: 30px;
}
.v1 .list-featured-product-others .list-featured-product-others-grid .list-featured-product-others-item .list-featured-product-others-item-left img {
  width: 200px;
}
.v1 .list-featured-product-others .list-featured-product-others-grid .list-featured-product-others-item .list-featured-product-others-item-content {
  padding: 0 10px;
  max-width: 300px;
  min-width: 200px;
}
.v1 .list-featured-product-others .list-featured-product-others-grid .list-featured-product-others-item .list-featured-product-others-item-content .list-featured-product-others-item-content-readmore {
  text-align: right;
}
.v1 .files-list {
  margin-bottom: 50px;
  padding: 30px 20px 15px;
  background-color: #fff;
}
@media (min-width: 768px) {
  .v1 .files-list {
    border-radius: 8px;
  }
}
@media (min-width: 1240px) {
  .v1 .files-list {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .v1 .files-list {
    padding: 30px 10px 15px;
    margin-bottom: 30px;
  }
}
.v1 .files-list__wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.v1 .files-list__item {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 125px;
  -ms-flex: 0 0 125px;
  flex: 0 0 125px;
  padding: 0 10px;
  padding-bottom: 15px;
}
.v1 .files-list__item:focus {
  outline: 0;
}
.v1 .files-list__link {
  display: block;
  text-decoration: none;
}
.v1 .files-list__link:hover {
  color: #f460a3;
}
.v1 .files-list__link-image {
  display: block;
  margin-bottom: 15px;
  width: 125px;
  height: 125px;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}
.v1 .files-list__link-label {
  display: block;
  font-size: 17px;
}
.v1 .flash-message {
  width: 100%;
}
.v1 .flash-message .content p {
  margin: 1.1em 0 1.1em;
}
.v1 .flash-message.success {
  color: #1d591d;
  background-color: #c2f3c2;
  font-weight: 300;
}
.v1 .flash-message.success .v1 .flash-message-cancel:hover:before,
.v1 .flash-message.success .v1 .flash-message-cancel:hover:after {
  background-color: #33a233;
}
.v1 .flash-message.success .v1 .flash-message-cancel:before,
.v1 .flash-message.success .v1 .flash-message-cancel:after {
  background-color: #1d591d;
}
.v1 .flash-message.info {
  color: #1d3859;
  background-color: #a2ccff;
  font-weight: 300;
}
.v1 .flash-message.info .v1 .flash-message-cancel:hover:before,
.v1 .flash-message.info .v1 .flash-message-cancel:hover:after {
  background-color: #316299;
}
.v1 .flash-message.info .v1 .flash-message-cancel:before,
.v1 .flash-message.info .v1 .flash-message-cancel:after {
  background-color: #1d3859;
}
.v1 .flash-message.warning {
  color: #59541d;
  background-color: #eae49c;
  font-weight: 300;
}
.v1 .flash-message.warning .v1 .flash-message-cancel:hover:before,
.v1 .flash-message.warning .v1 .flash-message-cancel:hover:after {
  background-color: #aaa337;
}
.v1 .flash-message.warning .v1 .flash-message-cancel:before,
.v1 .flash-message.warning .v1 .flash-message-cancel:after {
  background-color: #59541d;
}
.v1 .flash-message.error {
  color: #59201d;
  background-color: #d3b0ae;
  font-weight: 300;
}
.v1 .flash-message.error .v1 .flash-message-cancel:hover:before,
.v1 .flash-message.error .v1 .flash-message-cancel:hover:after {
  background-color: #a53c37;
}
.v1 .flash-message.error .v1 .flash-message-cancel:before,
.v1 .flash-message.error .v1 .flash-message-cancel:after {
  background-color: #59201d;
}
.v1 .flash-message-cancel {
  position: absolute;
  right: 0;
  top: 50%;
  margin-bottom: 2px;
  width: 21px;
  height: 21px;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  cursor: pointer;
}
.v1 .flash-message-cancel:before,
.v1 .flash-message-cancel:after {
  content: "";
  position: absolute;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
.v1 .flash-message-cancel:before {
  width: 15px;
  height: 1px;
  margin: 10px 0 0 1px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.v1 .flash-message-cancel:after {
  width: 1px;
  height: 15px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 3px 0 0 3px;
}
.v1 .flash-message.flash-notification {
  color: #59541d;
  background-color: #fde360;
}
.v1 .flash-message.flash-notification .flash-message-cancel:hover:before,
.v1 .flash-message.flash-notification .flash-message-cancel:hover:after {
  background-color: #59541d;
}
.v1 .flash-message.flash-notification .flash-message-cancel:before,
.v1 .flash-message.flash-notification .flash-message-cancel:after {
  background-color: #59541d;
}
.v1 .flash-message.flash-notification p {
  margin: 0.4em 0;
}
.v1 .flash-message.flash-notification span {
  font-size: 14px;
}
.v1 .flash-message.flash-rgpd {
  background-color: #333;
  color: #fff;
  padding: 0;
  position: fixed;
  bottom: 0;
  text-align: center;
  z-index: 1000;
}
.v1 .flash-message.flash-rgpd p {
  margin: 0.4em 0;
}
.v1 .flash-message.flash-rgpd span {
  font-size: 14px;
}
.v1 .flash-message.flash-rgpd .btn {
  padding: 2px 10px;
  font-size: 12px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.v1 .flash-message.flash-rgpd .btn-secondary {
  background-color: #ccc;
  color: #333;
  border-color: #ccc;
}
.v1 .flickity-gallery {
  text-align: center;
}
.v1 .flickity-gallery__thumbs {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.v1 .flickity-gallery__thumbs__thumb {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.v1 .flickity-gallery .product-preview__image-i {
  max-height: 400px;
}
.v1.footer {
  position: relative;
  z-index: 5;
  padding: 85px 0 50px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #333;
  color: #fff;
}
@media (min-width: 768px) and (max-width: 1239px) {
  .v1.footer {
    padding: 65px 0 50px;
  }
}
@media (max-width: 767px) {
  .v1.footer {
    padding: 25px 0 15px;
  }
}
@media (min-width: 1000px) {
  .v1.footer .footer__info {
    padding-top: 5px;
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1.footer .footer__info {
    margin-bottom: 10px;
  }
}
.v1.footer .footer__logo {
  display: inline-block;
  vertical-align: top;
  width: 158px;
  text-decoration: none;
  margin-right: 15px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1.footer .footer__logo {
    width: 128px;
  }
}
@media (max-width: 767px) {
  .v1.footer .footer__logo {
    width: 108px;
  }
}
.v1.footer .footer__logo-image,
.v1.footer .footer__logo img.image {
  display: block;
  width: 100% !important;
  height: auto !important;
}
.v1.footer .footer__phone {
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
  font-size: 20px;
  line-height: 1.1;
  color: #fff;
  text-decoration: none;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1.footer .footer__phone {
    font-size: 17px;
  }
}
@media (max-width: 767px) {
  .v1.footer .footer__phone {
    font-size: 14px;
    color: #c6c6c6;
  }
}
.v1.footer .footer__phone:hover {
  color: #f460a3;
}
.v1.footer .footer__copyright {
  font-size: 16px;
  color: #bebebf;
}
@media (max-width: 767px) {
  .v1.footer .footer__copyright {
    font-size: 14px;
    color: #c6c6c6;
  }
}
@media (min-width: 1000px) {
  .v1.footer .footer__main {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (min-width: 1000px) {
  .v1.footer .footer__content {
    width: 33.333%;
    padding-right: 20px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1.footer .footer__content {
    margin-bottom: 30px;
  }
}
@media (min-width: 1000px) {
  .v1.footer .footer__menu {
    width: 66.666%;
  }
}
.v1.footer .footer__bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-top: 85px;
}
@media (max-width: 767px) {
  .v1.footer .footer__bottom {
    margin-top: 25px;
  }
}
@media (min-width: 768px) {
  .v1.footer .footer__socials,
  .v1.footer .footer__subscribe {
    width: 50%;
  }
}
@media (min-width: 1240px) {
  .v1.footer .footer__subscribe {
    text-align: right;
  }
}
@media (min-width: 768px) and (max-width: 1239px) {
  .v1.footer .footer__subscribe {
    margin-top: -30px;
  }
}
@media (max-width: 767px) {
  .v1.footer .footer__subscribe {
    display: none;
  }
}
.v1 .footer-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  columns: 3;
}
.v1 .footer-menu__item {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 3px;
}
.v1 .footer-menu__item-link {
  display: inline-block;
  font-size: 16px;
  line-height: 1.2;
  color: #fff;
  text-decoration: none;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .footer-menu__item-link {
    font-size: 14px;
  }
}
.v1 .footer-menu__item-link:hover {
  color: #f460a3;
}
.v1 .step-self-value-array {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 40px;
}
@media (max-width: 767px) {
  .v1 .step-self-value-array {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.v1 .step-self-value-array .order-quantity {
  margin: 0 10px 20px;
  padding: 5px;
  background-color: #dcdcdc;
  text-align: center;
}
.v1 .step-self-value-array .orders-tiles__item {
  width: auto;
}
.v1 .step-self-value-array .grid-with-genders {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.v1 .step-self-value-array .grid-with-genders .orders-tiles__item {
  width: auto;
}
.v1 .step-self-value-array .grid-with-genders .mobile-form-group {
  width: 120px;
}
.v1 .step-self-value-array .grid-with-genders .quantity-field__input {
  padding-right: 60px;
  width: 120px;
  margin-bottom: 0;
}
.v1 .step-self-value-array .grid-with-genders .quantity-field__input + .btn {
  position: absolute;
  top: 6px;
  right: 7px;
  padding: 9px 10px;
  width: 50px;
  font-size: 14px;
}
.v1 .step-self-value-array .grid-with-genders td,
.v1 .step-self-value-array .grid-with-genders th {
  padding: 5px;
}
.v1 .step-self-value-array .grid-with-genders .params-board__item {
  width: auto;
}
.v1 .step-self-value-array .grid-with-genders .params-board__item .params-card {
  padding: 0;
  border: 0;
}
.v1 .step-self-value-array .grid-with-genders .params-board__item .params-card .params-card__title {
  margin-bottom: 0;
}
.v1 .step-self-value-array .grid-with-genders .params-board__item .params-card .params-card__title .row {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.v1 .step-self-value-array .grid-with-genders .params-board__item .params-card .params-card__title .row .col-4 {
  width: 80%;
}
.v1 .header {
  position: relative;
  z-index: 150;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
@media (max-width: 767px) {
  .v1 .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 6px rgba(115,115,115,0.1);
    box-shadow: 0 2px 6px rgba(115,115,115,0.1);
  }
}
.v1 .header .phone-contact {
  margin-left: 5%;
}
@media (max-width: 999px) {
  .v1 .header .phone-contact {
    display: none;
  }
}
.v1 .header .phone-contact .tooltip-inner {
  max-width: 400px;
  width: 400px;
}
.v1 .header.open .flash-message {
  display: none;
}
.v1 .header__main {
  position: relative;
  padding: 24px 0 22px;
  background-color: #fff;
  zoom: 1;
}
header.premium .v1 .header__main {
  padding: 14px 0 12px;
}
@media (max-width: 767px) {
  header.premium .v1 .header__main {
    padding: 0;
  }
}
.v1 .header__main .container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .header__main:before,
.v1 .header__main:after {
  content: "";
  display: table;
}
.v1 .header__main:after {
  clear: both;
}
@media (max-width: 767px) {
  .v1 .header__main {
    height: 50px;
    padding: 11px 0;
    margin-left: 48px;
  }
  .v1 .header__main .container {
    padding-left: 0;
  }
}
.v1 .header__logo {
  display: block;
  text-decoration: none;
}
header.premium .v1 .header__logo {
  width: 300px;
  height: auto;
}
@media (max-width: 767px) {
  header.premium .v1 .header__logo {
    margin-left: -180px;
  }
}
@media (min-width: 768px) {
  .v1 .header__logo {
    float: left;
    width: 200px;
    height: 42px;
  }
}
@media (max-width: 767px) {
  .v1 .header__logo {
    position: absolute;
    top: 0;
    left: 50%;
    width: 130px;
    height: 28px;
    margin-left: -89px;
    z-index: 1;
  }
  .nps .v1 .header__logo {
    position: initial;
    margin-left: 0;
  }
}
.v1 .header__logo-image {
  display: block;
  width: 100% !important;
  height: auto !important;
}
.v1 .header__phone {
  float: left;
  position: relative;
  padding: 8px 20px 0 0;
  color: #888b8d;
  text-decoration: none;
}
@media (min-width: 1240px) {
  .v1 .header__phone {
    margin: 10px 45px 0 0;
    padding: 0;
    width: auto;
    height: auto;
    font-size: 20px;
  }
  .v1 .header__phone:before {
    content: none;
  }
}
@media (max-width: 767px) {
  .v1 .header__phone {
    display: none;
  }
}
.v1 .header__phone:hover {
  color: #f460a3;
}
.v1 .header__phone-label {
  display: none;
}
@media (min-width: 1240px) {
  .v1 .header__phone-label {
    display: block;
  }
}
.v1 .header__search {
  position: relative;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
@media (max-width: 999px) {
  .v1 .header__search {
    margin-left: 0;
    margin-right: 0;
  }
}
.v1 .header__search.open .header__search-wrap {
  display: block;
}
.v1 .header__search-icon {
  display: none;
  padding: 10px 8px;
  font-size: 24px;
  color: #888b8d;
  cursor: pointer;
}
@media (max-width: 999px) {
  .v1 .header__search-icon {
    display: inline-block;
  }
}
@media (max-width: 767px) {
  .v1 .header__search-icon {
    padding: 4px 5px;
    font-size: 19px;
    color: #f460a3;
  }
}
@media (max-width: 999px) {
  .v1 .header__search-wrap {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 760px;
    padding: 0;
    margin-left: -240px;
    z-index: 10;
  }
}
@media (max-width: 767px) {
  .v1 .header__search-wrap {
    top: -10px;
    width: 100vw;
    margin-left: -47px;
  }
}
.v1 .header__settings,
.v1 .header__notifications,
.v1 .header__profile,
.v1 .header__cart {
  float: right;
}
.v1 .header__settings {
  margin-left: 10px;
}
@media (max-width: 767px) {
  .v1 .header__settings {
    display: none;
  }
}
.v1 .header__profile {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
@media (min-width: 768px) {
  .v1 .header__profile {
    padding: 4px 14px 0 0px;
    height: 48px;
  }
}
@media (min-width: 768px) {
  .v1 .header__profile.loggued {
    padding-top: 0px;
  }
}
.v1 .header__cart {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  outline: 0 none;
  -webkit-transition: 0.2s background ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s background ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s background ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s background ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: 0.2s background ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
}
@media (min-width: 768px) {
  .v1 .header__cart {
    width: 97px;
    height: 38px;
    padding: 10px 20px;
    border-radius: 50px;
    border: 1px solid rgba(255,255,255,0.263);
  }
  .v1 .header__cart:hover {
    border-color: #fff;
  }
  .v1 .header__cart:hover .header__cart-amount {
    height: 20px;
  }
  .v1 .header__cart:active {
    -webkit-box-shadow: 0 3px 7px rgba(0,0,0,0.25) inset;
    box-shadow: 0 3px 7px rgba(0,0,0,0.25) inset;
  }
}
@media (max-width: 767px) {
  .v1 .header__cart {
    margin: 2px 15px 0 15px;
    display: block;
  }
}
.v1 .header__cart-icon {
  font-size: 26px;
  color: #fff;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
@media (max-width: 767px) {
  .v1 .header__cart-icon {
    font-size: 20px;
    color: inherit;
  }
}
.v1 .header__cart-amount {
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  color: #fff;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
  height: 20px;
}
@media (max-width: 767px) {
  .v1 .header__cart-amount {
    position: absolute;
    top: -4px;
    right: -5px;
    min-width: 12px;
    padding: 1px 3px;
    text-align: center;
    border-radius: 10px;
    background-color: #f460a3;
    font-size: 9px;
    font-weight: 500;
    line-height: 1.2;
    color: #fff;
    height: auto;
  }
}
.v1 .header__notifications {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
@media (min-width: 768px) {
  .v1 .header__notifications {
    padding-top: 8px;
  }
}
@media (max-width: 767px) {
  .v1 .header__notifications {
    margin-top: 2px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
}
@media (max-width: 767px) {
  .v1 .header__profile {
    display: none;
  }
}
.v1 .header__menu {
  position: relative;
  z-index: 4;
  background-color: #333;
/* The sticky class is added to the navbar with JS when it reaches its scroll position */
}
@media (min-width: 768px) {
  .v1 .header__menu {
    height: 50px;
  }
}
@media (max-width: 767px) {
  .v1 .header__menu {
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    height: calc(100vh - 50px);
    padding-bottom: 50px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .open .v1 .header__menu {
    display: block;
  }
}
.v1 .header__menu:after {
  content: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(200,200,200,0.84);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@media (min-width: 768px) {
  .v1 .header__menu-inner {
    height: 50px;
  }
}
@media (max-width: 767px) {
  .v1 .header__menu-inner {
    padding: 0;
  }
}
.v1 .header__menu-main {
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
@media (min-width: 1000px) {
  .v1 .header__menu-main {
    float: left;
    width: 63%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .open .v1 .header__menu-main {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.v1 .header__menu-add {
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
@media (min-width: 1000px) {
  .v1 .header__menu-add {
    float: right;
    width: 37%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .header__menu-add {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    position: absolute;
    right: 60px;
    left: 0;
    background-color: #333;
    z-index: -1000;
  }
  .open .v1 .header__menu-add {
    z-index: 1000;
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
.v1 .header__menu-sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
@media (max-width: 767px) {
  .v1 .header__menu-sticky {
    top: 50px;
  }
}
.v1 .header__toggle {
  display: none;
  position: relative;
}
@media (max-width: 999px) {
  .open .v1 .header__toggle .header__toggle-icon {
    background-color: transparent;
  }
  .open .v1 .header__toggle .header__toggle-icon:before,
  .open .v1 .header__toggle .header__toggle-icon:after {
    top: 0;
    -webkit-transition: 0.2s -webkit-transform ease-in-out 0.2s, 0.2s top ease-in-out;
    -moz-transition: 0.2s -moz-transform ease-in-out 0.2s, 0.2s top ease-in-out;
    -o-transition: 0.2s -o-transform ease-in-out 0.2s, 0.2s top ease-in-out;
    -ms-transition: 0.2s -ms-transform ease-in-out 0.2s, 0.2s top ease-in-out;
    transition: 0.2s transform ease-in-out 0.2s, 0.2s top ease-in-out;
  }
  .open .v1 .header__toggle .header__toggle-icon:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .open .v1 .header__toggle .header__toggle-icon:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .header__toggle {
    float: right;
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 767px) {
  .v1 .header__toggle {
    float: left;
    width: 48px;
    height: 48px;
  }
}
@media (max-width: 767px) {
  .v1 .header__toggle_phone {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .header__toggle_tablet {
    display: block;
  }
}
.v1 .header__toggle-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .header__toggle-icon {
    width: 24px;
    margin: -1px 0 0 -12px;
  }
}
@media (max-width: 767px) {
  .v1 .header__toggle-icon {
    width: 16px;
    margin: -1px 0 0 -8px;
    background-color: #4f5559;
  }
}
.v1 .header__toggle-icon:before,
.v1 .header__toggle-icon:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
  -webkit-transition: 0.2s -webkit-transform ease-in-out, 0.2s top ease-in-out 0.2s;
  -moz-transition: 0.2s -moz-transform ease-in-out, 0.2s top ease-in-out 0.2s;
  -o-transition: 0.2s -o-transform ease-in-out, 0.2s top ease-in-out 0.2s;
  -ms-transition: 0.2s -ms-transform ease-in-out, 0.2s top ease-in-out 0.2s;
  transition: 0.2s transform ease-in-out, 0.2s top ease-in-out 0.2s;
}
@media (max-width: 767px) {
  .v1 .header__toggle-icon:before,
  .v1 .header__toggle-icon:after {
    background-color: #4f5559;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .header__toggle-icon:before {
    top: -9px;
  }
}
@media (max-width: 767px) {
  .v1 .header__toggle-icon:before {
    top: -7px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .header__toggle-icon:after {
    top: 9px;
  }
}
@media (max-width: 767px) {
  .v1 .header__toggle-icon:after {
    top: 7px;
  }
}
.v1 .header__user {
  display: none;
  border-bottom: 1px solid #474747;
}
@media (max-width: 767px) {
  .v1 .header__user {
    display: block;
  }
  .v1 .header__user.open .header__user-menu {
    display: block;
  }
  .v1 .header__user.open .header__user-label:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.v1 .header__user-label {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 54px;
  padding: 10px 48px;
  font-size: 15px;
  line-height: 1.2;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}
.v1 .header__user-label:hover {
  color: #f460a3;
}
.v1 .header__user-label:after {
  content: "";
  position: absolute;
  top: 26px;
  right: 23px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0;
  border-color: #c9c9c9 transparent transparent;
}
.v1 .header__user-menu {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #282828;
}
.v1 .header__user-item {
  border-bottom: 1px solid #323232;
}
.v1 .header__user-item:last-child {
  border-bottom: 0 none;
}
.v1 .header__user-link {
  display: block;
  padding: 14px 48px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
}
.v1 .header__user-link:hover {
  color: #f460a3;
}
.v1 .header__reseller {
  width: 150px;
  height: 40px;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f460a3+0,812975+100 */
  background: #f460a3;
/* Old browsers */
  background: -webkit-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: -moz-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: -o-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: -ms-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: linear-gradient(to right, #f460a3 0%, #812975 100%);
/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  border-radius: 50px;
  color: #fff;
  font-weight: bold;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}
.v1 .header__reseller-label {
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  text-align: center;
}
#menu.nav-sticky {
  padding-top: 72px;
}
#tooltip-ticket {
  text-align: center;
  vertical-align: middle;
}
.animated-letters {
  line-height: 25px;
}
.animated-letters span {
  display: inline-block;
}
.header-search {
  position: relative;
  font-size: 0;
}
@media (max-width: 767px) {
  .header-search .form-control {
    border: 0;
    border-radius: 0;
  }
}
.header-search__label {
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 12px 40px 12px 0;
  text-align: center;
  width: 100%;
  color: #ccc;
}
.header-search.active .search-field__icon {
  color: #fff;
}
.header-search.active .search-field__button {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
  pointer-events: auto;
}
@media (max-width: 999px) {
  .header-search.active .search-field__cancel {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    visibility: visible;
    pointer-events: auto;
  }
}
.header-search__input,
.header-search .header-search__input.search-engine {
  border-radius: 20px 0 0 20px;
  padding-right: 60px;
  padding-left: 15px;
  font-size: 18px;
  height: 40px;
  margin-top: 4px;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -o-transition-property: color;
  -ms-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  transition-duration: 0.5s;
  transition-timing-fonction: linear;
  border-color: #f460a3;
}
@media (max-width: 999px) {
  .header-search__input,
  .header-search .header-search__input.search-engine {
    padding-left: 15px;
  }
}
@media (max-width: 767px) {
  .header-search__input,
  .header-search .header-search__input.search-engine {
    padding-right: 70px;
  }
}
.header-search__input:focus,
.header-search .header-search__input.search-engine:focus {
  -webkit-box-shadow: 0 5px 19px 0 rgba(0,0,0,0.05);
  box-shadow: 0 5px 19px 0 rgba(0,0,0,0.05);
}
@media (max-width: 767px) {
  .header-search__input:focus,
  .header-search .header-search__input.search-engine:focus {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.header-search__input.animate,
.header-search .header-search__input.search-engine.animate {
  color: #ffc0cb;
}
.header-search__input.animate::placeholder,
.header-search .header-search__input.search-engine.animate::placeholder {
  color: #ffc0cb;
}
.header-search__input::placeholder,
.header-search .header-search__input.search-engine::placeholder,
.header-search__input::-moz-placeholder,
.header-search .header-search__input.search-engine::-moz-placeholder,
.header-search__input:-ms-input-placeholder,
.header-search .header-search__input.search-engine:-ms-input-placeholder {
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -o-transition-property: color;
  -ms-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  transition-duration: 0.5s;
  transition-timing-fonction: linear;
}
.header-search__button {
  margin-right: auto;
  vertical-align: bottom;
  padding: 8px 11px 7px !important;
  font-size: 18px;
  font-weight: normal;
  border-radius: 0 20px 20px 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  display: inline-block;
  height: 40px;
  margin-top: 4px;
}
@media (max-width: 767px) {
  .header-search__button {
    top: 6px;
    right: 15px;
    display: none;
    margin-right: 0;
  }
}
.header-search__icon {
  padding: 0;
  margin: 0;
  font-size: 16px;
  background: none;
  border: 0 none;
  outline: 0 none;
  color: #fff;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
@media (max-width: 999px) {
  .header-search__icon {
    left: 52px;
  }
}
@media (max-width: 767px) {
  .header-search__icon {
    top: 14px;
    font-size: 19px;
  }
}
.header-search_no-border .search-field__input {
  border: 0 none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (max-width: 767px) {
  .header-search_no-border {
    margin: 0 15px 10px;
  }
}
.header-search_big .search-field__input {
  height: 70px;
  padding: 22px 25px 23px 50px;
  font-size: 18px;
}
@media (max-width: 767px) {
  .header-search_big .search-field__input {
    padding: 14px 15px 14px 43px;
    height: 48px;
    font-size: 17px;
  }
}
.header-search_big .search-field__icon {
  top: 26px;
  left: 15px;
}
@media (max-width: 767px) {
  .header-search_big .search-field__icon {
    top: 14px;
  }
}
.header-search_big .search-field__button {
  left: 0;
  top: 10px;
  font-size: 20px;
}
@media (max-width: 767px) {
  .header-search_big .search-field__button {
    left: 3px;
    top: 1px;
    font-size: 18px;
  }
}
.header-search .tt-menu {
  right: 0;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  -webkit-box-shadow: 0 9px 21px 0 rgba(0,0,0,0.14);
  box-shadow: 0 9px 21px 0 rgba(0,0,0,0.14);
  margin-left: 18px;
}
@media (max-width: 767px) {
  .header-search .tt-menu {
    top: 49px !important;
    left: -1px !important;
    margin-left: 0;
  }
}
.header-search .tt-cursor {
  background-color: #fbfbfb;
}
.header-search .tt-suggestion {
  display: block;
  font-size: 18px;
  color: #4c4c4c;
  font-weight: normal;
  padding: 14px 20px 14px 20px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  -webkit-transition: background-color 0.1s ease-in-out;
  -moz-transition: background-color 0.1s ease-in-out;
  -o-transition: background-color 0.1s ease-in-out;
  -ms-transition: background-color 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out;
  text-decoration: none;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@media (max-width: 999px) {
  .header-search .tt-suggestion {
    padding-left: 15px;
  }
}
.header-search .tt-suggestion:last-child {
  border-bottom: 0;
}
.header-search .tt-suggestion:hover {
  background-color: #fbfbfb;
  text-decoration: none;
}
.header-search form {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.header-search form .twitter-typeahead {
  display: block;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.header-search .twitter-typeahead {
  max-width: 245px;
  margin-left: auto;
}
@media (max-width: 999px) {
  .header-search .twitter-typeahead {
    width: 720px;
    max-width: none;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .header-search .twitter-typeahead {
    width: 100vw;
    max-width: none;
    margin-left: 0;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .header-search .twitter-typeahead {
    max-width: 200px;
  }
}
.v1 .homepage-content .container {
  padding-top: 50px;
  padding-bottom: 50px;
}
.v1 .homepage-content .container embed,
.v1 .homepage-content .container iframe,
.v1 .homepage-content .container object,
.v1 .homepage-content .container video {
  max-width: 100%;
}
.v1 .homepage-content .container img {
  max-width: 90%;
}
.v1 .homepage-product-list {
  padding-top: 25px;
  padding-bottom: 50px;
}
@media (max-width: 767px) {
  .v1 .homepage-product-list {
    padding-bottom: 10px;
  }
}
#page-taxon img[data-src].defer,
img[data-src].defer {
  width: 100%;
  height: 1px;
  background-color: #fff;
}
html body picture.filter-picture-app_itemblock_small img[data-src].defer {
  padding-top: calc(100% * 145 / 195);
}
html body picture.filter-picture-app_itemblock_large img[data-src].defer {
  padding-top: calc(100% * 261 / 348);
}
html body picture.filter-picture-app_itemblock_small_medium img[data-src].defer {
  padding-top: calc(100% * 158 / 212);
}
html body picture.filter-picture-app_itemblock_medium img[data-src].defer {
  padding-top: calc(100% * 214 / 287);
}
html body img.defer.app_itemblock_small {
  width: 195px;
  height: 145px;
}
html body img.defer.app_itemblock_large {
  width: 348px;
  height: 261px;
}
html body img.defer.app_itemblock_small_medium {
  width: 212px;
  height: 158px;
}
html body img.defer.app_itemblock_medium {
  width: 287px;
  height: 214px;
}
.image-icon {
  float: left;
  top: 5px;
  position: absolute;
  left: 5px;
  z-index: 5;
  background-color: #ccc;
  border-radius: 20px;
  padding: 5px;
  color: #f460a3;
  font-weight: bold;
}
.v1 .images-box {
  margin: 40px 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media (max-width: 767px) {
  .v1 .images-box {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 30px 0;
  }
}
@media (min-width: 768px) {
  .v1 .images-box__image_big {
    width: 60%;
    padding-right: 10px;
  }
}
@media (max-width: 767px) {
  .v1 .images-box__image_big {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .v1 .images-box__image_small {
    padding: 0 5px;
  }
}
.v1 .images-box__image img {
  display: block;
  width: 100%;
  -webkit-box-shadow: 0 4px 18px rgba(0,0,0,0.1);
  box-shadow: 0 4px 18px rgba(0,0,0,0.1);
}
.v1 .images-box__body {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media (min-width: 768px) {
  .v1 .images-box__body {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 40%;
    padding-left: 10px;
  }
}
@media (max-width: 767px) {
  .v1 .images-box__body {
    margin: 0 -5px;
  }
  .v1 .images-box__body .images-box__image {
    width: 50%;
  }
}
.v1 .info-message {
  padding: 20px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
}
.v1 .info-message__text {
  font-size: 16px;
  color: #888b8d;
}
.v1 .info-message__icon {
  margin-bottom: 1em;
  text-align: center;
  height: 55px;
  text-align: center;
}
.v1 .info-message__icon:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.v1 .info-message__icon >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.v1 .info-message_default {
  background-color: #fcfcfc;
}
@media (max-width: 767px) {
  .v1 .info-message_default {
    padding: 15px 15px 30px;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}
@media (max-width: 767px) {
  .v1 .info-message_default .info-message__text {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .v1 .info-message_simple {
    padding: 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (max-width: 767px) {
  .v1 .info-message_simple .info-message__icon {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    margin: 0 20px 0 0;
    width: 50px;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .v1 .info-message_simple .info-message__text {
    font-size: 20px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .v1 .info-message_simple .info-message__text {
    min-height: 50px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
.v1 .info-message p {
  margin-bottom: 0;
}
.v1 .info-modal__body {
  margin-bottom: 60px;
  text-align: center;
}
@media (max-width: 767px) {
  .v1 .info-modal__body {
    margin-bottom: 25px;
  }
}
.v1 .info-modal__image {
  height: 100px;
  text-align: center;
  margin-bottom: 40px;
}
.v1 .info-modal__image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.v1 .info-modal__image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.v1 .info-modal__title {
  margin-bottom: 15px;
  font-size: 36px;
  line-height: 1.25;
  font-weight: 500;
}
@media (max-width: 767px) {
  .v1 .info-modal__title {
    font-size: 18px;
    line-height: 1.5;
  }
}
.v1 .info-modal__text {
  font-size: 14px;
  color: #888b8d;
}
.v1 .invoices-list {
  background-color: #fff;
}
@media (max-width: 767px) {
  .v1 .invoices-list {
    background-color: transparent;
  }
}
.v1 .invoices-list__item {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 17px;
  border-bottom: 1px solid #f2f2f2;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
@media (max-width: 767px) {
  .v1 .invoices-list__item {
    display: block;
    margin: 0 15px 10px;
    border-bottom: 0 none;
    background-color: #fff;
    zoom: 1;
  }
  .v1 .invoices-list__item:before,
  .v1 .invoices-list__item:after {
    content: "";
    display: table;
  }
  .v1 .invoices-list__item:after {
    clear: both;
  }
}
.v1 .invoices-list__item:last-child {
  border-bottom: 0 none;
}
.v1 .invoices-list__item:hover {
  background-color: #fafafa;
}
.v1 .invoices-list__item:hover .invoices-list__control {
  visibility: visible;
}
.v1 .invoices-list__item:hover .invoices-list__control-link {
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  -ms-transition-delay: 0.1s;
  transition-delay: 0.1s;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.v1 .invoices-list__item_header {
  font-weight: 500;
  border-width: 1px 0;
  border-style: solid;
  border-color: #e8e8e8;
  background-color: #fcfcfc !important;
}
@media (max-width: 767px) {
  .v1 .invoices-list__item_header {
    display: none;
  }
}
.v1 .invoices-list__item_header .invoices-list__col {
  padding: 28px 18px;
  border-right-color: transparent;
}
.v1 .invoices-list__col {
  width: 17%;
  padding: 38px 18px;
  border-right: 1px solid #f2f2f2;
}
@media (max-width: 767px) {
  .v1 .invoices-list__col {
    width: 100%;
    padding: 15px 0;
    border-right: 0 none;
    border-bottom: 1px solid #e8e8e8;
  }
}
.v1 .invoices-list__col:last-child {
  border-right: 0 none;
}
@media (max-width: 767px) {
  .v1 .invoices-list__col_wide,
  .v1 .invoices-list__col_centered {
    float: left;
    border-bottom: 0 none;
  }
}
@media (max-width: 767px) {
  .v1 .invoices-list__col_wide .invoices-list__label,
  .v1 .invoices-list__col_centered .invoices-list__label {
    margin-left: 0;
    font-size: 14px;
  }
}
.v1 .invoices-list__col_wide {
  width: 49%;
}
@media (max-width: 767px) {
  .v1 .invoices-list__col_wide {
    padding: 10px 15px;
    width: 50%;
  }
}
.v1 .invoices-list__col_centered {
  text-align: center;
}
@media (max-width: 767px) {
  .v1 .invoices-list__col_centered {
    padding: 10px 15px 10px 5px;
    text-align: right;
    width: 25%;
  }
}
.v1 .invoices-list__label {
  display: none;
}
@media (max-width: 767px) {
  .v1 .invoices-list__label {
    display: inline-block;
    margin-left: 15px;
    font-weight: bold;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .v1 .invoices-list__number {
    display: inline-block;
  }
}
.v1 .invoices-list__text {
  font-weight: 500;
}
@media (max-width: 767px) {
  .v1 .invoices-list__text {
    font-weight: normal;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .v1 .invoices-list__price,
  .v1 .invoices-list__date {
    font-size: 14px;
  }
}
.v1 .invoices-list__control {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  padding: 26px 0 0 15px;
}
@media (min-width: 768px) {
  .no-touchevents .v1 .invoices-list__control {
    visibility: hidden;
  }
}
@media (max-width: 767px) {
  .v1 .invoices-list__control {
    padding: 0;
    top: 0;
    right: 5px;
    left: auto;
    bottom: auto;
  }
}
.v1 .invoices-list__control-link {
  display: block;
  width: 48px;
  height: 48px;
  padding: 5px 0 0 1px;
  font-size: 24px;
  border-radius: 100px;
  -webkit-transition: 0.2s opacity ease-in-out, 0.2s -webkit-transform ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out, 0.2s -moz-transform ease-in-out;
  -o-transition: 0.2s opacity ease-in-out, 0.2s -o-transform ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out, 0.2s -ms-transform ease-in-out;
  transition: 0.2s opacity ease-in-out, 0.2s transform ease-in-out;
}
@media (min-width: 768px) {
  .no-touchevents .v1 .invoices-list__control-link {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateX(-10px);
    -moz-transform: translateX(-10px);
    -o-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }
}
@media (max-width: 767px) {
  .v1 .invoices-list__control-link {
    padding: 10px 0 0;
    font-size: 25px;
    color: #c1c1c1;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0 none;
  }
  .v1 .invoices-list__control-link:hover,
  .v1 .invoices-list__control-link:active {
    color: #c1c1c1;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.ui.label {
  background-color: #e8e8e8;
  border-radius: 5px;
  color: rgba(0,0,0,0.6);
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  padding: 8px 16px;
}
.ui.label.fluid {
  text-align: center;
  width: 100%;
}
.ui.label.black {
  background-color: #1b1c1d;
  color: #fff;
}
.ui.label.black.inverted {
  background-color: transparent;
  color: #1b1c1d;
}
.ui.label.black.basic {
  background-color: transparent;
  border: 1px solid #1b1c1d;
  color: #1b1c1d;
}
.ui.label.black.basic.inverted {
  background-color: transparent;
  color: #1b1c1d;
}
.ui.label.blue {
  background-color: #2185d0;
  color: #fff;
}
.ui.label.blue.inverted {
  background-color: transparent;
  color: #2185d0;
}
.ui.label.brown {
  background-color: #a5673f;
  color: #fff;
}
.ui.label.brown.inverted {
  background-color: transparent;
  color: #a5673f;
}
.ui.label.green {
  background-color: #21ba45;
  color: #fff;
}
.ui.label.green.inverted {
  background-color: transparent;
  color: #21ba45;
}
.ui.label.grey {
  background-color: #767676;
  color: #fff;
}
.ui.label.grey.inverted {
  background-color: transparent;
  color: #767676;
}
.ui.label.olive {
  background-color: #b5cc18;
  color: #fff;
}
.ui.label.olive.inverted {
  background-color: transparent;
  color: #b5cc18;
}
.ui.label.orange {
  background-color: #f2711c;
  color: #fff;
}
.ui.label.orange.inverted {
  background-color: transparent;
  color: #f2711c;
}
.ui.label.pink {
  background-color: #e03997;
  color: #fff;
}
.ui.label.pink.inverted {
  background-color: transparent;
  color: #e03997;
}
.ui.label.purple {
  background-color: #a333c8;
  color: #fff;
}
.ui.label.purple.inverted {
  background-color: transparent;
  color: #a333c8;
}
.ui.label.red {
  background-color: #f2711c;
}
.ui.label.red.inverted {
  background-color: transparent;
  color: #f2711c;
}
.ui.label.teal {
  background-color: #00b5ad;
  color: #fff;
}
.ui.label.teal.inverted {
  background-color: transparent;
  color: #00b5ad;
}
.ui.label.violet {
  background-color: #6435c9;
  color: #fff;
}
.ui.label.violet.inverted {
  background-color: transparent;
  color: #6435c9;
}
.ui.label.yellow {
  background-color: #fbbd08;
  color: #fff;
}
.ui.label.yellow.inverted {
  background-color: transparent;
  color: #fbbd08;
}
.ui.label .icon {
  width: auto;
  margin: 0 0.5em 0 0;
}
.ui.inverted {
  background-color: transparent;
  padding: 0;
}
@media (min-width: 1240px) {
  #landing-page .container {
    width: 1200px;
  }
}
#landing-page .lp-block-left .image {
  text-align: center;
}
#landing-page .lp-block-left .image img {
  max-height: 500px;
  max-width: 100%;
}
#landing-page .lp-block-right {
  padding: 10px;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  -moz-box-shadow: 0 0 3px #ccc;
}
#landing-page .lp-block-right .block-pink {
  background: url("i/sprite-pocmobile.png") no-repeat left -95px #e2007a;
  padding: 15px 5px;
}
#landing-page .lp-block-right .block-pink h4 {
  color: #fff;
  border-left: 5px solid #e30f6f;
  padding-left: 7px;
  line-height: 0.8em;
  margin: 0;
}
#landing-page .lp-block-right h5 {
  border-left: 5px solid #e30f6f;
  padding-left: 7px;
  font-size: 23px;
  margin: 10px 0;
  text-transform: uppercase;
}
#landing-page .lp-block-right .text1 {
  text-align: center;
  padding: 0 60px;
}
#landing-page .lp-block-right .block-prices {
  margin: 20px 13px;
}
#landing-page .lp-block-right .block-prices h1 {
  font-weight: 800;
  margin-bottom: 15px;
}
#landing-page .lp-block-right .block-prices h2 {
  font-weight: 800;
  margin-bottom: 15px;
}
#landing-page a {
  color: #e30f6f;
  text-decoration: none;
}
#landing-page .big-text {
  font-size: 20px;
}
#landing-page .pink {
  color: #e30f6f;
}
#landing-page p {
  margin: 0;
}
#landing-page .btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
#landing-page .btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
#landing-page .center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
#landing-page .btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#landing-page .text-uppercase {
  text-transform: uppercase;
}
#landing-page .benefits-block {
  margin-bottom: 20px;
}
.landing-page.footer {
  padding: 20px 0;
}
.landing-page.footer.nps .footer__content {
  color: #979797;
  font-size: 18px;
  text-align: center;
  display: block;
}
.landing-page.footer .footer__content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  width: 100%;
}
.landing-page.footer .footer__content .footer__copyright {
  padding-top: 10px;
  padding-left: 20px;
}
body.nps header.landing-page .user-infos {
  float: right;
  text-align: right;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
@media (max-width: 767px) {
  body.nps header.landing-page .user-infos {
    font-size: 12px;
  }
}
header.landing-page ul {
  list-style-type: none;
  list-style-position: inside;
}
@media (max-width: 767px) {
  header.landing-page ul {
    display: none;
  }
}
header.landing-page ul li {
  float: left;
  font-size: 12px;
  padding: 0 10px;
}
.landing-page .header .container {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.landing-page .header .container .header-right {
  text-align: center;
  margin-left: auto;
}
.landing-page .header .container .header-right .counter {
  font-size: 24px;
  font-weight: bold;
  color: #f460a3;
}
.landing-page.header .container {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.landing-page.header .container .header-right {
  text-align: center;
  margin-left: auto;
}
.landing-page.header .container .header-right .counter {
  font-size: 24px;
  font-weight: bold;
  color: #f460a3;
}
.landing-page.header .header__main {
  padding: 4px 0;
}
.landing-page.page {
  margin-top: 40px;
}
.link-with-icon {
  display: inline-block;
  text-decoration: none;
}
.link-with-icon_offset-left {
  margin-left: 8px;
}
.link-with-icon:hover {
  color: #f460a3;
}
.link-with-icon__icon {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  font-size: 22px;
  color: #bfbfbf;
}
.link-with-icon__label {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .link-with-icon__label {
    font-size: 14px;
  }
}
.v1 .login-register-box {
  padding: 45px 50px 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 100%;
}
@media (max-width: 767px) {
  .v1 .login-register-box__title {
    font-size: 20px;
  }
}
.v1 .login-register-box__subtitle {
  font-size: 14px;
}
@media (max-width: 767px) {
  .v1 .login-register-box {
    padding: 15px 10px 0px;
    display: block;
  }
}
.v1 .login-box {
  width: 530px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .v1 .login-box {
    max-width: 530px;
    width: auto;
  }
}
.v1 .login-box__form {
  padding: 45px 50px 50px;
  margin-bottom: 35px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 6px rgba(115,115,115,0.33);
  box-shadow: 0 2px 6px rgba(115,115,115,0.33);
}
@media (max-width: 767px) {
  .v1 .login-box__form {
    margin-bottom: 0;
    padding: 15px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.v1 .login-box__form-link {
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
}
.v1 .login-box__form-link:hover {
  color: #f460a3;
}
@media (max-width: 767px) {
  .v1 .login-box__form-link {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .v1 .login-box__form-footer {
    margin-top: 35px;
  }
}
.v1 .login-box__form-button {
  display: block;
  width: 100%;
}
.v1 .login-box__footer {
  font-size: 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .v1 .login-box__footer-item {
    margin-bottom: 20px;
  }
}
.v1 .login-box__customer {
  color: #434343;
  text-decoration: none;
}
.v1 .login-box__customer:hover {
  color: #f460a3;
}
.v1 .login-box__create {
  font-weight: bold;
  color: #f460a3;
  text-decoration: none;
}
.v1 .login-box__create:hover {
  color: #434343;
}
@media (max-width: 767px) {
  .v1 .login-box__customer,
  .v1 .login-box__create {
    font-size: 14px;
  }
}
.v1 .login-box__title {
  margin: 0 0 15px;
  font-size: 30px;
  line-height: 1.35;
  font-weight: bold;
}
@media (max-width: 767px) {
  .v1 .login-box__title {
    margin: 0 0 5px;
    font-size: 17px;
  }
}
.v1 .login-box__subtitle {
  font-size: 14px;
  margin-bottom: 15px;
}
.main {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.main.nps-survey {
  background-image: url("i/flying-business-cards.jpg");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.main-add-menu__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .main-add-menu__list-item {
    float: right;
  }
  .main-add-menu__list-item:last-child {
    margin-right: 0;
  }
  .main-add-menu__list-item.menu-drop-active,
  .main-add-menu__list-item:hover:not(.has-sub) {
    background-color: #212121;
  }
  .main-add-menu__list-item .wrapper {
    height: 50px;
    padding: 6px 15px;
  }
}
.main-add-menu__link {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 50px;
  padding: 12px 15px;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
.header__main .main-add-menu__link {
  color: #434343;
}
@media (min-width: 768px) and (max-width: 1239px) {
  .main-add-menu__link {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .main-add-menu__link {
    display: none;
  }
}
.main-add-menu__link-label {
  display: block;
  font-size: 12px;
  color: #888b8d;
}
.header__main .main-add-menu__link-label {
  color: #434343;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .main-add-menu__link-label {
    font-size: 10px;
  }
}
.main-add-menu__link-text {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.main-add-menu__link-icon {
  padding-right: 10px;
  color: #888b8d;
}
.main-menu__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .main-menu__list-item {
    float: left;
  }
  .main-menu__list-item:last-child {
    margin-right: 0;
  }
  .main-menu__list-item:first-child {
    margin-left: -15px;
  }
  .main-menu__list-item.menu-drop-active,
  .main-menu__list-item:hover:not(.has-sub) {
    background-color: #212121;
  }
}
@media (min-width: 768px) and (max-width: 1239px) {
  .main-menu__list-item {
    max-width: 160px;
  }
}
@media (max-width: 767px) {
  .main-menu__list-item .main-menu__link:after {
    content: "";
    position: absolute;
    top: 26px;
    right: 23px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0;
    border-color: #c9c9c9 transparent transparent;
  }
}
@media (max-width: 767px) {
  .main-menu__list-item .main-menu__sub-item {
    display: none;
  }
}
@media (max-width: 767px) {
  .main-menu__list-item.open .main-menu__link:after {
    top: 24px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@media (max-width: 767px) {
  .main-menu__list-item.open .main-menu__sub-item {
    display: block;
  }
}
.main-menu__link {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 50px;
  padding: 12px 15px;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
.main-menu__link .filler {
  display: none;
}
.main-menu__link a {
  color: #fff;
  text-decoration: none;
}
.main-menu__link:hover > a,
.main-menu__link:hover > .altlink,
.main-menu__link:hover > .link {
  color: #f460a3;
}
@media (min-width: 768px) and (max-width: 1239px) {
  .main-menu__link {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    white-space: nowrap;
  }
}
@media (max-width: 767px) {
  .main-menu__link {
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: relative;
    height: 54px;
    padding: 10px 48px;
    font-size: 15px;
    line-height: 1.2;
    color: #fff;
    font-weight: 500;
    border-bottom: 1px solid #474747;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -o-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .main-menu__link a {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .main-menu__link .filler {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: block;
  }
@media (max-width: 767px) {
    .main-menu__link.has-sub:after {
      content: "";
      position: absolute;
      top: 26px;
      right: 23px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 4px 0;
      border-color: #c9c9c9 transparent transparent;
    }
}
@media (max-width: 767px) {
    .main-menu__link.has-sub.open > .main-menu__sub-link:after {
      top: 24px;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
}
  .main-menu__link.has-sub.open .main-menu__sub2 {
    display: block;
  }
}
.main-menu__link-label {
  display: block;
  font-size: 12px;
  color: #888b8d;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .main-menu__link-label {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .main-menu__link-label {
    display: none;
  }
}
.menu-drop-show .main-menu__wrap.all {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.main-menu__wrap.all .close {
  top: 46px;
}
.main-menu__wrap.all .top-products {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px solid #c7c7c7;
  background-color: #fff;
  padding-top: 10px;
  padding-right: 15px;
}
.main-menu__wrap.all .top-products .title {
  font-weight: bold;
}
.main-menu__wrap.all .top-products .icon {
  color: #ffd630;
}
.main-menu__wrap.all .block-alpha {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.main-menu__wrap.all:before,
.main-menu__wrap.all:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50vw;
  background-color: #fff;
}
.main-menu__wrap.all:before {
  right: 100%;
}
.main-menu__wrap.all:after {
  left: 100%;
}
.main-menu__wrap .close {
  position: absolute;
  left: 99%;
  top: 16px;
  color: #000;
  z-index: 1000;
  cursor: pointer;
}
@media (max-width: 767px) {
  .main-menu__wrap .close {
    display: none;
  }
}
.main-menu__wrap .all-alpha {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  list-style-type: none;
  padding-left: 0;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #c1c1c1;
}
.main-menu__wrap .all-alpha .letter {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}
.main-menu__wrap .all-alpha .letter.active {
  background-color: #fff;
  color: #f460a3;
}
@media (min-width: 768px) {
  .main-menu__wrap {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transition: 0.1s opacity ease-in-out;
    -moz-transition: 0.1s opacity ease-in-out;
    -o-transition: 0.1s opacity ease-in-out;
    -ms-transition: 0.1s opacity ease-in-out;
    transition: 0.1s opacity ease-in-out;
  }
  .menu-drop-active .main-menu__wrap {
    display: block;
  }
  .menu-drop-show .main-menu__wrap {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@media (min-width: 768px) {
  .main-menu__wrap-inner {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    background-color: #f7f7f7;
    min-height: 500px;
  }
  .main-menu__wrap-inner:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 50vw;
    background-color: #f7f7f7;
  }
}
@media (min-width: 1000px) {
  .main-menu__wrap_with-banner .main-menu__box {
    width: 66.666%;
  }
  .main-menu__wrap_with-banner .main-menu__box:after {
    content: none;
  }
  .main-menu__wrap_with-banner .main-menu__banner {
    width: 33.333%;
  }
  .main-menu__wrap_with-banner .main-menu__sub {
    columns: 2;
  }
}
@media (min-width: 768px) {
  .main-menu__box {
    width: 100%;
    position: relative;
    padding: 20px 15px 0;
    background-color: #fff;
  }
  .main-menu__box:before,
  .main-menu__box:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50vw;
    background-color: #fff;
  }
  .main-menu__wrap.all .main-menu__box:before,
  .main-menu__wrap.all .main-menu__box:after {
    display: none;
  }
  .main-menu__box:before {
    right: 100%;
  }
  .main-menu__box:after {
    left: 100%;
  }
}
.main-menu__sub {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 768px) {
  .main-menu__sub {
    columns: 3;
  }
  .all .main-menu__sub {
    columns: auto;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (min-width: 768px) {
  .main-menu__sub-item {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .main-menu__sub-item {
    border-bottom: 1px solid #474747;
  }
  .main-menu__sub-item.has-sub .main-menu__sub-link:after {
    content: "";
    position: absolute;
    top: 26px;
    right: 23px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0;
    border-color: #c9c9c9 transparent transparent;
  }
  .main-menu__sub-item.has-sub.open .main-menu__sub-link:after {
    top: 24px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .main-menu__sub-item.has-sub.open .main-menu__sub2 {
    display: block;
  }
}
.main-menu__sub-item .letter {
  position: relative;
  left: -25px;
  top: 10px;
  color: #f460a3;
  height: 0;
  float: left;
}
.main-menu__sub-item[data-letter][data-bloc] {
  display: none;
}
.main-menu__sub-item[data-letter][data-bloc].active {
  display: block;
}
.main-menu__sub-link {
  text-decoration: none;
}
.main-menu__sub-link a {
  text-decoration: none;
}
.no-touchevents .main-menu__sub-link a:hover {
  color: #f460a3;
}
.no-touchevents .main-menu__sub-link:hover {
  color: #f460a3;
}
@media (min-width: 768px) {
  .main-menu__sub-link {
    font-size: 20px;
    line-height: 1.3;
    font-weight: 500;
  }
  a.main-menu__sub-link:hover {
    color: #f460a3;
  }
}
@media (max-width: 767px) {
  .main-menu__sub-link {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: relative;
    height: 54px;
    padding: 10px 48px;
    font-size: 15px;
    line-height: 1.2;
    color: #fff;
    font-weight: 500;
    background-color: #303030;
  }
  .main-menu__sub-link a {
    color: #fff;
  }
}
.main-menu__sub2 {
  margin: 0;
  padding: 10px 0 0;
  list-style: none;
}
@media (max-width: 767px) {
  .main-menu__sub2 {
    display: none;
    padding: 0;
    background-color: #282828;
  }
}
@media (max-width: 767px) {
  .main-menu__sub2-item {
    border-bottom: 1px solid #323232;
  }
  .main-menu__sub2-item:last-child {
    border-bottom: 0 none;
  }
}
.main-menu__sub2-link {
  font-size: 16px;
  line-height: 1.2;
  text-decoration: none;
}
@media (max-width: 767px) {
  .main-menu__sub2-link {
    display: block;
    padding: 14px 48px;
    font-size: 14px;
    color: #fff;
  }
}
.no-touchevents .main-menu__sub2-link:hover {
  color: #f460a3;
}
.no-touchevents a.main-menu__sub2-link:hover {
  color: #f460a3;
}
.main-menu__sub2-badge {
  display: inline-block;
  vertical-align: top;
  margin-left: 8px;
  padding: 4px 8px 2px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  color: #f460a3;
  -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.11);
  box-shadow: 0 2px 4px rgba(0,0,0,0.11);
}
@media (max-width: 767px) {
  .main-menu__sub2-badge {
    display: none;
  }
}
.main-menu__banner {
  display: none;
  padding: 50px 15px 0 0;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
@media (min-width: 1000px) {
  .main-menu__banner {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
.main-menu__banner-wrap {
  width: 310px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .main-menu__banner-wrap {
    width: 290px;
  }
}
.page.marketing-campaign.premium {
  font-size: 14px;
}
.page.marketing-campaign.premium .mc-block-top {
  height: 500px;
  background-color: #f460a3;
  padding: 60px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}
@media (max-width: 767px) {
  .page.marketing-campaign.premium .mc-block-top {
    height: auto;
    padding: 30px;
  }
}
.page.marketing-campaign.premium .mc-block-top .mc-form {
  border: 1px solid #939393;
  border-radius: 3px;
  float: right;
  width: 35%;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .page.marketing-campaign.premium .mc-block-top .mc-form {
    position: inherit;
    width: 100%;
  }
}
.page.marketing-campaign.premium .mc-block-top .mc-form .mc-header {
  padding: 25px 25px 0 0;
}
.page.marketing-campaign.premium .mc-block-top .mc-form .mc-fields {
  padding: 0 25px 25px;
  background-color: #fff;
}
.page.marketing-campaign.premium .mc-block-1,
.page.marketing-campaign.premium .mc-block-2 {
  width: 65%;
}
@media (max-width: 767px) {
  .page.marketing-campaign.premium .mc-block-1 {
    display: none;
  }
}
.page.marketing-campaign.premium .mc-block-2 {
  padding: 20px 20px 70px;
}
@media (max-width: 767px) {
  .page.marketing-campaign.premium .mc-block-2 {
    width: 100%;
  }
}
.page.marketing-campaign.premium .mc-block-2 .mc-block-steps {
  background-color: #f3f3f3;
  border: 1px solid #939393;
  border-radius: 3px;
  padding: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 40px;
}
.page.marketing-campaign.premium .mc-block-2 .mc-block-steps .step {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 33%;
  -ms-flex: 1 0 33%;
  flex: 1 0 33%;
}
.page.marketing-campaign.premium .mc-block-2 .mc-block-steps .step .inner {
  padding: 0 15px 5px;
}
.page.marketing-campaign.premium hr {
  margin: 0 0 20px;
  overflow: visible;
  padding: 0;
  border: none;
  border-top: 4px solid #f460a3;
  color: #f460a3;
  text-align: center;
}
.page.marketing-campaign.premium hr:after {
  display: inline-block;
  position: relative;
  top: -0.7em;
  font-size: 1.5em;
  padding: 0 0.25em;
  background: #f3f3f3;
  font-weight: bold;
}
.page.marketing-campaign.premium hr.one:after {
  content: "1";
}
.page.marketing-campaign.premium hr.two:after {
  content: "2";
}
.page.marketing-campaign.premium hr.three:after {
  content: "3";
}
.menu-banner {
  display: block;
  text-decoration: none;
  text-align: center;
}
.menu-banner:hover .menu-banner__image {
  -webkit-box-shadow: 0 4px 28px rgba(0,0,0,0.15);
  box-shadow: 0 4px 28px rgba(0,0,0,0.15);
}
.menu-banner:hover .menu-banner__title {
  color: #f460a3;
}
.menu-banner__image {
  display: block;
  margin-bottom: 25px;
  height: 300px;
  background-color: #fff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0 4px 18px rgba(0,0,0,0.1);
  box-shadow: 0 4px 18px rgba(0,0,0,0.1);
  -webkit-transition: 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s box-shadow ease-in-out;
  transition: 0.2s box-shadow ease-in-out;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .menu-banner__image {
    height: 290px;
  }
}
.menu-banner__title {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
.menu-banner__text {
  font-size: 16px;
  font-weight: 500;
  color: #888b8d;
}
.menu-services {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  list-style-type: none;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.menu-services .menu-services-item {
  cursor: pointer;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 33%;
  -ms-flex: 0 1 33%;
  flex: 0 1 33%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 25px;
  padding: 10px;
}
.menu-services .menu-services-item:hover {
  background-color: #ece9e9;
  border-radius: 4px;
}
.menu-services .menu-services-item img,
.menu-services .menu-services-item svg {
  width: 70px;
  height: 70px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.menu-services .menu-services-item div.content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.menu-services .menu-services-item div.content p {
  margin: 0;
}
.menu-services .menu-services-item div.content p.description {
  font-size: 14px;
}
.menu-services .menu-services-item div.content p.title {
  font-size: 20px;
}
.v1 .message {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 17px;
}
.v1 .message__header {
  padding: 28px 20px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 230px;
  -ms-flex: 0 0 230px;
  flex: 0 0 230px;
}
.v1 .message__body {
  padding: 28px 20px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.v1 .message__date,
.v1 .message__origin {
  color: #888b8d;
}
.v1 .message__date span,
.v1 .message__origin span {
  font-weight: 500;
  color: #434343;
}
.v1 .message__date {
  margin-bottom: 12px;
}
.v1 .message__name {
  font-weight: bold;
}
.v1 .message__description {
  color: #555;
}
.v1 .messages {
  background-color: #fff;
}
.v1 .messages__header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 20px 25px;
}
@media (max-width: 767px) {
  .v1 .messages__header {
    padding: 15px;
  }
}
@media (min-width: 768px) {
  .v1 .messages__select-all {
    padding-top: 10px;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .v1 .messages__select-all {
    padding-top: 13px;
    font-size: 14px;
  }
  .v1 .messages__select-all .checkbox span {
    padding-top: 3px;
  }
}
.v1 .messages__button {
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .v1 .messages__button {
    padding: 11px 0 0;
    width: 49px;
    height: 49px;
    border-radius: 100px;
  }
}
@media (max-width: 767px) {
  .v1 .messages__button-label {
    display: none;
  }
}
.v1 .messages__button-icon {
  display: none;
  font-size: 22px;
}
@media (max-width: 767px) {
  .v1 .messages__button-icon {
    display: block;
  }
}
.v1 .messages__item {
  position: relative;
  padding: 30px 40px 25px 60px;
  font-size: 17px;
  border-top: 1px solid #e8e8e8;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
@media (min-width: 768px) {
  .v1 .messages__item {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (max-width: 767px) {
  .v1 .messages__item {
    padding: 20px 15px 15px 50px;
    font-size: 14px;
  }
}
.v1 .messages__item:hover,
.v1 .messages__item.selected {
  background-color: #fafafa;
}
.v1 .messages__item:hover .messages__check,
.v1 .messages__item.selected .messages__check {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  -ms-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
@media (min-width: 768px) {
  .v1 .messages__item-info {
    width: 35%;
  }
}
@media (max-width: 767px) {
  .v1 .messages__item-info {
    margin-bottom: 3px;
  }
}
@media (min-width: 768px) {
  .v1 .messages__item-body {
    width: 65%;
    padding-left: 20px;
  }
}
.v1 .messages__item-date {
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .v1 .messages__item-date {
    margin-bottom: 0;
  }
}
.v1 .messages__item-name,
.v1 .messages__item-value {
  font-weight: bold;
}
.v1 .messages__item-label {
  color: #888b8d;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .messages__item-text {
    font-size: 15px;
  }
}
.v1 .messages__check {
  position: absolute;
  top: 28px;
  left: 25px;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.no-touchevents .v1 .messages__check {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
@media (max-width: 767px) {
  .v1 .messages__check {
    left: 15px;
    top: 18px;
  }
}
.modal-reseller-subscribe {
  position: relative;
}
.modal-reseller-subscribe .btn {
  background: #f460a3;
/* Old browsers */
  background: -webkit-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: -moz-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: -o-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: -ms-linear-gradient(left, #f460a3 0%, #812975 100%);
  background: linear-gradient(to right, #f460a3 0%, #812975 100%);
/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.modal-reseller-subscribe .text-email {
  color: #f460a3;
  font-size: 14px;
}
.modal-reseller-subscribe .text-email a {
  color: #f460a3;
  font-size: 14px;
}
.modal-reseller-subscribe button.close {
  position: absolute;
  right: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: pointer;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  color: #000;
  line-height: 1;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal-reseller-subscribe button.close:hover,
.modal-reseller-subscribe button.close:focus {
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  filter: alpha(opacity=75);
}
.v1 .modal-content__title,
.v1 .modal-content__subtitle {
  font-weight: bold;
  font-size: 30px;
  color: #343434;
}
@media (max-width: 767px) {
  .v1 .modal-content__title,
  .v1 .modal-content__subtitle {
    font-size: 18px;
  }
}
.v1 .modal-content__title {
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .v1 .modal-content__title {
    margin-bottom: 10px;
  }
}
.v1 .modal-content__subtitle {
  margin-top: 40px;
}
@media (max-width: 767px) {
  .v1 .modal-content__subtitle {
    margin-top: 30px;
    margin-bottom: 25px;
  }
}
.v1 .modal-content__label {
  font-size: 17px;
}
@media (min-width: 768px) {
  .v1 .modal-content__label {
    padding-top: 15px;
    text-align: right;
  }
}
@media (max-width: 767px) {
  .v1 .modal-content__label {
    margin-bottom: 15px;
  }
}
.v1 .modal-content__label-value {
  font-size: 16px;
  font-weight: bold;
}
.v1 .modal-content__box {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-height: 408px;
  background-color: #fcfcfc;
  padding: 20px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  font-size: 16px;
  color: #888b8d;
}
@media (max-width: 767px) {
  .v1 .modal-content__box {
    padding: 15px;
    font-size: 14px;
  }
}
.v1 .modal-content__box-icon {
  margin-bottom: 1em;
  text-align: center;
}
.v1 .modal-content__box p:last-child {
  margin-bottom: 0;
}
.v1.modal,
.v1 .modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  outline: 0 none;
  background-color: rgba(200,200,200,0.84);
}
.v1.modal_dark,
.v1 .modal_dark {
  background-color: rgba(91,91,91,0.92);
}
@media (min-width: 768px) {
  .v1.modal_sm .modal-dialog,
  .v1 .modal_sm .modal-dialog {
    width: 500px;
    max-width: 500px;
  }
}
@media (min-width: 768px) {
  .v1.modal_md .modal-dialog,
  .v1 .modal_md .modal-dialog {
    width: 700px;
    max-width: 700px;
  }
}
@media (min-width: 1000px) {
  .v1.modal_lg .modal-dialog,
  .v1 .modal_lg .modal-dialog {
    width: 900px;
    max-width: 900px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1.modal_lg .modal-dialog,
  .v1 .modal_lg .modal-dialog {
    width: 750px;
    max-width: 750px;
  }
}
.v1.modal:after,
.v1 .modal:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.v1.modal-close,
.v1 .modal-close {
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 24px;
  height: 24px;
  background: none;
  border: 0 none;
  outline: 0 none;
  cursor: pointer;
}
.v1.modal-close:before,
.v1 .modal-close:before,
.v1.modal-close:after,
.v1 .modal-close:after {
  content: "";
  position: absolute;
  left: 0;
  width: 24px;
  height: 1px;
  background-color: #ddd;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
.v1.modal-close:after,
.v1 .modal-close:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.v1.modal-close:before,
.v1 .modal-close:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.v1.modal-close:hover:before,
.v1 .modal-close:hover:before,
.v1.modal-close:hover:after,
.v1 .modal-close:hover:after {
  background-color: #f460a3;
}
.v1.modal-dialog,
.v1 .modal-dialog {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 30px 0;
  min-width: 300px;
  width: 550px;
}
@media (max-width: 767px) {
  .v1.modal-dialog,
  .v1 .modal-dialog {
    width: 95%;
    max-width: 550px;
  }
}
.v1.modal-content,
.v1 .modal-content {
  position: relative;
  padding: 50px;
  background-color: #fff;
  outline: 0;
  text-align: left;
  border-radius: 8px;
  -webkit-box-shadow: 0 2px 6px rgba(115,115,115,0.33);
  box-shadow: 0 2px 6px rgba(115,115,115,0.33);
}
@media (max-width: 767px) {
  .v1.modal-content,
  .v1 .modal-content {
    padding: 30px 15px;
  }
}
.v1.modal-content_no-padding,
.v1 .modal-content_no-padding {
  padding: 0 !important;
}
.v1.modal-content_no-padding .modal-footer,
.v1 .modal-content_no-padding .modal-footer {
  margin: 50px 0 0;
}
.v1.modal-content_no-padding .modal-footer-top,
.v1 .modal-content_no-padding .modal-footer-top {
  margin: 0;
}
.v1.modal-content_no-bg,
.v1 .modal-content_no-bg {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.v1.modal-content__title,
.v1 .modal-content__title {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 20px;
}
.v1.modal-backdrop,
.v1 .modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
}
.v1.modal-open,
.v1 .modal-open {
  overflow: hidden;
}
.v1.modal-open .modal,
.v1 .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.v1.modal-open .canva-modal,
.v1 .modal-open .canva-modal {
  overflow: hidden;
}
.v1.modal-buttons,
.v1 .modal-buttons {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 35px -10px 0;
}
@media (max-width: 767px) {
  .v1.modal-buttons,
  .v1 .modal-buttons {
    margin-left: -7px;
    margin-right: -7px;
  }
}
.v1.modal-buttons .modal-button,
.v1 .modal-buttons .modal-button {
  margin: 0 10px;
  width: 50%;
}
@media (max-width: 767px) {
  .v1.modal-buttons .modal-button,
  .v1 .modal-buttons .modal-button {
    margin: 0 7px;
  }
}
@media (max-width: 767px) {
  .v1.modal-buttons_mobile-simple,
  .v1 .modal-buttons_mobile-simple {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .v1.modal-buttons_mobile-simple .modal-button,
  .v1 .modal-buttons_mobile-simple .modal-button {
    width: 100%;
    margin: 0 0 20px;
  }
  .v1.modal-buttons_mobile-simple .modal-button:last-child,
  .v1 .modal-buttons_mobile-simple .modal-button:last-child {
    margin: 0;
  }
}
.v1.modal-footer,
.v1 .modal-footer {
  padding: 25px 50px;
  margin: 50px -50px -50px;
  background-color: #fcfcfc;
  border-radius: 0 0 8px 8px;
}
@media (max-width: 767px) {
  .v1.modal-footer,
  .v1 .modal-footer {
    margin: 15px -15px -30px;
    padding: 25px 15px;
  }
}
.v1.modal-footer .modal-buttons,
.v1 .modal-footer .modal-buttons {
  margin-top: 0;
}
.v1.modal-footer-top,
.v1 .modal-footer-top {
  margin: -50px -50px 0;
  padding: 25px 50px;
  border-radius: 8px 8px 0 0;
}
#professional-option-check-modal .modal-close-button {
  width: 65%;
}
#professional-option-check-modal .modal-submit-button {
  width: 35%;
}
@media (max-width: 767px) {
  #professional-option-check-modal .modal-close-button {
    width: 100%;
  }
  #professional-option-check-modal .modal-submit-button {
    width: 100%;
  }
  #professional-option-check-modal .modal-button {
    margin: 4px 7px;
  }
  #professional-option-check-modal .modal-buttons {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.v1.canva-modal,
.v1 .canva-modal {
  background-color: rgba(0,0,0,0.4);
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}
.v1.canva-modal-content,
.v1 .canva-modal-content {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 0;
  width: 100%;
}
.v1.canva-modal-dialog,
.v1 .canva-modal-dialog {
  margin: 0;
  padding: 2rem;
}
.v1.canva-modal-error,
.v1 .canva-modal-error {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
}
@media (max-width: 767px) {
  .v1.canva-modal-error,
  .v1 .canva-modal-error {
    padding: 1rem;
  }
}
.v1.canva-modal-error img,
.v1 .canva-modal-error img {
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
}
.v1.canva-modal-error-close-button,
.v1 .canva-modal-error-close-button {
  margin-top: 1rem;
}
.v1.canva-modal-loader,
.v1 .canva-modal-loader {
  height: 48px;
  margin: auto;
  position: absolute;
  width: 48px;
}
.v1.canva-form-modal-content,
.v1 .canva-form-modal-content {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.v1.canva-form-modal-form,
.v1 .canva-form-modal-form {
  width: 100%;
}
.v1.canva-form-modal-form p.title,
.v1 .canva-form-modal-form p.title {
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.v1.canva-form-modal-form .content,
.v1 .canva-form-modal-form .content {
  font-size: 15px;
  padding-bottom: 40px;
}
.v1.canva-form-modal-form .btns,
.v1 .canva-form-modal-form .btns {
  text-align: right;
}
.v1.canva-form-modal-form .btns .btn,
.v1 .canva-form-modal-form .btns .btn {
  margin-left: 40px;
}
.v1.canva-form-modal-form .clear,
.v1 .canva-form-modal-form .clear {
  clear: both;
}
.v1.canva-form-modal-loader,
.v1 .canva-form-modal-loader {
  display: none;
  margin-top: 30px;
}
.notification-box {
  position: relative;
  text-align: center;
}
.notification-box.active .notification-box__dropdown {
  display: block;
}
.notification-box__sign {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: inline-block;
}
@media (max-width: 767px) {
  .notification-box__sign {
    width: 22px;
    height: 22px;
  }
}
.notification-box__sign-icon {
  font-size: 25px;
  color: #4f5559;
  pointer-events: none;
}
@media (max-width: 767px) {
  .notification-box__sign-icon {
    font-size: 22px;
  }
}
.notification-box__sign_active:after {
  content: "";
  position: absolute;
  top: 1px;
  right: -2px;
  width: 16px;
  height: 16px;
  border: 2px solid #fefefe;
  background-color: #f460a3;
  border-radius: 50%;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
@media (max-width: 767px) {
  .notification-box__sign_active:after {
    top: -4px;
    width: 13px;
    height: 13px;
    border-width: 1px;
  }
}
.notification-box__dropdown {
  display: none;
  position: absolute;
  z-index: 2;
  top: 100%;
  padding-top: 30px;
}
@media (min-width: 768px) {
  .notification-box__dropdown {
    left: 50%;
    margin-left: -180px;
  }
}
@media (max-width: 767px) {
  .notification-box__dropdown {
    right: -40px;
  }
}
.notification-box__dropdown:before,
.notification-box__dropdown:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 0 14px 21px;
  border-style: solid;
}
@media (min-width: 768px) {
  .notification-box__dropdown:before,
  .notification-box__dropdown:after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@media (max-width: 767px) {
  .notification-box__dropdown:before,
  .notification-box__dropdown:after {
    right: 37px;
  }
}
.notification-box__dropdown:after {
  top: 10px;
  border-color: transparent transparent #fff;
}
.notification-box__dropdown:before {
  top: 9px;
  border-color: transparent transparent #d5d5d5;
}
.notification-box__dropdown-inner {
  width: 360px;
  max-height: 416px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 14px;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  -webkit-box-shadow: 0 24px 36px rgba(51,51,51,0.06);
  box-shadow: 0 24px 36px rgba(51,51,51,0.06);
}
@media (max-width: 767px) {
  .notification-box__dropdown-inner {
    width: 300px;
  }
}
.notification-box__item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  border-bottom: 1px solid #d5d5d5;
  padding: 20px 30px 30px;
  background-color: #fff;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
@media (max-width: 767px) {
  .notification-box__item {
    padding: 15px;
  }
}
.notification-box__item:last-child {
  border-bottom: 0 none;
}
.notification-box__item_selected {
  background-color: #f7f7f7;
}
.notification-box__item-content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  text-align: center;
}
.notification-box__item-image {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  height: 50px;
  text-align: center;
  margin-right: 25px;
  margin-top: 7px;
}
.notification-box__item-image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.notification-box__item-image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.notification-box__item-image + .notification-box__item-content {
  text-align: left;
}
.notification-box__item-title {
  font-size: 20px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .notification-box__item-title {
    letter-spacing: 0.1pt;
  }
}
@media (max-width: 767px) {
  .notification-box__item-title {
    font-size: 16px;
  }
}
.notification-box__item-text {
  margin-bottom: 15px;
  font-size: 14px;
  color: #b2b2b2;
}
@media (max-width: 767px) {
  .notification-box__item-text {
    font-size: 12px;
  }
}
.notification-box__item-link {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #ff5aa3;
  text-decoration: none;
}
@media (max-width: 767px) {
  .notification-box__item-link {
    font-size: 13px;
  }
}
.notification-box__item-link:hover {
  color: #000;
}
.v1 .option-tile {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  padding: 20px;
  text-decoration: none;
  background-color: #fefefe;
  border: 1px solid #ededee;
  transfirm: translate3d(0, 0, 0);
  -webkit-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  height: 300px;
}
.v1 .option-tile__icons-gallery {
  position: absolute;
  padding: 10px;
  bottom: -19px;
  left: 11px;
  font-size: 14px;
  font-weight: bold;
  color: #f460a3;
  text-decoration: none;
  border-radius: 20px;
  background-color: #fff;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-box-shadow: 3px 3px 5px #ccc;
  box-shadow: 3px 3px 5px #ccc;
  z-index: 1000;
}
@media (max-width: 999px) {
  .v1 .option-tile__icons-gallery {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    top: 1px;
    left: 1px;
    font-size: 9px;
    bottom: auto;
  }
}
.v1 .option-tile__icons-gallery:hover {
  color: #f460a3;
  -webkit-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  -o-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
}
.v1 .option-tile .icons-recommended {
  display: block;
  font-size: 12px;
  position: absolute;
  right: -10px;
  top: -30px;
  height: 22px;
}
.v1 .option-tile .icons-recommended .inner {
  position: relative;
  float: right;
}
.v1 .option-tile .icons-recommended .icon {
  width: 18px;
  height: 22px;
  padding: 3px;
  background-repeat: no-repeat;
  background-position: 1px center;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.v1 .option-tile .icons-recommended .icon.bg-grey {
  background-color: #c7c7c7;
}
.v1 .option-tile .icons-recommended .icon.bg-grey:before {
  border-right-color: #c7c7c7;
}
.v1 .option-tile .icons-recommended .icon.bg-pink {
  background-color: #f460a3;
}
.v1 .option-tile .icons-recommended .icon.bg-pink:before {
  border-right-color: #f460a3;
}
.v1 .option-tile .icons-recommended .icon:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right-width: 5px;
  border-right-style: solid;
  left: -5px;
  top: 0;
}
.v1 .option-tile .icons-recommended .icon.yellow-star {
  background-image: url("i/recommend-icon-star.svg");
}
.v1 .option-tile .icons-recommended .icon.icon-info {
  background-image: url("i/recommend-icon-info.svg");
}
.v1 .option-tile .icons-recommended .icon.icon-green-leaf {
  background-image: url("i/recommend-icon-leaf.svg");
}
.v1 .option-tile .icons-recommended .icon.icon-bookmark {
  background-image: url("i/recommend-icon-bookmark.svg");
}
.v1 .option-tile .icons-recommended .icon.icon-queen {
  background-image: url("i/recommend-icon-queen.svg");
}
.v1 .option-tile .icons-recommended .icon.icon-eraser {
  background-image: url("i/recommend-icon-eraser.svg");
}
.v1 .option-tile .icons-recommended .icon.icon-blue-leaf {
  background-image: url("i/recommend-icon-leaf.svg");
}
.v1 .option-tile .icons-recommended .label {
  float: left;
  background-color: #e7e7e7;
  padding: 3px 10px 3px 8px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  height: 22px;
  font-weight: bold;
}
.params-board_items-2 .v1 .option-tile {
  height: 390px;
}
@media (max-width: 767px) {
  .v1 .option-tile {
    padding: 15px;
  }
}
.v1 .option-tile:hover,
.v1 .option-tile.active {
  z-index: 2;
  background-color: #fff;
  border-color: #d5d5d5;
  -webkit-box-shadow: 0 4px 18px rgba(51,51,51,0.07);
  box-shadow: 0 4px 18px rgba(51,51,51,0.07);
}
.v1 .option-tile:hover.with-description .option-tile__image,
.v1 .option-tile.active.with-description .option-tile__image {
  height: 120px;
}
.v1 .option-tile:hover.with-description .option-tile__description,
.v1 .option-tile.active.with-description .option-tile__description {
  -webkit-transition: 0.2s opacity ease-in-out, 0.2s -webkit-transform ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out, 0.2s -moz-transform ease-in-out;
  -o-transition: 0.2s opacity ease-in-out, 0.2s -o-transform ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out, 0.2s -ms-transform ease-in-out;
  transition: 0.2s opacity ease-in-out, 0.2s transform ease-in-out;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -ms-filter: none;
  filter: none;
  position: static;
}
.params-board_items-2 .v1 .option-tile:hover.with-description .option-tile__image,
.params-board_items-2 .v1 .option-tile.active.with-description .option-tile__image {
  height: auto;
  padding-top: 0;
}
.v1 .option-tile:hover .option-tile__icons-gallery,
.v1 .option-tile.active .option-tile__icons-gallery {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  -ms-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
@media (max-width: 999px) {
  .v1 .option-tile:hover,
  .v1 .option-tile.active {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #ededee;
  }
}
.v1 .option-tile:hover .fill-type-1,
.v1 .option-tile.active .fill-type-1 {
  fill: #fef7fb;
}
.v1 .option-tile:hover .fill-type-2,
.v1 .option-tile.active .fill-type-2 {
  fill: #fe92c4;
}
.v1 .option-tile:hover .fill-type-3,
.v1 .option-tile.active .fill-type-3 {
  fill: #fbd7e7;
}
.v1 .option-tile:hover .fill-type-4,
.v1 .option-tile.active .fill-type-4 {
  fill: #fbd8e8;
}
.v1 .option-tile:hover .fill-type-m10,
.v1 .option-tile.active .fill-type-m10 {
  fill: #e8cdd9;
}
.v1 .option-tile:hover .fill-type-m20,
.v1 .option-tile.active .fill-type-m20 {
  fill: #d5bdc8;
}
.v1 .option-tile:hover .fill-type-m30,
.v1 .option-tile.active .fill-type-m30 {
  fill: #c1acb7;
}
.v1 .option-tile:hover .fill-type-m40,
.v1 .option-tile.active .fill-type-m40 {
  fill: #ae9aa5;
}
.v1 .option-tile:hover .fill-type-m50,
.v1 .option-tile.active .fill-type-m50 {
  fill: #998892;
}
.v1 .option-tile:hover .fill-type-m60,
.v1 .option-tile.active .fill-type-m60 {
  fill: #84757e;
}
.v1 .option-tile:hover .fill-type-m70,
.v1 .option-tile.active .fill-type-m70 {
  fill: #6e6169;
}
.v1 .option-tile:hover .fill-type-m80,
.v1 .option-tile.active .fill-type-m80 {
  fill: #574c52;
}
.v1 .option-tile:hover .fill-type-m90,
.v1 .option-tile.active .fill-type-m90 {
  fill: #3f343a;
}
.v1 .option-tile:hover .fill-type-m100,
.v1 .option-tile.active .fill-type-m100 {
  fill: #1e1117;
}
.v1 .option-tile:hover .fill-type-Contour,
.v1 .option-tile.active .fill-type-Contour {
  fill: #cd679a;
}
.v1 .option-tile:hover .fill-type-Txt,
.v1 .option-tile.active .fill-type-Txt {
  fill: #f9d7e5;
}
.v1 .option-tile:hover .fill-type-blue,
.v1 .option-tile.active .fill-type-blue {
  fill: #09c;
}
.v1 .option-tile:hover .fill-type-blue2,
.v1 .option-tile.active .fill-type-blue2 {
  fill: #0074a6;
}
.v1 .option-tile:hover .fill-type-yellow,
.v1 .option-tile.active .fill-type-yellow {
  fill: #ff0;
}
.v1 .option-tile:hover .fill-type-green,
.v1 .option-tile.active .fill-type-green {
  fill: #9c0;
}
.v1 .option-tile:hover .fill-type-magenta,
.v1 .option-tile.active .fill-type-magenta {
  fill: #f09;
}
.v1 .option-tile:hover .fill-type-magenta2,
.v1 .option-tile.active .fill-type-magenta2 {
  fill: #c37;
}
.v1 .option-tile:hover .fill-type-magenta3,
.v1 .option-tile.active .fill-type-magenta3 {
  fill: #c06;
}
.v1 .option-tile:hover .fill-type-none,
.v1 .option-tile.active .fill-type-none {
  fill: none;
}
.v1 .option-tile:hover .stroke-type-Contour,
.v1 .option-tile.active .stroke-type-Contour {
  stroke: #cd679a;
}
.v1 .option-tile:hover .stroke-type-Contour-3,
.v1 .option-tile.active .stroke-type-Contour-3 {
  stroke: #fbd7e7;
}
.v1 .option-tile__image {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: distribute;
  -moz-box-pack: distribute;
  -o-box-pack: distribute;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  margin-bottom: 12px;
  height: 162px;
  -webkit-transition: 0.2s -webkit-transform ease-in-out, 0.2s height ease-in-out, 0.2s width ease-in-out, 0.2s margin-top ease-in-out, 0.2s padding-top ease-in-out;
  -moz-transition: 0.2s -moz-transform ease-in-out, 0.2s height ease-in-out, 0.2s width ease-in-out, 0.2s margin-top ease-in-out, 0.2s padding-top ease-in-out;
  -o-transition: 0.2s -o-transform ease-in-out, 0.2s height ease-in-out, 0.2s width ease-in-out, 0.2s margin-top ease-in-out, 0.2s padding-top ease-in-out;
  -ms-transition: 0.2s -ms-transform ease-in-out, 0.2s height ease-in-out, 0.2s width ease-in-out, 0.2s margin-top ease-in-out, 0.2s padding-top ease-in-out;
  transition: 0.2s transform ease-in-out, 0.2s height ease-in-out, 0.2s width ease-in-out, 0.2s margin-top ease-in-out, 0.2s padding-top ease-in-out;
  position: relative;
}
.v1 .option-tile__image_md {
  height: 235px;
}
@media (max-width: 999px) {
  .v1 .option-tile__image_md {
    height: 152px;
  }
}
.v1 .option-tile__image img,
.v1 .option-tile__image svg {
  max-height: 100%;
  max-width: 100%;
  -webkit-transition: 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s box-shadow ease-in-out;
  transition: 0.2s box-shadow ease-in-out;
}
.v1 .option-tile__image [class^="fill-type-"],
.v1 .option-tile__image [class*=" fill-type-"] {
  -webkit-transition: 0.2s fill ease-in-out;
  -moz-transition: 0.2s fill ease-in-out;
  -o-transition: 0.2s fill ease-in-out;
  -ms-transition: 0.2s fill ease-in-out;
  transition: 0.2s fill ease-in-out;
}
.params-board_items-2 .v1 .option-tile__image {
  height: 275px;
  padding-top: 60px;
}
.v1 .option-tile__body {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: distribute;
  -moz-box-pack: distribute;
  -o-box-pack: distribute;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.v1 .option-tile__title {
  display: block;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 999px) {
  .v1 .option-tile__title {
    font-size: 15px;
  }
}
.v1 .option-tile__description {
  color: #888b8d;
  font-size: 14px;
  text-align: center;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  -o-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px);
}
.v1 .option-tile__description .option-tile.with-description .option-tile:hover .v1 .option-tile__description .option-tile.with-description {
  -webkit-transition: 0.2s opacity ease-in-out, 0.2s -webkit-transform ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out, 0.2s -moz-transform ease-in-out;
  -o-transition: 0.2s opacity ease-in-out, 0.2s -o-transform ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out, 0.2s -ms-transform ease-in-out;
  transition: 0.2s opacity ease-in-out, 0.2s transform ease-in-out;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -ms-filter: none;
  filter: none;
  position: static;
}
.v1 .option-tile__button {
  display: none;
  margin: -9px 0;
  position: absolute;
  bottom: -5px;
  right: 0;
}
.v1 .option-tile_simple {
  padding: 30px 18px;
  height: 225px;
}
@media (max-width: 767px) {
  .v1 .option-tile_simple {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
.v1 .option-tile_simple .option-tile__image {
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: 104px;
}
@media (max-width: 767px) {
  .v1 .option-tile_simple .option-tile__image {
    height: auto;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .v1 .option-tile_simple .option-tile__image svg {
    height: auto;
    margin-left: 5px;
    margin-right: 5px;
  }
}
.v1 .option-tile_simple .option-tile__body {
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .v1 .option-tile_simple .option-tile__body {
    padding-left: 5px;
  }
}
.v1 .option-tile_md {
  padding-top: 38px;
  padding-bottom: 30px;
}
.v1 .option-tile_lg {
  padding: 45px 30px;
}
@media (max-width: 999px) {
  .v1 .option-tile_lg {
    padding: 20px 30px 15px;
  }
}
.v1 .option-tile_check .option-tile__image {
  margin-bottom: 20px;
}
@media (max-width: 999px) {
  .v1 .option-tile_check .option-tile__image {
    margin-bottom: 5px;
  }
}
.v1 .option-tile_check:hover .option-tile__button,
.v1 .option-tile_check.active .option-tile__button {
  display: inline-block;
}
@media (max-width: 999px) {
  .v1 .option-tile_check:hover .option-tile__button,
  .v1 .option-tile_check.active .option-tile__button {
    display: none;
  }
}
.v1 .order-card {
  padding: 30px 15px;
  background-color: #fff;
  border: 1px solid #ededee;
  -webkit-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  zoom: 1;
}
.v1 .order-card:before,
.v1 .order-card:after {
  content: "";
  display: table;
}
.v1 .order-card:after {
  clear: both;
}
@media (max-width: 767px) {
  .v1 .order-card {
    padding: 20px 15px 20px;
    border-left: 0 none;
    border-right: 0 none;
    border-bottom: 0 none;
  }
}
.v1 .order-card:hover {
  -webkit-box-shadow: 0 6px 27px rgba(51,51,51,0.07);
  box-shadow: 0 6px 27px rgba(51,51,51,0.07);
  border-color: #d5d5d5;
}
.v1 .order-card:hover .order-card__button-wrap {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
}
.v1 .order-card_static {
  border-color: #d5d5d5;
}
.v1 .order-card_static .order-card__button-wrap {
  opacity: 1 !important;
  -ms-filter: none !important;
  filter: none !important;
  visibility: visible !important;
}
.v1 .order-card_lg {
  padding: 30px 55px;
}
.v1 .order-card_md {
  padding: 30px 25px;
}
.v1 .order-card__item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .order-card__title {
  -webkit-flex-basis: 60%;
  flex-basis: 60%;
  padding-top: 7px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
}
@media (max-width: 767px) {
  .v1 .order-card__title {
    font-size: 16px;
  }
}
.v1 .order-card__price {
  -webkit-flex-basis: 40%;
  flex-basis: 40%;
  font-size: 30px;
  line-height: 0.5;
  font-weight: bold;
  text-align: right;
}
.v1 .order-card__price .small {
  font-size: 12px;
}
@media (max-width: 767px) {
  .v1 .order-card__price {
    margin-top: 5px;
    margin-right: 5px;
    font-size: 20px;
  }
}
.v1 .order-card__price .price {
  margin-bottom: 15px;
}
.v1 .order-card__price .original-price {
  text-decoration: line-through;
  font-size: 20px;
  font-weight: normal;
  color: #bfbfbf;
}
.v1 .order-card__delivery {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .v1 .order-card__delivery {
    float: left;
    margin-bottom: 5px;
    width: 83%;
  }
}
.v1 .order-card__delivery-label {
  font-size: 16px;
  color: #888b8d;
}
@media (max-width: 767px) {
  .v1 .order-card__delivery-label {
    font-size: 14px;
  }
}
.v1 .order-card__delivery-value {
  font-size: 17px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .v1 .order-card__delivery-value {
    font-size: 16px;
  }
}
.v1 .order-card__button-wrap {
  text-align: center;
}
.no-touchevents .v1 .order-card__button-wrap {
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
@media (max-width: 999px) {
  .no-touchevents .v1 .order-card__button-wrap {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    visibility: visible;
  }
}
.v1 .order-card__button {
  display: block;
  width: 100%;
}
@media (max-width: 767px) {
  .v1 .order-card__button {
    float: right;
    padding-top: 14px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .v1 .order-card__button .btn-label {
    display: none;
  }
}
@media (max-width: 767px) {
  .v1 .order-card__button-wrap {
    float: left;
    width: 17%;
  }
}
.v1 .order-panel {
  background-color: #fff;
  padding: 15px 0;
}
@media (max-width: 767px) {
  .v1 .order-panel {
    padding: 20px 0;
  }
}
@media (min-width: 1000px) {
  .v1 .order-panel__wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    margin: 0 -10px;
  }
}
@media (min-width: 1000px) {
  .v1 .order-panel__col {
    padding: 0 10px;
  }
}
@media (min-width: 1240px) {
  .v1 .order-panel__col {
    width: 50%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .order-panel__col:first-child {
    width: 35%;
  }
  .v1 .order-panel__col:last-child {
    width: 65%;
  }
}
@media (max-width: 767px) {
  .v1 .order-panel__col.tos {
    font-size: 14px;
  }
}
@media (min-width: 1000px) {
  .v1 .order-panel__controls {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    margin: 0 -10px;
  }
}
@media (min-width: 1240px) {
  .v1 .order-panel__controls {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -o-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .order-panel__controls {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -moz-box-align: end;
    -o-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
}
@media (min-width: 1000px) {
  .v1 .order-panel__controls-col {
    width: 50%;
    padding: 0 10px;
  }
}
@media (min-width: 1000px) {
  .v1 .order-panel__total {
    padding-right: 20px;
    text-align: right;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .order-panel__total {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .order-panel__total {
    margin-bottom: 20px;
    text-align: right;
  }
}
@media (max-width: 767px) {
  .v1 .order-panel__total {
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 10px;
  }
}
.v1 .order-panel__total-title {
  font-size: 30px;
  font-weight: 500;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .v1 .order-panel__total-title {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 50%;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    font-size: 24px;
  }
}
.v1 .order-panel__total-label {
  font-size: 17px;
  color: #888b8d;
}
@media (max-width: 767px) {
  .v1 .order-panel__total-label {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 50%;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    font-size: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .order-panel__buttons {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-direction: reverse;
    -moz-box-direction: reverse;
    -o-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -o-box-orient: horizontal;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    margin: 0 -10px;
  }
}
@media (min-width: 1000px), (max-width: 767px) {
  .v1 .order-panel__button {
    display: block;
    width: 100%;
  }
  .v1 .order-panel__button:not(:last-child) {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .order-panel__button {
    width: 50%;
    margin: 0 10px;
  }
}
@media (min-width: 768px) {
  .v1 .order-report {
    margin: 30px 0;
  }
}
@media (min-width: 1000px) {
  .v1 .order-report {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (min-width: 1000px) {
  .v1 .order-report__content {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0;
    flex: 1 0 0;
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .v1 .order-report__content {
    padding-right: 0;
    margin: 0 -15px 30px -15px;
    padding: 15px;
    background: #fff;
  }
}
@media (min-width: 1000px) {
  .v1 .order-report__aside {
    -webkit-flex-basis: 400px;
    flex-basis: 400px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .order-report__aside {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .v1 .order-report__aside {
    margin: 0 -15px;
  }
}
.v1 .order-report__title {
  margin: 0 0 10px v;
  font-size: 36px;
  font-weight: 500;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .order-report__title {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .v1 .order-report__title {
    font-size: 18px;
  }
}
.v1 .order-report__text {
  color: #747474;
}
@media (min-width: 1000px) {
  .v1 .order-report__text {
    margin-bottom: 65px;
    font-size: 20px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .order-report__text {
    margin-bottom: 25px;
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .v1 .order-report__text {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.3;
  }
}
@media (max-width: 767px) {
  .v1 .order-report__buttons {
    text-align: center;
  }
}
@media (max-width: 999px) {
  .v1 .order-report__button {
    font-size: 15px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .v1 .order-report__button {
    display: block;
  }
}
.v1 .order-report__button:first-child {
  margin-right: 30px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .order-report__button:first-child {
    margin-bottom: 30px;
    padding: 18px 38px;
  }
}
@media (max-width: 999px) {
  .v1 .order-report__button:first-child {
    padding: 18px 35px;
  }
}
@media (max-width: 767px) {
  .v1 .order-report__button:first-child {
    margin-right: 0;
    padding: 14px 35px;
  }
}
.v1 .order-summary {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .v1 .order-summary {
    border: 1px solid #e8e8e8;
    background-color: #fff;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary {
    margin: 0 0 30px;
  }
}
.v1 .order-summary__tr {
  border-bottom: 1px solid #e8e8e8;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media (max-width: 767px) {
  .v1 .order-summary__tr {
    border: 1px solid #e8e8e8;
    background-color: #fff;
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .v1 .order-summary__tr:last-child {
    border-bottom: 0;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary__tr:last-child {
    margin-bottom: 0;
  }
}
.v1 .order-summary__tr_header {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 767px) {
  .v1 .order-summary__tr_header {
    display: none;
  }
}
.v1 .order-summary__th,
.v1 .order-summary__td {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
}
@media (min-width: 1000px) {
  .v1 .order-summary__th.product-info,
  .v1 .order-summary__td.product-info,
  .v1 .order-summary__th.package-count,
  .v1 .order-summary__td.package-count {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 58%;
    -ms-flex: 1 1 58%;
    flex: 1 1 58%;
  }
}
@media (min-width: 1000px) {
  .v1 .order-summary__th.address,
  .v1 .order-summary__td.address,
  .v1 .order-summary__th.prices,
  .v1 .order-summary__td.prices,
  .v1 .order-summary__th.dates,
  .v1 .order-summary__td.dates,
  .v1 .order-summary__th.shipment-prices,
  .v1 .order-summary__td.shipment-prices {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 1 21%;
    -ms-flex: 0 1 21%;
    flex: 0 1 21%;
  }
}
.v1 .order-summary__th.package-count,
.v1 .order-summary__td.package-count {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .order-summary__th.package-count .order-summary__item-title,
.v1 .order-summary__td.package-count .order-summary__item-title {
  font-weight: bold;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  text-align: right;
}
@media (max-width: 999px) {
  .v1 .order-summary__th.package-count,
  .v1 .order-summary__td.package-count {
    display: none;
  }
}
@media (max-width: 999px) {
  .v1 .order-summary__th.product-info,
  .v1 .order-summary__td.product-info {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -o-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
}
@media (max-width: 999px) {
  .v1 .order-summary__th.address,
  .v1 .order-summary__td.address {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -o-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
}
@media (max-width: 999px) {
  .v1 .order-summary__th.dates,
  .v1 .order-summary__td.dates {
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -o-box-ordinal-group: 3;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    border-bottom: 0;
    border-right: 1px solid #e8e8e8;
    font-size: 12px;
  }
  .v1 .order-summary__th.dates .mobile-package-count,
  .v1 .order-summary__td.dates .mobile-package-count {
    font-size: 16px;
  }
}
@media (max-width: 999px) {
  .v1 .order-summary__th.prices,
  .v1 .order-summary__td.prices {
    -webkit-box-ordinal-group: 4;
    -moz-box-ordinal-group: 4;
    -o-box-ordinal-group: 4;
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    border-bottom: 0;
  }
}
@media (max-width: 999px) {
  .v1 .order-summary__th.shipment-prices,
  .v1 .order-summary__td.shipment-prices {
    display: none;
  }
}
@media (min-width: 768px) {
  .v1 .order-summary__th,
  .v1 .order-summary__td {
    padding: 25px 20px;
  }
}
@media (min-width: 768px) {
  .v1 .order-summary__th_col-1,
  .v1 .order-summary__td_col-1 {
    -webkit-flex-basis: 58%;
    flex-basis: 58%;
    min-width: 0;
  }
}
@media (min-width: 768px) {
  .v1 .order-summary__th_col-2,
  .v1 .order-summary__td_col-2,
  .v1 .order-summary__th_col-3,
  .v1 .order-summary__td_col-3 {
    -webkit-flex-basis: 21%;
    flex-basis: 21%;
    text-align: center;
    min-width: 0;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary__th_col-2,
  .v1 .order-summary__td_col-2,
  .v1 .order-summary__th_col-3,
  .v1 .order-summary__td_col-3 {
    -webkit-flex-basis: 48%;
    flex-basis: 48%;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary__th_col-2,
  .v1 .order-summary__td_col-2 {
    border-right: 1px solid #e8e8e8;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary__th,
  .v1 .order-summary__td {
    border-bottom: 1px solid #e8e8e8;
    padding: 15px;
  }
  .v1 .order-summary__th:last-child,
  .v1 .order-summary__td:last-child {
    border-bottom: 0;
  }
}
@media (min-width: 1000px) {
  .v1 .order-summary__th.product-info,
  .v1 .order-summary__td.product-info,
  .v1 .order-summary__th.address,
  .v1 .order-summary__td.address,
  .v1 .order-summary__th.prices,
  .v1 .order-summary__td.prices {
    border-bottom: 1px solid #e8e8e8;
  }
}
.v1 .order-summary__th {
  font-size: 20px;
  font-weight: 500;
  color: #555;
  letter-spacing: 0.2pt;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .order-summary__th {
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .v1 .order-summary__td {
    border-right: 1px solid #efefef;
  }
  .v1 .order-summary__td:last-child {
    border-right: 0;
  }
}
@media (min-width: 768px) {
  .v1 .order-summary__td_col-2,
  .v1 .order-summary__td_col-3 {
    padding-top: 48px;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary__td_col-2,
  .v1 .order-summary__td_col-3 {
    font-size: 16px;
  }
}
@media (max-width: 999px) {
  .v1 .order-summary__td {
    border-bottom: 1px solid #efefef;
  }
}
.v1 .order-summary__item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  font-size: 17px;
}
@media (max-width: 767px) {
  .v1 .order-summary__item {
    margin-bottom: 10px;
  }
}
.v1 .order-summary__item-aside {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 40%;
  -ms-flex: 0 1 40%;
  flex: 0 1 40%;
  padding-right: 30px;
  min-width: 0;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .order-summary__item-aside {
    padding-right: 20px;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary__item-aside {
    padding-right: 15px;
  }
}
.v1 .order-summary__item-title {
  margin-bottom: 5px;
  font-weight: bold;
  letter-spacing: 0.85pt;
}
@media (max-width: 767px) {
  .v1 .order-summary__item-title {
    font-size: 16px;
  }
}
.v1 .order-summary__item-text {
  color: #6c6c6c;
}
@media (max-width: 767px) {
  .v1 .order-summary__item-text {
    font-size: 14px;
  }
}
.v1 .order-summary__item-text ul {
  padding-left: 0px;
  list-style-type: none;
  font-size: 10px;
}
.v1 .order-summary__item-image {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  text-align: center;
}
.v1 .order-summary__item-image-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .order-summary__item-image-container .order-summary__item-image {
  padding-right: 10px;
}
.v1 .order-summary__item-image-container .order-summary__item-image:last-child {
  padding-right: 0;
}
@media (max-width: 767px) {
  .v1 .order-summary__item-image-container .order-summary__item-image:last-child {
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary__item-image-container .order-summary__item-image {
    padding-right: 0;
    padding-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary__item-image-container {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
  }
}
.v1 .order-summary__item-image:after {
  content: "";
  display: block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.v1 .order-summary__item-image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.v1 .order-summary__item-image__legend {
  text-align: center;
  font-size: 75%;
  color: #6a6a6a;
}
.v1 .order-summary__item-image-xl {
  text-align: center;
  width: 300px;
}
.v1 .order-summary__item-image-xl:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.v1 .order-summary__item-image-xl >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.v1 .order-summary__item-image > img {
  vertical-align: middle;
}
.v1 .order-summary__item-image .main-product-image picture,
.v1 .order-summary__item-image .main-product-image img {
  max-width: 100%;
}
.v1 .order-summary__item-body {
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 60%;
  -ms-flex: 0 1 60%;
  flex: 0 1 60%;
  min-width: 0;
}
.v1 .order-summary__item-error-message {
  position: relative;
  padding: 0 20px 0 30px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #282828;
}
@media (min-width: 1240px) {
  .v1 .order-summary__item-error-message {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .v1 .order-summary__item-error-message {
    -webkit-flex-basis: 44%;
    flex-basis: 44%;
  }
}
.v1 .order-summary__item-error-message:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 23px;
  height: 20px;
  background: url("i/warning-sign-pink-fill.svg") no-repeat 0 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}
.v1 .order-summary__delivery {
  border-radius: 8px;
  border: 1px solid #d5d5d5;
  background-color: #fcfcfc;
  -webkit-box-shadow: 0 12px 30px rgba(51,51,51,0.04);
  box-shadow: 0 12px 30px rgba(51,51,51,0.04);
}
@media (min-width: 1000px) {
  .v1 .order-summary__delivery {
    margin-top: 35px;
    padding: 20px 10px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .order-summary__delivery {
    margin-top: 20px;
    padding: 15px 10px;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary__delivery {
    margin: 10px 0;
    padding: 10px;
  }
  .v1 .order-summary__delivery .order-summary__item {
    font-size: 14px;
  }
}
.v1 .order-summary__delivery-message {
  position: relative;
  padding: 0 20px 0 30px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #282828;
}
@media (min-width: 1240px) {
  .v1 .order-summary__delivery-message {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .v1 .order-summary__delivery-message {
    -webkit-flex-basis: 44%;
    flex-basis: 44%;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary__delivery-message {
    font-size: 14px;
  }
}
.v1 .order-summary__delivery-message:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 23px;
  height: 20px;
  background: url("i/warning-sign.svg") no-repeat 0 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}
.v1 .order-summary__delivery-text {
  padding: 0 0 0 30px !important;
  font-size: 16px;
  color: #545454;
}
@media (max-width: 767px) {
  .v1 .order-summary__delivery-text {
    padding-top: 3px !important;
    font-size: 15px;
  }
}
.v1 .order-summary__delivery-checkbox {
  display: inline-block;
  vertical-align: top;
}
@media (min-width: 768px) {
  .v1 .order-summary__delivery-option {
    -webkit-flex-basis: 56%;
    flex-basis: 56%;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary__delivery-option {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
.v1 .order-summary__info {
  display: inline-block;
  vertical-align: top;
  margin-left: 7px;
  font-size: 21px;
  color: #03a9f4;
  cursor: default;
}
.v1 .order-summary__info-label {
  margin-right: 5px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .v1 .order-summary__info-label {
    display: none;
  }
}
.v1 .order-summary__date p.date-title {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
  color: #888b8d;
}
.v1 .order-summary p.date-title {
  font-size: 12px;
  font-weight: bold;
  color: #888b8d;
  margin: 0;
}
.v1 .order-summary .address-card__edit {
  font-size: 12px;
}
.v1 .order-summary .address-card__edit a {
  color: #f460a3;
}
.v1 .order-summary .address-card__edit .address-card__address {
  font-size: 12px;
}
.v1 .order-summary.totals .order-summary__td_col-1 {
  font-weight: bold;
  text-align: right;
}
@media (max-width: 999px) {
  .v1 .order-summary.totals .order-summary__td_col-1 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
}
.v1 .order-summary.totals .order-summary__td_col-2 {
  padding-top: 25px;
  text-align: left;
}
@media (max-width: 999px) {
  .v1 .order-summary.totals .order-summary__td_col-2 {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
}
@media (max-width: 767px) {
  .v1 .order-summary.totals .order-summary__td_col-2 {
    padding-top: 15px;
  }
}
@media (max-width: 767px) {
  .v1 .order-tracking {
    margin-top: 15px;
  }
}
.v1 .order-tracking__table {
  border-width: 1px 0 0;
}
.v1 .order-tracking__table .btn {
  white-space: nowrap;
}
@media (max-width: 767px) {
  .v1 .order-tracking__table {
    display: none;
  }
}
.v1 .order-tracking__table th {
  font-size: 17px;
  font-weight: 500;
  background-color: #fcfcfc;
}
@media (min-width: 768px) and (max-width: 1239px) {
  .v1 .order-tracking__table th {
    font-size: 14px;
  }
}
.v1 .order-tracking__table th,
.v1 .order-tracking__table td {
  padding: 10px;
}
.v1 .order-tracking__table td {
  font-size: 16px;
  color: #333;
  text-align: center;
  background-color: #fff !important;
  border-bottom: 1px solid #e8e8e8;
}
@media (min-width: 768px) and (max-width: 1239px) {
  .v1 .order-tracking__table td {
    font-size: 13px;
  }
}
.v1 .order-tracking__table tr:last-child td {
  border-bottom: 0;
}
.v1 .order-tracking__product {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: left;
}
.v1 .order-tracking__product-aside {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 70px;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
}
.v1 .order-tracking__product-image {
  width: 54px;
  height: 54px;
  text-align: center;
}
.v1 .order-tracking__product-image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.v1 .order-tracking__product-image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.v1 .order-tracking__product-image.float-left {
  float: left;
  padding-right: 10px;
}
.v1 .order-tracking__product-body {
  font-size: 17px;
}
@media (min-width: 768px) and (max-width: 1239px) {
  .v1 .order-tracking__product-body {
    font-size: 13px;
  }
}
.v1 .order-tracking__product-title {
  padding: 0 15px;
}
.v1 .order-tracking__product-title__link {
  text-decoration: none;
}
.v1 .order-tracking__product-title__link:hover {
  color: #f460a3;
}
.v1 .order-tracking__product-id {
  margin: 10px 0;
  font-weight: bold;
}
.v1 .order-tracking__column {
  width: 11%;
}
.v1 .order-tracking__column_product {
  width: 30%;
}
.v1 .order-tracking__column_status {
  width: 15%;
}
.v1 .order-tracking__column_tax,
.v1 .order-tracking__column_status,
.v1 .order-tracking__column_anomaly {
  text-align: center !important;
}
.v1 .order-tracking__status {
  font-weight: 500;
  color: #f460a3;
}
.v1 .order-tracking__status-icon {
  margin-left: 10px;
  font-size: 11px;
  color: #888b8d;
}
.v1 .order-tracking__status_check {
  font-weight: normal;
  color: #333;
}
.v1 .order-tracking__anomaly {
  font-weight: bold;
  color: #d0d0d0;
}
.v1 .order-tracking__anomaly_active {
  color: #f460a3;
}
.v1 .order-tracking__cards {
  display: none;
  margin-left: 15px;
  margin-right: 15px;
}
@media (max-width: 767px) {
  .v1 .order-tracking__cards {
    display: block;
  }
}
.v1 .order-tracking__cards .order-tracking__product {
  padding-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
}
.v1 .order-tracking__cards .order-tracking__product-image {
  height: 40px;
  width: 40px;
}
.v1 .order-tracking__cards .order-tracking__product-aside {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 55px;
  -ms-flex: 0 0 55px;
  flex: 0 0 55px;
}
.v1 .order-tracking__cards .order-tracking__product-title {
  font-size: 14px;
}
.v1 .order-tracking__cards .order-tracking__product-status {
  font-weight: 500;
  color: #f460a3;
  font-size: 14px;
}
.v1 .order-tracking__cards .order-tracking__description-field {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}
.v1 .order-tracking__cards .order-tracking__description-field:last-child {
  margin-bottom: 0;
}
.v1 .order-tracking__cards .order-tracking__order-label,
.v1 .order-tracking__cards .order-tracking__delivery-label,
.v1 .order-tracking__cards .order-tracking__price-label,
.v1 .order-tracking__cards .order-tracking__event-label,
.v1 .order-tracking__cards .order-tracking__order-date,
.v1 .order-tracking__cards .order-tracking__delivery-date,
.v1 .order-tracking__cards .order-tracking__price-date,
.v1 .order-tracking__cards .order-tracking__event-date,
.v1 .order-tracking__cards .order-tracking__order-value,
.v1 .order-tracking__cards .order-tracking__delivery-value,
.v1 .order-tracking__cards .order-tracking__price-value,
.v1 .order-tracking__cards .order-tracking__event-value {
  font-size: 14px;
}
.v1 .order-tracking__cards .order-tracking__order-label,
.v1 .order-tracking__cards .order-tracking__delivery-label,
.v1 .order-tracking__cards .order-tracking__price-label,
.v1 .order-tracking__cards .order-tracking__event-label {
  font-weight: bold;
}
.v1 .order-tracking__cards .order-tracking__event-label,
.v1 .order-tracking__cards .order-tracking__event-value {
  text-align: right;
}
.v1 .order-tracking__cards .order-tracking__event-value {
  color: #d0d0d0;
}
.v1 .order-tracking__cards .order-tracking__event-value_active {
  color: #f460a3;
}
.v1 .order-tracking__cards .order-tracking__status_check {
  font-size: 14px;
}
.v1 .order-tracking__card {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 10px;
}
.v1 .order-tracking__card:last-child {
  margin-bottom: 0;
}
.v1 .order-tracking__options-list {
  margin: 10px 0;
  padding: 0;
  list-style: none;
}
.v1 .order-tracking__options-list-item {
  display: inline;
  border-right: solid 1px #d6d5d5;
  padding-right: 6px;
  font-size: 14px;
}
.v1 .order-tracking__options-list-item:last-child {
  border: none;
  padding-right: 0;
}
.v1 .orders-tiles {
  margin: 0 -20px;
}
@media (min-width: 1000px) {
  .v1 .orders-tiles {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .product-config .v1 .orders-tiles {
    margin: 0 -15px;
  }
}
.v1 .orders-tiles_items-3 {
  margin: 0 -10px;
}
.v1 .orders-tiles_items-3 .orders-tiles__item {
  padding: 0 10px;
}
.only-one .v1 .orders-tiles_items-3 .orders-tiles__item {
  width: auto;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
@media (min-width: 1240px) {
  .v1 .orders-tiles_items-3 .orders-tiles__item {
    width: 33.333%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .orders-tiles_items-3 .orders-tiles__item {
    width: 50%;
  }
}
.v1 .orders-tiles__item {
  padding: 0 20px;
  margin-bottom: 40px;
}
@media (min-width: 1000px) {
  .v1 .orders-tiles__item {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .v1 .orders-tiles__item {
    margin-bottom: 0;
  }
  .product-config .v1 .orders-tiles__item {
    padding: 0;
  }
}
.v1 .only-one .orders-tiles_items-3 .orders-tiles__item {
  width: auto;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.v1 .only-one .product-config {
  margin: 0 -15px;
}
.page {
  padding-bottom: 50px;
}
.page.page-no-padding-top {
  padding-top: 0;
}
@media (max-width: 767px) {
  .page {
    padding-bottom: 30px;
  }
}
.page__header {
  background-color: #fff;
}
@media (min-width: 1000px) {
  .page__header {
    padding: 20px 0;
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page__header {
    padding: 20px 0;
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .page__header {
    padding: 0;
    background-color: #333;
    margin-bottom: 20px;
  }
}
.page__header_no-offset {
  margin-bottom: 0 !important;
}
@media (max-width: 767px) {
  .page__header_mobile-offset {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .page__header-inner {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (min-width: 768px) {
  .page__header-panel {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: top;
    -moz-box-align: top;
    -o-box-align: top;
    -ms-flex-align: top;
    -webkit-align-items: top;
    align-items: top;
  }
}
@media (max-width: 767px) {
  .page__header-panel {
    position: relative;
    -webkit-box-shadow: 0 2px 6px 0 rgba(115,115,115,0.33);
    box-shadow: 0 2px 6px 0 rgba(115,115,115,0.33);
  }
}
.page__header-controls {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  margin-left: 30px;
}
@media (max-width: 767px) {
  .page__header-controls {
    display: none;
  }
}
@media (min-width: 1000px) {
  .page__header-controls .radio-group {
    margin: -5px 0 -7px;
  }
}
.page__header-breadcrumbs {
  margin-top: 15px;
}
@media (max-width: 767px) {
  .page__header-breadcrumbs {
    margin-top: 0;
    padding: 0;
    background-color: #f7f7f7;
  }
}
@media (min-width: 1000px) {
  .page__header.has-header-menu-stikcy {
    margin-top: 60px;
  }
}
.page__body {
  position: relative;
}
.page__title {
  margin: 0;
  font-size: 30px;
  line-height: 1.1;
  font-weight: 500;
}
@media (min-width: 768px) and (max-width: 999px) {
  .page__title {
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .page__title {
    padding: 15px;
    font-size: 15px;
    text-align: center;
    color: #fff;
  }
}
.page__wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.page__wrap_simple .page__content {
  -webkit-align-self: flex-start;
  align-self: flex-start;
  -ms-flex-item-align: start;
}
.page__wrap_offset-bottom {
  margin-bottom: 60px;
  clear: left;
}
@media (max-width: 767px) {
  .page__wrap_offset-bottom {
    margin-bottom: 20px;
  }
}
.page__content_full {
  width: 100%;
}
.page__content_no-bg {
  background-color: transparent !important;
}
.page__content-header {
  margin: -50px -50px 40px;
  padding: 25px 50px;
  border-bottom: 1px solid #e8e8e8;
}
@media (max-width: 767px) {
  .page__content-header {
    margin: -30px -15px 20px;
    padding: 15px;
    border-bottom: 0 none;
    background-color: #f7f7f7;
  }
}
.page__content-footer {
  padding: 25px 50px;
  margin: 50px -50px -50px;
  background-color: #fcfcfc;
}
@media (max-width: 767px) {
  .page__content-footer {
    margin: 0 -15px -15px;
    padding: 25px 15px 25px;
  }
}
.page__back-link {
  position: relative;
  display: inline-block;
  padding-left: 27px;
  font-size: 17px;
  text-decoration: none;
}
@media (max-width: 767px) {
  .page__back-link {
    padding-left: 22px;
    font-size: 14px;
  }
}
.page__back-link:hover {
  color: #f460a3;
}
.page__back-link:hover:before {
  color: #f460a3;
  -webkit-transform: translateX(-5px);
  -moz-transform: translateX(-5px);
  -o-transform: translateX(-5px);
  -ms-transform: translateX(-5px);
  transform: translateX(-5px);
}
.page__back-link:before {
  content: "\e900";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #888b8d;
  -webkit-transition: 0.2s -webkit-transform ease-in-out, 0.2s color ease-in-out;
  -moz-transition: 0.2s -moz-transform ease-in-out, 0.2s color ease-in-out;
  -o-transition: 0.2s -o-transform ease-in-out, 0.2s color ease-in-out;
  -ms-transition: 0.2s -ms-transform ease-in-out, 0.2s color ease-in-out;
  transition: 0.2s transform ease-in-out, 0.2s color ease-in-out;
}
@media (max-width: 767px) {
  .page__back-link:before {
    font-size: 17px;
  }
}
.page_no-offset {
  padding-bottom: 0 !important;
}
@media (min-width: 1000px) {
  .page_with-aside-sm .page__aside {
    width: 30%;
    padding-right: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page_with-aside-sm .page__aside {
    width: 40%;
    padding-right: 20px;
  }
}
@media (max-width: 999px) {
  .page_with-aside-sm .page__aside {
    margin-bottom: 20px;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .page_with-aside-sm .page__aside {
    margin-bottom: 20px;
  }
}
.page_with-aside-sm .page__content {
  background-color: #fefefe;
}
@media (min-width: 1000px) {
  .page_with-aside-sm .page__content {
    width: 70%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page_with-aside-sm .page__content {
    width: 60%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page_with-aside-sm .page__header {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .page_with-aside-sm .page__wrap {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (max-width: 767px) {
  .page_with-aside-sm .page__wrap_no-offset {
    margin-left: 0;
    margin-right: 0;
  }
}
.page_with-aside {
  padding: 60px 0 0;
}
.page_with-aside .page__title {
  margin: 0 0 55px;
  font-size: 30px;
  line-height: 1.333;
  font-weight: bold;
}
.page_with-aside .page__wrap {
  margin-bottom: 50px;
}
.page_with-aside .page__content {
  width: 60%;
}
.page_with-aside .page__aside {
  width: 40%;
}
@media (max-width: 767px) {
  .page_with-aside-right {
    padding-top: 0;
    background-color: #f8f8f8;
  }
}
@media (max-width: 767px) {
  .page_with-aside-right .page__wrap {
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.page_with-aside-right .page__content {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -o-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
}
@media (max-width: 767px) {
  .page_with-aside-right .page__content {
    width: auto;
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -o-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
}
.page_with-aside-right .page__aside {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -o-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
  padding-top: 100px;
  padding-left: 100px;
}
@media (min-width: 768px) and (max-width: 1239px) {
  .page_with-aside-right .page__aside {
    padding-left: 50px;
  }
}
@media (max-width: 767px) {
  .page_with-aside-right .page__aside {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -o-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    width: 100vw;
    margin: 0 -15px 15px;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .page_with-aside-right .page__title {
    margin-bottom: 15px;
    padding: 0;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: #434343;
  }
}
.page_with-menu .page__header {
  margin-bottom: 0;
}
@media (max-width: 999px), (min-width: 768px) and (max-width: 999px), (min-width: 1000px) {
  .page_with-menu .page__wrap {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.page_with-menu .page__content {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -o-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}
@media (min-width: 1000px) {
  .page_with-menu .page__content {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .page_with-menu .page__content {
    margin-top: 20px;
  }
}
.page_with-menu .page__aside {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -o-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
}
@media (min-width: 768px) and (max-width: 999px), (min-width: 1000px) {
  .page_with-menu .page__aside {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
}
@media (max-width: 767px) {
  .page_with-menu .page__aside {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.page_print-gallery .page__content {
  width: 59%;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .page_print-gallery .page__content {
    width: 70%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page_print-gallery .page__content {
    width: 65%;
  }
}
@media (max-width: 767px) {
  .page_print-gallery .page__content {
    width: 100%;
    margin-bottom: 15px;
  }
}
.page_print-gallery .page__aside {
  width: 41%;
  padding-left: 75px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .page_print-gallery .page__aside {
    width: 30%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page_print-gallery .page__aside {
    width: 35%;
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .page_print-gallery .page__aside {
    width: 100%;
    padding-left: 0;
  }
}
@media (max-width: 999px) {
  .page_print-gallery .page__wrap {
    margin-top: 20px;
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .page_print-gallery .page__wrap {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (max-width: 999px) {
  .page_print-gallery .page-separator {
    display: none;
  }
}
.page_auth {
  padding-top: 70px;
}
@media (max-width: 767px) {
  .page_auth {
    padding-top: 30px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page_product .page__header {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .page_product .page__wrap {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (min-width: 1000px) {
  .page_product .page__aside {
    padding-right: 35px;
  }
}
@media (min-width: 1240px) {
  .page_product .page__aside {
    width: 45%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .page_product .page__aside {
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page_product .page__aside {
    width: 30%;
    padding-right: 30px;
  }
}
.page_product .page__content {
  -webkit-align-self: flex-start;
  align-self: flex-start;
  -ms-flex-item-align: start;
}
@media (min-width: 1240px) {
  .page_product .page__content {
    width: 55%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .page_product .page__content {
    width: 60%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page_product .page__content {
    width: 70%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page_cart .page__header {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .page_factures .page__content {
    margin-top: 20px;
  }
}
.page-amount {
  width: 100%;
}
@media (min-width: 1000px) {
  .page-amount {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page-amount {
    width: 80%;
    margin: 0 auto;
  }
}
.page-amount__numbers {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
@media (max-width: 999px) {
  .page-amount__numbers {
    display: none;
  }
}
.page-amount__select {
  display: none;
}
@media (max-width: 999px) {
  .page-amount__select {
    width: 100%;
    margin-bottom: 15px;
    display: block;
  }
}
.page-amount__arrow {
  position: relative;
  width: 20px;
  height: 45px;
  cursor: pointer;
}
.page-amount__arrow:before {
  content: "";
  position: absolute;
  top: 15px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  -webkit-transition: 0.2s border-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out;
  transition: 0.2s border-color ease-in-out;
}
.page-amount__arrow_right:before {
  left: 0;
  border-left: 8px solid #c9c9c9;
  margin-left: 5px;
}
.page-amount__arrow_right:hover:before {
  border-left-color: #434343;
}
.page-amount__arrow_left:before {
  right: 0;
  border-right: 8px solid #c9c9c9;
  margin-right: 5px;
}
.page-amount__arrow_left:hover:before {
  border-right-color: #434343;
}
.page-amount__list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  border-radius: 4px;
  padding: 2px;
  overflow: hidden;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .page-amount__list {
    width: 450px;
  }
}
.page-amount__number {
  position: relative;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  color: #888b8d;
  text-align: center;
  background-color: #f7f7f7;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
.page-amount__number_separator {
  cursor: default;
  background-color: #f7f7f7 !important;
}
.page-amount__number_separator:before {
  content: none !important;
}
.page-amount__number:before {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: 0;
  border-radius: 4px;
  border: 1px solid transparent;
  z-index: 1;
  -webkit-transition: 0.2s border-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out;
  transition: 0.2s border-color ease-in-out;
}
.page-amount__number:hover,
.page-amount__number.active {
  background-color: #fff;
}
.page-amount__number:hover:before,
.page-amount__number.active:before {
  border-color: #f460a3;
}
.page-amount__button {
  margin-left: 30px;
  cursor: pointer;
}
@media (max-width: 999px) {
  .page-amount__button {
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    width: 100%;
    margin-left: 0;
    border-radius: 3px;
  }
}
.page-amount__button-text {
  display: none;
  font-size: 15px;
  font-weight: bold;
}
@media (max-width: 999px) {
  .page-amount__button-text {
    display: inline;
  }
}
@media (max-width: 999px) {
  .page-amount__button.check-link:before {
    content: none;
  }
}
.page-amount__button.check-link:after {
  content: none;
  font-size: 13px;
}
@media (max-width: 999px) {
  .page-amount__button.check-link:after {
    content: "\e906";
    font-family: 'icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.dashboard .content-dashboard .block table.table {
  font-size: 12px;
}
.dashboard .content-dashboard .block table.table th,
.dashboard .content-dashboard .block table.table td {
  padding: 5px 10px;
}
.dashboard .content-dashboard .block table.table th .ui.label,
.dashboard .content-dashboard .block table.table td .ui.label {
  font-size: 12px;
}
.dashboard .content-dashboard .block table.table th.empty,
.dashboard .content-dashboard .block table.table td.empty {
  font-style: italic;
  color: #888b8d;
  text-align: center;
  padding: 50px;
}
.dashboard .content-dashboard .block.account .content {
  font-size: 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.dashboard .content-dashboard .block.account .content .label,
.dashboard .content-dashboard .block.account .content .value {
  margin-top: 5px;
}
.dashboard .content-dashboard .block.account .content .label {
  color: #888b8d;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 40%;
  -ms-flex: 1 1 40%;
  flex: 1 1 40%;
  text-align: right;
  padding-right: 15px;
}
.dashboard .content-dashboard .block.account .content .value {
  color: #434343;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 60%;
  -ms-flex: 1 1 60%;
  flex: 1 1 60%;
}
.dashboard .content-dashboard .block.contact .content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.dashboard .content-dashboard .block.contact .content p {
  margin-bottom: 0;
}
.dashboard .content-dashboard .block.contact .content .logo {
  width: 119px;
  height: 134px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  margin-right: 15px;
}
.dashboard .content-dashboard .block.contact .content .contact-info {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
}
.dashboard .content-dashboard .block.last-order-items {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 66%;
  -ms-flex: 1 0 66%;
  flex: 1 0 66%;
}
.dashboard .content-dashboard .block.last-order-items .ui.label,
.dashboard .content-dashboard .block.last-quotations .ui.label,
.dashboard .content-dashboard .block.last-payment-reports .ui.label {
  width: 100%;
}
.dashboard .content-dashboard .block.last-order-items a,
.dashboard .content-dashboard .block.last-quotations a,
.dashboard .content-dashboard .block.last-payment-reports a {
  color: #f460a3;
}
.dashboard .content-dashboard .block.last-order-items ul,
.dashboard .content-dashboard .block.last-quotations ul,
.dashboard .content-dashboard .block.last-payment-reports ul {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}
.dashboard .content-dashboard .block.statistics {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 33%;
  -ms-flex: 1 0 33%;
  flex: 1 0 33%;
}
.dashboard .content-dashboard .block.statistics .content {
  font-size: 12px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.dashboard .content-dashboard .block.statistics .content #statistics-placeholder {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 80%;
  -ms-flex: 1 0 80%;
  flex: 1 0 80%;
}
.dashboard .content-dashboard .block.statistics .content .row {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 20%;
  -ms-flex: 1 1 20%;
  flex: 1 1 20%;
}
.dashboard .content-dashboard .block.statistics .content .row .left {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 60%;
  -ms-flex: 1 1 60%;
  flex: 1 1 60%;
}
.dashboard .content-dashboard .block.statistics .content .row .right {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 40%;
  -ms-flex: 1 1 40%;
  flex: 1 1 40%;
  color: #888b8d;
}
.dashboard .content-dashboard .block.statistics .content .row .left,
.dashboard .content-dashboard .block.statistics .content .row .right {
  padding: 10px;
}
.dashboard .content-dashboard .block.sample .content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.dashboard .content-dashboard .block.sample .content .row {
  margin: 0;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.dashboard .content-dashboard .block.sample .content .main-product-image {
  padding-right: 10px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 220px;
  -ms-flex: 0 1 220px;
  flex: 0 1 220px;
}
.dashboard .content-dashboard .block.sample .content .main-product-image .image-icon {
  display: none;
}
.dashboard .content-dashboard .block.sample .content .product-info {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.dashboard .content-dashboard .block.sample .content .product-info p {
  font-weight: bold;
  margin-bottom: 10px;
}
.dashboard .content-dashboard .block.sample .content .product-info .btn.btn-sm {
  padding: 10px;
}
.dashboard .content-dashboard .block.sample .content .description {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  color: #979797;
  font-size: 14px;
  font-style: italic;
  margin-top: 10px;
}
.v1 .page-menu {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 6px rgba(115,115,115,0.33);
  box-shadow: 0 2px 6px rgba(115,115,115,0.33);
}
@media (max-width: 999px), (min-width: 1000px) {
  .v1 .page-menu {
    height: 51px;
    text-align: center;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
  .v1 .page-menu::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px), (min-width: 1000px) {
  .v1 .page-menu {
    margin-bottom: 0;
    padding: 0 15px;
    border-top: 1px solid #eaeaea;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
@media (max-width: 767px) {
  .v1 .page-menu {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .v1 .page-menu_mobile-simple {
    margin-bottom: 0;
  }
}
@media (min-width: 1000px) {
  .v1 .page-menu_simple {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .v1 .page-menu_simple .page-menu__linkchoice_widget_options {
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .v1 .page-menu_slim {
    margin-bottom: 0;
  }
}
@media (max-width: 999px), (min-width: 1000px) {
  .v1 .page-menu__item {
    display: inline-block;
    line-height: 1;
  }
}
.v1 .page-menu__link {
  display: block;
  padding: 17px 15px 17px 12px;
  font-size: 20px;
  color: #888b8d;
  text-decoration: none;
  border-left: 4px solid transparent;
  -webkit-transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  -moz-transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  -o-transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  -ms-transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
}
@media (max-width: 999px), (min-width: 1000px) {
  .v1 .page-menu__link {
    position: relative;
    font-size: 15px;
    line-height: 1.1;
    border-left: 0;
    padding: 18px 12px 17px;
  }
  .v1 .page-menu__link:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 12px;
    right: 12px;
    height: 4px;
    -webkit-transition: background-color 0.2s ease-in-out;
    -moz-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }
}
.v1 .page-menu__link:hover {
  color: #333;
  text-decoration: none;
}
@media (max-width: 999px), (min-width: 1000px) {
  .v1 .page-menu__link:hover {
    background-color: transparent;
  }
  .v1 .page-menu__link:hover:after {
    background-color: #f460a3;
  }
}
.active .v1 .page-menu__link {
  background-color: #fcfcfc;
  border-left-color: #f460a3;
  color: #333;
}
@media (max-width: 999px), (min-width: 1000px) {
  .active .v1 .page-menu__link {
    background-color: transparent;
  }
  .active .v1 .page-menu__link:after {
    background-color: #f460a3;
  }
}
@media (max-width: 999px), (min-width: 1000px) {
  .v1 .page-menu__link_badge:after {
    bottom: 3px;
  }
}
.v1 .page-menu__badge {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  padding: 3px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  font-size: 10px;
  color: #f460a3;
  font-weight: bold;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.11);
  box-shadow: 0 5px 10px rgba(0,0,0,0.11);
}
.v1 .page-menu .active .page-menu__link {
  background-color: #fcfcfc;
  border-left-color: #f460a3;
  color: #333;
}
@media (max-width: 999px), (min-width: 1000px) {
  .v1 .page-menu .active .page-menu__link {
    background-color: transparent;
  }
  .v1 .page-menu .active .page-menu__link:after {
    background-color: #f460a3;
  }
}
.page-nav {
  background-color: #f7f7f7;
}
@media (min-width: 1000px) {
  .page-nav {
    padding: 30px 50px 20px 35px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page-nav {
    padding: 30px 20px 20px;
  }
}
@media (max-width: 767px) {
  .page-nav {
    background-color: #333;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
  .page-nav::-webkit-scrollbar {
    display: none;
  }
}
.page-nav__title {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: bold;
  color: #434343;
}
@media (min-width: 768px) and (max-width: 999px) {
  .page-nav__title {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .page-nav__title {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    padding: 13px 10px;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
  }
}
.page-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (max-width: 767px) {
  .page-nav__list {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .page-nav__list-item {
    border-top: 1px solid #dfdfdf;
  }
}
@media (max-width: 767px) {
  .page-nav__list-item {
    display: inline-block;
    vertical-align: top;
  }
}
.page-nav__link {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 10px 0;
  font-size: 18px;
  color: #434343;
  text-decoration: none;
}
@media (min-width: 1000px) {
  .page-nav__link {
    min-height: 93px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .page-nav__link {
    font-size: 16px;
    min-height: 80px;
  }
}
@media (max-width: 767px) {
  .page-nav__link {
    padding: 13px 10px;
    font-size: 15px;
    color: #999;
  }
}
.no-touchevents .page-nav__link:hover {
  color: #f460a3;
}
@media (min-width: 1000px) {
  .page-panel {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .page-panel {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
@media (max-width: 999px) {
  .page-panel {
    margin-bottom: 25px;
  }
}
.page-panel__title {
  font-size: 16px;
  color: #888b8d;
}
@media (min-width: 768px) {
  .page-panel__title {
    padding-right: 15px;
  }
}
@media (max-width: 767px) {
  .page-panel__title {
    margin-bottom: 5px;
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .page-panel__controls {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}
.v1 .paginationContainer {
  margin: auto;
  padding: 1rem 0;
  text-align: right;
}
.v1 .paginationContainer .pagination {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -moz-box-pack: center;
  -webkit-box-pack: center;
border-ra
}
.v1 .pagination {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  list-style: none;
  padding-left: 0;
}
.v1 .pagination .pageItem .pageLink {
  background-color: transparent;
  border-radius: 0.125rem;
  text-decoration: none;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.v1 .pagination .pageItem .pageLink:hover {
  background-color: rgba(0,0,0,0.1);
}
.v1 .pagination .pageItem.active .pageLink {
  background-color: #f460a3;
  -webkit-box-shadow: 0 2px 10px rgba(255,145,195,0.5);
  box-shadow: 0 2px 10px rgba(255,145,195,0.5);
  color: #fff;
}
.v1 .pagination .pageItem.disable .pageLink {
  cursor: not-allowed;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  pointer-events: none;
}
.v1 .pagination .pageItem.disable .pageLink:hover {
  background-color: transparent;
}
.v1 .pageLink {
  display: block;
  line-height: 1.25;
  padding: 0.5rem 0.75rem;
  position: relative;
}
.v1 .params-board {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #fefefe;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #ededee;
}
.v1 .params-board_items-2 .params-board__item {
  width: 50%;
}
.v1 .params-board_items-3 .params-board__item {
  width: 33.333333%;
}
@media (max-width: 999px) {
  .v1 .params-board_items-3 .params-board__item {
    width: 50%;
  }
}
.v1 .params-board_items-4 .params-board__item {
  width: 25%;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .params-board_items-4 .params-board__item {
    width: 33.333333%;
  }
}
@media (max-width: 999px) {
  .v1 .params-board_items-4 .params-board__item {
    width: 50%;
  }
}
.v1 .params-board_items-10 .params-board__item {
  width: 10%;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .params-board_items-10 .params-board__item {
    width: 33.333333%;
  }
}
@media (max-width: 999px) {
  .v1 .params-board_items-10 .params-board__item {
    width: 50%;
  }
}
.v1 .params-board__item {
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: #ededee;
}
@media (min-width: 1000px) {
  .v1 .params-board__item {
    width: 33.333333%;
  }
}
@media (max-width: 999px) {
  .v1 .params-board__item {
    width: 50%;
  }
}
.v1 .params-board__item-inner {
  margin: -1px;
  cursor: pointer;
}
.v1 .params-board_title {
  background-color: #f7f7f7;
  margin: 0;
  padding: 5px 5px 15px 5px;
  text-align: center;
  font-weight: bold;
}
#page-product .v1 .params-board_title {
  color: #f460a3;
  padding: 15px 5px 25px 5px;
}
.v1 .params-board_alert {
  border: 1px solid #fde360;
  border-radius: 5px;
  background-color: #fff5c6;
  padding: 10px;
  margin: 0 20px 20px 20px;
  color: #736000;
  margin-top: 10px;
}
.v1 #page-product .params-board_title {
  color: #f460a3;
  padding: 15px 5px 25px 5px;
}
.v1 .params-card {
  position: relative;
  width: 100%;
  padding: 30px 20px 40px;
  background-color: #fff;
  border: 1px solid #ededee;
  -webkit-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
}
@media (min-width: 1000px) {
  .minigrid .v1 .params-card {
    padding-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .minigrid .v1 .params-card {
    padding-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .v1 .params-card {
    padding: 30px 20px 40px;
    border-color: transparent;
  }
}
@media (min-width: 768px) {
  .v1 .params-card:hover,
  .v1 .params-card.active {
    border-color: #d5d5d5;
    -webkit-box-shadow: 0 6px 27px rgba(51,51,51,0.07);
    box-shadow: 0 6px 27px rgba(51,51,51,0.07);
  }
}
.v1 .params-card:hover .params-card__button,
.v1 .params-card.active .params-card__button {
  visibility: visible;
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
@media (max-width: 767px) {
  .v1 .params-card.active {
    border-color: #d5d5d5;
  }
}
@media (max-width: 999px) {
  .v1 .params-card__header {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}
.v1 .params-card__header-link {
  display: none;
  font-weight: bold;
  font-size: 15px;
  text-decoration: none;
  color: #f460a3;
}
@media (max-width: 767px) {
  .v1 .params-card__header-link {
    display: block;
  }
}
.minigrid .v1 .params-card__title,
.minigrid .v1 .params-card__text,
.minigrid .v1 .params-card__price {
  font-size: 15px;
}
@media (max-width: 767px) {
  .v1 .params-card__title,
  .v1 .params-card__text,
  .v1 .params-card__price {
    font-size: 15px;
  }
}
.v1 .params-card__title {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .v1 .params-card__title {
    margin-bottom: 12px;
  }
}
.v1 .params-card__text {
  font-size: 16px;
  line-height: 1.375;
  color: #7e8284;
}
.v1 .params-card__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  padding: 0 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@media (min-width: 1000px) {
  .minigrid .v1 .params-card__footer {
    bottom: 0;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .minigrid .v1 .params-card__footer {
    bottom: 0;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .v1 .params-card__footer {
    bottom: 20px;
  }
}
.v1 .params-card__price {
  padding-top: 3px;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.1;
}
.v1 .params-card__button {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
@media (min-width: 1000px) {
  .minigrid .v1 .params-card__button {
    bottom: 3px;
    right: 3px;
  }
}
@media (min-width: 768px) {
  .minigrid .v1 .params-card__button {
    bottom: 3px;
    right: 3px;
  }
}
@media (max-width: 767px) {
  .v1 .params-card__button {
    display: none;
  }
}
@media (min-width: 1000px) {
  .v1 .minigrid .params-card {
    padding-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .v1 .minigrid .params-card {
    padding-bottom: 10px;
  }
}
.v1 .minigrid .params-card__title,
.v1 .minigrid .params-card__text,
.v1 .minigrid .params-card__price {
  font-size: 15px;
}
@media (min-width: 1000px) {
  .v1 .minigrid .params-card__footer {
    bottom: 0;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .v1 .minigrid .params-card__footer {
    bottom: 0;
    padding: 0;
  }
}
@media (min-width: 1000px) {
  .v1 .minigrid .params-card__button {
    bottom: 3px;
    right: 3px;
  }
}
@media (min-width: 768px) {
  .v1 .minigrid .params-card__button {
    bottom: 3px;
    right: 3px;
  }
}
.v1 .params-list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .params-list {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.v1 .params-list__item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 0 10px;
}
@media (min-width: 768px) {
  .v1 .params-list__item {
    margin-bottom: 20px;
  }
}
.v1 .params-list_items-2 .params-list__item {
  width: 50%;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .params-list_items-2 .params-list__item {
    width: 100%;
  }
}
@media (max-width: 999px) {
  .v1 .params-list_items-2 .params-list__item {
    width: 100%;
  }
}
@media (min-width: 1000px) {
  .v1 .params-list_offset-top {
    margin-top: 50px;
  }
}
.v1 .payment-card {
  cursor: pointer;
  position: relative;
  background-color: #fff;
  -webkit-transition: 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s box-shadow ease-in-out;
  transition: 0.2s box-shadow ease-in-out;
}
.v1 .payment-card .check-link {
  width: auto;
}
@media (min-width: 768px) {
  .v1 .payment-card {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
  }
}
@media (max-width: 767px) {
  .v1 .payment-card {
    padding: 15px;
    height: 100%;
  }
}
.v1 .payment-card__header {
  position: relative;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
  -webkit-transition: 0.2s border-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out;
  transition: 0.2s border-color ease-in-out;
  border-bottom: 1px solid #e2e2e2;
}
@media (min-width: 768px) {
  .v1 .payment-card__header {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    padding: 20px 15px;
    height: 80px;
  }
  .v1 .payment-card__header .payment-card__button {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .v1 .payment-card__header {
    min-height: 38px;
    margin-bottom: 10px;
  }
}
.v1 .payment-card__title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  letter-spacing: 0.2pt;
}
@media (max-width: 767px) {
  .v1 .payment-card__title {
    font-size: 15px;
    text-align: center;
  }
}
.v1 .payment-card__logo {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 40px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
@media tablet_destop {
  .v1 .payment-card__logo {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .v1 .payment-card__logo {
    height: 40px;
  }
}
.v1 .payment-card__logo img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 767px) {
  .v1 .payment-card__logo img {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .v1 .payment-card__body {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    -ms-box-flex: 1;
    box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    padding: 30px 15px 10px;
  }
}
.v1 .payment-card__text {
  height: 100%;
  font-size: 17px;
  color: #343434;
}
@media (max-width: 767px) {
  .v1 .payment-card__text {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.2;
  }
}
@media (max-width: 767px) {
  .v1 .payment-card__text .mobile {
    display: block;
  }
}
@media (min-width: 768px) {
  .v1 .payment-card__text .mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  .v1 .payment-card__text .full {
    display: none;
  }
}
@media (min-width: 768px) {
  .v1 .payment-card__text .full {
    display: block;
  }
}
.v1 .payment-card__info {
  display: inline-block;
  vertical-align: top;
  width: 21px;
  height: 21px;
  font-size: 21px;
  color: #03a9f4;
  cursor: default;
}
@media (min-width: 768px) {
  .v1 .payment-card__footer {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 80px;
    padding: 15px;
  }
}
.v1 .payment-card__button {
  width: 45px;
  display: none;
}
@media (max-width: 767px) {
  .v1 .payment-card__button {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.v1 .payment-card__check-link {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 15px;
  font-weight: bold;
  color: #f460a3;
  text-decoration: none;
}
.payment-card.active .v1 .payment-card__check-link {
  display: none;
}
@media (min-width: 768px) {
  .v1 .payment-card__check-link {
    display: none;
  }
}
.v1 .payment-card__commission {
  font-size: 16px;
  line-height: 1.3;
  color: #9e9e9e;
}
@media (min-width: 768px) {
  .v1 .payment-card__commission {
    max-width: 155px;
  }
}
@media (max-width: 767px) {
  .v1 .payment-card__commission {
    font-size: 14px;
  }
}
.no-touchevents .v1 .payment-card:hover {
  -webkit-box-shadow: 0 30px 42px rgba(51,51,51,0.06), inset 0 0 0 1px #d5d5d5;
  box-shadow: 0 30px 42px rgba(51,51,51,0.06), inset 0 0 0 1px #d5d5d5;
}
.no-touchevents .v1 .payment-card:hover .payment-card__header {
  border-bottom-color: #d5d5d5;
}
.v1 .payment-card.active .payment-card__button {
  display: block;
}
.v1 .payment-card.active .payment-card__check-link {
  display: none;
}
.v1 .payment-discount__title {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .v1 .payment-discount__title {
    margin-bottom: 15px;
    font-size: 18px;
  }
}
.v1 .payment-discount__info {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .v1 .payment-discount__info {
    margin-bottom: 20px;
  }
}
.v1 .payment-discount__text {
  font-size: 17px;
  color: #888b8d;
}
@media (max-width: 767px) {
  .v1 .payment-discount__text {
    font-size: 15px;
  }
}
.v1 .payment-discount__table {
  text-align: center;
}
.v1 .payment-discount__table th {
  text-align: center;
}
.v1 .payment-list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .v1 .payment-list {
    margin: 0 -5px 35px;
  }
}
@media (max-width: 767px) {
  .v1 .payment-list {
    margin: 0 -15px 20px;
  }
}
.v1 .payment-list__item {
  margin-bottom: 10px;
  padding: 0 5px;
}
@media (min-width: 768px) {
  .v1 .payment-list__item {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (min-width: 1240px) {
  .v1 .payment-list__item {
    -webkit-flex-basis: 20%;
    flex-basis: 20%;
  }
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .payment-list__item {
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .payment-list__item {
    -webkit-flex-basis: 33.333%;
    flex-basis: 33.333%;
  }
}
@media (max-width: 767px) {
  .v1 .payment-list__item {
    -webkit-flex-basis: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
}
.personal-modal__title,
.personal-modal__subtitle {
  font-weight: bold;
  font-size: 30px;
  color: #343434;
}
@media (max-width: 767px) {
  .personal-modal__title,
  .personal-modal__subtitle {
    font-size: 18px;
  }
}
.personal-modal__title {
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .personal-modal__title {
    margin-bottom: 10px;
  }
}
.personal-modal__subtitle {
  margin-top: 40px;
}
@media (max-width: 767px) {
  .personal-modal__subtitle {
    margin-top: 30px;
    margin-bottom: 25px;
  }
}
.personal-modal__label {
  font-size: 17px;
}
@media (min-width: 768px) {
  .personal-modal__label {
    padding-top: 15px;
    text-align: right;
  }
}
@media (max-width: 767px) {
  .personal-modal__label {
    margin-bottom: 15px;
  }
}
.personal-modal__label-value {
  font-size: 16px;
  font-weight: bold;
}
.personal-modal__box {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-height: 408px;
  background-color: #fcfcfc;
  padding: 20px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  font-size: 16px;
  color: #888b8d;
}
@media (max-width: 767px) {
  .personal-modal__box {
    padding: 15px;
    font-size: 14px;
  }
}
.personal-modal__box-icon {
  margin-bottom: 1em;
  text-align: center;
}
.personal-modal__box p:last-child {
  margin-bottom: 0;
}
.personalization-modal__title {
  margin: 0 0 35px;
  font-weight: bold;
  font-size: 20px;
}
@media (max-width: 767px) {
  .personalization-modal__title {
    font-size: 17px;
  }
}
.personalization-modal__content {
  position: relative;
  padding-right: 60px;
  font-size: 17px;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .personalization-modal__content {
    font-size: 14px;
  }
}
.personalization-modal__content-image {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 10px;
  width: 49px;
  height: 49px;
  font-size: 26px;
  text-align: center;
  color: #4f5559;
  background-color: #fdfdfd;
  border: 1px solid #c7c7c7;
  border-radius: 50px;
}
.v1 .post-card {
  display: block;
  font-size: 20px;
  line-height: 1.333;
  text-decoration: none;
}
.v1 .post-card__body {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.no-touchevents .v1 .post-card:hover .post-card__image:after {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.v1 .post-card:hover .post-card__title {
  color: #f460a3;
}
.v1 .post-card__image {
  display: block;
  position: relative;
  margin-bottom: 20px;
  height: 335px;
  overflow: hidden;
  border-radius: 2px;
  background-position: center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .post-card__image {
    height: 265px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .post-card__image {
    height: 200px;
  }
}
.v1 .post-card__image:after {
  content: "";
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f460a3;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  border-radius: 2px;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.v1 .post-card__title {
  display: block;
  font-weight: 500;
  color: #333;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .post-card__title {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .v1 .post-card__title {
    font-size: 15px;
  }
}
.v1 .post-card__text {
  display: block;
  color: #888b8d;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .post-card__text {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .v1 .post-card__text {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .v1 .post-card_mobile-small {
    display: inline;
  }
}
@media (max-width: 767px) {
  .v1 .post-card_mobile-small .post-card__image {
    width: 130px;
    height: 110px;
    margin: 0 15px 0 0;
    float: left;
  }
}
@media (max-width: 767px) {
  .v1 .post-card_mobile-tile .post-card__image {
    height: 0;
    padding-bottom: 60%;
    margin: 0;
    border-radius: 0;
  }
}
@media (max-width: 767px) {
  .v1 .post-card_mobile-tile .post-card__body {
    display: block;
    padding: 10px 15px;
  }
}
@media (max-width: 767px) {
  .v1 .post-card_mobile-tile .post-card__title {
    margin-bottom: 3px;
  }
}
@media (min-width: 768px) {
  .v1 .posts-list {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
}
@media (min-width: 768px) {
  .v1 .posts-list__item {
    width: 33.333%;
    padding: 0 15px;
  }
}
@media (min-width: 1000px) {
  .v1 .posts-list__item {
    margin-bottom: 50px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .posts-list__item {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .v1 .posts-list_simple .posts-list__item {
    padding: 15px 0;
    border-top: 1px solid #e2e2e3;
  }
}
@media (max-width: 767px) {
  .v1 .posts-list_tiles {
    margin: 0 -15px;
  }
  .v1 .posts-list_tiles .posts-list__item {
    margin-bottom: 15px;
  }
}
.premium-services {
  padding: 55px 0 25px;
  background-color: #f7f7f7;
}
@media (min-width: 768px) and (max-width: 999px) {
  .premium-services {
    padding: 40px 0 25px;
  }
}
@media (max-width: 767px) {
  .premium-services {
    padding: 20px 0;
  }
}
.premium-services__title {
  margin: 0 0 30px;
  font-size: 36px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .premium-services__title {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  .premium-services__title {
    margin-bottom: 10px;
    font-size: 17px;
  }
}
.premium-services__list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px;
}
@media (min-width: 1240px) {
  .premium-services__list {
    margin: 0 -15px;
  }
}
.premium-services__list-item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-basis: 25%;
  flex-basis: 25%;
  padding: 0 10px;
  margin-bottom: 30px;
}
@media (min-width: 1240px) {
  .premium-services__list-item {
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .premium-services__list-item {
    margin-bottom: 10px;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
}
.premium-services .service-card {
  width: 100%;
}
.v1 .preview-card {
  background-color: #fff;
  -webkit-transition: 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s box-shadow ease-in-out;
  transition: 0.2s box-shadow ease-in-out;
}
@media (min-width: 768px) {
  .v1 .preview-card {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    border: 1px solid #ddd;
  }
}
@media (max-width: 767px) {
  .v1 .preview-card {
    -webkit-box-shadow: 0 4px 18px rgba(51,51,51,0.07);
    box-shadow: 0 4px 18px rgba(51,51,51,0.07);
  }
}
.v1 .preview-card:hover {
  -webkit-box-shadow: 0 4px 18px rgba(0,0,0,0.11);
  box-shadow: 0 4px 18px rgba(0,0,0,0.11);
}
.v1 .preview-card:hover .preview-card__button {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  -ms-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.v1 .preview-card__image {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
  height: 260px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .preview-card__image {
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .preview-card__image {
    -webkit-flex-basis: 40%;
    flex-basis: 40%;
  }
}
@media (max-width: 767px) {
  .v1 .preview-card__image {
    height: auto;
    padding-bottom: 40%;
  }
}
.v1 .preview-card__body {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
  padding: 25px 15px 20px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .preview-card__body {
    -webkit-flex-basis: 60%;
    flex-basis: 60%;
    padding: 25px;
  }
}
@media (max-width: 767px) {
  .v1 .preview-card__body {
    padding: 15px 15px 20px;
  }
}
.v1 .preview-card__title {
  font-size: 17px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .v1 .preview-card__title {
    font-size: 16px;
  }
}
.v1 .preview-card__text {
  margin-bottom: 12px;
  font-size: 14px;
}
@media (max-width: 767px) {
  .v1 .preview-card__text {
    margin-bottom: 0;
    font-size: 15px;
  }
}
.v1 .preview-card__footer {
  position: relative;
  padding: 5px 100px 0 0;
  min-height: 38px;
}
@media (max-width: 999px) {
  .v1 .preview-card__footer {
    padding: 0;
    min-height: 0;
  }
}
.v1 .preview-card__button {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  cursor: pointer;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
@media (max-width: 999px) {
  .v1 .preview-card__button {
    display: none;
  }
}
.v1 .preview-card__link {
  font-size: 14px;
  color: #f460a3;
  text-decoration: none;
}
.v1 .preview-card__link:hover {
  color: #434343;
}
.v1 .preview-list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -15px 20px;
}
@media (max-width: 999px) {
  .v1 .preview-list {
    display: block;
    margin-top: 20px;
  }
}
.v1 .preview-list__item {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
  padding: 0 15px;
  margin-bottom: 30px;
}
@media (max-width: 999px) {
  .v1 .preview-list__item {
    margin-bottom: 15px;
  }
}
#preview-thumbnail-modal .modal-dialog {
  width: auto !important;
}
#preview-thumbnail-modal .modal-content {
  text-align: center;
  border-radius: 0;
  padding: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
#preview-thumbnail-modal .modal-content img {
  margin-bottom: -7px;
}
.v1 .preview-tile {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #fefefe;
  border: 1px solid #ededee;
  transfirm: translate3d(0, 0, 0);
  -webkit-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
}
@media (min-width: 768px) {
  .v1 .preview-tile {
    height: 275px;
  }
  .params-board_items-2 .v1 .preview-tile {
    height: 450px;
  }
}
.v1 .preview-tile .icons-recommended {
  display: block;
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 10px;
  height: 22px;
  z-index: 1000;
}
.v1 .preview-tile .icons-recommended .inner {
  position: relative;
  float: right;
}
.v1 .preview-tile .icons-recommended .icon {
  width: 16px;
  height: 22px;
  padding: 3px;
  background-repeat: no-repeat;
  background-position: 1px center;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #f460a3;
}
.v1 .preview-tile .icons-recommended .icon.bg-grey {
  background-color: #f460a3;
}
.v1 .preview-tile .icons-recommended .icon.bg-grey:before {
  border-right-color: #f460a3;
}
.v1 .preview-tile .icons-recommended .icon.bg-pink {
  background-color: #f460a3;
}
.v1 .preview-tile .icons-recommended .icon.bg-pink:before {
  border-right-color: #f460a3;
}
.v1 .preview-tile .icons-recommended .icon:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right-width: 8px;
  border-right-style: solid;
  left: -8px;
  top: 0;
  border-right-color: #f460a3;
}
.v1 .preview-tile .icons-recommended .icon.yellow-star {
  background-image: url("i/recommend-icon-star.svg");
}
.v1 .preview-tile .icons-recommended .icon.icon-info {
  background-image: url("i/recommend-icon-info.svg");
}
.v1 .preview-tile .icons-recommended .icon.icon-green-leaf {
  background-image: url("i/recommend-icon-leaf.svg");
}
.v1 .preview-tile .icons-recommended .icon.icon-bookmark {
  background-image: url("i/recommend-icon-bookmark.svg");
}
.v1 .preview-tile .icons-recommended .icon.icon-queen {
  background-image: url("i/recommend-icon-queen.svg");
}
.v1 .preview-tile .icons-recommended .icon.icon-eraser {
  background-image: url("i/recommend-icon-eraser.svg");
}
.v1 .preview-tile .icons-recommended .icon.icon-blue-leaf {
  background-image: url("i/recommend-icon-leaf.svg");
}
.v1 .preview-tile .icons-recommended .label {
  float: left;
  background-color: #fff;
  padding: 3px 10px 3px 8px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  height: 22px;
  font-weight: bold;
  border: 1px solid #f460a3;
}
@media (min-width: 768px) {
  .v1 .preview-tile:hover,
  .v1 .preview-tile.active {
    z-index: 2;
    background-color: #fff;
    border-color: #d5d5d5;
    -webkit-box-shadow: 0 4px 18px rgba(51,51,51,0.07);
    box-shadow: 0 4px 18px rgba(51,51,51,0.07);
  }
  .v1 .preview-tile:hover .preview-tile__image,
  .v1 .preview-tile.active .preview-tile__image {
    position: relative;
    margin-top: 0;
    width: 100%;
    border-radius: 0;
  }
  .v1 .preview-tile:hover .preview-tile__body,
  .v1 .preview-tile.active .preview-tile__body {
    padding-top: 15px;
  }
  .v1 .preview-tile:hover .preview-tile__button,
  .v1 .preview-tile.active .preview-tile__button,
  .v1 .preview-tile:hover .preview-tile__image-big,
  .v1 .preview-tile.active .preview-tile__image-big {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    visibility: visible;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    -ms-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .v1 .preview-tile:hover .preview-tile__list,
  .v1 .preview-tile.active .preview-tile__list {
    position: static;
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    -ms-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
}
.v1 .preview-tile__image {
  border-radius: 100px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: 0.2s height ease-in-out, 0.2s width ease-in-out, 0.2s border-radius ease-in-out, 0.2s margin-top ease-in-out;
  -moz-transition: 0.2s height ease-in-out, 0.2s width ease-in-out, 0.2s border-radius ease-in-out, 0.2s margin-top ease-in-out;
  -o-transition: 0.2s height ease-in-out, 0.2s width ease-in-out, 0.2s border-radius ease-in-out, 0.2s margin-top ease-in-out;
  -ms-transition: 0.2s height ease-in-out, 0.2s width ease-in-out, 0.2s border-radius ease-in-out, 0.2s margin-top ease-in-out;
  transition: 0.2s height ease-in-out, 0.2s width ease-in-out, 0.2s border-radius ease-in-out, 0.2s margin-top ease-in-out;
}
@media (max-width: 767px) {
  .v1 .preview-tile__image {
    position: static;
  }
}
.v1 .preview-tile__image-big {
  position: absolute;
  padding: 10px;
  bottom: -19px;
  left: 11px;
  font-size: 14px;
  font-weight: bold;
  color: #f460a3;
  text-decoration: none;
  border-radius: 20px;
  background-color: #fff;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-box-shadow: 3px 3px 5px #ccc;
  box-shadow: 3px 3px 5px #ccc;
  z-index: 1000;
}
@media (max-width: 999px) {
  .v1 .preview-tile__image-big {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    top: 1px;
    left: 1px;
    font-size: 9px;
    bottom: auto;
  }
}
.v1 .preview-tile__image-big:hover {
  color: #f460a3;
  -webkit-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  -o-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
}
.v1 .preview-tile__body {
  position: relative;
  width: 100%;
  overflow: hidden;
  -webkit-transition: 0.2s padding-top ease-in-out;
  -moz-transition: 0.2s padding-top ease-in-out;
  -o-transition: 0.2s padding-top ease-in-out;
  -ms-transition: 0.2s padding-top ease-in-out;
  transition: 0.2s padding-top ease-in-out;
}
.v1 .preview-tile__header {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  min-height: 38px;
  text-decoration: none;
}
@media (min-width: 768px) {
  .v1 .preview-tile__header {
    margin-bottom: 12px;
  }
}
.v1 .preview-tile__button {
  position: absolute;
  bottom: -15px;
  right: 10px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.v1 .preview-tile__title {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 1.3;
  font-weight: bold;
  text-align: center;
  max-height: 40px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .v1 .preview-tile__title {
    font-size: 10px;
    max-height: 26px;
  }
}
.v1 .preview-tile__list {
  position: absolute;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  font-size: 14px;
  color: #888b8d;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  -o-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: 0.2s opacity ease-in-out, 0.2s -webkit-transform ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out, 0.2s -moz-transform ease-in-out;
  -o-transition: 0.2s opacity ease-in-out, 0.2s -o-transform ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out, 0.2s -ms-transform ease-in-out;
  transition: 0.2s opacity ease-in-out, 0.2s transform ease-in-out;
}
.v1 .preview-tile_small .preview-tile__image {
  margin-top: 75px;
  width: 95px;
  height: 95px;
}
@media (max-width: 767px) {
  .v1 .preview-tile_small .preview-tile__image {
    margin-top: 15px;
  }
}
@media (min-width: 768px) {
  .v1 .preview-tile_small .preview-tile__body {
    padding: 8px 15px 15px;
  }
}
@media (max-width: 767px) {
  .v1 .preview-tile_small .preview-tile__body {
    padding: 3px 10px 10px;
  }
}
@media (min-width: 768px) {
  .v1 .preview-tile_small:hover .preview-tile__image,
  .v1 .preview-tile_small.active .preview-tile__image {
    height: 115px;
  }
  .params-board_items-2 .v1 .preview-tile_small:hover .preview-tile__image,
  .params-board_items-2 .v1 .preview-tile_small.active .preview-tile__image {
    height: 275px;
  }
}
.v1 .preview-tile_big .preview-tile__image {
  margin-top: 40px;
  width: 125px;
  height: 125px;
}
@media (max-width: 767px) {
  .v1 .preview-tile_big .preview-tile__image {
    margin-top: 15px;
    width: 95px;
    height: 95px;
  }
}
@media (min-width: 768px) {
  .params-board_items-2 .v1 .preview-tile_big .preview-tile__image {
    margin-top: 140px;
  }
}
.v1 .preview-tile_big .preview-tile__body {
  padding: 8px 10px 10px;
}
@media (max-width: 767px) {
  .v1 .preview-tile_big .preview-tile__body {
    padding-top: 3px;
  }
}
@media (min-width: 768px) {
  .v1 .preview-tile_big:hover .preview-tile__image,
  .v1 .preview-tile_big.active .preview-tile__image {
    height: 190px;
  }
  .v1 .preview-tile_big:hover .preview-tile__title,
  .v1 .preview-tile_big.active .preview-tile__title {
    max-height: 40px;
  }
}
#modal-reseller-print-grid-prices .modal-content {
  padding: 20px 50px 50px;
}
@media (max-width: 767px) {
  #modal-reseller-print-grid-prices .modal-content {
    padding: 20px;
  }
}
#modal-reseller-print-grid-prices a.close {
  float: right;
}
#modal-reseller-print-grid-prices p.title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
#modal-reseller-print-grid-prices p.title span.icon-crop {
  color: #f460a3;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .v1 .print-options-modal__image {
    margin-bottom: 55px;
  }
}
@media (max-width: 767px) {
  .v1 .print-options-modal__image {
    margin-bottom: 25px;
  }
}
.v1 .print-options-modal__image img {
  display: block;
  max-width: 100%;
}
.v1 .print-options-modal__title {
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .v1 .print-options-modal__title {
    font-size: 20px;
    text-transform: capitalize;
  }
}
.v1 .print-options-modal__content {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .v1 .print-options-modal__content p {
    font-size: 17px;
  }
}
.v1 .print-options-modal__button {
  min-width: 280px;
}
@media (max-width: 999px) {
  .v1 .print-options-modal__footer {
    text-align: center;
  }
}
.v1 .product-card {
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-transition: 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s box-shadow ease-in-out;
  transition: 0.2s box-shadow ease-in-out;
}
.v1 .product-card:hover {
  -webkit-box-shadow: 0 4px 18px rgba(0,0,0,0.11);
  box-shadow: 0 4px 18px rgba(0,0,0,0.11);
}
.v1 .product-card:hover .product-card__link,
.v1 .product-card:hover .product-card__image-big {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
@media (min-width: 1240px) {
  .v1 .product-card {
    height: 267px;
  }
}
@media (min-width: 1000px) {
  .v1 .product-card {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
.v1 .product-card__image,
.v1 .product-card__body {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
}
.v1 .product-card__image {
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .product-card__image {
    height: 265px;
  }
}
@media (max-width: 767px) {
  .v1 .product-card__image {
    height: 235px;
  }
}
.v1 .product-card__image-big {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px;
  color: #939698;
  text-decoration: none;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  -o-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
}
.v1 .product-card__image-big:hover {
  color: #f460a3;
}
.v1 .product-card__body {
  padding: 45px 25px 25px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .product-card__body {
    padding: 15px;
  }
}
@media (max-width: 767px) {
  .v1 .product-card__body {
    padding: 11px;
  }
}
.v1 .product-card__title {
  margin-bottom: 10px;
  font-size: 17px;
  line-height: 1.3;
  font-weight: bold;
}
@media (max-width: 999px) {
  .v1 .product-card__title {
    font-size: 15px;
  }
}
.v1 .product-card__text {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.57;
  color: #888b8d;
}
@media (max-width: 999px) {
  .v1 .product-card__text {
    line-height: 1.3;
  }
}
.v1 .product-card__price {
  font-size: 14px;
  font-weight: bold;
}
.v1 .product-card__footer {
  position: relative;
  padding: 8px 100px 0 0;
  min-height: 38px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .product-card__footer {
    padding-top: 0;
  }
}
.v1 .product-card__link {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.v1 .product-config {
  background-color: #fefefe;
}
@media (max-width: 767px) {
  .v1 .product-config {
    -webkit-box-shadow: 0 4px 18px rgba(51,51,51,0.07);
    box-shadow: 0 4px 18px rgba(51,51,51,0.07);
  }
}
.v1 .product-config_layout-2 {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.v1 .product-config_layout-2 .product-config__content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (min-width: 1000px) {
  .v1 .product-config_layout-2 .product-config__content {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
.v1 .product-config__top {
  padding: 30px 35px;
  background-color: #fcfcfc;
  border-bottom: 1px solid #f1f1f1;
}
.only-one .v1 .product-config__top {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.only-one .v1 .product-config__top .row {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.only-one .v1 .product-config__top .row [class*="col-"] {
  width: auto;
}
@media (max-width: 999px) {
  .v1 .product-config__top {
    padding: 20px 15px;
    border-bottom: 0 none;
  }
}
@media (max-width: 767px) {
  .v1 .product-config__top {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.v1 .product-config__top .radio-group_small {
  margin-top: 15px;
}
.v1 .product-config__content {
  padding: 35px;
}
.only-one .v1 .product-config__content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
@media (max-width: 767px) {
  .v1 .product-config__content {
    padding: 15px;
  }
}
@media (max-width: 767px) {
  .v1 .product-config__content_mobile-fix-padding {
    padding-top: 0;
  }
}
.v1 .product-config__content_big {
  padding: 40px 55px;
}
@media (min-width: 768px) and (max-width: 999px), (max-width: 767px) {
  .v1 .product-config__content_big {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .product-config__content .tab-col-12 {
    width: 100%;
  }
}
.v1 .product-config__title {
  margin: 0 0 25px;
  font-size: 18px;
  font-weight: 500;
}
.v1 .product-config__title:last-child {
  margin-bottom: 0;
}
@media (min-width: 1000px) {
  .v1 .product-config__message {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    min-height: 220px;
  }
}
@media (max-width: 999px) {
  .v1 .product-config__message {
    margin-bottom: 30px;
  }
}
.v1 .product-config__message-icon {
  margin-bottom: 25px;
  height: 50px;
  text-align: center;
}
.v1 .product-config__message-icon:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.v1 .product-config__message-icon >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.v1 .product-config__message-text {
  padding: 0 30px;
  font-size: 14px;
  line-height: 1.36;
  text-align: center;
}
@media (max-width: 767px) {
  .v1 .product-config__message-text {
    padding: 0 15px;
  }
}
@media (min-width: 1000px) {
  .v1 .product-config__thumb {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 220px;
  }
}
.v1 .product-config__thumb img {
  max-width: 100%;
  max-height: 100%;
}
.v1 .product-config__orders {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .product-config__controls {
  position: relative;
  padding-bottom: 10px;
  zoom: 1;
}
.v1 .product-config__controls:before,
.v1 .product-config__controls:after {
  content: "";
  display: table;
}
.v1 .product-config__controls:after {
  clear: both;
}
.v1 .product-config__fields {
  position: relative;
}
@media (max-width: 999px), (min-width: 1000px) and (max-width: 1239px) {
  .v1 .product-config__fields {
    margin-bottom: 20px;
  }
}
.v1 .product-config__add {
  display: block;
  margin-top: 4px;
  position: relative;
  min-height: 29px;
  padding: 5px 0 0 40px;
  font-size: 14px;
  color: #434343;
  text-decoration: none;
}
@media (min-width: 768px) {
  .v1 .product-config__add {
    float: left;
    max-width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .product-config__add {
    max-width: 75%;
  }
}
@media (max-width: 767px) {
  .v1 .product-config__add {
    margin-bottom: 15px;
  }
}
.v1 .product-config__add-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 29px;
  height: 29px;
  border-radius: 50px;
  border: 1px solid #e6e6e6;
  -webkit-transition: 0.2s border-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out;
  transition: 0.2s border-color ease-in-out;
}
.v1 .product-config__add-icon:before,
.v1 .product-config__add-icon:after {
  content: "";
  position: absolute;
  background-color: #e6e6e6;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
.v1 .product-config__add-icon:before {
  top: 7px;
  left: 13px;
  width: 1px;
  height: 13px;
}
.v1 .product-config__add-icon:after {
  width: 13px;
  height: 1px;
  left: 7px;
  top: 13px;
}
.v1 .product-config__add:hover {
  color: #f460a3;
}
.v1 .product-config__add:hover .product-config__add-icon {
  border-color: #f460a3;
}
.v1 .product-config__add:hover .product-config__add-icon:before,
.v1 .product-config__add:hover .product-config__add-icon:after {
  background-color: #f460a3;
}
@media (min-width: 768px) {
  .v1 .product-config__check {
    float: right;
  }
}
.v1 .product-config__row-remove {
  position: absolute;
  left: 100%;
  top: 10px;
  margin-left: 10px;
  font-size: 20px;
  color: #dcdcdd;
  text-decoration: none;
}
@media (max-width: 999px) {
  .v1 .product-config__row-remove {
    display: none;
  }
}
.v1 .product-config__row-remove:hover,
.v1 .product-config__row-remove:active {
  color: #f460a3;
}
@media (min-width: 1000px) {
  .v1 .product-config__slim-wrap {
    margin: 0 auto;
    width: 85%;
  }
}
.v1 .product-config .orders-tiles,
.v1 .product-config .tiles-list {
  margin-top: 55px;
}
@media (max-width: 999px) {
  .v1 .product-config .orders-tiles,
  .v1 .product-config .tiles-list {
    margin-top: 0;
  }
}
.v1 .product-config .form-group_number .form-label {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  color: #898c8e;
}
.v1 .product-config .form-group_number .form-control {
  height: 60px;
  padding: 11px 60px 12px 20px;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid #e7e7e7;
  background-color: #fcfcfc;
}
.v1 .product-config .form-group_number .form-control:focus {
  -webkit-box-shadow: 0 2px 7px 0 rgba(0,0,0,0.05);
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.05);
}
.v1 .product-config .form-group_number .form-control:focus + .form-control-button {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
  pointer-events: auto;
}
.v1 .product-config .form-group_number .form-control-button {
  position: absolute;
  top: 50%;
  right: 8px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  padding: 8px 10px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  pointer-events: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.v1 .product-config__footer {
  padding: 40px 55px;
}
@media (max-width: 999px) {
  .v1 .product-config__footer {
    padding: 20px 15px;
  }
}
.v1 .product-config__footer-text {
  font-size: 16px;
}
.v1 .only-one .product-config__top {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.v1 .only-one .product-config__top .row {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.v1 .only-one .product-config__top .row [class*="col-"] {
  width: auto;
}
.v1 .only-one .product-config__top .row [class*="col-"].col-6 {
  max-width: 100%;
}
.v1 .only-one .product-config__content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
.v1 .product-icons {
  margin-bottom: 30px;
}
.v1 .product-icons__item {
  display: inline-block;
  margin-right: 75px;
}
.v1 .product-icons__item:last-child {
  margin-right: 0;
}
.v1 .product-icons__item-double svg {
  margin-right: 7px;
}
.v1 .product-icons__item-double svg:last-child {
  margin-right: 0;
}
.v1 .product-icons__title {
  display: block;
  padding-top: 15px;
  font-size: 17px;
  color: #434343;
}
.v1 .product-image {
  overflow: hidden;
}
@media (min-width: 768px) {
  .v1 .product-image {
    border-radius: 2px;
  }
}
@media (max-width: 767px) {
  .v1 .product-image {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}
.v1 .product-image img {
  display: block;
  max-width: 100%;
}
.v1 .product-last-reviews__title {
  font-size: 30px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .v1 .display2cols .page__aside,
  .v1 .display2cols .page__content {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .v1 {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (max-width: 999px) {
  .v1 {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.v1 .page__aside {
  float: left;
}
@media (max-width: 767px) {
  .v1 .page__aside {
    float: none;
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -o-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    margin-top: 20px;
  }
}
@media (max-width: 999px) {
  .v1 .page__aside {
    float: none;
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -o-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    margin-top: 20px;
    width: 100%;
  }
}
.v1 .page__content {
  float: left;
}
@media (max-width: 767px) {
  .v1 .page__content {
    float: none;
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -o-box-ordinal-group: 3;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
    margin-bottom: 30px;
  }
}
@media (max-width: 999px) {
  .v1 .page__content {
    float: none;
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -o-box-ordinal-group: 3;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
    margin-bottom: 30px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .v1 .steps {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -o-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
}
@media (max-width: 999px) {
  .v1 .steps {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -o-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    width: auto;
  }
}
@media (max-width: 767px) {
  .v1 .product-info-mobile {
    padding: 10px;
    font-size: 14px;
    margin-bottom: 0;
  }
  .v1 .product-info-mobile .title {
    font-weight: bold;
  }
  .v1 .product-info-mobile ul {
    list-style-type: none;
  }
}
@media (max-width: 999px) {
  .v1 .product-info-mobile {
    padding: 10px;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .v1 .product-info-mobile .title {
    font-weight: bold;
  }
  .v1 .product-info-mobile ul {
    list-style-type: none;
  }
}
#templateBestMatchingModal [class*="col-"] img {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
#templateBestMatchingModal [class*="col-"]:hover img {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
#documentChoicesModal [class*="col-"] img {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
#documentChoicesModal [class*="col-"]:hover img {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.v1 .product-params__title {
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 500;
}
.v1 .product-params__text {
  margin-bottom: 30px;
  font-size: 17px;
  color: #888b8d;
}
.v1 .product-params__text strong {
  color: #555;
}
.v1 .product-params__list {
  margin-bottom: 20px;
}
@media (min-width: 1000px) {
  .v1 .product-params__list-item {
    margin-right: 75px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .product-params__list-item {
    margin-right: 35px;
  }
}
@media (min-width: 768px) {
  .v1 .product-params__list-item {
    display: inline-block;
  }
  .v1 .product-params__list-item:last-child {
    margin-right: 0 !important;
  }
}
@media (max-width: 767px) {
  .v1 .product-params__list-item {
    margin-bottom: 20px;
  }
}
.v1 .product-params__list-image img,
.v1 .product-params__list-image svg {
  margin-right: 7px;
}
.v1 .product-params__list-image img:last-child,
.v1 .product-params__list-image svg:last-child {
  margin-right: 0;
}
.v1 .product-params__list-title {
  margin-top: 15px;
  font-size: 17px;
}
@media (max-width: 767px) {
  .v1 .product-params__list-title {
    margin-top: 5px;
  }
}
.v1 .product-preview {
  padding: 25px 30px;
  background-color: #fff;
  border-radius: 4px;
}
@media (max-width: 999px) {
  .v1 .product-preview {
    padding: 35px 15px 25px;
  }
}
.v1 .product-preview .free-shipping {
  color: #f460a3;
  font-weight: bold;
  font-size: 18px;
}
.v1 .product-preview__list {
  margin: 30px 0 35px;
  font-size: 17px;
}
.v1 .product-preview__list-item:not(:last-child) {
  margin-bottom: 10px;
}
.v1 .product-preview__list-label {
  color: #888b8d;
}
@media (max-width: 767px) {
  .v1 .product-preview__list-label {
    font-size: 14px;
  }
}
.v1 .product-preview__list-value {
  font-weight: 500;
}
.v1 .product-preview__list_simple {
  margin: 0 0 35px;
}
@media (max-width: 767px) {
  .v1 .product-preview__list_simple {
    margin-bottom: 15px;
  }
}
.v1 .product-preview__list_simple .product-preview__list-item {
  margin-bottom: 0;
}
.v1 .product-preview__list_simple .product-preview__list-label {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  color: #434343;
}
@media (max-width: 767px) {
  .v1 .product-preview__list_mobile-view {
    font-size: 14px;
    margin: 0 0 20px;
  }
}
.v1 .product-preview__image {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.v1 .product-preview__image .main-product-image {
  position: relative;
  width: 100%;
  text-align: center;
}
.v1 .product-preview__image .main-product-image .product-preview__image-i {
  max-height: 200px;
}
@media (min-width: 768px) {
  .v1 .product-preview__image .main-product-image {
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}
.display2cols .v1 .product-preview__image .main-product-image .product-preview__image-i {
  max-height: 400px;
}
.v1 .product-preview__image .product-preview__image-zoomable .product-preview__image-i {
  max-height: 80px;
}
@media (max-width: 767px) {
  .v1 .product-preview__image {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -o-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
@media (max-width: 999px) {
  .v1 .product-preview__image {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -o-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.v1 .product-preview__image-i {
  max-width: 100%;
}
.v1 .product-preview__image_md {
  margin-bottom: 15px;
}
.v1 .product-preview__image__img {
  max-width: 100%;
  max-height: 100%;
}
.v1 .product-preview__image__text {
  background: rgba(50,50,50,0.7);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 2;
  font-weight: bold;
  color: #fff;
}
.v1 .product-preview__image-zoomable {
  padding-left: 0;
  list-style-decoration: none;
  list-style-type: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.v1 .product-preview__image-zoomable li {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 25%;
  -ms-flex: 0 1 25%;
  flex: 0 1 25%;
  cursor: pointer;
  padding-right: 10px;
}
.v1 .product-preview__title {
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: bold;
  color: #434343;
}
@media (max-width: 767px) {
  .v1 .product-preview__title {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
@media (max-width: 999px) {
  .v1 .product-preview__title {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
.v1 .product-preview__text {
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 1.57;
}
@media (max-width: 767px) {
  .v1 .product-preview__text {
    font-size: 12px;
  }
}
@media (max-width: 999px) {
  .v1 .product-preview__text {
    font-size: 12px;
  }
}
.v1 .product-preview__text_md {
  font-size: 15px;
  line-height: 1.3;
}
@media (max-width: 767px) {
  .v1 .product-preview__text_md {
    font-size: 14px;
  }
}
@media (max-width: 999px) {
  .v1 .product-preview__text_md {
    font-size: 14px;
  }
}
.v1 .product-preview__text h3 {
  font-size: 15px;
}
@media (max-width: 767px) {
  .v1 .product-preview__text h3 {
    font-size: 14px;
  }
}
@media (max-width: 999px) {
  .v1 .product-preview__text h3 {
    font-size: 14px;
  }
}
.v1 .product-preview__text ul,
.v1 .product-preview__text ol,
.v1 .product-preview__text dl {
  line-height: 1.5em;
  padding-left: 20px;
}
.v1 .product-preview__text li,
.v1 .product-preview__text dt,
.v1 .product-preview__text dd {
  padding-bottom: 15px;
  list-style-type: none;
}
.v1 .product-preview__button {
  display: block;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .product-preview__button {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.v1 .product-preview__button-icon {
  display: inline-block;
  vertical-align: top;
}
@media (min-width: 768px) {
  .v1 .product-preview__button-icon {
    margin: 4px 10px 0 0;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .v1 .product-preview__button-icon {
    margin: 2px 10px 0 0;
  }
}
.v1 .product-preview__button-label {
  font-size: 16px;
}
.v1 .product-preview__button:last-child {
  margin-bottom: 0;
}
.v1 .product-preview__download {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 15px;
}
@media (min-width: 768px) and (max-width: 1239px), (max-width: 767px) {
  .v1 .product-preview__download {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.v1 .product-preview__download-label {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
  padding-top: 5px;
  font-size: 16px;
  line-height: 1.31;
  font-weight: bold;
}
@media (min-width: 768px) and (max-width: 1239px), (max-width: 767px) {
  .v1 .product-preview__download-label {
    -webkit-flex-basis: auto;
    flex-basis: auto;
    margin-bottom: 10px;
  }
}
.v1 .product-preview__download-list {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
  text-align: right;
}
@media (min-width: 768px) and (max-width: 1239px), (max-width: 767px) {
  .v1 .product-preview__download-list {
    -webkit-flex-basis: auto;
    flex-basis: auto;
    -webkit-align-self: flex-start;
    align-self: flex-start;
    -ms-flex-item-align: start;
  }
}
.v1 .product-preview__download-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
  width: 36px;
  height: 70px;
  padding-bottom: 22px;
  text-decoration: none;
}
.v1 .product-preview__download-link:hover .product-preview__download-link-label {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
}
.v1 .product-preview__download-link:hover .product-preview__download-image {
  -webkit-box-shadow: 0 10px 12px rgba(0,0,0,0.06);
  box-shadow: 0 10px 12px rgba(0,0,0,0.06);
}
.v1 .product-preview__download-link-label {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 5px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.no-touchevents .v1 .product-preview__download-link-label {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.v1 .product-preview__download-image {
  display: block;
  width: 36px;
  height: 47px;
  -webkit-transition: 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s box-shadow ease-in-out;
  transition: 0.2s box-shadow ease-in-out;
}
.v1 .product-preview__status {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  border-top: 1px solid #e8e8e8;
  padding-top: 15px;
  font-size: 17px;
}
@media (max-width: 767px) {
  .v1 .product-preview__status {
    font-size: 14px;
  }
}
.v1 .product-preview__status-label {
  width: 40%;
  padding-right: 5px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .v1 .product-preview__status-label {
    width: 50%;
  }
}
.v1 .product-preview__status-text {
  width: 60%;
}
@media (max-width: 767px) {
  .v1 .product-preview__status-text {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .v1 .product-preview_simple .product-preview__wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 15px;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .v1 .product-preview_simple .product-preview__image {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    margin: 0 15px 20px 0;
    width: auto;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
  }
  .v1 .product-preview_simple .product-preview__image-i {
    width: 100%;
  }
  .v1 .product-preview_simple .product-preview__text {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.2;
  }
  .v1 .product-preview_simple .product-preview__text p {
    margin: 0 0 0.5em;
  }
  .v1 .product-preview_simple .product-preview__button {
    padding: 10px 0 0;
    background: none;
    border: 0 none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #f460a3;
  }
}
@media (max-width: 999px) {
  .v1 .product-preview_simple .product-preview__wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 15px;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .v1 .product-preview_simple .product-preview__image {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    margin: 0 15px 20px 0;
    width: auto;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
  }
  .v1 .product-preview_simple .product-preview__image-i {
    width: 100%;
  }
  .v1 .product-preview_simple .product-preview__text {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.2;
  }
  .v1 .product-preview_simple .product-preview__text p {
    margin: 0 0 0.5em;
  }
  .v1 .product-preview_simple .product-preview__button {
    padding: 10px 0 0;
    background: none;
    border: 0 none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #f460a3;
  }
}
.v1 .display2cols .product-preview__image .main-product-image .product-preview__image-i {
  max-height: 400px;
}
.product-tile {
  display: block;
  text-decoration: none;
  -webkit-transition: 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s box-shadow ease-in-out;
  transition: 0.2s box-shadow ease-in-out;
}
.product-tile .gallery-control {
  position: absolute;
  top: 50%;
  left: 5px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 38px;
  height: 38px;
  -webkit-transition: 0.2s width ease-in-out, 0.2s height ease-in-out;
  -moz-transition: 0.2s width ease-in-out, 0.2s height ease-in-out;
  -o-transition: 0.2s width ease-in-out, 0.2s height ease-in-out;
  -ms-transition: 0.2s width ease-in-out, 0.2s height ease-in-out;
  transition: 0.2s width ease-in-out, 0.2s height ease-in-out;
}
.product-tile .gallery-control[data-gallery-control-right] {
  left: inherit;
  right: 5px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.product-tile .gallery-control:hover {
  width: 46px;
  height: 46px;
}
#page-taxon .product-tile {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
#page-taxon .item-advice .product-tile {
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.product-tile:hover {
  -webkit-box-shadow: 0 4px 18px rgba(0,0,0,0.15);
  box-shadow: 0 4px 18px rgba(0,0,0,0.15);
  text-decoration: none;
}
.product-tile:hover .product-tile__body {
  border-color: transparent;
}
.product-tile:hover .product-tile__more,
.product-tile:hover .product-tile__button,
.product-tile:hover .product-tile__fullscreen {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  -ms-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.product-tile__image {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 2px 2px 0 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #d9dbdb;
  border-width: 1px 1px 0;
  border-style: solid;
  border-color: #d9dbdb;
  width: 100%;
  height: 75%;
}
@media (max-width: 767px) {
  #page-taxon .item-advice .product-tile__image {
    height: auto;
  }
}
.product-tile__image-container {
  display: block;
  position: relative;
  line-height: 0;
}
#page-taxon .product-tile__image {
  height: auto;
}
.product-tile__image-gallery {
  padding: 10px 0;
  text-align: center;
  background-color: #fff;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #d9dbdb;
  display: none;
}
.product-tile__badge {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 6px 15px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #f460a3;
  background-color: rgba(255,255,255,0.78);
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.11);
  box-shadow: 0 5px 10px rgba(0,0,0,0.11);
}
.product-tile__body {
  position: relative;
  display: block;
  height: 70px;
  padding: 10px;
  background-color: #fff;
  border-radius: 0 0 2px 2px;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #d9dbdb;
  color: #888b8d;
  -webkit-transition: 0.2s border-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out;
  transition: 0.2s border-color ease-in-out;
}
.product-tile__body_slim {
  padding-right: 90px;
}
@media (max-width: 999px), (min-width: 1000px) and (max-width: 1239px) {
  .product-tile__body_slim {
    padding-right: 10px;
  }
}
#page-taxon .product-tile__body {
  height: auto;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding: 20px 20px 90px;
  font-size: 15px;
}
#page-taxon .product-tile__body h3 {
  text-align: center;
}
#page-taxon .product-tile__body li,
#page-taxon .product-tile__body dt,
#page-taxon .product-tile__body dd {
  padding-bottom: 10px;
  list-style-type: none;
}
#page-taxon .product-tile__body ul {
  padding-left: 0;
}
#page-taxon .product-tile__body .btn.btn-block {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  width: auto;
}
#page-taxon .top-list .item-advice .product-tile__body {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
  padding: 10px;
  font-size: 12px;
}
#page-taxon .top-list .product-tile__body {
  padding: 10px 10px 90px;
}
.product-tile__title {
  display: block;
  font-size: 20px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .product-tile__title {
    margin-top: 5px;
    font-size: 15px;
  }
}
.product-tile__secondline {
  text-align: center;
}
@media (max-width: 767px) {
  .product-tile__secondline p {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.product-tile__label {
  display: block;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0;
}
.homepage-product-list .product-tile__label {
  text-align: center;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
}
#page-taxon .product-tile__label,
#page-creations .product-tile__label {
  font-weight: bold;
  color: #333;
}
#page-taxon .item-advice .product-tile__label {
  color: #888b8d;
  margin-bottom: 5px;
}
.product-tile__button {
  position: absolute;
  top: -20px;
  right: 14px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
@media (max-width: 999px), (min-width: 1000px) and (max-width: 1239px) {
  .product-tile__button {
    display: none;
  }
}
.item-advice .product-tile__button {
  top: inherit;
  bottom: 20px;
}
.product-tile__more {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  text-align: center;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.product-tile__more-link {
  padding: 13px 20px 14px;
}
@media (max-width: 767px) {
  .product-tile__more-link {
    display: none;
  }
}
.product-tile__fullscreen {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px;
  color: #939698;
  text-decoration: none;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  -webkit-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  -o-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
  transition: 0.2s opacity ease-in-out, 0.2s color ease-in-out;
}
.product-tile__fullscreen:hover {
  color: #f460a3;
}
.product-tile_md .product-tile__image {
  height: 205px;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .product-tile_md .product-tile__image {
    height: 155px;
  }
}
@media (max-width: 767px) {
  .product-tile_md .product-tile__image {
    height: 110px;
  }
}
.product-tile_md .product-tile__more {
  bottom: 25px;
}
.product-tile_cover .product-tile__image {
  height: 225px;
  border-radius: 2px;
}
@media (max-width: 767px) {
  .product-tile_cover .product-tile__image {
    height: 0;
    padding-bottom: 100%;
  }
}
.product-tile_cover .product-tile__more {
  bottom: 15px;
}
.product-valider__order {
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .product-valider__order {
    margin-bottom: 15px;
  }
}
.product-valider__title {
  margin: 0 0 20px;
  font-size: 36px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .product-valider__title {
    font-size: 17px;
  }
}
.product-valider__file {
  margin-bottom: 45px;
  font-size: 17px;
}
@media (max-width: 767px) {
  .product-valider__file {
    margin-bottom: 25px;
  }
}
.product-valider__file-title {
  font-weight: 500;
}
.product-valider__file-link {
  display: inline-block;
  position: relative;
  padding-right: 70px;
  font-size: 16px;
  text-decoration: none;
  color: #888b8d;
}
@media (max-width: 767px) {
  .product-valider__file-link {
    font-size: 14px;
  }
}
.product-valider__file-link:hover {
  color: #f460a3;
}
.product-valider__file-image {
  position: absolute;
  top: -30px;
  right: 0;
  width: 72px;
  height: 83px;
  text-align: center;
}
.product-valider__file-image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.product-valider__file-image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.product-valider__tabnav-item {
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .product-valider__tabnav-item {
    margin-bottom: 20px;
    font-size: 17px;
  }
}
.product-valider__check span {
  font-weight: 500;
}
.product-valider__tabgroup {
  zoom: 1;
}
.product-valider__tabgroup:before,
.product-valider__tabgroup:after {
  content: "";
  display: table;
}
.product-valider__tabgroup:after {
  clear: both;
}
@media (max-width: 767px) {
  .product-valider__tabgroup {
    margin-bottom: 25px;
  }
}
.product-valider__tab {
  display: none;
}
.product-valider__text {
  margin-bottom: 20px;
  font-size: 17px;
  color: #888b8d;
  letter-spacing: 0.17pt;
}
@media (max-width: 767px) {
  .product-valider__text {
    font-size: 14px;
  }
}
.product-valider__download-title {
  margin-bottom: 25px;
  font-size: 17px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .product-valider__download-title {
    margin-bottom: 10px;
  }
}
.product-valider__download-file {
  margin-bottom: 20px;
}
.product-valider__download-message {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.product-valider__download-message .form-control {
  height: 120px;
  resize: none;
}
@media (min-width: 768px) {
  .v1 .products-list {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
}
@media (max-width: 767px) {
  .v1 .products-list {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.v1 .products-list__item {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .v1 .products-list__item {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .v1 .products-list__item {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
.promo-banner {
  background-color: #fafaf8;
}
@media (min-width: 768px) {
  .promo-banner {
    margin-bottom: 55px;
  }
}
@media (max-width: 767px) {
  .promo-banner {
    margin-bottom: 15px;
  }
}
.promo-banner__inner {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media (min-width: 1000px) {
  .promo-banner__inner {
    height: 380px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .promo-banner__inner {
    height: 280px;
  }
}
@media (max-width: 767px) {
  .promo-banner__inner {
    height: auto;
  }
}
.promo-banner__aside {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-basis: 55%;
  flex-basis: 55%;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
@media (min-width: 1240px) {
  .promo-banner__aside {
    padding-right: 50px;
  }
}
@media (min-width: 768px) and (max-width: 1239px) {
  .promo-banner__aside {
    padding-right: 20px;
  }
}
@media (max-width: 767px) {
  .promo-banner__aside {
    padding-right: 10px;
  }
}
.promo-banner__image-i {
  display: block;
  max-width: 100%;
}
.promo-banner__content {
  -webkit-flex-basis: 45%;
  flex-basis: 45%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .promo-banner__content {
    padding: 50px 0;
  }
}
@media (max-width: 767px) {
  .promo-banner__content {
    padding: 20px 0;
  }
}
.promo-banner__title {
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 1.375;
  font-weight: 500;
}
@media (min-width: 768px) and (max-width: 999px) {
  .promo-banner__title {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .promo-banner__title {
    font-size: 12px;
    margin-bottom: 15px;
  }
}
.promo-banner__text {
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 1.2;
  color: #888b8d;
}
@media (max-width: 999px) {
  .promo-banner__text {
    display: none;
  }
}
@media (max-width: 767px) {
  .promo-banner__button {
    font-size: 10px;
    padding: 8px 15px;
  }
}
.v1 .quantity-field {
  position: relative;
}
.v1 .quantity-field__label {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  color: #898c8e;
}
@media (max-width: 767px) {
  .v1 .quantity-field__label {
    font-size: 15px;
  }
}
.v1 .quantity-field__input {
  border: 1px solid #e7e7e7;
  background: #fcfcfc;
}
.v1 .quantity-field__input:focus {
  background: #fff;
  -webkit-box-shadow: 0 2px 7px 0 rgba(0,0,0,0.05);
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.05);
}
.v1 .quantity-field__input:focus + .quantity-field__button {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
}
.v1 .quantity-field_layout-2 {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.v1 .quantity-field_layout-2 .quantity-field__wrap {
  width: 165px;
}
@media (max-width: 767px) {
  .v1 .quantity-field_layout-2 .quantity-field__wrap {
    width: auto;
  }
}
.v1 .quantity-field_layout-2 .quantity-field__label {
  margin-right: 20px;
  margin-bottom: 0;
  color: #434343;
  width: 60%;
  text-align: right;
}
.quantity-modal__title {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .quantity-modal__title {
    font-size: 20px;
  }
}
.quantity-modal__text {
  margin-bottom: 40px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .quantity-modal__text {
    margin-bottom: 20px;
    font-size: 20px;
  }
}
.v1 .question-box {
  padding: 35px 25px 40px;
  background-color: #fff;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .v1 .question-box {
    padding: 25px 15px 30px;
    -webkit-box-shadow: 0 4px 31px rgba(106,106,106,0.13);
    box-shadow: 0 4px 31px rgba(106,106,106,0.13);
  }
}
.v1 .question-box__title {
  margin: 0 0 35px;
  font-size: 36px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .question-box__title {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  .v1 .question-box__title {
    margin-bottom: 15px;
    font-size: 18px;
  }
}
.v1 .question-box__form-footer {
  text-align: center;
}
.v1 .question-widget {
  padding: 55px 60px;
  border-radius: 6px;
  background-color: #fff;
  font-size: 16px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .question-widget {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .v1 .question-widget {
    padding: 25px 15px;
  }
}
.v1 .question-widget__title {
  margin: 0 0 20px;
  font-weight: 700;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .question-widget__title {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .v1 .question-widget__title {
    margin: 0 0 10px;
    font-size: 18px;
  }
}
.v1 .question-widget__text {
  margin-bottom: 40px;
  color: #777;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .question-widget__text {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .v1 .question-widget__text {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.2;
  }
}
.v1 .question-widget__buttons {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 0 -10px;
}
.v1 .question-widget__buttons-item {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
  padding: 0 10px;
}
.v1 .question-widget__button {
  display: block;
  width: 100%;
  text-transform: uppercase;
}
.quotation-block-choice-list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 10px -20px;
}
@media (max-width: 999px) {
  .quotation-block-choice-list {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.quotation-block-choice-list input[type="radio"] {
  display: none;
}
.quotation-block-choice-list input[type="radio"]:checked + .choice-item,
.quotation-block-choice-list input[type="radio"] + .choice-item:hover {
  border-color: #f460a3;
}
.quotation-block-choice-list input[type="radio"]:checked + .choice-item .container-diamond,
.quotation-block-choice-list input[type="radio"] + .choice-item:hover .container-diamond {
  color: #f460a3;
}
.quotation-block-choice-list input[type="radio"]:checked + .choice-item .container-diamond svg path,
.quotation-block-choice-list input[type="radio"] + .choice-item:hover .container-diamond svg path {
  fill: #f460a3;
}
.quotation-block-choice-list .choice-item {
  background-color: #e6e6e6;
  cursor: pointer;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 33%;
  -ms-flex: 0 1 33%;
  flex: 0 1 33%;
  border: 1px solid #969696;
  margin: -10px 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 20px;
}
.quotation-block-choice-list .choice-item .title {
  font-weight: bold;
}
.quotation-block-choice-list .choice-item .text-secondary {
  font-size: 14px;
}
.quotation-block-choice-list .choice-item .label-aside img {
  width: 100%;
}
.quotation-block-choice-list .choice-item .label-content {
  float: left;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: start;
  align-items: start;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-top: 15px;
}
@media (max-width: 999px) {
  .quotation-block-choice-list .choice-item {
    margin: 10px 20px;
  }
}
.quotation-block-choice-list .choice-item.choice-item-1 {
  background-image: url("i/q-bg1.png");
  background-image: url("i/q-bg1.png"), -webkit-linear-gradient(#dee1e5, #e1e3e8 50%, #d4d8e0 91%, #bfc6cf 94%, #cdd2d9);
  background-image: url("i/q-bg1.png"), -moz-linear-gradient(#dee1e5, #e1e3e8 50%, #d4d8e0 91%, #bfc6cf 94%, #cdd2d9);
  background-image: url("i/q-bg1.png"), -o-linear-gradient(#dee1e5, #e1e3e8 50%, #d4d8e0 91%, #bfc6cf 94%, #cdd2d9);
  background-image: url("i/q-bg1.png"), -ms-linear-gradient(#dee1e5, #e1e3e8 50%, #d4d8e0 91%, #bfc6cf 94%, #cdd2d9);
  background-image: url("i/q-bg1.png"), linear-gradient(#dee1e5, #e1e3e8 50%, #d4d8e0 91%, #bfc6cf 94%, #cdd2d9);
}
.quotation-block-choice-list .choice-item.choice-item-2 {
  background-image: url("i/q-bg2.png");
  background-image: url("i/q-bg2.png"), -webkit-linear-gradient(#e0e3e7, #e4e7eb 40%, #e0e3e9 70%, #d4d8de 85%, #d4d8de 100%);
  background-image: url("i/q-bg2.png"), -moz-linear-gradient(#e0e3e7, #e4e7eb 40%, #e0e3e9 70%, #d4d8de 85%, #d4d8de 100%);
  background-image: url("i/q-bg2.png"), -o-linear-gradient(#e0e3e7, #e4e7eb 40%, #e0e3e9 70%, #d4d8de 85%, #d4d8de 100%);
  background-image: url("i/q-bg2.png"), -ms-linear-gradient(#e0e3e7, #e4e7eb 40%, #e0e3e9 70%, #d4d8de 85%, #d4d8de 100%);
  background-image: url("i/q-bg2.png"), linear-gradient(#e0e3e7, #e4e7eb 40%, #e0e3e9 70%, #d4d8de 85%, #d4d8de 100%);
}
.quotation-block-choice-list .choice-item.choice-item-2 ul {
  padding-left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style-position: inside;
}
.quotation-block-choice-list .choice-item.choice-item-2 ul li {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}
@media (max-width: 999px) {
  .quotation-block-choice-list .choice-item.choice-item-2 ul li {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
.quotation-block-choice-list.list-2 .choice-item {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
  display: block;
  margin: 0 20px;
  position: relative;
  padding-bottom: 50px;
  background-position: bottom right;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 999px) {
  .quotation-block-choice-list.list-2 .choice-item {
    font-size: 14px;
    margin: 10px 20px;
  }
}
.quotation-block-choice-list.list-2 .choice-item .container-diamond {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 30px;
}
.quotation-block-choice-list.list-2 .choice-item .container-diamond .aside {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 80px;
  -ms-flex: 0 1 80px;
  flex: 0 1 80px;
  margin-right: 10px;
}
.quotation-block-choice-list.list-2 .choice-item .container-diamond .body {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-transform: uppercase;
}
.quotation-block-choice-list.list-2 .choice-item a.btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.v1 .quotation-chat {
  padding: 15px;
}
.v1 .quotation-chat .item {
  background-color: #fff;
  margin: 15px 0;
  padding: 15px;
}
.v1 .quotation-chat .item.customer {
  border-left: 2px solid #333;
}
.v1 .quotation-chat .item.admin {
  border-left: 2px solid #f460a3;
}
.v1 .quotation-chat .item .message {
  display: block;
}
.v1 .block.block-table.quotations .col-1 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 10%;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
}
.v1 .block.block-table.quotations .col-2,
.v1 .block.block-table.quotations .col-3,
.v1 .block.block-table.quotations .col-4,
.v1 .block.block-table.quotations .col-5,
.v1 .block.block-table.quotations .col-6 {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 18%;
  -ms-flex: 0 0 18%;
  flex: 0 0 18%;
}
.v1 .block.block-table.quotations .table {
  border-bottom-width: 1px;
}
.v1 .radio-buttons__choices {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.v1 .radio-buttons__choices.has-error {
  border: 1px solid #f460a3;
}
.v1 .radio-buttons__choices .svg {
  position: relative;
}
.v1 .radio-buttons__choices input[type="radio"]:checked+.radio-buttons__item .inner {
  border: 1px solid #f460a3;
}
.v1 .radio-buttons__choices input[type="radio"]:checked+.radio-buttons__item .check-link {
  display: block;
  width: 38px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.v1 .radio-buttons__choices input[type="radio"]:checked+.radio-buttons__item .radio-buttons__item-label {
  background-color: #fff;
  color: #434343;
}
.v1 .radio-buttons__choices input[type="checkbox"]:checked+.radio-buttons__item .inner {
  border: 1px solid #f460a3;
}
.v1 .radio-buttons__choices input[type="checkbox"]:checked+.radio-buttons__item .check-link {
  display: block;
  width: 38px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.v1 .radio-buttons__choices input[type="checkbox"]:checked+.radio-buttons__item .radio-buttons__item-label {
  background-color: #fff;
  color: #434343;
}
.v1 .radio-buttons__choices .radio-buttons__item {
  text-align: center;
  display: inline-block;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 33%;
  -ms-flex: 1 1 33%;
  flex: 1 1 33%;
}
.v1 .radio-buttons__choices .radio-buttons__item .inner {
  padding: 7px;
  margin-right: 7px;
  height: 100%;
  border: 1px solid #e6e6e6;
}
.v1 .radio-buttons__choices .radio-buttons__item:not(:last-child) {
  margin-right: 0px;
}
.v1 .radio-buttons__choices .radio-buttons__item:last-child {
  margin-right: 0px;
}
.v1 .radio-buttons__choices .radio-buttons__item:last-child .inner {
  margin-right: 0;
}
.v1 .radio-buttons__choices .radio-buttons__item-label {
  min-width: 0;
  display: inline-block;
  padding: 0;
}
.v1 .radio-buttons.nps-rating {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media (max-width: 999px) {
  .v1 .radio-buttons.nps-rating {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) {
  .v1 .radio-buttons_align-right {
    text-align: right;
  }
}
.v1 .radio-buttons__label {
  display: inline-block;
  vertical-align: top;
  padding-top: 11px;
  margin-right: 10px;
  font-weight: 500;
}
.v1 .radio-buttons__item {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.v1 .radio-buttons__item:not(:last-child) {
  margin-right: 7px;
}
.v1 .radio-buttons__item-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  cursor: pointer;
}
.v1 .radio-buttons__item-input:checked + span {
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  box-shadow: 0 5px 10px rgba(0,0,0,0.12);
}
.v1 .radio-buttons__item-label {
  display: block;
  padding: 13px 15px;
  min-width: 80px;
  border-color: #e6e6e6;
  font-size: 16px;
  color: #c1c1c1;
  border-radius: 2px;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.v1 .nps-rating .radio-buttons__item {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
@media (max-width: 999px) {
  .v1 .nps-rating .radio-buttons__item {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    -o-box-flex: 0;
    -ms-box-flex: 0;
    box-flex: 0;
    -webkit-flex-grow: 0;
    flex-grow: 0;
  }
}
.v1 .nps-rating .radio-buttons__item-input {
  display: none;
}
.v1 .customer-type-request .radio-buttons__item-input {
  display: none;
}
.v1 .quotation-pricings .radio-buttons__item {
  cursor: pointer;
}
.v1 .quotation-pricings .radio-buttons__item-input {
  display: none;
}
.v1 .nps-rating.radio-buttons .radio-buttons__item-label {
  min-width: 0;
  padding: 12px 10px 8px;
  color: #333;
}
.v1 input[type="radio"]:checked + .radio-buttons__item-label {
  color: #fff;
}
input[type="radio"]:checked+.radio-buttons__item .radio-buttons__item-label,
input[type="checkbox"]:checked+.radio-buttons__item .radio-buttons__item-label {
  color: #fff;
  border-color: #f460a3;
  background-color: #f460a3;
}
.v1 .radio-group {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .radio-group_small {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -o-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
}
.v1 .radio-group_small .radio-group__label {
  padding: 6px 10px;
  font-size: 14px;
}
.v1 .radio-group_small .radio-group__wrap {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.v1 .radio-group__title {
  margin-right: 20px;
  font-size: 16px;
  color: #888b8d;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .radio-group__title {
    display: none;
  }
}
@media (max-width: 767px) {
  .v1 .radio-group__title {
    font-size: 14px;
    width: 40%;
    text-align: right;
  }
}
.v1 .radio-group__wrap {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #ddd;
  border-radius: 100px;
  border: 1px solid #fde360;
  background-color: #fff5c6;
}
.v1 .radio-group__item {
  display: inline-block;
  vertical-align: top;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-bottom: 0;
}
.v1 .radio-group__input {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.v1 .radio-group__input:checked + .radio-group__label {
  color: #555;
}
.v1 .radio-group__input:checked + .radio-group__label:before {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.v1 .radio-group__input.checked + .radio-group__label {
  color: #555;
}
.v1 .radio-group__input.checked + .radio-group__label:before {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.v1 .radio-group__label {
  display: block;
  position: relative;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #949596;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .radio-group__label {
    padding: 5px 15px;
  }
}
.v1 .radio-group__label:hover {
  color: #f460a3;
}
.v1 .radio-group__label:before {
  content: "";
  position: absolute;
  left: -10px;
  right: -10px;
  top: -1px;
  bottom: -1px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  border-radius: 100px;
  background-color: #fff;
  -webkit-box-shadow: 0 4px 31px rgba(105,105,105,0.12);
  box-shadow: 0 4px 31px rgba(105,105,105,0.12);
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.v1 .radio-group__label-inner {
  display: block;
  position: relative;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .radio-group__label-inner {
    font-size: 15px;
  }
}
.v1 .rating {
  color: #333;
}
.v1 .rating_small .rating__title {
  margin-bottom: 5px;
}
.v1 .rating_small .rating__stars {
  width: 165px;
  height: 26px;
}
.v1 .rating_small .rating__stars-bg,
.v1 .rating_small .rating__stars-overlay {
  -webkit-background-size: 330px 26px;
  -moz-background-size: 330px 26px;
  background-size: 330px 26px;
}
.v1 .rating_small .rating__stars-overlay {
  background-position: -165px 0;
}
.v1 .rating__value {
  margin-bottom: 5px;
  font-size: 40px;
  line-height: 1.2;
  font-weight: 500;
}
@media (max-width: 767px) {
  .v1 .rating__value {
    margin-bottom: 0;
    font-size: 20px;
  }
}
.v1 .rating__title {
  margin-bottom: 20px;
  font-size: 20px;
}
@media (max-width: 767px) {
  .v1 .rating__title {
    margin-bottom: 5px;
    font-size: 15px;
  }
}
.v1 .rating__stars {
  position: relative;
  display: inline-block;
  width: 210px;
  height: 33px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .rating__stars {
    width: 166px;
    height: 26px;
  }
}
@media (max-width: 767px) {
  .v1 .rating__stars {
    width: 105px;
    height: 17px;
  }
}
.v1 .rating__stars-bg,
.v1 .rating__stars-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("i/stars.svg");
  background-repeat: no-repeat;
  -webkit-background-size: 420px 33px;
  -moz-background-size: 420px 33px;
  background-size: 420px 33px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .rating__stars-bg,
  .v1 .rating__stars-overlay {
    -webkit-background-size: 332px 26px;
    -moz-background-size: 332px 26px;
    background-size: 332px 26px;
  }
}
@media (max-width: 767px) {
  .v1 .rating__stars-bg,
  .v1 .rating__stars-overlay {
    -webkit-background-size: 210px 17px;
    -moz-background-size: 210px 17px;
    background-size: 210px 17px;
  }
}
.v1 .rating__stars-bg {
  background-position: 0 0;
}
.v1 .rating__stars-overlay {
  background-position: -210px 0;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .rating__stars-overlay {
    background-position: -166px 0;
  }
}
@media (max-width: 767px) {
  .v1 .rating__stars-overlay {
    background-position: -105px 0;
  }
}
.registration-box__wrap {
  margin: 0 auto;
}
@media (min-width: 1000px) {
  .registration-box__wrap {
    width: 770px;
  }
  .nps-survey .registration-box__wrap {
    width: 650px;
  }
}
.registration-box__footer {
  background-color: #fff;
}
.registration-box__header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .registration-box__header {
    margin-bottom: 10px;
  }
}
.registration-box__title {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin: 0;
  font-size: 30px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .registration-box__title {
    font-size: 17px;
  }
}
.registration-box__step {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-top: 15px;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  color: #343434;
}
.registration-box__step a {
  color: #f460a3;
}
@media (max-width: 767px) {
  .registration-box__step {
    padding-top: 2px;
    font-size: 14px;
  }
}
.registration-box__form {
  margin-bottom: 70px;
  padding: 45px 40px 50px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 6px rgba(115,115,115,0.33);
  box-shadow: 0 2px 6px rgba(115,115,115,0.33);
}
.registration-box__form .nps-question-recommend {
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .registration-box__form {
    margin-bottom: 20px;
    padding: 15px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.registration-box__form-title {
  margin: 0 0 20px;
  font-size: 30px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .registration-box__form-title {
    font-size: 17px;
  }
}
.nps-survey .registration-box__form-title {
  color: #f460a3;
  text-align: center;
}
.registration-box__form-section {
  margin-bottom: 30px;
}
.landing-page.nps .registration-box__form-section {
  margin-bottom: 0;
}
.registration-box__form-subtitle {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .registration-box__form-subtitle {
    font-size: 15px;
  }
}
.landing-page.nps .registration-box__form {
  margin-top: 70px;
  padding-bottom: 30px;
  border-radius: 5px;
}
.registration-box__form .customer-type-request .check-link {
  display: none;
}
.registration-box__footer {
  padding: 25px 0;
}
@media (max-width: 767px) {
  .registration-box__footer {
    padding: 25px 15px;
  }
}
.registration-box__footer .registration-box__wrap {
  text-align: center;
}
@media (min-width: 768px) {
  .registration-box__button {
    min-width: 270px;
  }
}
@media (max-width: 767px) {
  .registration-box__button {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .registration-box__message {
    display: inline-block;
    vertical-align: middle;
    padding-top: 2px;
    margin-left: 50px;
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .registration-box__message {
    font-size: 14px;
    text-align: center;
  }
}
.registration-box__message sup {
  top: 0;
  margin-right: 5px;
  font-size: 16px;
  color: #bfbfbf;
}
.registration-box__message-text {
  color: #f460a3;
}
.registration-box__info {
  margin-bottom: 15px;
  font-size: 16px;
  color: #a4a7a8;
  cursor: default;
}
.registration-box__info-inner {
  display: inline-block;
  position: relative;
  padding-right: 30px;
}
.registration-box__info-inner:after {
  content: "\e913";
  position: absolute;
  top: 0;
  right: 0;
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
  color: #03a9f4;
}
.registration-box__info-inner-left-14 {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  padding-right: 0;
}
.registration-box__info-inner-left-14:after {
  content: "\e913";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #03a9f4;
}
.registration-box__info-inner-alt {
  display: inline-block;
  position: relative;
  padding-right: 30px;
}
.registration-box__info-inner-alt:after {
  content: "\e913";
  position: absolute;
  left: 4px;
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
  color: #03a9f4;
}
.reinsurance-sidebar {
  position: absolute;
  top: 40px;
  left: -70px;
}
.reinsurance-sidebar .picto {
  width: 72px;
  height: 72px;
  display: block;
  margin-bottom: 10px;
}
.reinsurance-sidebar .picto:last {
  margin-bottom: 0;
}
.reinsurance-sidebar .picto img {
  width: 72px;
  height: 72px;
}
@media (max-width: 767px) {
  .reinsurance-sidebar {
    display: none;
  }
}
@media (max-width: 999px) {
  .reinsurance-sidebar {
    display: none;
  }
}
.report-modal__order {
  margin-bottom: 25px;
}
.report-modal__title {
  margin: 0 0 25px;
  font-size: 36px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .report-modal__title {
    font-size: 18px;
  }
}
.report-modal__section-title {
  margin: 10px 0 15px;
  font-size: 17px;
  font-weight: bold;
}
.report-modal .control-label-vertical {
  font-size: 14px;
  color: #888b8d;
  font-weight: normal;
}
.request-modal__label {
  margin-bottom: 10px;
  font-size: 17px;
}
@media (max-width: 767px) {
  .request-modal__label {
    font-size: 15px;
  }
}
.request-modal__label-value {
  font-size: 16px;
  font-weight: bold;
}
.request-modal__title {
  margin-bottom: 45px;
  font-size: 30px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .request-modal__title {
    margin-bottom: 15px;
    font-size: 18px;
  }
}
.request-modal__subtitle {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .request-modal__subtitle {
    font-size: 15px;
  }
}
.request-modal__content {
  margin-bottom: 60px;
}
@media (max-width: 767px) {
  .request-modal__content {
    margin-bottom: 40px;
  }
}
.request-modal__link {
  display: inline-block;
  position: relative;
  padding-right: 80px;
  font-size: 16px;
  text-decoration: none;
  color: #434343;
}
@media (max-width: 767px) {
  .request-modal__link {
    font-size: 15px;
  }
}
.request-modal__link:hover {
  color: #f460a3;
}
.request-modal__link-image {
  position: absolute;
  top: -15px;
  right: -20px;
  width: 72px;
  height: 83px;
  text-align: center;
}
.request-modal__link-image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.request-modal__link-image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.request-modal__box {
  padding: 15px 25px 25px;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  line-height: 1.3;
}
@media (max-width: 767px) {
  .request-modal__box {
    padding: 15px;
    font-size: 15px;
    border-radius: 2px;
    border-color: #e8e8e8;
  }
}
.request-modal__box p:last-child {
  margin-bottom: 0;
}
.review {
  background-color: #fff;
}
@media (max-width: 767px) {
  .review {
    padding: 20px 0;
  }
}
@media (max-width: 999px) {
  .review {
    padding: 20px 0;
  }
}
@media (min-width: 1000px) {
  .review__body {
    float: left;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    width: 70%;
    padding-right: 50px;
  }
}
@media (max-width: 999px) {
  .review__body {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (max-width: 767px) {
  .review__body {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    padding-right: 0;
  }
}
@media (min-width: 1000px) {
  .review__aside {
    float: left;
    width: 30%;
    padding: 0px 0 30px 30px;
    border-left: 1px solid #dcdcdd;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .review__aside {
    margin-top: 0px;
  }
}
@media (max-width: 999px) {
  .review__aside {
    padding-top: 10px;
    text-align: center;
  }
}
.review__image {
  text-align: center;
  width: 155px;
  height: 155px;
  overflow: hidden;
  border-radius: 100px;
  background-color: #fff;
  -webkit-box-shadow: 0 4px 15px rgba(0,0,0,0.2);
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
}
.review__image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.review__image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
@media (min-width: 1000px) {
  .review__image {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 0 155px;
    -ms-flex: 0 0 155px;
    flex: 0 0 155px;
    margin-top: 5px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .review__image {
    width: 110px;
    height: 110px;
    margin: 0 auto 20px;
  }
}
@media (max-width: 999px) {
  .review__image {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    height: auto;
    width: 25%;
    -webkit-align-self: center;
    align-self: center;
    -ms-flex-item-align: center;
  }
}
@media (max-width: 767px) {
  .review__image {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    width: 25%;
    height: auto;
    -webkit-align-self: center;
    align-self: center;
    -ms-flex-item-align: center;
  }
}
@media (min-width: 1000px) {
  .review__content {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    padding-left: 45px;
  }
}
@media (max-width: 999px) {
  .review__content {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    padding-left: 25px;
  }
}
@media (max-width: 767px) {
  .review__content {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    padding-left: 25px;
  }
}
.review__title {
  margin: 0 0 10px;
  font-size: 36px;
  line-height: 1.333;
  font-weight: 500;
  color: #333;
}
@media (min-width: 768px) and (max-width: 999px) {
  .review__title {
    font-size: 34px;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .review__title {
    font-size: 17px;
    margin: 0;
  }
}
.review__label {
  margin-bottom: 20px;
  font-size: 20px;
  color: #888b8d;
}
@media (max-width: 767px) {
  .review__label {
    margin-bottom: 5px;
    font-size: 13px;
  }
}
.review__text {
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 1.2;
  color: #4f5559;
}
@media (min-width: 768px) and (max-width: 999px) {
  .review__text {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .review__text {
    margin-bottom: 20px;
    font-size: 14px;
  }
}
.review__button {
  color: #f460a3;
  -webkit-box-shadow: 0 16px 30px rgba(111,111,111,0.19);
  box-shadow: 0 16px 30px rgba(111,111,111,0.19);
}
.review .container {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 767px) {
  .review .container {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 999px) {
  .review .container {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .samples-box__title {
    margin-bottom: 30px;
    font-size: 18px;
  }
}
.samples-box__content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .samples-box__content {
    display: block;
  }
}
.samples-box__content-aside {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 175px;
  -ms-flex: 0 0 175px;
  flex: 0 0 175px;
  padding-right: 25px;
}
@media (max-width: 767px) {
  .samples-box__content-aside {
    padding-right: 0;
  }
}
.samples-box__content-image {
  width: 150px;
  text-align: center;
}
.samples-box__content-image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.samples-box__content-image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 767px) {
  .samples-box__content-image {
    margin: 0 auto;
  }
}
.samples-box__content-text {
  font-size: 17px;
  color: #888b8d;
}
@media (max-width: 767px) {
  .samples-box__content-text {
    font-size: 14px;
  }
}
.samples-box__status {
  margin-bottom: 12px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .samples-box__status {
    font-size: 14px;
  }
}
.samples-box__status-value {
  font-weight: bold;
}
.samples-box__panel {
  border: 1px solid #d7d7d7;
  border-radius: 8px;
}
@media (max-width: 767px) {
  .samples-box__panel {
    margin-left: -15px;
    margin-right: -15px;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0;
  }
}
.samples-box__panel-header {
  position: relative;
  padding: 20px 90px 20px 20px;
  background-color: #fcfcfc;
  border-radius: 8px 8px 0 0;
}
@media (max-width: 767px) {
  .samples-box__panel-header {
    padding: 30px 90px 30px 15px;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0;
  }
}
.samples-box__panel-edit {
  position: absolute;
  top: 11px;
  right: 20px;
  width: 49px;
  height: 49px;
  padding: 8px 0 0;
  font-size: 20px;
  border-radius: 100px;
}
@media (max-width: 767px) {
  .samples-box__panel-edit {
    top: 16px;
  }
}
.samples-box__panel-title {
  font-size: 20px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .samples-box__panel-title {
    font-size: 15px;
  }
}
.samples-box__panel-body {
  padding: 25px 20px;
}
.samples-box__list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 0 -20px;
  line-height: 1.38;
}
@media (max-width: 767px) {
  .samples-box__list {
    display: block;
  }
}
.samples-box__list-item {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .samples-box__list-item {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .samples-box__list-item:last-child {
    margin-bottom: 0;
  }
}
.samples-box__list a {
  text-decoration: none;
}
.samples-box__list a:hover {
  color: #f460a3;
}
.saved-modal__header {
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .saved-modal__header {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
.saved-modal__header-title {
  font-size: 20px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .saved-modal__header-title {
    font-size: 18px;
  }
}
.saved-modal__header-download {
  font-size: 17px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .saved-modal__header-download {
    display: none;
  }
}
.saved-modal__list {
  padding: 25px 50px;
}
@media (max-width: 767px) {
  .saved-modal__list {
    padding: 15px;
  }
}
.saved-modal__item {
  zoom: 1;
}
.saved-modal__item:before,
.saved-modal__item:after {
  content: "";
  display: table;
}
.saved-modal__item:after {
  clear: both;
}
@media (min-width: 768px) {
  .saved-modal__item {
    padding: 14px 50px;
    margin-right: -50px;
    margin-left: -50px;
  }
}
@media (max-width: 767px) {
  .saved-modal__item {
    padding: 5px 15px;
    margin-right: -15px;
    margin-left: -15px;
  }
}
.saved-modal__item:nth-child(even) {
  background-color: #fcfcfc;
}
.saved-modal__file {
  float: left;
  width: 80%;
  font-size: 17px;
  color: #333;
  text-decoration: none;
}
.saved-modal__file-image {
  float: left;
}
.saved-modal__file-icon {
  margin: -5px 0 -20px -20px;
  width: 72px;
  height: 83px;
}
@media (max-width: 767px) {
  .saved-modal__file-icon {
    width: 65px;
  }
}
.saved-modal__file-icon img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
.saved-modal__file-content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 5px;
  height: 46px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .saved-modal__file-content {
    margin-bottom: 10px;
  }
}
.saved-modal__file-name {
  position: relative;
  display: inline-block;
  padding-right: 35px;
  text-decoration: none;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 100%;
}
@media (max-width: 767px) {
  .saved-modal__file-name {
    font-size: 13px;
  }
}
.saved-modal__file-name:hover {
  color: #f460a3;
}
.saved-modal__file-name:after {
  position: absolute;
  top: -3px;
  right: 0;
  content: "\e90d";
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 26px;
  color: #dcdcdd;
}
.saved-modal__file-text {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 100%;
}
@media (max-width: 767px) {
  .saved-modal__file-text {
    font-size: 12px;
  }
}
.saved-modal__file-text a {
  color: #f460a3;
  text-decoration: none;
}
.saved-modal__file-text a:hover {
  color: #434343;
}
.saved-modal__date {
  float: left;
  width: 20%;
  padding-top: 15px;
  font-size: 17px;
  text-align: right;
}
@media (max-width: 767px) {
  .saved-modal__date {
    padding-top: 20px;
    font-size: 12px;
  }
}
.search-field {
  position: relative;
}
@media (max-width: 767px) {
  .search-field .form-control {
    border: 0;
    border-radius: 0;
  }
}
.search-field.active .search-field__icon {
  color: #333;
}
.search-field.active .search-field__button {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
  pointer-events: auto;
}
@media (max-width: 999px) {
  .search-field.active .search-field__cancel {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    visibility: visible;
    pointer-events: auto;
  }
}
.search-field__input {
  border-radius: 3px;
  padding-left: 60px;
  padding-right: 60px;
  font-size: 18px;
}
@media (max-width: 999px) {
  .search-field__input {
    padding-left: 85px;
  }
}
@media (max-width: 767px) {
  .search-field__input {
    padding-right: 70px;
  }
}
.search-field__input:focus {
  border: 1px solid #e7e7e7;
  -webkit-box-shadow: 0 5px 19px 0 rgba(0,0,0,0.05);
  box-shadow: 0 5px 19px 0 rgba(0,0,0,0.05);
}
@media (max-width: 767px) {
  .search-field__input:focus {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.search-field__cancel {
  display: none;
  position: absolute;
  top: 15px;
  left: 17px;
  height: 18px;
  width: 18px;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
@media (max-width: 999px) {
  .search-field__cancel {
    display: block;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    visibility: hidden;
    pointer-events: none;
  }
}
.search-field__cancel:after,
.search-field__cancel:before {
  content: "";
  position: absolute;
  top: 8px;
  width: 100%;
  height: 2px;
  background-color: #4f5559;
}
.search-field__cancel:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.search-field__cancel:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.search-field__button {
  position: absolute;
  right: 4px;
  top: 4px;
  padding: 8px 11px 7px !important;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  pointer-events: none;
  font-size: 18px;
  font-weight: normal;
  border-radius: 4px;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
@media (max-width: 767px) {
  .search-field__button {
    top: 6px;
    right: 15px;
  }
}
.search-field__icon {
  position: absolute;
  left: 25px;
  top: 16px;
  padding: 0;
  margin: 0;
  font-size: 16px;
  background: none;
  border: 0 none;
  outline: 0 none;
  color: #a5a5a5;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
@media (max-width: 999px) {
  .search-field__icon {
    left: 52px;
  }
}
@media (max-width: 767px) {
  .search-field__icon {
    top: 14px;
    font-size: 19px;
  }
}
.search-field_no-border .search-field__input {
  border: 0 none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (max-width: 767px) {
  .search-field {
    margin: 0 15px 10px;
  }
}
.search-field_big .search-field__input {
  height: 70px;
  padding: 22px 25px 23px 50px;
  font-size: 18px;
}
@media (max-width: 767px) {
  .search-field_big .search-field__input {
    padding: 14px 15px 14px 43px;
    height: 48px;
    font-size: 17px;
  }
}
.search-field_big .search-field__icon {
  top: 26px;
  left: 15px;
}
@media (max-width: 767px) {
  .search-field_big .search-field__icon {
    top: 14px;
  }
}
.search-field_big .search-field__button {
  left: 0;
  top: 10px;
  font-size: 20px;
}
@media (max-width: 767px) {
  .search-field_big .search-field__button {
    left: 3px;
    top: 1px;
    font-size: 18px;
  }
}
.search-field .twitter-typeahead {
  display: block !important;
}
.search-field .tt-menu {
  top: 51px !important;
  right: 0;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  -webkit-box-shadow: 0 9px 21px 0 rgba(0,0,0,0.14);
  box-shadow: 0 9px 21px 0 rgba(0,0,0,0.14);
}
@media (max-width: 767px) {
  .search-field .tt-menu {
    top: 49px !important;
    left: -1px !important;
  }
}
.search-field .tt-cursor {
  background-color: #fbfbfb;
}
.search-field .tt-suggestion {
  display: block;
  font-size: 18px;
  color: #4c4c4c;
  font-weight: normal;
  padding: 14px 20px 14px 60px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  -webkit-transition: background-color 0.1s ease-in-out;
  -moz-transition: background-color 0.1s ease-in-out;
  -o-transition: background-color 0.1s ease-in-out;
  -ms-transition: background-color 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out;
  text-decoration: none;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@media (max-width: 999px) {
  .search-field .tt-suggestion {
    padding-left: 85px;
  }
}
.search-field .tt-suggestion:last-child {
  border-bottom: 0;
}
.search-field .tt-suggestion:hover {
  background-color: #fbfbfb;
  text-decoration: none;
}
.top-list .search-list-item {
  -webkit-flex-basis: 25%;
  flex-basis: 25%;
  margin-bottom: 30px;
}
@media (max-width: 999px) {
  .top-list .search-list-item {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
}
.top-list .search-list-item-image {
  border: 1px solid #d9dbdb;
  border-radius: 0 0 2px 2px;
  border-bottom-width: 0;
}
.top-list .search-list-item-content {
  background-color: #fff;
  border: 1px solid #d9dbdb;
  border-radius: 0 0 2px 2px;
  border-top-width: 0;
  color: #888b8d;
  display: block;
  height: 148px;
  padding: 10px;
  position: relative;
  -webkit-transition: 0.2s border-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out;
  transition: 0.2s border-color ease-in-out;
}
.top-list .search-list-item-content-title {
  display: -webkit-box;
  font-size: 20px;
  font-weight: 500;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
@media (max-width: 767px) {
  .top-list .search-list-item-content-title {
    font-size: 15px;
    margin-top: 5px;
  }
}
.top-list .search-list-item-content-footer {
  bottom: 16px;
  position: absolute;
  left: 10px;
  right: 10px;
}
.top-list .search-list-item-content .search-list-item-content-footer a {
  color: #fff;
}
@media (max-width: 767px) {
  .top-list .search-list-item-content-desc {
    display: none;
  }
}
.chosen-container {
  width: 100% !important;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.chosen-container * {
  -webkit-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.chosen-container .chosen-drop {
  position: absolute;
  top: 100%;
  z-index: 1010;
  width: 100%;
  border: 1px solid #aaa;
  border-top: 0;
  background: #fff;
  -webkit-box-shadow: 0 4px 5px rgba(0,0,0,0.15);
  -webkit-box-shadow: 0 4px 5px rgba(0,0,0,0.15);
  box-shadow: 0 4px 5px rgba(0,0,0,0.15);
  display: none;
}
.chosen-container.chosen-with-drop .chosen-drop {
  display: block;
}
.chosen-container a {
  cursor: pointer;
}
.chosen-container .search-choice .group-name,
.chosen-container .chosen-single .group-name {
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-weight: normal;
  color: #999;
}
.chosen-container .search-choice .group-name:after,
.chosen-container .chosen-single .group-name:after {
  content: ":";
  padding-left: 2px;
  vertical-align: top;
}
.chosen-container-single .chosen-single {
  width: 100%;
  height: 48px;
  padding: 11px 60px 12px;
  font-size: 17px;
  line-height: 1.4;
  color: #494949;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  border-radius: 2px;
  -webkit-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  outline: 0 none;
  -webkit-appearance: none;
  position: relative;
  display: block;
  overflow: hidden;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
  text-decoration: none;
  white-space: nowrap;
}
.chosen-container-single .chosen-single input[type="text"] {
  cursor: pointer;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
  width: 0;
}
.chosen-container-single .chosen-default {
  color: #999;
}
.chosen-container-single .chosen-single span {
  display: block;
  overflow: hidden;
  margin-right: 26px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chosen-container-single .chosen-single-with-deselect span {
  margin-right: 38px;
}
.chosen-container-single .chosen-single abbr {
  position: absolute;
  top: 6px;
  right: 26px;
  display: block;
  width: 12px;
  height: 12px;
  background: url("chosen-sprite.png") -42px 1px no-repeat;
  font-size: 1px;
}
.chosen-container-single .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single .chosen-single div {
  position: absolute;
  left: 25px;
  top: 15px;
  display: block;
  width: 18px;
}
.chosen-container-single .chosen-single div b {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 16px;
  background: none;
  border: 0 none;
  outline: 0 none;
  color: #a5a5a5;
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.chosen-container-single .chosen-single div b:before {
  content: "\e918";
}
.chosen-container-single .chosen-search {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 3px 4px;
  white-space: nowrap;
}
.chosen-container-single .chosen-search input[type="text"] {
  margin: 1px 0;
  padding: 4px 20px 4px 5px;
  width: 100%;
  height: auto;
  outline: 0;
  border: 1px solid #aaa;
  background: url("chosen-sprite.png") no-repeat 100% -20px;
  font-size: 1em;
  line-height: normal;
  border-radius: 0;
}
.chosen-container-single .chosen-drop {
  margin-top: -1px;
  border-radius: 0 0 4px 4px;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.chosen-container-single.chosen-container-single-nosearch .chosen-search {
  position: absolute;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  pointer-events: none;
}
.chosen-container .chosen-results {
  color: #444;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;
}
.chosen-container .chosen-results li {
  display: none;
  margin: 0;
  padding: 5px 6px;
  list-style: none;
  word-wrap: break-word;
  -webkit-touch-callout: none;
}
.chosen-container .chosen-results li.active-result {
  display: list-item;
  cursor: pointer;
}
.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: #ccc;
  cursor: default;
}
.chosen-container .chosen-results li.highlighted {
  background-color: #f460a3;
  color: #fff;
}
.chosen-container .chosen-results li.no-results {
  color: #777;
  display: list-item;
  background: #f4f4f4;
}
.chosen-container .chosen-results li.group-result {
  display: list-item;
  font-weight: bold;
  cursor: default;
}
.chosen-container .chosen-results li.group-option {
  padding-left: 15px;
}
.chosen-container .chosen-results li em {
  font-style: normal;
  text-decoration: underline;
}
.chosen-container-multi .chosen-choices {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0 5px;
  width: 100%;
  height: auto;
  border: 1px solid #aaa;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #eee), color-stop(15%, #fff));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #eee), color-stop(15%, #fff));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #eee), color-stop(15%, #fff));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #eee), color-stop(15%, #fff));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #eee), color-stop(15%, #fff));
  background-image: -webkit-linear-gradient(#eee 1%, #fff 15%);
  background-image: -moz-linear-gradient(#eee 1%, #fff 15%);
  background-image: -o-linear-gradient(#eee 1%, #fff 15%);
  background-image: -ms-linear-gradient(#eee 1%, #fff 15%);
  background-image: linear-gradient(#eee 1%, #fff 15%);
  cursor: text;
}
.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none;
}
.chosen-container-multi .chosen-choices li.search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  margin: 1px 0;
  padding: 0;
  height: 25px;
  outline: 0;
  border: 0 !important;
  background: transparent !important;
  -webkit-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #999;
  font-size: 100%;
  line-height: normal;
  border-radius: 0;
  width: 25px;
}
.chosen-container-multi .chosen-choices li.search-choice {
  position: relative;
  margin: 3px 5px 3px 0;
  padding: 3px 20px 3px 5px;
  border: 1px solid #aaa;
  max-width: 100%;
  border-radius: 3px;
  background-color: #eee;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: -webkit-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-image: -moz-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-image: -o-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-image: -ms-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  -webkit-background-size: 100% 19px;
  -moz-background-size: 100% 19px;
  background-size: 100% 19px;
  background-repeat: repeat-x;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0,0,0,0.05);
  -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0,0,0,0.05);
  box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0,0,0,0.05);
  color: #333;
  line-height: 13px;
  cursor: default;
}
.chosen-container-multi .chosen-choices li.search-choice span {
  word-wrap: break-word;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  position: absolute;
  top: 4px;
  right: 3px;
  display: block;
  width: 12px;
  height: 12px;
  background: url("chosen-sprite.png") -42px 1px no-repeat;
  font-size: 1px;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
  background-position: -42px -10px;
}
.chosen-container-multi .chosen-choices li.search-choice-disabled {
  padding-right: 5px;
  border: 1px solid #ccc;
  background-color: #e4e4e4;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: -webkit-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-image: -moz-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-image: -o-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-image: -ms-linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  color: #666;
}
.chosen-container-multi .chosen-choices li.search-choice-focus {
  background: #d4d4d4;
}
.chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
  background-position: -42px -10px;
}
.chosen-container-multi .chosen-results {
  margin: 0;
  padding: 0;
}
.chosen-container-multi .chosen-drop .result-selected {
  display: list-item;
  color: #ccc;
  cursor: default;
}
.chosen-container-active .chosen-single {
  border-color: #b1b1b1;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  box-shadow: 0 5px 10px rgba(0,0,0,0.12);
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -18px 2px;
}
.chosen-container-active .chosen-choices {
  border: 1px solid #5897fb;
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.3);
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.3);
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
}
.chosen-container-active .chosen-choices li.search-field input[type="text"] {
  color: #222 !important;
}
.chosen-disabled {
  opacity: 0.5 !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)" !important;
  filter: alpha(opacity=50) !important;
  cursor: default;
}
.chosen-disabled .chosen-single {
  cursor: default;
}
.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}
.chosen-rtl {
  text-align: right;
}
.chosen-rtl .chosen-single {
  overflow: visible;
  padding: 0 8px 0 0;
}
.chosen-rtl .chosen-single span {
  margin-right: 0;
  margin-left: 26px;
  direction: rtl;
}
.chosen-rtl .chosen-single-with-deselect span {
  margin-left: 38px;
}
.chosen-rtl .chosen-single div {
  right: auto;
  left: 3px;
}
.chosen-rtl .chosen-single abbr {
  right: auto;
  left: 26px;
}
.chosen-rtl .chosen-choices li {
  float: right;
}
.chosen-rtl .chosen-choices li.search-field input[type="text"] {
  direction: rtl;
}
.chosen-rtl .chosen-choices li.search-choice {
  margin: 3px 5px 3px 0;
  padding: 3px 5px 3px 19px;
}
.chosen-rtl .chosen-choices li.search-choice .search-choice-close {
  right: auto;
  left: 4px;
}
.chosen-rtl.chosen-container-single .chosen-results {
  margin: 0 0 4px 4px;
  padding: 0 4px 0 0;
}
.chosen-rtl .chosen-results li.group-option {
  padding-right: 15px;
  padding-left: 0;
}
.chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
  border-right: none;
}
.chosen-rtl .chosen-search input[type="text"] {
  padding: 4px 5px 4px 20px;
  background: url("chosen-sprite.png") no-repeat -30px -20px;
  direction: rtl;
}
.chosen-rtl.chosen-container-single .chosen-single div b {
  background-position: 6px 2px;
}
.chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b {
  background-position: -12px 2px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: url("chosen-sprite@2x.png") !important;
    -webkit-background-size: 52px 37px !important;
    -moz-background-size: 52px 37px !important;
    background-size: 52px 37px !important;
    background-repeat: no-repeat !important;
  }
}
select.select7 {
  width: 100%;
  position: relative;
  color: #494949;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  background-color: #fff;
  padding: 11px 22px 11px 10px;
  cursor: pointer;
  height: 48px;
}
.select7 {
  position: relative;
  text-align: left;
  display: block;
  line-height: 1.428571429;
}
.select7_sm .select7__current,
.select7_sm .select7__option {
  padding-top: 9px;
  padding-bottom: 9px;
}
.select7_sm .select7__option {
  font-size: 14px;
}
.select7_sm .select7__current {
  height: 40px;
}
.select7_sm .select7__current-value {
  font-size: 14px;
}
.select7_sm .select7__drop {
  padding-top: 38px;
}
.select7,
.select7 * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.select7_native_dropdown .select7__current {
  cursor: default !important;
}
.select7_native_dropdown .select7__caret {
  border-color: #c9c9c9 transparent transparent !important;
}
.select7_dropdown {
  display: inline-block;
  margin: 0 -5px;
}
.select7_dropdown .select7__current {
  height: auto;
  padding: 0 15px 0 10px;
  border: 0 none;
  position: relative;
  cursor: pointer;
  background-color: transparent;
}
.select7_dropdown .select7__current:hover .select7__current-value {
  color: #f460a3;
}
.select7_dropdown .select7__current:hover .select7__caret {
  border-color: #f460a3 transparent transparent;
}
.select7_dropdown .select7__drop {
  z-index: 1000;
  top: 0;
  left: 0;
  min-width: 100%;
  padding: 48px 0 5px;
  margin-top: -6px;
  border-width: 1px;
  border-radius: 2px;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.select7_dropdown .select7__current-value {
  overflow: visible;
  display: inline-block;
  border-bottom: 1px dotted;
}
.select7_dropdown .select7__caret {
  position: static;
  margin-top: 0;
  border-color: #c9c9c9 transparent transparent;
}
.select7_dropdown .select7__option {
  padding: 11px 10px;
}
.select7_noopts .select7__caret {
  display: none;
}
.select7_noopts .select7__current {
  cursor: default;
}
.select7_noopts .select7__current:hover .select7__current-value {
  color: inherit;
}
.select7_noopts .select7__current-value {
  border-bottom-color: transparent;
}
.select7_position_right .select7__drop {
  left: auto;
  right: 0;
}
.select7_position_right .select7__option {
  text-align: right;
}
.select7__current {
  position: relative;
  color: #494949;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  background-color: #fff;
  padding: 11px 22px 11px 10px;
  cursor: pointer;
  height: 48px;
}
.select7__current-value {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select7__current:hover .select7__caret {
  border-color: #f460a3 transparent transparent;
}
.select7__drop {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  min-width: 100%;
  padding: 48px 0 5px;
  margin: 0;
  font-size: 17px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #b1b1b1;
  border-radius: 2px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.select7__drop-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 168px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.select7__caret {
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 9px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-width: 8px 7px 0 7px;
  border-style: solid;
  border-color: #c9c9c9 transparent transparent;
  -webkit-transition: border-color 0.1s linear;
  -moz-transition: border-color 0.1s linear;
  -o-transition: border-color 0.1s linear;
  -ms-transition: border-color 0.1s linear;
  transition: border-color 0.1s linear;
}
.select7__option,
.select7__optgroup {
  display: block;
  overflow: hidden;
  padding: 11px 10px;
  clear: both;
  text-decoration: none;
  font-weight: normal;
  color: #5b5b5b;
  white-space: nowrap;
}
.select7__option {
  cursor: pointer;
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  font-weight: normal;
}
.select7_readonly .select7__option {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}
.select7__option_disabled {
  background-color: #fbfbfb !important;
  color: #b6b6b6 !important;
  cursor: default !important;
}
.select7__option:hover {
  color: #494949;
  background-color: #f6f6f6;
}
.select7_readonly .select7__option:hover {
  color: inherit;
  background-color: inherit;
}
.select7__option_current:before {
  content: "✓ ";
  color: #f460a3;
}
.select7__optgroup {
  cursor: default;
  font-weight: bold;
}
.select7__optgroup:hover {
  background-color: #fdfdfd;
}
.select7__optgroup_collapse .select7__optgroup-label:after {
  content: " ↓";
}
.select7__optgroup_collapse .select7__optgroup-items {
  display: none;
}
.select7__optgroup_collapse_open .select7__optgroup-label:after {
  content: " ↑";
}
.select7__optgroup_collapse_open .select7__optgroup-items {
  display: block;
}
.select7__optgroup-items {
  padding: 0;
}
.select7__placeholder {
  color: #b6b6b6 !important;
}
.select7__icon {
  display: inline-block;
  height: 25px;
  margin-right: 5px;
  vertical-align: text-top;
  text-align: center;
  letter-spacing: 0;
  font-size: 0;
}
.select7__icon:after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}
.select7__icon > img {
  letter-spacing: normal;
  font-size: 12px;
  border: 0 none;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.select7_open .select7__current {
  z-index: 1001;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}
.select7_open .select7__current:hover .select7__current-value {
  border-color: transparent;
}
.select7_open .select7__current:hover .select7__caret {
  border-color: transparent transparent #f460a3;
}
.select7_open .select7__caret {
  border-color: transparent transparent #c9c9c9;
  border-width: 0 7px 8px 7px;
}
.select7_open .select7__drop {
  display: block;
}
.select7_open.select7_dropdown .select7__current {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select7_open.select7_dropdown .select7__current:hover .select7__caret {
  border-color: transparent transparent #f460a3;
}
.select7_open.select7_dropdown .select7__caret {
  border-color: transparent transparent #c9c9c9;
}
.service-card {
  padding: 60px 20px;
  border-radius: 8px;
  font-size: 20px;
  line-height: 1.3;
  background-color: #fff;
  border: 1px solid #e8e8e8;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .service-card {
    padding: 30px 20px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .service-card {
    padding: 25px 15px;
  }
}
@media (max-width: 767px) {
  .service-card {
    padding: 15px 10px;
  }
}
.service-card__image {
  height: 70px;
  margin-bottom: 30px;
  text-align: center;
}
.service-card__image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.service-card__image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 767px) {
  .service-card__image {
    margin-bottom: 10px;
  }
}
.service-card__title {
  font-weight: 500;
  color: #333;
}
@media (max-width: 999px) {
  .service-card__title {
    font-size: 16px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .service-card__title {
    font-size: 13px;
    font-weight: normal;
  }
}
.service-card__text {
  margin-top: 15px;
  color: #888b8d;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .service-card__text {
    font-size: 16px;
  }
}
@media (max-width: 999px) {
  .service-card__text {
    display: none;
  }
}
.settings-box {
  margin-bottom: 40px;
  padding: 12px 20px;
  -webkit-box-shadow: 0 12px 30px rgba(51,51,51,0.04);
  box-shadow: 0 12px 30px rgba(51,51,51,0.04);
  border: 1px solid rgba(165,165,165,0.45);
  border-radius: 8px;
  font-size: 17px;
  line-height: 1.58;
}
@media (min-width: 1000px) {
  .settings-box {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-align: stretch;
    -moz-box-align: stretch;
    -o-box-align: stretch;
    -ms-flex-align: stretch;
    -webkit-align-items: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .settings-box {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .settings-box {
    padding: 10px;
  }
}
.settings-box__item {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 10px;
}
@media (min-width: 1000px) {
  .settings-box__item {
    padding: 0 20px;
  }
}
@media (max-width: 767px) {
  .settings-box__item {
    padding: 0 5px;
  }
}
.settings-box__info {
  padding-top: 5px;
}
@media (min-width: 1000px) and (max-width: 1239px), (max-width: 767px) {
  .settings-box__info-value,
  .settings-box__info-label {
    font-size: 15px;
  }
}
.settings-box__manual {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding-top: 12px;
}
@media (max-width: 999px) {
  .settings-box__manual {
    padding-top: 20px;
  }
}
.settings-box__manual-text {
  padding: 5px 10px 0 0;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .settings-box__manual-text {
    -webkit-flex-basis: 195px;
    flex-basis: 195px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .settings-box__manual-text {
    font-size: 15px;
  }
}
.settings-box__manual-download {
  display: block;
  margin-bottom: -10px;
  width: 72px;
  height: 83px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: 0.2s -webkit-transform ease-in-out;
  -moz-transition: 0.2s -moz-transform ease-in-out;
  -o-transition: 0.2s -o-transform ease-in-out;
  -ms-transition: 0.2s -ms-transform ease-in-out;
  transition: 0.2s transform ease-in-out;
}
.settings-box__manual-download:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.settings-box__manual-download >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.settings-box__manual-download:hover {
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}
.settings-box__list-item:not(:last-child) {
  margin-bottom: 4px;
}
.settings-box__data-label {
  color: #888b8d;
}
.settings-box__data-value {
  font-weight: 500;
}
@media (min-width: 1000px) and (max-width: 1239px), (max-width: 767px) {
  .settings-box__data-label,
  .settings-box__data-value {
    font-size: 15px;
  }
}
.settings-panel {
  position: relative;
  margin-right: 10px;
}
.settings-panel.active .settings-panel__dropdown {
  display: block;
}
.settings-panel__sign {
  width: 48px;
  height: 48px;
  background-color: #fefefe;
  font-size: 25px;
  line-height: 46px;
  text-align: center;
  color: #4f5559;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out, 0.2s background-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out, 0.2s background-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out, 0.2s background-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out, 0.2s background-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out, 0.2s background-color ease-in-out, 0.2s box-shadow ease-in-out;
}
.settings-panel__dropdown {
  display: none;
  position: absolute;
  z-index: 2;
  top: 78px;
  right: -20px;
  width: 480px;
}
.settings-panel__dropdown:before,
.settings-panel__dropdown:after {
  content: "";
  position: absolute;
  right: 30px;
  width: 0;
  height: 0;
  border-width: 0 14px 21px;
  border-style: solid;
}
.settings-panel__dropdown:before {
  top: -21px;
  border-color: transparent transparent #d5d5d5;
}
.settings-panel__dropdown:after {
  top: -20px;
  border-color: transparent transparent #fff;
}
.settings-panel__dropdown-inner {
  padding: 30px 50px 35px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  -webkit-box-shadow: 0 24px 36px rgba(51,51,51,0.06);
  box-shadow: 0 24px 36px rgba(51,51,51,0.06);
}
.settings-panel__title {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
}
.settings-panel__buttons {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: 40px -5px 0;
}
.settings-panel__button {
  margin: 0 5px;
  width: 100%;
  white-space: nowrap;
  padding-left: 15px;
  padding-right: 15px;
}
.settings-panel__item {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.settings-panel__item-image {
  width: 48px;
  height: 48px;
  margin-right: 15px;
  text-align: center;
}
.settings-panel__item-image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.settings-panel__item-image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.settings-panel__item-content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.settings-panel__item-actions {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.settings-panel__item-row {
  margin-bottom: 3px;
  font-size: 17px;
  color: #434343;
}
.settings-panel__item-row:last-child {
  margin-bottom: 0;
}
.settings-panel__item-type {
  color: #888b8d;
}
.settings-panel__item-button {
  margin: 0 2px;
  padding: 14px 16px;
}
.share-panel {
  padding: 40px 0;
  background-color: #f7f7f7;
}
@media (max-width: 767px) {
  .share-panel {
    padding: 25px 0;
  }
}
@media (min-width: 768px) {
  .share-panel__inner {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
.share-panel__label {
  margin-right: 100px;
  font-size: 30px;
  font-weight: 500;
  color: #545454;
}
@media (max-width: 767px) {
  .share-panel__label {
    font-size: 18px;
    margin: 0 0 10px;
  }
}
@media (max-width: 767px) {
  .share-panel__content {
    zoom: 1;
  }
  .share-panel__content:before,
  .share-panel__content:after {
    content: "";
    display: table;
  }
  .share-panel__content:after {
    clear: both;
  }
}
.v1 .side-menu {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 6px rgba(115,115,115,0.33);
  box-shadow: 0 2px 6px rgba(115,115,115,0.33);
}
@media (max-width: 999px) {
  .v1 .side-menu {
    height: 51px;
    text-align: center;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
  .v1 .side-menu::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .side-menu {
    margin-bottom: 30px;
    padding: 0 15px;
    border-top: 1px solid #eaeaea;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
@media (max-width: 767px) {
  .v1 .side-menu {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .v1 .side-menu_mobile-simple {
    margin-bottom: 0;
  }
}
@media (min-width: 1000px) {
  .v1 .side-menu_simple {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .v1 .side-menu_simple .side-menu__linkchoice_widget_options {
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .v1 .side-menu_slim {
    margin-bottom: 0;
  }
}
@media (max-width: 999px) {
  .v1 .side-menu__item {
    display: inline-block;
    line-height: 1;
  }
}
.v1 .side-menu__link {
  display: block;
  padding: 17px 15px 17px 12px;
  font-size: 20px;
  color: #888b8d;
  text-decoration: none;
  border-left: 4px solid transparent;
  -webkit-transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  -moz-transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  -o-transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  -ms-transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
  transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;
}
@media (max-width: 999px) {
  .v1 .side-menu__link {
    position: relative;
    font-size: 15px;
    line-height: 1.1;
    border-left: 0;
    padding: 18px 12px 17px;
  }
  .v1 .side-menu__link:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 12px;
    right: 12px;
    height: 4px;
    -webkit-transition: background-color 0.2s ease-in-out;
    -moz-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
  }
}
.v1 .side-menu__link:hover {
  color: #333;
}
@media (max-width: 999px) {
  .v1 .side-menu__link:hover {
    background-color: transparent;
  }
  .v1 .side-menu__link:hover:after {
    background-color: #f460a3;
  }
}
.v1 .side-menu__link_active {
  background-color: #fcfcfc;
  border-left-color: #f460a3;
  color: #333;
}
@media (max-width: 999px) {
  .v1 .side-menu__link_active {
    background-color: transparent;
  }
  .v1 .side-menu__link_active:after {
    background-color: #f460a3;
  }
}
@media (max-width: 999px) {
  .v1 .side-menu__link_badge:after {
    bottom: 3px;
  }
}
@media (max-width: 767px) {
  .v1 .side-menu_level1 {
    display: none;
  }
}
.v1 .side-menu_level1__link {
  padding-left: 30px;
}
.v1 .side-menu__badge {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  padding: 3px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  font-size: 10px;
  color: #f460a3;
  font-weight: bold;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.11);
  box-shadow: 0 5px 10px rgba(0,0,0,0.11);
}
.v1 .active .side-menu__link {
  background-color: #fcfcfc;
  border-left-color: #f460a3;
  color: #333;
}
@media (max-width: 999px) {
  .v1 .active .side-menu__link {
    background-color: transparent;
  }
  .v1 .active .side-menu__link:after {
    background-color: #f460a3;
  }
}
.small-card {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.small-card__image {
  width: 48px;
  height: 48px;
  text-align: center;
  margin-right: 20px;
}
.small-card__image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.small-card__image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.small-card__text {
  font-size: 17px;
}
@media (max-width: 767px) {
  .small-card__text {
    font-size: 15px;
  }
}
.small-card__number {
  font-weight: bold;
}
.v1 .socials {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.v1 .socials__item {
  display: block;
  width: 48px;
  height: 48px;
  margin: 0 10px 10px 0;
  border: 1px solid rgba(255,255,255,0.08);
  border-radius: 2px;
  font-size: 16px;
  line-height: 45px;
  color: #a9a9a9;
  text-align: center;
  text-decoration: none;
  -webkit-transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .socials__item {
    width: 44px;
    height: 44px;
  }
}
@media (max-width: 767px) {
  .v1 .socials__item {
    width: 42px;
    height: 42px;
    margin: 0 6px 6px 0;
    line-height: 39px;
  }
}
.v1 .socials__item:hover {
  border-color: #f460a3;
  color: #f460a3;
}
.v1 .socials_colored .socials__item {
  border: 0 none;
  background-color: #fff;
}
.v1 .socials_colored .socials__item:hover {
  -webkit-box-shadow: 0 4px 18px rgba(0,0,0,0.05);
  box-shadow: 0 4px 18px rgba(0,0,0,0.05);
}
.v1 .socials_colored .socials__item_facebook {
  color: #3b5998;
}
.v1 .socials_colored .socials__item_gplus {
  color: #dc4e41;
}
.v1 .socials_colored .socials__item_twitter {
  color: #55acee;
}
.v1 .socials_colored .socials__item_pinterest {
  color: #c21a2d;
}
.v1 .socials_colored .socials__item_linkedin {
  color: #0077b5;
}
.v1 .socials_colored .socials__item_instagram {
  color: #2e5e86;
}
.specifications-modal__title {
  margin-bottom: 45px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 767px) {
  .specifications-modal__title {
    margin-bottom: 15px;
    font-size: 17px;
    text-align: left;
  }
}
.specifications-modal .btn {
  padding: 21px 20px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .specifications-modal .btn {
    padding: 15px;
    font-size: 15px;
  }
}
.specifications-modal .btn-link {
  margin-top: 3px;
  padding: 0 !important;
}
.state {
  padding: 5px 7px;
  border-radius: 5px;
  font-size: 15px;
  white-space: nowrap;
  font-weight: normal;
  display: inline-block;
}
.style-card {
  position: relative;
  display: block;
  width: 100%;
  padding: 50px 35px 70px;
  border: 1px solid #ededee;
  background-color: #fefefe;
  text-decoration: none;
  color: #434343;
  -webkit-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
}
@media (max-width: 767px) {
  .style-card {
    padding-top: 30px;
  }
}
.style-card:hover,
.style-card.active {
  border-color: #d5d5d5;
  background-color: #fff;
  -webkit-box-shadow: 0 4px 18px rgba(51,51,51,0.07);
  box-shadow: 0 4px 18px rgba(51,51,51,0.07);
}
.style-card:hover .style-card__button,
.style-card.active .style-card__button {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
}
.style-card__image {
  display: block;
  height: 65px;
  text-align: center;
  margin-bottom: 10px;
}
.style-card__image:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}
.style-card__image >img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
}
.style-card__label {
  display: block;
  font-size: 16px;
  line-height: 1.375;
  font-weight: 500;
  text-align: center;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .style-card__label {
    font-size: 14px;
  }
}
.style-card__button-wrap {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
}
.style-card__button {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  cursor: pointer;
  -webkit-transition: 0.2s opacity ease-in-out;
  -moz-transition: 0.2s opacity ease-in-out;
  -o-transition: 0.2s opacity ease-in-out;
  -ms-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}
.subscribe-box {
  white-space: nowrap;
}
.subscribe-box__label {
  display: inline-block;
  vertical-align: top;
  margin: 10px 20px 0 0;
  font-size: 20px;
}
@media (min-width: 768px) and (max-width: 1239px) {
  .subscribe-box__label {
    display: block;
    margin: 0 0 5px;
    font-size: 18px;
  }
}
.subscribe-box__form {
  position: relative;
}
@media (min-width: 1240px) {
  .subscribe-box__form {
    display: inline-block;
    vertical-align: top;
    width: 345px;
  }
}
.subscribe-box__field {
  padding: 9px 65px 9px 10px;
  font-size: 20px;
  color: #fff;
  background: none;
  border-color: #434343;
}
.subscribe-box__field::-webkit-input-placeholder {
  color: #515151;
}
.subscribe-box__field::-moz-placeholder {
  color: #515151;
}
.subscribe-box__field:-moz-placeholder {
  color: #515151;
}
.subscribe-box__field:-ms-input-placeholder {
  color: #515151;
}
.subscribe-box__button {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 48px;
  padding: 7px;
  font-size: 16px;
  font-weight: bold;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  text-transform: uppercase;
}
.support-modal__order {
  position: relative;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .support-modal__order {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
.support-modal__order-label {
  font-weight: 500;
}
@media (min-width: 768px) {
  .support-modal__order-label {
    padding-top: 8px;
    font-size: 20px;
    width: 40%;
  }
}
@media (max-width: 767px) {
  .support-modal__order-label {
    margin-bottom: 10px;
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .support-modal__order-info {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -o-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 60%;
  }
}
.support-modal__table {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .support-modal__table {
    margin-bottom: 20px;
  }
}
.support-modal__table-header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  border-radius: 4px 4px 0 0;
  border: 1px solid #f2f2f2;
  background-color: #fcfcfc;
  font-size: 17px;
  border-bottom: 0;
}
@media (max-width: 767px) {
  .support-modal__table-header {
    display: none;
  }
}
.support-modal__table-body {
  background-color: #fff;
  border: 1px solid #f2f2f2;
}
@media (max-width: 767px) {
  .support-modal__table-body {
    background-color: #fcfcfc;
    margin: 0 -15px;
  }
}
@media (min-width: 768px) {
  .support-modal__line {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
  }
}
@media (max-width: 767px) {
  .support-modal__line {
    padding: 15px;
  }
}
.support-modal__item {
  font-size: 14px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media (min-width: 768px) {
  .support-modal__item {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 25%;
    -ms-flex: 1 0 25%;
    flex: 1 0 25%;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid #f2f2f2;
  }
  .support-modal__item:last-child {
    border-right: 0 none;
  }
}
@media (max-width: 767px) {
  .support-modal__item {
    padding: 10px 0;
    line-height: 1.3;
  }
}
.support-modal__item-wrap {
  color: #333;
}
@media (min-width: 1000px) {
  .support-modal__item-wrap {
    padding: 18px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .support-modal__item-wrap {
    padding: 13px;
  }
}
@media (max-width: 767px) {
  .support-modal__item-wrap {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    font-size: 14px;
    text-align: right;
  }
}
.support-modal__col {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 25%;
  -ms-flex: 1 0 25%;
  flex: 1 0 25%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  color: #434343;
  padding: 18px;
  font-size: 14px;
  font-weight: 500;
}
@media (min-width: 768px) and (max-width: 999px) {
  .support-modal__col {
    padding: 13px;
  }
}
@media (min-width: 768px) {
  .support-modal__label-mob {
    display: none;
  }
}
@media (max-width: 767px) {
  .support-modal__label-mob {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    font-size: 14px;
    font-weight: bold;
  }
}
.v1 .switch-templates {
  position: absolute;
  bottom: -20px;
  right: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .switch-templates .tab {
  border-radius: 5px 5px 0 0;
  text-decoration: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 10px 20px;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  position: relative;
}
.v1 .switch-templates .tab .icon {
  padding-right: 20px;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
  color: #ddd;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
.v1 .switch-templates .tab.active {
  background-color: #f7f7f7;
}
.v1 .switch-templates .tab:not(.active) {
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
.v1 .switch-templates .tab:not(.active):hover {
  color: #f460a3;
}
.v1 .switch-templates .tab:not(.active):hover .icon,
.v1 .switch-templates .tab:not(.active):hover .secondary {
  color: #f460a3;
}
.v1 .switch-templates .tab h2.title,
.v1 .switch-templates .tab p.title {
  font-size: 16px;
  margin: 0;
  font-weight: bold;
}
.v1 .switch-templates .tab .secondary {
  color: #434343;
  font-size: 12px;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
}
.v1 .switch-templates .tab .tab-content-image {
  display: inline-block;
  position: absolute;
  width: 48px;
  height: 48px;
  margin-top: -48px;
  margin-left: -16px;
}
.v1 .switch-templates .tab .tab-content-image img {
  max-width: 100%;
  max-height: 100%;
}
@media (min-width: 768px) {
  .v1 .grid-with-scroll {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 70%;
  }
  .v1 .grid-with-scroll .tariff-container {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .v1 .grid-with-scroll.has-nav-step {
    padding-bottom: 75px;
  }
}
.v1 .display2cols .grid-with-scroll {
  width: 50%;
}
.v1 .tariff-list {
  width: 100%;
}
.v1 .tariff-list.only-one {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .tariff-list .download-price-grid {
  padding: 10px;
  text-align: center;
  float: right;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .tariff-list .download-price-grid .label {
  text-align: right;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-right: 15px;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
  font-size: 16px;
}
.v1 .tariff-list .download-price-grid a {
  display: block;
  padding-left: 15px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  font-size: 18px;
  color: #fff;
}
.v1 .tariff-list .download-price-grid .btn {
  min-width: 250px;
}
.v1 .tariff-list .download-price-grid .btn span.icon {
  margin-right: 10px;
}
.v1 .tariff-list .timeremaining {
  font-size: 12px;
  color: #888b8d;
}
.v1 .tariff-list .pink {
  color: #f460a3;
}
.v1 .tariff-list .hurryup {
  font-size: 12px;
}
.v1 .tariff-list table tr.highlight-hover {
  -webkit-transition: background-color 0.2s ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: background-color 0.2s ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: background-color 0.2s ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
}
.v1 .tariff-list table tr.highlight-hover:hover {
  background-color: #ffbfe9;
}
.v1 .tariff-list table tr.highlight-hover:hover td {
  background-color: #ffbfe9;
}
.v1 .tariff-list table tr.highlight-hover:hover td p.original-price {
  color: #f460a3;
}
.v1 .tariff-list th {
  padding: 10px 20px !important;
}
.v1 .tariff-list th.quantity {
  padding: 10px !important;
  width: 150px;
}
.v1 .tariff-list th,
.v1 .tariff-list td {
  text-align: center;
}
.v1 .tariff-list td {
  padding: 0;
}
@media (max-width: 767px) {
  .v1 .tariff-list__wrap {
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 20px;
    margin-top: 15px;
  }
}
.v1 .tariff-list__wrap .tariff-list__link {
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
}
.v1 .tariff-list__title {
  margin-bottom: 8px;
  font-size: 16px;
  color: #636363;
}
.v1 .tariff-list__text {
  font-size: 12px;
  font-weight: normal;
  color: #888b8d;
}
.v1 .tariff-list__panel {
  padding: 15px 15px 12px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  zoom: 1;
}
.v1 .tariff-list__panel:before,
.v1 .tariff-list__panel:after {
  content: "";
  display: table;
}
.v1 .tariff-list__panel:after {
  clear: both;
}
.v1 .tariff-list__panel-label {
  margin-top: 3px;
  float: left;
  font-size: 18px;
  font-weight: 500;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
}
@media (max-width: 767px) {
  .v1 .tariff-list__panel-label {
    font-size: 15px;
  }
}
.v1 .tariff-list__panel-select {
  position: relative;
  float: right;
}
.v1 .tariff-list__panel-select .select7__current {
  border: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  height: 30px;
}
.v1 .tariff-list__panel-select .select7__current-value {
  border-bottom: 0;
  font-size: 15px;
  font-weight: bold;
}
.v1 .tariff-list__panel-select .select7_open .select7__caret {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #b8b9bb;
}
.v1 .tariff-list__panel-select .select7__caret {
  right: 0;
  border-width: 5px 5px 0 5px;
  border-color: #b8b9bb transparent transparent;
}
.v1 .tariff-list__delivery {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  padding: 10px 15px;
}
.v1 .tariff-list__delivery-item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding-bottom: 7px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 5px;
}
.v1 .tariff-list__delivery-item:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.v1 .tariff-list__delivery-description {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.v1 .tariff-list__delivery-price {
  font-size: 18px;
  font-weight: bold;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
}
@media (max-width: 767px) {
  .v1 .tariff-list__delivery-price {
    font-size: 15px;
  }
}
.v1 .tariff-list__delivery-label {
  font-size: 18px;
  color: #7e8284;
}
@media (max-width: 767px) {
  .v1 .tariff-list__delivery-label {
    font-size: 15px;
  }
}
.v1 .tariff-list__delivery-date {
  font-size: 14px;
}
@media (max-width: 767px) {
  .v1 .tariff-list__delivery-date {
    font-size: 12px;
  }
}
.v1 .tariff-list__cell {
  position: relative;
  margin: -1px;
  height: 70px;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-transition: background-color 0.2s ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -o-transition: background-color 0.2s ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  transition: background-color 0.2s ease-in-out, 0.2s border-color ease-in-out, 0.2s box-shadow ease-in-out;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media (min-width: 1000px) and (max-width: 1239px) {
  .v1 .tariff-list__cell {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.v1 .tariff-list__cell:hover {
  border-color: #d5d5d5;
  -webkit-box-shadow: 0 4px 18px rgba(51,51,51,0.07);
  box-shadow: 0 4px 18px rgba(51,51,51,0.07);
  margin-left: auto;
}
.v1 .tariff-list__cell:hover .tariff-list__cell-button {
  display: inline-block;
}
.v1 .tariff-list__cell:hover .tariff-list__cell-value {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  margin-left: auto;
}
.v1 .tariff-list__cell-value {
  display: inline-block;
  vertical-align: middle;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 16px;
  color: #333;
}
.v1 .tariff-list__cell-value p {
  margin: 0;
  padding: 0;
}
.v1 .tariff-list__cell-value p.original-price {
  color: #bfbfbf;
  font-size: 14px;
  text-decoration: line-through;
}
.v1 .tariff-list__cell-button {
  display: none;
  padding: 2px 0 0;
  width: 48px;
  height: 36px;
  margin-left: 5px;
  font-size: 21px;
  margin-right: auto;
}
.v1 .tariff-list__link {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #f460a3;
  text-decoration: none;
}
.v1 .tariff-list__link:hover {
  color: #434343;
}
.v1 .tariff-list__link-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.v1 .tariff-list__tooltip {
  position: relative;
  padding-top: 12px;
  height: 70px;
  margin: -1px;
}
.v1 .tariff-list__tooltip-value {
  min-width: 120px;
}
.v1 .tariff-list .tooltip-inner {
  width: 315px;
}
@media (min-width: 768px) {
  .v1 .grid-with-scroll .display2cols {
    width: 50%;
  }
}
#technical-specs .modal-content {
  padding: 20px 50px 50px;
}
@media (max-width: 767px) {
  #technical-specs .modal-content {
    padding: 20px;
  }
}
.technical-specs a.close {
  float: right;
}
.technical-specs select.pivot {
  margin-bottom: 20px;
}
.technical-specs p.title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.technical-specs p.title span.icon-crop {
  color: #f460a3;
  margin-right: 10px;
}
.technical-specs .content .wrapper {
  width: 55%;
  float: left;
  font-size: 12px;
}
@media (max-width: 767px) {
  .technical-specs .content .wrapper {
    float: none;
    width: auto;
  }
}
.technical-specs .content .wrapper th {
  padding: 10px;
  text-align: center;
}
@media (max-width: 767px) {
  .technical-specs .content .wrapper th {
    font-size: 14px;
  }
}
.technical-specs .content .wrapper td {
  padding: 5px;
  text-align: center;
}
@media (max-width: 767px) {
  .technical-specs .content .wrapper td {
    font-size: 14px;
  }
}
.technical-specs .content .wrapper span.far {
  color: #f460a3;
}
.technical-specs .content .description {
  width: 45%;
  padding-left: 15px;
  float: left;
  font-size: 14px;
}
@media (max-width: 767px) {
  .technical-specs .content .description {
    float: none;
    width: auto;
    padding-left: 0;
  }
}
.technical-specs .content .clear {
  clear: both;
}
#technical-specs-with-thickness .modal-content {
  padding: 20px 50px 50px;
}
@media (max-width: 767px) {
  #technical-specs-with-thickness .modal-content {
    padding: 20px;
  }
}
.technical-specs-with-thickness .clear {
  clear: both;
}
.technical-specs-with-thickness a.close {
  float: right;
}
.technical-specs-with-thickness select.pivot {
  margin-bottom: 20px;
}
.technical-specs-with-thickness p.title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.technical-specs-with-thickness p.title span.icon-crop {
  color: #f460a3;
  margin-right: 10px;
}
.technical-specs-with-thickness .content {
  font-size: 14px;
}
.technical-specs-with-thickness .content .list {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.technical-specs-with-thickness .content .list .item {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 0 50%;
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .technical-specs-with-thickness .content .list .item {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
  }
}
.technical-specs-with-thickness .content .list .item .item-content {
  float: left;
}
.technical-specs-with-thickness .content .list .item .image {
  float: left;
  margin-right: 5px;
  width: 105px;
  height: 85px;
  text-align: center;
}
@media (min-width: 768px) {
  .technical-specs-with-thickness .content .list .item .image {
    margin-right: 20px;
  }
}
.technical-specs-with-thickness .content .list .item .title {
  text-align: left;
  color: #3fa9f5;
  margin-bottom: 0;
}
.technical-specs-with-thickness .content .list .item .value {
  color: #f460a3;
  font-weight: bold;
}
.technical-specs-with-thickness .content .list .item ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
.v1 .thumbnails {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.v1 .thumbnails .thumbnail {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
}
.v1 .thumbnails .thumbnail .thumbnail-legend {
  color: #7c7e7e;
}
.v1 .thumbnails .thumbnail img {
  max-width: 240px;
  max-height: 160px;
}
.v1 .tiles-list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 -8px;
}
.v1 .tiles-list__item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-flex-basis: 33.333%;
  flex-basis: 33.333%;
  padding: 0 8px;
  margin-bottom: 16px;
}
@media (max-width: 999px) {
  .v1 .tiles-list__item {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
}
.v1 .tiles-section {
  padding: 50px 0 20px;
  background-color: #f7f7f7;
}
@media (max-width: 767px) {
  .v1 .tiles-section {
    padding: 20px 0;
  }
}
.v1 .tiles-section__title {
  margin: 0 0 50px;
  font-size: 36px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .v1 .tiles-section__title {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
}
.v1 .tiles-section__list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 30px;
  padding: 0;
  list-style: none;
}
@media (max-width: 767px) {
  .v1 .tiles-section__list {
    margin: 0;
  }
}
.v1 .tiles-section__list-item {
  width: 33.333%;
  padding: 0 10px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .v1 .tiles-section__list-item {
    margin-bottom: 10px;
  }
}
.v1 .tiles-section__item {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  height: 110px;
  text-decoration: none;
}
@media (max-width: 767px) {
  .v1 .tiles-section__item {
    height: 60px;
  }
}
.v1 .tiles-section__item img {
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 767px) {
  .v1 .tooltip.tooltip-step {
    display: none;
  }
}
.v1 .tooltip.tooltip-tarif-header {
  font-weight: normal;
}
@media (min-width: 1000px) {
  .v1 .top-list {
    padding: 55px 0;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .top-list {
    padding: 35px 0;
  }
}
@media (max-width: 767px) {
  .v1 .top-list {
    padding: 20px 0;
  }
}
.v1 .top-list__title {
  margin: 0 0 35px;
  font-size: 36px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .top-list__title {
    font-size: 34px;
  }
}
@media (max-width: 767px) {
  .v1 .top-list__title {
    margin: 0 0 15px;
    font-size: 17px;
  }
}
.v1 .top-list__items {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -15px;
  padding: 0;
}
.v1 .top-list__item {
  -webkit-flex-basis: 20%;
  flex-basis: 20%;
  padding: 0 15px;
  margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .v1 .top-list__item {
    -webkit-flex-basis: 25%;
    flex-basis: 25%;
  }
}
@media (max-width: 767px) {
  .v1 .top-list__item {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
}
#page-taxon .v1 .top-list__item.item-advice {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
}
@media (max-width: 999px) {
  #page-taxon .v1 .top-list__item.item-advice {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
}
#page-taxon .v1 .top-list__item {
  -webkit-flex-basis: 25%;
  flex-basis: 25%;
}
@media (max-width: 767px) {
  #page-taxon .v1 .top-list__item {
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
}
.v1 ul.tracking-list {
  list-style-position: inside;
  list-style-type: none;
  margin: 0;
  font-size: 10px;
  padding: 0;
}
.v1 ul.tracking-list li {
  text-align: left;
  list-style-type: none;
  font-size: 10px;
}
.v1 ul.tracking-list li a {
  text-decoration: none;
}
.upload-container-v2 .upload__collapse-item .upload__collapse-item_header .radio-icon svg .focus {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.upload-container-v2 .upload__collapse-item.active .upload__collapse-item_header .radio-icon svg .focus {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.upload-container-v2 .upload__collapse-item .upload__collapse-item_header {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.upload-container-v2 .upload__collapse-item .upload__collapse-item_header .radio-icon {
  position: absolute;
  left: 0;
  top: -2px;
  width: 15px;
}
.upload-container-v2 .upload__collapse-item .upload__collapse-item_header .title {
  display: inline-block;
  margin: 0 0 0 24px;
  font-size: 18px;
}
.upload-container-v2 .upload__collapse-item .upload__collapse-item_header .specs {
  margin-left: 16px;
  font-size: 12px;
  color: #f460a3;
}
.upload-container-v2 .upload__collapse-item .upload__collapse-item_header .specs a {
  text-decoration: none;
  color: #f460a3;
}
.upload-container-v2 .upload__collapse-item .upload__collapse-item_content {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.upload-container-v2 .upload__collapse-item .upload__collapse-item_content .upload__collapse-item_content-divider {
  width: 1px;
  background-color: #e8e8e8;
  margin: 4px 0 4px 7px;
}
.upload-container-v2 .upload__collapse-item .upload__collapse-item_content .upload__collapse-item_content-container {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  margin: 15px;
}
.upload-container-v2 .upload__collapse-item .upload__collapse-item_content .title {
  display: inline-block;
}
.upload-container-v2 .upload__collapse-item .upload__collapse-item_content .title::first-letter {
  text-transform: capitalize;
}
.upload-area {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 10px 16px 10px 10px;
  min-height: 107px;
  overflow: hidden;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  -webkit-transition: 0.2s border-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out;
  transition: 0.2s border-color ease-in-out;
}
.upload-area:hover {
  border-color: #f460a3;
}
.upload-area_empty {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.upload-area_empty .upload-area__icon {
  margin: 20px 0 0;
  float: none;
}
.upload-area__icon {
  float: left;
  margin: 10px 0 0 -5px;
  width: 72px;
}
.upload-area__content {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  width: 0;
  overflow: hidden;
  padding-top: 15px;
}
.upload-area__title {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.upload-area__label {
  margin-bottom: 5px;
  padding-right: 30px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 1.1;
  color: #888b8d;
}
.upload-area__progress {
  position: relative;
}
.upload-area__progress-bar {
  position: relative;
  height: 3px;
  background-color: #f3f3f3;
}
.upload-area__progress-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  background-color: #03a9f4;
  -webkit-transition: 0.1s width ease-in-out;
  -moz-transition: 0.1s width ease-in-out;
  -o-transition: 0.1s width ease-in-out;
  -ms-transition: 0.1s width ease-in-out;
  transition: 0.1s width ease-in-out;
}
.upload-area__progress-cancel {
  position: absolute;
  right: -4px;
  bottom: 100%;
  margin-bottom: 2px;
  width: 21px;
  height: 21px;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  cursor: pointer;
}
.upload-area__progress-cancel:hover:before,
.upload-area__progress-cancel:hover:after {
  background-color: #f460a3;
}
.upload-area__progress-cancel:before,
.upload-area__progress-cancel:after {
  content: "";
  position: absolute;
  background-color: #a6a6a6;
  -webkit-transition: 0.2s background-color ease-in-out;
  -moz-transition: 0.2s background-color ease-in-out;
  -o-transition: 0.2s background-color ease-in-out;
  -ms-transition: 0.2s background-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
}
.upload-area__progress-cancel:before {
  width: 15px;
  height: 1px;
  margin: 10px 0 0 3px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.upload-area__progress-cancel:after {
  width: 1px;
  height: 15px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 3px 0 0 10px;
}
.upload-file-info__item {
  font-size: 16px;
  margin-bottom: 3px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.upload-file-info__item .preview-info-tooltip {
  font-size: 100%;
  line-height: 17px;
}
.upload-form-file-handler {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
@media (max-width: 767px) {
  .upload-form-file-handler {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.upload-box__order {
  margin-bottom: 25px;
}
.upload-box__title {
  margin: 0 0 20px;
  font-size: 36px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .upload-box__title {
    font-size: 18px;
  }
}
.upload-box__content {
  margin-bottom: 50px;
}
.upload-box__content-title {
  margin-bottom: 5px;
  font-size: 17px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .upload-box__content-title {
    margin-bottom: 8px;
  }
}
.upload-box__content-text {
  font-size: 17px;
  line-height: 1.35;
  color: #888b8d;
}
@media (max-width: 767px) {
  .upload-box__content-text {
    font-size: 14px;
    line-height: 1.7;
  }
}
@media (max-width: 767px) {
  .upload-box__file {
    margin-bottom: 20px;
  }
  .upload-box__file .upload-file {
    width: 100%;
  }
}
.upload-box__form-title {
  margin-bottom: 25px;
  font-size: 17px;
  font-weight: 500;
}
.upload-box__form-message {
  height: 120px !important;
  resize: none;
}
.upload-box__thumbnails {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 999px) {
  .upload-box__thumbnails {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.upload-error {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.6em;
  width: 100%;
  padding-left: 6px;
  padding-top: 1px;
  padding-bottom: 0;
  background-color: #f6a8a8;
  color: #730303;
}
.upload-error.has-error {
  display: block;
}
.user-panel {
  position: relative;
}
.user-panel.active .user-panel__dropdown {
  display: block;
}
.user-panel.active .user-panel__label:after {
  border-top-color: #434343;
}
.user-panel__login {
  display: block;
  margin: 0 35px;
  font-size: 20px;
  font-weight: 500;
  color: #434343;
  text-decoration: none;
}
.user-panel__login:hover {
  color: #f460a3;
}
.user-panel__label {
  text-align: center;
  position: relative;
  padding-right: 22px;
  font-size: 20px;
  font-weight: 500;
  -webkit-transition: 0.2s color ease-in-out;
  -moz-transition: 0.2s color ease-in-out;
  -o-transition: 0.2s color ease-in-out;
  -ms-transition: 0.2s color ease-in-out;
  transition: 0.2s color ease-in-out;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.user-panel__label-icon {
  text-align: center;
}
.user-panel__label-text {
  font-size: 14px;
}
.user-panel__label:hover:after {
  border-top-color: #434343;
}
.user-panel__label.loggued:after {
  top: 20px;
  content: "";
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 7px 0;
  border-color: #c9c9c9 transparent transparent;
  -webkit-transition: 0.2s border-color ease-in-out;
  -moz-transition: 0.2s border-color ease-in-out;
  -o-transition: 0.2s border-color ease-in-out;
  -ms-transition: 0.2s border-color ease-in-out;
  transition: 0.2s border-color ease-in-out;
}
.user-panel__label .text-secondary {
  font-size: 80%;
  text-align: center;
  line-height: 75%;
}
.user-panel__label p {
  margin: 0;
}
.user-panel__label .item {
  font-size: inherit;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.user-panel__dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: -7px;
  margin-top: 29px;
  min-width: 250px;
  max-width: 500px;
  -webkit-box-shadow: 0 24px 36px rgba(51,51,51,0.06);
  box-shadow: 0 24px 36px rgba(51,51,51,0.06);
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
}
.user-panel__dropdown-list {
  margin: 0;
  padding: 0 10px;
  list-style: none;
}
.user-panel__dropdown-item {
  border-bottom: 1px solid #f6f6f6;
}
.user-panel__dropdown-item:last-child {
  border-bottom: 0 none;
}
.user-panel__link {
  display: block;
  padding: 17px 30px;
  font-size: 17px;
  font-weight: 500;
  color: #434343;
  text-decoration: none;
}
.user-panel__link:hover {
  color: #f460a3;
}
.user-panel__link_grey {
  font-weight: normal;
  color: #c6c6c6;
}
.user-panel .item {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #434343;
  text-decoration: none;
}
.user-panel .item:hover {
  color: #f460a3;
}
.upload-file__wrap {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 20px 10px 20px 20px;
  width: 100%;
  height: 100%;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: 0.2s border-color, ease-in-out ease-in-out;
  -moz-transition: 0.2s border-color, ease-in-out ease-in-out;
  -o-transition: 0.2s border-color, ease-in-out ease-in-out;
  -ms-transition: 0.2s border-color, ease-in-out ease-in-out;
  transition: 0.2s border-color, ease-in-out ease-in-out;
}
.upload-file__wrap.active {
  border-color: #f460a3;
}
.upload-file__wrap.active:before,
.upload-file__wrap.active:after {
  background-color: #f460a3;
}
.upload-file__wrap:hover {
  border-color: #f460a3;
}
.upload-file__wrap:hover:before,
.upload-file__wrap:hover:after {
  background-color: #f460a3;
}
.upload-file__wrap:before {
  width: 2px;
  height: 28px;
}
.upload-file__wrap:after {
  width: 28px;
  height: 2px;
}
.upload-file__icon-box {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.upload-file__icon-box .icon {
  font-size: 30px;
  display: inline-block;
  vertical-align: middle;
  color: #f460a3;
}
.upload-file__name {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.upload-file_small {
  width: 120px;
  height: 120px;
}
.upload-file.active .upload-file__wrap:before,
.upload-file.active .upload-file__wrap:after {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.ytb-img {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
  max-width: 100%;
}
.ytb-img .btn-play {
  width: 68px;
  top: 50%;
  left: 50%;
  height: 48px;
  margin-left: -34px;
  margin-top: -24px;
  border-radius: 50%/10%;
  color: #fff;
  padding: 0;
  position: absolute;
  text-align: center;
  -webkit-transition: all 150ms ease-out;
  -moz-transition: all 150ms ease-out;
  -o-transition: all 150ms ease-out;
  -ms-transition: all 150ms ease-out;
  transition: all 150ms ease-out;
}
.ytb-img .btn-play-bg {
  -webkit-transition: fill 0.1s cubic-bezier(0.4, 0, 1, 1), fill-opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
  -moz-transition: fill 0.1s cubic-bezier(0.4, 0, 1, 1), fill-opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
  -o-transition: fill 0.1s cubic-bezier(0.4, 0, 1, 1), fill-opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
  -ms-transition: fill 0.1s cubic-bezier(0.4, 0, 1, 1), fill-opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
  transition: fill 0.1s cubic-bezier(0.4, 0, 1, 1), fill-opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
  fill: #212121;
  fill-opacity: 0.8;
}
.ytb-img:hover .btn-play-bg {
  fill: #f00;
  fill-opacity: 1;
}
.ytb-img:not(.deferred) .btn-play {
  display: none;
}
.alert {
  padding: 15px;
}
.alert.error {
  border: 1px solid #d2461c;
  background-color: rgba(210,70,28,0.12);
  color: #d2461c;
}
@-moz-keyframes placeholderShimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}
@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}
@-o-keyframes placeholderShimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}
@-ms-keyframes placeholderShimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}
@keyframes placeholderShimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}
@-moz-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes letterAnim {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    font-size: 16px;
    color: #494949;
    font-weight: normal;
  }

  50% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    transform: rotate(720deg);
    color: #f460a3;
    font-weight: bold;
  }

  100% {
    -webkit-transform: rotate(1440deg);
    -moz-transform: rotate(1440deg);
    -o-transform: rotate(1440deg);
    -ms-transform: rotate(1440deg);
    transform: rotate(1440deg);
    font-size: 16px;
    color: #494949;
    font-weight: normal;
  }
}
@-webkit-keyframes letterAnim {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    font-size: 16px;
    color: #494949;
    font-weight: normal;
  }

  50% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    transform: rotate(720deg);
    color: #f460a3;
    font-weight: bold;
  }

  100% {
    -webkit-transform: rotate(1440deg);
    -moz-transform: rotate(1440deg);
    -o-transform: rotate(1440deg);
    -ms-transform: rotate(1440deg);
    transform: rotate(1440deg);
    font-size: 16px;
    color: #494949;
    font-weight: normal;
  }
}
@-o-keyframes letterAnim {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    font-size: 16px;
    color: #494949;
    font-weight: normal;
  }

  50% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    transform: rotate(720deg);
    color: #f460a3;
    font-weight: bold;
  }

  100% {
    -webkit-transform: rotate(1440deg);
    -moz-transform: rotate(1440deg);
    -o-transform: rotate(1440deg);
    -ms-transform: rotate(1440deg);
    transform: rotate(1440deg);
    font-size: 16px;
    color: #494949;
    font-weight: normal;
  }
}
@-ms-keyframes letterAnim {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    font-size: 16px;
    color: #494949;
    font-weight: normal;
  }

  50% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    transform: rotate(720deg);
    color: #f460a3;
    font-weight: bold;
  }

  100% {
    -webkit-transform: rotate(1440deg);
    -moz-transform: rotate(1440deg);
    -o-transform: rotate(1440deg);
    -ms-transform: rotate(1440deg);
    transform: rotate(1440deg);
    font-size: 16px;
    color: #494949;
    font-weight: normal;
  }
}
@keyframes letterAnim {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    font-size: 16px;
    color: #494949;
    font-weight: normal;
  }

  50% {
    -webkit-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -o-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    transform: rotate(720deg);
    color: #f460a3;
    font-weight: bold;
  }

  100% {
    -webkit-transform: rotate(1440deg);
    -moz-transform: rotate(1440deg);
    -o-transform: rotate(1440deg);
    -ms-transform: rotate(1440deg);
    transform: rotate(1440deg);
    font-size: 16px;
    color: #494949;
    font-weight: normal;
  }
}
